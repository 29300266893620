import { FC } from 'react';
import { IconProps } from '.';

export const IconPaperPlane: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.98975 16.7054C5.98975 16.8146 6.04964 16.9148 6.14487 16.9659C6.24004 17.0169 6.35574 17.0103 6.44469 16.9486L8.44795 15.5631L5.98978 14.3762V16.7054H5.98975Z"
        fill="currentColor"
      />
      <path
        d="M17.9065 1.07827C17.8515 1.02689 17.7804 1 17.7087 1C17.6654 1 17.6222 1.00972 17.5814 1.03006L0.287426 9.62326C0.110664 9.71126 -0.00119043 9.89411 9.56008e-06 10.0941C0.00120955 10.2939 0.114954 10.4755 0.29299 10.5615L4.85086 12.7615L13.9643 5.1738L5.98013 13.3065L11.6581 16.047C11.7278 16.0809 11.804 16.0977 11.8793 16.0977C11.9436 16.0977 12.0073 16.0859 12.0679 16.0617C12.2002 16.0088 12.3053 15.9027 12.3577 15.7687L17.9794 1.40294C18.0239 1.28989 17.9949 1.16064 17.9065 1.07827Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { useRef, useEffect, useState } from 'react';

export const usePortal = (id: string): Element | null => {
  const rootElRef = useRef<HTMLDivElement | null>(null);
  const [rootElSet, setRootElSet] = useState(false); // Necessary to detect when rootElRef will be initialized

  const createRootEl = (id: string) => {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    return rootContainer;
  };

  const getRootEl = () => {
    return rootElRef?.current;
  };

  useEffect(() => {
    rootElRef.current = document.createElement('div'); // Needs to initialize inside first render because Next.js can break when using document outside useEffect
    setRootElSet(true);
  }, []);

  useEffect(() => {
    if (!rootElSet) return;

    const existingTarget = document.getElementById(id);
    const targetEl = existingTarget || createRootEl(id);

    if (!existingTarget && document?.body?.lastElementChild) {
      document.body.insertBefore(targetEl, document?.body?.lastElementChild?.nextElementSibling);
    }

    if (rootElRef.current) targetEl.appendChild(rootElRef.current);

    return () => {
      rootElRef.current?.remove();
      if (!targetEl.childElementCount) targetEl.remove();
    };
  }, [id, rootElSet]);

  return getRootEl();
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconPngFile: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 22.3304V1.66957C6.75 0.74749 7.42895 0 8.26648 0H17.1758L24 7.51304V22.3304C24 23.2525 23.321 24 22.4835 24H8.26648C7.42895 24 6.75 23.2525 6.75 22.3304Z"
        fill="#CAD6D9"
      />
      <path
        d="M17.25 5.83333V0L24 7.5H18.75C17.9216 7.5 17.25 6.75381 17.25 5.83333Z"
        fill="#92AAB1"
      />
      <path
        d="M0 11.1667C0 10.5223 0.811484 10 1.8125 10H19.9375C20.9385 10 21.75 10.5223 21.75 11.1667V22.8333C21.75 23.4777 20.9385 24 19.9375 24H1.8125C0.811484 24 0 23.4777 0 22.8333V11.1667Z"
        fill="#005FAD"
      />
      <path
        d="M17.5332 18.1133V16.7866H20.3423V19.9233C20.0594 20.2563 19.6584 20.5428 19.1392 20.7827C18.62 21.0226 18.099 21.1426 17.5762 21.1426C16.9388 21.1426 16.3766 20.9868 15.8896 20.6753C15.4027 20.3602 15.016 19.8911 14.7295 19.2681C14.4466 18.6414 14.3052 17.9056 14.3052 17.0605C14.3052 16.194 14.4484 15.4528 14.7349 14.8369C15.0249 14.221 15.3991 13.7609 15.8574 13.4565C16.3193 13.1522 16.8708 13 17.5117 13C18.2816 13 18.8975 13.1969 19.3594 13.5908C19.8249 13.9811 20.1239 14.5558 20.2563 15.3149L18.9673 15.6104C18.8706 15.2057 18.6934 14.896 18.4355 14.6812C18.1777 14.4663 17.8698 14.3589 17.5117 14.3589C16.9567 14.3589 16.5073 14.5755 16.1636 15.0088C15.8198 15.4385 15.6479 16.0955 15.6479 16.98C15.6479 17.9289 15.8341 18.6504 16.2065 19.1445C16.5252 19.5706 16.9567 19.7837 17.501 19.7837C17.7552 19.7837 18.0184 19.7246 18.2905 19.6064C18.5662 19.4847 18.8115 19.32 19.0264 19.1123V18.1133H17.5332Z"
        fill="white"
      />
      <path
        d="M8.03174 21.0078V13.1338H9.29932L11.9419 18.3921V13.1338H13.1558V21.0078H11.8452L9.24561 15.873V21.0078H8.03174Z"
        fill="white"
      />
      <path
        d="M2 21.0078V13.1338H4.09473C4.87533 13.1338 5.38558 13.1714 5.62549 13.2466C6.00863 13.3683 6.32373 13.6279 6.5708 14.0254C6.82145 14.4229 6.94678 14.9349 6.94678 15.5615C6.94678 16.1309 6.83936 16.6089 6.62451 16.9956C6.40967 17.3787 6.14111 17.6491 5.81885 17.8066C5.49658 17.9606 4.94157 18.0376 4.15381 18.0376H3.2998V21.0078H2ZM3.2998 14.4658V16.7002H4.01953C4.50293 16.7002 4.83057 16.6662 5.00244 16.5981C5.1779 16.5301 5.32113 16.4084 5.43213 16.2329C5.54313 16.0539 5.59863 15.8354 5.59863 15.5776C5.59863 15.3162 5.54134 15.096 5.42676 14.917C5.31217 14.738 5.17074 14.618 5.00244 14.5571C4.83415 14.4963 4.47786 14.4658 3.93359 14.4658H3.2998Z"
        fill="white"
      />
      <path d="M9.33286 1H8V2.33286H9.33286V1Z" fill="#005FAD" />
      <path d="M10.6664 2.33301H9.3335V3.66586H10.6664V2.33301Z" fill="#005FAD" />
      <path d="M11.9994 1H10.6665V2.33286H11.9994V1Z" fill="#005FAD" />
      <path d="M13.3329 2.33301H12V3.66586H13.3329V2.33301Z" fill="#005FAD" />
      <path d="M14.6664 1H13.3335V2.33286H14.6664V1Z" fill="#005FAD" />
      <path d="M15.9998 2.33301H14.667V3.66586H15.9998V2.33301Z" fill="#005FAD" />
      <path d="M9.33286 3.66406H8V4.99692H9.33286V3.66406Z" fill="#005FAD" />
      <path d="M10.6664 4.99609H9.3335V6.32895H10.6664V4.99609Z" fill="#005FAD" />
      <path d="M11.9994 3.66406H10.6665V4.99692H11.9994V3.66406Z" fill="#005FAD" />
      <path d="M13.3329 4.99609H12V6.32895H13.3329V4.99609Z" fill="#005FAD" />
      <path d="M14.6664 3.66406H13.3335V4.99692H14.6664V3.66406Z" fill="#005FAD" />
      <path d="M15.9998 4.99609H14.667V6.32895H15.9998V4.99609Z" fill="#005FAD" />
      <path d="M9.33286 6.34082H8V7.67368H9.33286V6.34082Z" fill="#005FAD" />
      <path d="M10.6664 7.67383H9.3335V9.00668H10.6664V7.67383Z" fill="#005FAD" />
      <path d="M11.9994 6.34082H10.6665V7.67368H11.9994V6.34082Z" fill="#005FAD" />
      <path d="M13.3329 7.67383H12V9.00668H13.3329V7.67383Z" fill="#005FAD" />
      <path d="M14.6664 6.34082H13.3335V7.67368H14.6664V6.34082Z" fill="#005FAD" />
      <path d="M15.9998 7.67383H14.667V9.00668H15.9998V7.67383Z" fill="#005FAD" />
    </svg>
  );
};

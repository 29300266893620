import cn from 'classnames';
import { forwardRef, useMemo } from 'react';
import { FileRejection, FileWithPath, useDropzone, Accept } from 'react-dropzone';

type DropzoneProps = {
  additonalClassname?: string;
  onDrop: (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => void;
  onError: (error: Error) => void;
  accept: Accept;
  wrapperClassname?: string;
  maxFiles?: number;
  maxSize?: number;
  children?: React.ReactNode;
};

export const Dropzone = forwardRef<HTMLElement, DropzoneProps>((props, ref) => {
  const {
    additonalClassname,
    onDrop,
    onError,
    accept,
    wrapperClassname,
    maxFiles = 50,
    maxSize,
    children,
  } = props;

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFocused } =
    useDropzone({
      accept,
      maxFiles,
      maxSize,
      onDrop,
      onError,
      multiple: maxFiles > 1,
    });

  const className = useMemo(
    () => ({
      ...(isFocused ? { '!bg-surface !text-on-secondary': true } : {}),
      ...(isDragActive ? { '!bg-secondary-variant !text-black': true } : {}),
      ...(isDragAccept ? { '!bg-secondary-variant !text-black': true } : {}),
      ...(isDragActive ? { '!bg-surface !text-black': true } : {}),
      ...(isDragAccept ? { '!bg-success-dark !text-black': true } : {}),
      ...(isDragReject ? { '!bg-error-dark !text-white': true } : {}),

      ...(additonalClassname ? { [additonalClassname]: true } : {}),
    }),
    [isFocused, isDragActive, isDragAccept, isDragReject, additonalClassname],
  );

  return (
    <section ref={ref} className={cn('dropzone-container mb-4 h-full xl:mb-0', wrapperClassname)}>
      <div
        {...getRootProps({
          className: cn(
            className,
            'transition-all text-center rounded-2xl cursor-pointer border-[1px] border-dashed border-on-background-dimmed flex justify-center items-center p-12 dropzone-basic !text-on-background-dimmed hover:border-primary-cta hover:bg-surface',
          ),
        })}
      >
        <input {...getInputProps()} />
        {children}
      </div>
    </section>
  );
});

Dropzone.displayName = 'Dropzone';

import { FC } from 'react';
import { IconProps } from '.';

export const IconCheck: FC<IconProps> = ({ width = '24px', height = '24px', className = '' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 3.816L10.308 17.52L5.22 12.432L6.912 10.74L10.308 14.136L22.308 2.136L24 3.816ZM21.348 9.864C21.504 10.548 21.6 11.268 21.6 12C21.6 17.304 17.304 21.6 12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C13.896 2.4 15.648 2.952 17.136 3.9L18.864 2.172C16.92 0.804 14.556 0 12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 10.572 23.736 9.204 23.28 7.932L21.348 9.864Z"
        fill="#B2B2B2"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconDownload: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5125 7.78965C14.0025 5.11293 11.73 3.10345 9 3.10345C6.8325 3.10345 4.95 4.37586 4.0125 6.23793C1.755 6.48621 0 8.46465 0 10.8621C0 13.4302 2.0175 15.5172 4.5 15.5172H14.25C16.32 15.5172 18 13.7793 18 11.6379C18 9.58965 16.4625 7.92931 14.5125 7.78965ZM12.75 10.0862L9 13.9655L5.25 10.0862H7.5V6.98276H10.5V10.0862H12.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconStarOutline: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.82922L7.11293 4.73935C6.63723 5.76367 5.66591 6.46938 4.54472 6.60526L2.45396 6.85866L3.99648 8.29258C4.82366 9.06153 5.19468 10.2034 4.97745 11.3117L4.57236 13.3784L6.41276 12.3545C7.39969 11.8054 8.60031 11.8054 9.58724 12.3545L11.4276 13.3784L11.0226 11.3117C10.8053 10.2034 11.1763 9.06153 12.0035 8.29258L13.546 6.85866L11.4553 6.60526C10.3341 6.46938 9.36278 5.76367 8.88708 4.73935L8 2.82922ZM9.26901 1.1326C8.76755 0.0528083 7.23246 0.0528054 6.731 1.13259L5.42092 3.95357C5.21705 4.39257 4.80077 4.69501 4.32026 4.75325L1.23252 5.12748C0.0506164 5.27072 -0.423755 6.73068 0.448225 7.54127L2.7263 9.65895C3.08081 9.9885 3.23981 10.4779 3.14671 10.9529L2.54846 14.0051C2.31947 15.1734 3.56138 16.0757 4.60175 15.4969L7.31976 13.9848C7.74273 13.7494 8.25728 13.7494 8.68025 13.9848L11.3982 15.4969C12.4386 16.0757 13.6805 15.1734 13.4515 14.0051L12.8533 10.9529C12.7602 10.4779 12.9192 9.9885 13.2737 9.65895L15.5518 7.54127C16.4238 6.73068 15.9494 5.27072 14.7675 5.12748L11.6797 4.75325C11.1992 4.69501 10.783 4.39257 10.5791 3.95357L9.26901 1.1326Z"
        fill="currentColor"
      />
    </svg>
  );
};

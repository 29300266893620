import cn from 'classnames';
import {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IconCheckmark, IconCloseSlim, IconFilter } from '@unique/icons';
import { InputField } from './InputField';
import { useOutsideClick } from './helpers';

// ⚠️ WARNING: Do not use this component anymore. Use Filter component instead.
// TODO: Remove this component when removing all knowledge legacy code

export interface SelectOption {
  id: string;
  name: string;
  isSelected?: boolean;
  category?: string;
  tooltipOnHover?: string;
}

export interface SelectCategory {
  name: ReactNode | string;
  id: string;
}

export interface SelectFieldProps {
  name: string;
  disabled?: boolean;
  disabledToolTip?: ReactNode | string;
  tooltipOnHover?: ReactNode | string;
  title?: string;
  className?: string;
  popupClasses?: string;
  multiple?: boolean;
  selectedLabel?: ReactNode | string;
  actionPrefix?: string;
  icon?: ReactNode;
  queryId?: string;
  showSearch?: boolean;
  forceShowClose?: boolean;
  hideIcon?: boolean;
  options?: SelectOption[];
  categories?: SelectCategory[];
  onUpdate?: (selected: string) => void;
  onSelect?: (selected: SelectOption | undefined) => void;
  handleClickFilter?: () => void;
}

export const SelectField: FC<SelectFieldProps> = ({
  name,
  title,
  disabled,
  disabledToolTip,
  tooltipOnHover,
  selectedLabel = '',
  multiple = false,
  className = '',
  popupClasses = '',
  icon,
  actionPrefix = '',
  showSearch,
  forceShowClose = false,
  hideIcon = false,
  options = [],
  categories,
  onUpdate,
  onSelect,
  handleClickFilter,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | SelectOption[] | undefined | null
  >();
  const [searchText, setSearchText] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [optionsToShow, setOptionsToShow] = useState<SelectOption[]>(options);
  const popupRef = useRef(null);

  const showDefaultValue = !!selectedLabel;

  const handleClickPopup = () => {
    setShowPopup(false);
    setSearchText('');
  };

  // If options change (like a reset), updates the optionsToShow state
  useEffect(() => {
    setOptionsToShow(options);
  }, [options]);

  useOutsideClick(popupRef, handleClickPopup);

  const handleSelectOption = (option: SelectOption | undefined) => {
    if (onUpdate) onUpdate(option?.id || '');

    if (multiple && option) {
      if (
        Array.isArray(selectedOption) &&
        selectedOption.findIndex((optionItem) => optionItem.id === option?.id) > -1
      ) {
        const newOption = [...selectedOption];
        newOption.splice(
          selectedOption.findIndex((optionItem) => optionItem.id === option?.id),
          1,
        );
        setSelectedOption(newOption);
      } else if (Array.isArray(selectedOption)) {
        setSelectedOption([...selectedOption, option]);
      } else {
        setSelectedOption([option]);
      }
      setIsActive(true);
    } else if (option) {
      setSelectedOption(option);
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    setSearchText('');
    setShowPopup(false);
  };

  const handleClearFilter = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedOption(null);
    handleSelectOption(undefined);
  };

  /**
   * When the popup is closed, reset the optionsToShow to the original options
   */
  useEffect(() => {
    if (!showPopup && optionsToShow.length != options.length) {
      setOptionsToShow(options);
    }
  }, [showPopup]);

  const handleClickFilterButton = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    const target = ev.target as HTMLInputElement;

    const isSearchInput = target.name === 'filter-search';

    !disabled && !isSearchInput && setShowPopup(!showPopup);
    handleClickFilter && handleClickFilter();
  };

  const onSearchOption = (evt: ChangeEvent<HTMLInputElement>) => {
    const target = evt.target as HTMLInputElement;
    setSearchText(target.value);
    if (!searchText) {
      setOptionsToShow([...options]);
      return;
    }
    const newOptions = [...options].filter((option) =>
      option.name?.toLowerCase().includes(target.value.toLowerCase()),
    );
    setOptionsToShow(newOptions);
  };

  /**
   * Trigger when user select an option or validate form using the TextField
   * @param option SelectOption to applu. If undefined take the first one displayed.
   * @returns void
   */
  const applySelectField = (option?: SelectOption) => {
    if (optionsToShow.length) {
      if (!option) {
        option = [...optionsToShow].filter((opt) => !opt.isSelected)[0];
      }
      onSelect ? onSelect(option) : handleSelectOption(option);
    }
  };

  const isSelected = (option: SelectOption) => {
    if (option.isSelected) return true;
    if (multiple && Array.isArray(selectedOption)) {
      return selectedOption.findIndex((optionItem) => optionItem.id === option.id) > -1;
    }
    if (!Array.isArray(selectedOption)) {
      return option.id === selectedOption?.id;
    }
    return false;
  };

  useEffect(() => {
    if (!selectedLabel) {
      setSelectedOption(null);
      setIsActive(false);
    }
  }, [selectedLabel]);

  useEffect(() => {
    if (showDefaultValue) setIsActive(true);
  }, [showDefaultValue]);

  const showSelectedOption = useMemo(() => {
    if (multiple) return Array.isArray(selectedOption) && !!selectedOption.length;
    else return !!selectedOption;
  }, [selectedOption, multiple]);

  const OptionItems = ({ items }: { items: SelectOption[] }) => {
    const counterSelectedOption = items.filter((option) => option.isSelected).length;
    return (
      <>
        {items
          ?.sort((a, b) => Number(b.isSelected) - Number(a.isSelected))
          .map((option, index) => (
            <button
              className={cn({
                'text-on-control-main hover:bg-background-variant hover:text-on-background-main relative block w-full truncate px-4 py-2 text-left transition':
                  true,
                'bg-control text-on-surface': isSelected(option),
                'bg-background-variant text-on-background-main':
                  !isSelected(option) && searchText && !isHovered && index == counterSelectedOption,
              })}
              key={option.id + index}
              onClick={() => applySelectField(option)}
              data-value={option.name}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span className="body-2">{option.name}</span>
              <span
                className={`absolute right-2 top-[6px] opacity-0 ${
                  isSelected(option) ? 'opacity-100' : ''
                }`}
              >
                <IconCheckmark className="text-primary-cta h-[24px]" width="12px" height="12px" />
              </span>
            </button>
          ))}
      </>
    );
  };

  return (
    <button
      title={title}
      disabled={disabled}
      className={cn({
        'body-2 bg-surface text-on-surface hover:bg-background-variant group relative flex min-h-[40px] cursor-pointer items-center rounded-bl rounded-br-2xl rounded-tl-2xl rounded-tr px-3 py-2 text-left transition-all':
          true,
        [className]: true,
        '!cursor-not-allowed': disabled,
        'pointer-events-none': !options?.length,
      })}
      onClick={handleClickFilterButton}
    >
      {disabled && disabledToolTip && (
        <div className="shadow-tooltip subtitle-2 bg-control pointer-events-none absolute left-0 top-[40px] w-full min-w-[220px] rounded-lg p-4 opacity-0 transition-all group-hover:opacity-100">
          {disabledToolTip}
        </div>
      )}

      {tooltipOnHover && (
        <div
          className={cn({
            'shadow-tooltip bg-secondary text-on-secondary pointer-events-none absolute left-0 top-[48px] rounded-lg px-2 py-0.5 text-sm font-medium opacity-0 transition-all group-hover:opacity-100':
              true,
            '!opacity-0': showPopup,
          })}
        >
          {tooltipOnHover}
        </div>
      )}

      <div
        className={cn({
          'flex w-full items-center': true,
          'pointer-events-none opacity-50': disabled,
        })}
      >
        {!hideIcon && (
          <div
            className={cn({
              'text-primary-cta pointer-events-none mr-2': true,
            })}
          >
            {icon ? icon : <IconFilter width="16px" height="16px" />}
          </div>
        )}

        {showDefaultValue && (
          <div className="body-2 pr-2">
            <span className="capitalize">{selectedLabel}</span>
          </div>
        )}

        {showSelectedOption && !showDefaultValue && (
          <div className="body-2 pr-2">
            {Array.isArray(selectedOption) ? selectedOption[0]?.name : selectedOption?.name}
          </div>
        )}

        {!showDefaultValue && !showSelectedOption && (
          <div className="body-2 text-on-control-main pr-2">
            <span className="mr-1 hidden sm:inline-block">{actionPrefix} </span>
            <span>{name}</span>
          </div>
        )}

        {isActive && (!showDefaultValue || forceShowClose) && (
          <span
            className="text-on-control-dimmed group-hover:text-on-surface m-auto transition-all"
            onClick={handleClearFilter}
          >
            <IconCloseSlim width="18px" height="18px" />
          </span>
        )}
      </div>

      {showPopup && (
        <div
          ref={popupRef}
          className={`bg-surface absolute left-0 top-full z-10 mt-1 w-full overflow-hidden rounded-lg py-1 shadow-xl ${popupClasses}`}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault();
              applySelectField();
            }}
          >
            <div className="global-scrollbar max-h-[200px] overflow-y-auto" data-testid="listitems">
              {showSearch && (
                <InputField
                  placeholder="Search"
                  name="filter-search"
                  classes="!h-8 !px-2"
                  inputWrapperClasses="!px -2 !py-1"
                  autoFocus={true}
                  handleChange={onSearchOption}
                  tabIndex={0}
                />
              )}

              {optionsToShow && (
                <>
                  {categories ? (
                    <>
                      {categories.map((category) => {
                        const optionsForCategory = optionsToShow.filter(
                          (option) => option.category === category.id,
                        );

                        if (!optionsForCategory?.length) return null;
                        return (
                          <div
                            key={category.id}
                            className="border-b-secondary border-b pb-2 last:border-none"
                          >
                            <div className="label px-4 pb-2 pt-3">{category.name}</div>
                            <OptionItems items={optionsForCategory} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <OptionItems items={optionsToShow} />
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      )}
    </button>
  );
};

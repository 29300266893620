import { FC } from 'react';
import { IconProps } from '.';

export const IconThumbsUpFeedback: FC<IconProps> = ({
  width = '32px',
  height = '32px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#04C694" />
      <path
        d="M9 22.2222H10.4C10.785 22.2222 11.1 21.9111 11.1 21.5309V15.3086C11.1 14.9284 10.785 14.6173 10.4 14.6173H9V22.2222ZM22.881 17.2997C22.958 17.1269 23 16.9402 23 16.7467V16C23 15.2395 22.37 14.6173 21.6 14.6173H17.75L18.394 11.4025C18.429 11.2504 18.408 11.0844 18.338 10.9462C18.177 10.6351 17.974 10.3516 17.722 10.1027L17.4 9.77777L12.913 14.2094C12.647 14.4721 12.5 14.8247 12.5 15.1911V20.6114C12.5 21.4963 13.235 22.2222 14.138 22.2222H19.815C20.305 22.2222 20.767 21.9664 21.019 21.5516L22.881 17.2997Z"
        fill="white"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconMdFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29.7739V2.22609C9 0.996653 9.90527 0 11.022 0H22.9011L32 10.0174V29.7739C32 31.0033 31.0947 32 29.978 32H11.022C9.90527 32 9 31.0033 9 29.7739Z"
        fill="#CAD6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16.875C13 16.3918 13.3918 16 13.875 16L28.625 16C29.1082 16 29.5 16.3918 29.5 16.875C29.5 17.3582 29.1082 17.75 28.625 17.75H13.875C13.3918 17.75 13 17.3582 13 16.875Z"
        fill="#57DADC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 12.875C13 12.3918 13.3918 12 13.875 12L28.625 12C29.1082 12 29.5 12.3918 29.5 12.875C29.5 13.3582 29.1082 13.75 28.625 13.75H13.875C13.3918 13.75 13 13.3582 13 12.875Z"
        fill="#57DADC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 20.875C13 20.3918 13.3918 20 13.875 20L28.625 20C29.1082 20 29.5 20.3918 29.5 20.875C29.5 21.3582 29.1082 21.75 28.625 21.75H13.875C13.3918 21.75 13 21.3582 13 20.875Z"
        fill="#57DADC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 24.875C13 24.3918 13.3918 24 13.875 24L28.625 24C29.1082 24 29.5 24.3918 29.5 24.875C29.5 25.3582 29.1082 25.75 28.625 25.75H13.875C13.3918 25.75 13 25.3582 13 24.875Z"
        fill="#57DADC"
      />
      <path d="M23 7.77778V0L32 10H25C23.8954 10 23 9.00508 23 7.77778Z" fill="#92AAB1" />
      <path
        d="M0 9.5C0 8.67157 0.820811 8 1.83333 8H20.1667C21.1792 8 22 8.67157 22 9.5V24.5C22 25.3284 21.1792 26 20.1667 26H1.83333C0.820811 26 0 25.3284 0 24.5V9.5Z"
        fill="#109D9F"
      />
      <path
        d="M3 22V11.9785H5.48145L6.97168 18.8145L8.44824 11.9785H10.9365V22H9.39844V14.1113L7.76465 22H6.16504L4.54492 14.1113V22H3Z"
        fill="white"
      />
      <path
        d="M12.584 11.9785H15.6123C16.3779 11.9785 16.9544 12.0651 17.3418 12.2383C17.7337 12.4069 18.0938 12.694 18.4219 13.0996C18.75 13.5052 19.0075 14.0316 19.1943 14.6787C19.3812 15.3258 19.4746 16.1302 19.4746 17.0918C19.4746 17.9577 19.3766 18.7119 19.1807 19.3545C18.9847 19.9925 18.7363 20.4984 18.4355 20.8721C18.1348 21.2458 17.7702 21.5283 17.3418 21.7197C16.918 21.9066 16.3711 22 15.7012 22H12.584V11.9785ZM14.2383 13.6738V20.3115H15.4824C16.0156 20.3115 16.4007 20.2614 16.6377 20.1611C16.8792 20.0609 17.0752 19.9105 17.2256 19.71C17.3805 19.5049 17.5081 19.1836 17.6084 18.7461C17.7087 18.3086 17.7588 17.7321 17.7588 17.0166C17.7588 16.2738 17.7064 15.6882 17.6016 15.2598C17.5013 14.8314 17.3418 14.4941 17.123 14.248C16.9089 14.002 16.6468 13.8402 16.3369 13.7627C16.1045 13.7035 15.6556 13.6738 14.9902 13.6738H14.2383Z"
        fill="white"
      />
    </svg>
  );
};

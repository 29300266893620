'use client';

import { FC } from 'react';
import { ErrorPage } from './ErrorPage';

interface NotFoundPageProps {
  basePath?: string;
}

export const NotFoundPage: FC<NotFoundPageProps> = ({ basePath }) => {
  return (
    <ErrorPage
      basePath={basePath}
      statusCode={404}
      title="Page Not Found"
      description={`Unfortunately, it appears that the page you were seeking does not exist\n at this time.`}
    />
  );
};

import { FC } from 'react';
import { IconProps } from '.';

type IconSpaceProps = IconProps & {
  includeShadow?: boolean;
};
export const IconSpace: FC<IconSpaceProps> = ({
  width = '20px',
  height = '20px',
  includeShadow = false,
}) => {
  if (includeShadow) {
    width = '20px';
    height = '20px';
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_i_8638_699)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.43354 3.30016L5.94215 7.10214C5.66757 7.51485 5.98903 8.05264 6.51142 8.05264H11.4875C12.0099 8.05264 12.3314 7.51485 12.0568 7.10214L9.57208 3.30016C9.31089 2.89995 8.69474 2.89995 8.43354 3.30016ZM3.63153 15H7.42073C7.76808 15 8.05227 14.7158 8.05227 14.3684V10.5789C8.05227 10.2316 7.76808 9.94735 7.42073 9.94735H3.63153C3.28419 9.94735 3 10.2316 3 10.5789V14.3684C3 14.7158 3.28419 15 3.63153 15ZM14.9998 12.4737C14.9998 13.8689 13.8688 15 12.4737 15C11.0785 15 9.94754 13.8689 9.94754 12.4737C9.94754 11.0784 11.0785 9.94736 12.4737 9.94736C13.8688 9.94736 14.9998 11.0784 14.9998 12.4737Z"
            fill="currentColor"
            className="bg-on-background-dimmed"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_8638_699"
            x="-6.10352e-05"
            y="0"
            width="18.0001"
            height="17"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8638_699" />
          </filter>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43354 3.30016L5.94215 7.10214C5.66757 7.51485 5.98903 8.05264 6.51142 8.05264H11.4875C12.0099 8.05264 12.3314 7.51485 12.0568 7.10214L9.57208 3.30016C9.31089 2.89995 8.69474 2.89995 8.43354 3.30016ZM3.63153 15H7.42073C7.76808 15 8.05227 14.7158 8.05227 14.3684V10.5789C8.05227 10.2316 7.76808 9.94735 7.42073 9.94735H3.63153C3.28419 9.94735 3 10.2316 3 10.5789V14.3684C3 14.7158 3.28419 15 3.63153 15ZM14.9998 12.4737C14.9998 13.8689 13.8688 15 12.4737 15C11.0785 15 9.94754 13.8689 9.94754 12.4737C9.94754 11.0784 11.0785 9.94736 12.4737 9.94736C13.8688 9.94736 14.9998 11.0784 14.9998 12.4737Z"
        fill="currentColor"
      />
    </svg>
  );
};

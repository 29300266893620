'use client';

import { FC } from 'react';
import { ErrorPage } from './ErrorPage';

interface NoAccessPageProps {
  logout?: () => void;
  selfUrl?: string;
}

export const ForbiddenPage: FC<NoAccessPageProps> = ({ logout, selfUrl }) => {
  return (
    <ErrorPage
      statusCode={403}
      title="Forbidden"
      description="You've entered a restricted area that demands either initial authorization or a re-login for access. Your current status suggests that you either lack access or need to re-authenticate."
      logout={logout}
      selfUrl={selfUrl}
      showReturnHome={false}
    />
  );
};

import cn from 'classnames';
import { FC, ReactNode } from 'react';

type RadioButtonBarProps = {
  options: RadioButtonBarOption[];
  handleChangeOption: (value: string) => void;
};

export type RadioButtonBarOption = {
  label: string | ReactNode;
  value: string;
  selected: boolean;
};

const RadioButtonBar: FC<RadioButtonBarProps> = ({ options, handleChangeOption }) => {
  return (
    <div className="bg-background-variant flex cursor-pointer flex-wrap gap-y-2 overflow-hidden rounded-[8px] p-1">
      {options.map((option) => (
        <div
          onClick={() => handleChangeOption(option.value)}
          key={option.value}
          className={cn({
            'subtitle-2 bg-background-variant text-on-background-dimmed hover:bg-background hover:text-on-background-main mr-1 rounded-[8px] px-2 py-[5px] transition last-of-type:mr-0 last-of-type:border-0 md:px-4 md:py-1.5':
              true,
            'bg-primary-cta !text-on-primary hover:bg-primary-variant': option.selected,
          })}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default RadioButtonBar;

import { FC } from 'react';
import { IconProps } from '.';

export const IconFileNotFound: FC<IconProps> = ({ width = '105px', height = '91px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 105 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2036 0C19.4066 0 18.7605 0.644653 18.7605 1.43987V18.4304C18.7605 19.2256 19.4066 19.8703 20.2036 19.8703H31.7485V25.356C31.6536 25.3466 31.5573 25.3418 31.4599 25.3418H4.32934C2.73532 25.3418 1.44311 26.6311 1.44311 28.2215C1.44311 29.812 2.73532 31.1013 4.32934 31.1013H15.5856C13.9916 31.1013 12.6994 32.3906 12.6994 33.981C12.6994 35.5715 13.9916 36.8608 15.5856 36.8608H26.8419V42.3323H2.88623C1.29221 42.3323 0 43.6216 0 45.212C0 46.8025 1.29221 48.0918 2.88623 48.0918H17.8946V89.5601C17.8946 90.3553 18.5407 91 19.3377 91H58.8791H66.9605H88.8958C89.6929 91 90.339 90.3553 90.339 89.5601V84.9525C90.339 84.1573 89.6929 83.5127 88.8958 83.5127H68.4036V78.0411H74.4647H79.9485H101.595C103.189 78.0411 104.481 76.7518 104.481 75.1614C104.481 73.571 103.189 72.2816 101.595 72.2816H79.9485V66.8101H88.8958C89.6929 66.8101 90.339 66.1655 90.339 65.3703V60.4747H98.4204C100.014 60.4747 101.307 59.1854 101.307 57.5949C101.307 56.0045 100.014 54.7152 98.4204 54.7152H90.339V19.8703V16.9905H87.8292L73.0216 0H20.2036Z"
        fill="#D8D8D8"
      />
      <path
        d="M74.71 16.8519H90.1575L73.3057 0V15.4237C73.3057 16.2125 73.9344 16.8519 74.71 16.8519Z"
        fill="#9C9C9C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.0047 30.1078L47.9234 51.7447C48.009 53.7602 49.6682 55.3502 51.6858 55.3502C53.7103 55.3502 55.3725 53.7498 55.4489 51.727L56.2656 30.086C56.3647 27.4604 54.2627 25.2778 51.6348 25.2778C48.9982 25.2778 46.8929 27.4741 47.0047 30.1078ZM51.7653 67.4075C54.1943 67.4075 56.1634 65.4387 56.1634 63.0101C56.1634 60.5815 54.1943 58.6127 51.7653 58.6127C49.3363 58.6127 47.3672 60.5815 47.3672 63.0101C47.3672 65.4387 49.3363 67.4075 51.7653 67.4075Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconAdd: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39998 1.40006C9.39998 0.626828 8.77315 -3.93393e-08 7.99992 0C7.22669 -3.93393e-08 6.59986 0.626828 6.59986 1.40006V6.59977L1.40006 6.59977C0.626827 6.59977 0 7.2266 0 7.99983C0 8.77306 0.626827 9.39989 1.40006 9.39989H6.59986V14.5999C6.59986 15.3732 7.22669 16 7.99992 16C8.77315 16 9.39998 15.3732 9.39998 14.5999V9.39989H14.5999C15.3732 9.39989 16 8.77306 16 7.99983C16 7.2266 15.3732 6.59977 14.5999 6.59977L9.39998 6.59977V1.40006Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconThumbsDownFeedback: FC<IconProps> = ({
  width = '32px',
  height = '32px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#D71C1C" />
      <path
        d="M23 9.77777H21.6C21.215 9.77777 20.9 10.0889 20.9 10.4691V16.6914C20.9 17.0716 21.215 17.3827 21.6 17.3827H23V9.77777ZM9.119 14.7002C9.042 14.8731 9 15.0597 9 15.2533V16C9 16.7605 9.63 17.3827 10.4 17.3827H14.25L13.606 20.5975C13.571 20.7496 13.592 20.9155 13.662 21.0538C13.823 21.3649 14.026 21.6484 14.278 21.8973L14.6 22.2222L19.087 17.7906C19.353 17.5279 19.5 17.1753 19.5 16.8089V11.3955C19.5 10.5037 18.765 9.77777 17.862 9.77777H12.192C11.695 9.77777 11.24 10.0336 10.988 10.4484L9.119 14.7002Z"
        fill="white"
      />
    </svg>
  );
};

'use client';

import { ButtonIcon, MarkdownPreview, Modal, Textarea } from '@unique/component-library';
import IndeterminateCheckbox from '@unique/component-library/src/IndeterminateCheckbox';
import { FC, useMemo, useState } from 'react';
interface TermsAndConditionsProps {
  text: string;
  date: Date;
  handleAccept: () => void;
  shouldShow?: boolean;
  isLoadingAccept?: boolean;
  isLoadingCreate?: boolean;
  isAccepted?: boolean;
  closeModal: () => void;
  allowEdit?: boolean;
  createTermsAndConditions?: (termsAndConditions: string) => void;
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  isLoadingAccept = false,
  isLoadingCreate = false,
  date,
  text,
  shouldShow,
  handleAccept,
  isAccepted,
  closeModal,
  createTermsAndConditions,
  allowEdit = false,
}) => {
  const [agreed, setAgreed] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState(text);

  const lastUpdated = date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const onAcceptClick = () => {
    setAgreed(!agreed);
    closeModal();
  };

  const onDiscardClick = () => {
    setEditedText(text);
    setEditMode(false);
  };

  const onSaveClick = () => {
    createTermsAndConditions && createTermsAndConditions(editedText);
    setEditMode(false);
  };

  const hasChanges = useMemo(() => editedText !== text, [editedText, text]);

  return (
    <Modal shouldShow={shouldShow}>
      <h2 className="title-s text-on-background-main text-center">Terms & Conditions</h2>
      <p className="body-2 text-on-background-main mt-6 text-center">Last Updated: {lastUpdated}</p>
      {editMode ? (
        <Textarea
          name="terms-and-conditions"
          placeholder="Write Terms and Conditions here"
          className="mt-8 !w-[700px] overflow-x-auto rounded-md border-0 !py-3 focus:pr-4"
          style={{ boxShadow: 'none' }}
          value={editedText}
          onChange={(e) => setEditedText((e.target as HTMLInputElement).value)}
        />
      ) : (
        <div className="global-scrollbar body-1 border-control bg-surface text-on-background-main mt-6 h-[495px] w-full overflow-y-auto rounded border-[1px] px-4 py-6">
          <MarkdownPreview text={text} />
        </div>
      )}
      {!isAccepted ? (
        <>
          <div className="mt-6 flex items-center leading-4">
            <IndeterminateCheckbox onChange={onAcceptClick} id="agreed" checked={agreed} />
            <label className="body-2 text-on-background-main ml-3 cursor-pointer" htmlFor="agreed">
              I have read and I agree to the Terms & Conditions
            </label>
          </div>
          <ButtonIcon
            className="ml-auto !block"
            disabled={!agreed || isLoadingAccept}
            isLoading={isLoadingAccept}
            onClick={handleAccept}
          >
            Agree
          </ButtonIcon>
        </>
      ) : (
        <div className="ml-auto mt-6 flex justify-end">
          {!allowEdit ? (
            <ButtonIcon onClick={closeModal}>Close</ButtonIcon>
          ) : (
            <>
              {editMode ? (
                <>
                  <ButtonIcon className="mr-auto !block" onClick={onDiscardClick}>
                    Discard
                  </ButtonIcon>
                  <ButtonIcon
                    className="!block"
                    onClick={onSaveClick}
                    isLoading={isLoadingCreate}
                    disabled={!hasChanges}
                  >
                    Save
                  </ButtonIcon>
                </>
              ) : (
                <>
                  <ButtonIcon className="mr-auto !block" onClick={() => setEditMode(true)}>
                    Edit
                  </ButtonIcon>
                  <ButtonIcon className="!block" onClick={closeModal}>
                    Close
                  </ButtonIcon>
                </>
              )}
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

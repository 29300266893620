import { Initials } from '@unique/component-library';
import { IconGroup } from '@unique/icons';

export const getMemberIcon = (label: string | JSX.Element, value: string) => {
  return (
    <>
      {value.startsWith('group') ? (
        <IconGroup width="15px" height="15px" />
      ) : (
        <Initials name={typeof label === 'string' ? label : ''} />
      )}
    </>
  );
};

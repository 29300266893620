import cn from 'classnames';
import { FC, MouseEvent, ReactNode } from 'react';
import { MenuDropdownHeader } from './MenuDropdownHeader';
import { MenuItem } from '../MenuItem';

export interface UserMenuItem {
  title: string;
  icon: ReactNode;
  externalUrl?: string;
  handleClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

interface MenuDropdownProps {
  showDropdown: boolean;
  sections: UserMenuItem[][];
  userName?: string;
  userEmail?: string;
  profileImage?: ReactNode;
}

const MenuDivider = () => <div className="border-b-control w-full border-b" />;

export const MenuDropdown: FC<MenuDropdownProps> = ({
  showDropdown,
  sections,
  userName,
  userEmail,
  profileImage,
}) => {
  const userInfoIsProvided = !!profileImage && !!userName && !!userEmail;
  return (
    <div
      className={cn({
        'bg-surface pointer-events-none absolute right-0 top-14 z-50 min-w-[250px] max-w-[65%] overflow-hidden rounded-lg opacity-0 shadow-xl transition-opacity md:absolute md:top-16 md:mt-4':
          true,
        '!pointer-events-auto !opacity-100': showDropdown,
      })}
    >
      {userInfoIsProvided && (
        <>
          <MenuDropdownHeader
            userName={userName}
            userEmail={userEmail}
            profileImage={profileImage}
          />
          <MenuDivider />
        </>
      )}
      {sections.map((section, index) => (
        <div key={`user-menu-section-${index}`}>
          {section.map((item, secondIndex) => (
            <div className={item.className} key={`user-menu-item-${secondIndex}`}>
              <MenuItem
                key={secondIndex}
                title={item.title}
                icon={item.icon}
                externalUrl={item.externalUrl}
                handleClick={item.handleClick}
              />
            </div>
          ))}
          {index !== sections?.length - 1 ? <MenuDivider /> : <></>}
        </div>
      ))}
    </div>
  );
};

import { FC, PropsWithChildren } from 'react';

export const LoadingText: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="loading-text">
      {children}
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
};

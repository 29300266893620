import { IconProps } from '.';
import { FC } from 'react';

export const IconMeatballsMenu: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80001 8.99995C4.80001 9.99406 3.99412 10.8 3.00001 10.8C2.0059 10.8 1.20001 9.99406 1.20001 8.99995C1.20001 8.00584 2.0059 7.19995 3.00001 7.19995C3.99412 7.19995 4.80001 8.00584 4.80001 8.99995ZM10.8 8.99995C10.8 9.99406 9.99413 10.8 9.00001 10.8C8.0059 10.8 7.20001 9.99406 7.20001 8.99995C7.20001 8.00584 8.0059 7.19995 9.00001 7.19995C9.99413 7.19995 10.8 8.00584 10.8 8.99995ZM15 10.8C15.9941 10.8 16.8 9.99406 16.8 8.99995C16.8 8.00584 15.9941 7.19995 15 7.19995C14.0059 7.19995 13.2 8.00584 13.2 8.99995C13.2 9.99406 14.0059 10.8 15 10.8Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconDeleteModal: FC<IconProps> = ({ width = '126px', height = '126px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="63" cy="63" r="63" fill="url(#paint0_radial_502_5546)" />
      <circle cx="111" cy="26" r="15" fill="#D71C1C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.578 18.9546L112.301 29.1392H109.702L109.418 18.9546H112.578ZM111.84 33.4539C111.584 33.6078 111.305 33.6847 111.002 33.6847C110.533 33.6847 110.13 33.5178 109.794 33.184C109.458 32.8502 109.292 32.4489 109.297 31.9802C109.292 31.5161 109.458 31.1184 109.794 30.787C110.13 30.4555 110.533 30.2898 111.002 30.2898C111.451 30.2898 111.847 30.4555 112.188 30.787C112.529 31.1184 112.701 31.5161 112.706 31.9802C112.701 32.2927 112.62 32.5779 112.461 32.836C112.302 33.094 112.095 33.3 111.84 33.4539Z"
        fill="white"
      />
      <rect
        x="32"
        y="55"
        width="61.6418"
        height="32.3881"
        rx="5"
        fill="url(#paint1_linear_502_5546)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.627 36V63.1642H79.015V46.4478L68.5673 36H46.627ZM49.5713 78.8358H46.627V106H49.5713V78.8358ZM51.5349 78.8358H54.4793V100.776H51.5349V78.8358ZM59.3863 78.8358H56.4419V101.821H59.3863V78.8358ZM61.348 78.8358H64.2923V101.821H61.348V78.8358ZM69.2003 78.8358H66.256V101.821H69.2003V78.8358ZM71.163 78.8358H74.1074V101.821H71.163V78.8358ZM79.0154 78.8358H76.071V101.821H79.0154V78.8358Z"
        fill="url(#paint2_linear_502_5546)"
      />
      <circle cx="85.5" cy="71.5" r="2.5" fill="#E05454" />
      <path
        d="M68.5674 36L73.7913 41.2239L79.0151 46.4478H68.5674V36Z"
        fill="url(#paint3_linear_502_5546)"
      />
      <path d="M44.0156 63.1641H81.1052" stroke="#02161D" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M44.0156 78.0859C43.6014 78.0859 43.2656 78.4217 43.2656 78.8359C43.2656 79.2502 43.6014 79.5859 44.0156 79.5859V78.0859ZM81.1052 79.5859C81.5194 79.5859 81.8552 79.2502 81.8552 78.8359C81.8552 78.4217 81.5194 78.0859 81.1052 78.0859V79.5859ZM44.0156 79.5859H81.1052V78.0859H44.0156V79.5859Z"
        fill="#02161D"
      />
      <defs>
        <radialGradient
          id="paint0_radial_502_5546"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(63 63) rotate(90) scale(63)"
        >
          <stop stopColor="#F0ABAB" />
          <stop offset="1" stopColor="#F8D6D6" stopOpacity="0.8" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_502_5546"
          x1="62.8209"
          y1="55"
          x2="62.8209"
          y2="87.3881"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#073B4C" />
          <stop offset="1" stopColor="#2E5F6F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_502_5546"
          x1="63"
          y1="36"
          x2="62.8212"
          y2="106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D71C1C" />
          <stop offset="1" stopColor="#E05454" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_502_5546"
          x1="74"
          y1="41"
          x2="63.5"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E05454" />
          <stop offset="1" stopColor="#F0ABAB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { INTERNAL_ASSISTANT_PREFIX } from '@unique/shared-library';

export enum ColumnItemType {
  Folder = 'folder',
  SyncedFolder = 'syncedFolder',
  SpaceFolder = 'spaceFolder',
  File = 'file',
}

export const getColumnItemType = (externalId?: string, isFolder: boolean = false) => {
  if (!isFolder) return ColumnItemType.File;

  const isExternal = !!externalId && !externalId.startsWith(INTERNAL_ASSISTANT_PREFIX);
  if (isExternal) return ColumnItemType.SyncedFolder;

  const isSpace = !!externalId && externalId.startsWith(INTERNAL_ASSISTANT_PREFIX);
  if (isSpace) return ColumnItemType.SpaceFolder;

  return ColumnItemType.Folder;
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconManageAIModules: FC<IconProps> = ({ width = '189px', height = '156px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 189 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9224_57527)">
        <path
          d="M172.5 78C172.5 79.4329 172.461 80.8557 172.384 82.2685C172.102 87.5504 171.282 92.7898 169.936 97.9051C169.934 97.9072 169.934 97.9072 169.936 97.9094C169.759 98.586 169.572 99.2604 169.374 99.9284C169.365 99.9607 169.356 99.9908 169.346 100.021C169.052 101.023 168.739 102.016 168.406 102.999V103.001C163.169 118.449 153.224 131.869 139.969 141.375C126.713 150.881 110.812 155.996 94.5 156C57.6051 156 26.696 130.387 18.5815 95.9702C17.1943 90.0809 16.4958 84.0504 16.5 78C16.5 76.7977 16.5273 75.6018 16.5819 74.4124C17.2284 59.8721 21.951 45.8066 30.2103 33.8223C30.3095 33.6757 30.4107 33.5292 30.5142 33.3849C44.6102 13.2018 68.0145 0 94.5 0C131.931 0 163.202 26.3649 170.757 61.5381L170.759 61.5424C171.246 63.8092 171.632 66.1104 171.918 68.4461C172.307 71.6158 172.502 74.8065 172.5 78Z"
          fill="currentColor"
          className="text-surface"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.8039 51.5861V49.9339C66.8039 41.4644 63.3443 29.6904 46.6005 29.6904H44.7034C28.0418 29.6904 24.5 41.4098 24.5 49.9339V51.5861C24.5 59.9465 28.0014 72.0498 44.7034 72.0498H46.6005C61.656 71.4625 66.8039 62.4111 66.8039 51.5861Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          d="M59.7206 59.4961C59.7206 61.5134 58.0874 63.1488 56.0727 63.1488L35.2273 63.1488C33.2126 63.1488 31.5793 61.5134 31.5793 59.4961L31.5793 47.4943C31.5793 45.4769 33.2126 43.8416 35.2273 43.8416L56.0727 43.8416C58.0874 43.8416 59.7206 45.4769 59.7206 47.4943L59.7206 59.4961Z"
          fill="currentColor"
          className="text-primary-variant"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.8731 43.8406L44.8731 40.1879L46.4365 40.1879L46.4365 43.8406L44.8731 43.8406Z"
          fill="currentColor"
          className="text-primary-variant"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.8731 66.8014L44.8731 63.1487L46.4365 63.1487L46.4365 66.8014L44.8731 66.8014Z"
          fill="currentColor"
          className="text-primary-variant"
        />
        <path
          d="M31.5833 53.6249C31.5833 56.723 31.5833 56.37 31.5833 59.2345C29.4247 59.2345 27.6748 56.723 27.6748 53.6249C27.6748 50.5269 29.4247 48.0154 31.5833 48.0154C31.5833 50.6411 31.5833 50.5269 31.5833 53.6249Z"
          fill="currentColor"
          className="text-primary-variant"
        />
        <path
          d="M59.7233 53.6253C59.7233 50.5273 59.7233 50.8802 59.7233 48.0158C61.8819 48.0158 63.6318 50.5273 63.6318 53.6253C63.6318 56.7234 61.8819 59.2349 59.7233 59.2349C59.7233 56.6091 59.7233 56.7234 59.7233 53.6253Z"
          fill="currentColor"
          className="text-primary-variant"
        />
        <path
          d="M49.5606 37.7097C49.5606 39.7991 47.869 41.4929 45.7824 41.4929C43.6957 41.4929 42.0042 39.7991 42.0042 37.7097C42.0042 35.6203 43.6957 33.9265 45.7824 33.9265C47.869 33.9265 49.5606 35.6203 49.5606 37.7097Z"
          fill="currentColor"
          className="text-primary-variant"
        />
        <path
          d="M34.864 68.8727C34.864 67.4688 36.0021 66.3307 37.406 66.3307H53.897C55.3009 66.3307 56.439 67.4688 56.439 68.8727C56.439 68.8727 54.112 70.9906 45.6512 70.9906C37.1904 70.9906 34.864 68.8727 34.864 68.8727Z"
          fill="currentColor"
          className="text-primary-variant"
        />
        <ellipse
          cx="38.6021"
          cy="51.6545"
          rx="3.13362"
          ry="3.13773"
          fill="currentColor"
          className="text-primary-cta"
        />
        <ellipse
          cx="52.702"
          cy="51.6545"
          rx="3.13362"
          ry="3.13773"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          d="M64.1408 59.6231C64.5415 59.6732 64.9264 59.8104 65.2682 60.025C65.61 60.2396 65.9006 60.5264 66.1193 60.8652C66.3381 61.204 66.4797 61.5865 66.5343 61.9858C66.5889 62.3851 66.5551 62.7915 66.4352 63.1764L71.2857 67.2082L69.5493 70.7472L62.7797 64.9575C62.1451 64.7137 61.6222 64.2462 61.31 63.6434C60.9979 63.0406 60.9181 62.3445 61.0859 61.687C61.2537 61.0294 61.6573 60.4561 62.2204 60.0758C62.7834 59.6954 63.4667 59.5343 64.1408 59.6231Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M87.1422 152.213H83.6578L82 138.492L87.1428 138.493L87.1422 152.213Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M80.1182 150.927H88.0002V155.214H76.8574V154.174C76.8574 153.313 77.201 152.487 77.8125 151.878C78.424 151.269 79.2534 150.927 80.1182 150.927Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M101.285 152.213H97.8002L96.1426 138.492L101.285 138.493L101.285 152.213Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M94.2608 150.927H102.143V155.214H91V154.174C91 153.313 91.3436 152.487 91.9551 151.878C92.5666 151.269 93.396 150.927 94.2608 150.927Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M102.571 101.618L102.282 121.998L101.992 148.783L95.6212 148.2L92.436 111.226L88.3819 148.2H81.805L81.1426 110.935L83.4591 102.783L102.571 101.618Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M99.1426 99.3307L105.627 88.5234L102.985 81.5421L106.583 72.0336L106.716 72.09C106.803 72.1269 108.847 73.031 109.15 76.2687L113.285 89.1465L102.25 103.333L99.1426 99.3307Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M68.2265 64.3158L78.4989 71.4685L85.6577 69.4237L94.857 73.6608L94.7906 73.786C94.7472 73.868 93.6897 75.794 90.4404 75.8424L77.2916 78.8938L64 67.0422L68.2265 64.3158Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M82.9618 103.177L82 103.135L86.4673 78.2514L84.6382 70.7303C84.5431 70.339 84.5933 69.926 84.7792 69.5696C84.9652 69.2132 85.274 68.9382 85.647 68.7966L88.9381 67.5489L90.1007 64.3158L99.2741 65.3153L99.4997 67.842C100.494 68.1644 108.68 70.9209 108.115 74.0618C107.557 77.162 104.266 86.9156 104.099 87.4099L103.522 101.398L103.451 101.434C103.366 101.477 97.0431 104.62 90.1741 104.62C87.7551 104.62 85.267 104.23 82.9618 103.177Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M100.184 59.0387C101.958 55.7929 100.766 51.7233 97.5213 49.949C94.277 48.1747 90.2093 49.3676 88.4358 52.6134C86.6623 55.8591 87.8546 59.9287 91.0989 61.703C94.3432 63.4773 98.4109 62.2845 100.184 59.0387Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M99.7586 62.3337C98.4929 63.6832 96.1423 62.9587 95.9774 61.1152C95.9645 60.972 95.9654 60.828 95.9802 60.685C96.0654 59.8684 96.5367 59.127 96.4238 58.2648C96.3983 58.0502 96.3184 57.8457 96.1918 57.6706C95.1832 56.3188 92.8155 58.2753 91.8636 57.0515C91.28 56.3011 91.9661 55.1197 91.5182 54.281C90.927 53.174 89.176 53.7201 88.0779 53.1139C86.8562 52.4394 86.9293 50.5632 87.7335 49.4221C88.7143 48.0303 90.4339 47.2877 92.1321 47.1807C93.8303 47.0737 95.5168 47.5331 97.1022 48.1516C98.9036 48.8542 100.69 49.8253 101.798 51.4106C103.147 53.3386 103.276 55.9306 102.602 58.185C102.192 59.5564 100.792 61.2317 99.7586 62.3337Z"
          fill="currentColor"
          className="text-on-surface"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.196 25.4404V24.1244C109.196 17.3782 106.44 8 93.1036 8H91.5925C78.3211 8 75.5 17.3348 75.5 24.1244V25.4404C75.5 32.0997 78.289 41.7402 91.5925 41.7402H93.1036C105.096 41.2724 109.196 34.0628 109.196 25.4404Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M103.554 31.7408C103.554 33.3477 102.253 34.6503 100.648 34.6503L84.0446 34.6503C82.4398 34.6503 81.1389 33.3477 81.1389 31.7408L81.1389 22.1811C81.1389 20.5742 82.4398 19.2716 84.0446 19.2716L100.648 19.2716C102.253 19.2716 103.554 20.5742 103.554 22.1811V31.7408Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.7276 19.2709L91.7276 16.3615L92.9729 16.3615L92.9729 19.2709L91.7276 19.2709Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.7276 37.5599L91.7276 34.6504L92.9729 34.6504L92.9729 37.5599L91.7276 37.5599Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M81.142 27.0646C81.142 29.5322 81.142 29.2511 81.142 31.5327C79.4226 31.5327 78.0288 29.5322 78.0288 27.0646C78.0288 24.5969 79.4226 22.5964 81.142 22.5964C81.142 24.6879 81.142 24.5969 81.142 27.0646Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M103.556 27.0647C103.556 24.597 103.556 24.8782 103.556 22.5966C105.276 22.5966 106.669 24.597 106.669 27.0647C106.669 29.5324 105.276 31.5328 103.556 31.5328C103.556 29.4414 103.556 29.5324 103.556 27.0647Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M95.4613 14.3875C95.4613 16.0518 94.1139 17.4009 92.4518 17.4009C90.7898 17.4009 89.4424 16.0518 89.4424 14.3875C89.4424 12.7233 90.7898 11.3741 92.4518 11.3741C94.1139 11.3741 95.4613 12.7233 95.4613 14.3875Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M83.7551 39.2097C83.7551 38.0914 84.6616 37.1849 85.7799 37.1849H98.9154C100.034 37.1849 100.94 38.0914 100.94 39.2097C100.94 39.2097 99.0866 40.8967 92.3474 40.8967C85.6082 40.8967 83.7551 39.2097 83.7551 39.2097Z"
          fill="currentColor"
          className="text-control"
        />
        <ellipse
          cx="86.7326"
          cy="25.4949"
          rx="2.496"
          ry="2.49927"
          fill="currentColor"
          className="text-background-variant"
        />
        <ellipse
          cx="97.9635"
          cy="25.4949"
          rx="2.496"
          ry="2.49927"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.5 45.4571V44.4416C143.5 39.2363 141.374 32 131.083 32H129.917C119.677 32 117.5 39.2027 117.5 44.4416V45.4571C117.5 50.5954 119.652 58.034 129.917 58.034H131.083C140.336 57.6731 143.5 52.1101 143.5 45.4571Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M139.146 50.3188C139.146 51.5586 138.143 52.5637 136.904 52.5637L124.093 52.5637C122.855 52.5637 121.851 51.5586 121.851 50.3188L121.851 42.9425C121.851 41.7026 122.855 40.6975 124.093 40.6975L136.904 40.6975C138.143 40.6975 139.146 41.7026 139.146 42.9425V50.3188Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.021 40.6965L130.021 38.4515L130.982 38.4515L130.982 40.6965L130.021 40.6965Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.021 54.8083L130.021 52.5634L130.982 52.5634L130.982 54.8083L130.021 54.8083Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M121.853 46.7098C121.853 48.6139 121.853 48.397 121.853 50.1575C120.526 50.1575 119.451 48.6139 119.451 46.7098C119.451 44.8058 120.526 43.2622 121.853 43.2622C121.853 44.876 121.853 44.8058 121.853 46.7098Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M139.148 46.7102C139.148 44.8061 139.148 45.0231 139.148 43.2626C140.475 43.2626 141.551 44.8061 141.551 46.7102C141.551 48.6143 140.475 50.1578 139.148 50.1578C139.148 48.5441 139.148 48.6143 139.148 46.7102Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M132.902 36.9284C132.902 38.2126 131.862 39.2536 130.58 39.2536C129.297 39.2536 128.258 38.2126 128.258 36.9284C128.258 35.6443 129.297 34.6033 130.58 34.6033C131.862 34.6033 132.902 35.6443 132.902 36.9284Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M123.869 56.0815C123.869 55.2186 124.569 54.5192 125.432 54.5192H135.567C136.43 54.5192 137.129 55.2186 137.129 56.0815C137.129 56.0815 135.699 57.3832 130.499 57.3832C125.299 57.3832 123.869 56.0815 123.869 56.0815Z"
          fill="currentColor"
          className="text-control"
        />
        <ellipse
          cx="126.167"
          cy="45.499"
          rx="1.92593"
          ry="1.92845"
          fill="currentColor"
          className="text-background-variant"
        />
        <ellipse
          cx="134.833"
          cy="45.499"
          rx="1.92593"
          ry="1.92845"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M161.5 79.1761V78.3559C161.5 74.1516 159.783 68.3069 151.471 68.3069H150.529C142.258 68.3069 140.5 74.1245 140.5 78.3559V79.1761C140.5 83.3262 142.238 89.3344 150.529 89.3344H151.471C158.945 89.0428 161.5 84.5497 161.5 79.1761Z"
          fill="currentColor"
          className="text-background-variant"
        />
        <path
          d="M157.984 83.1029C157.984 84.1044 157.173 84.9162 156.173 84.9162L145.826 84.9162C144.825 84.9162 144.015 84.1044 144.015 83.1029L144.015 77.1451C144.015 76.1437 144.825 75.3319 145.826 75.3319L156.173 75.3319C157.173 75.3319 157.984 76.1437 157.984 77.1452L157.984 83.1029Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.613 75.3311L150.613 73.5178L151.389 73.5178L151.389 75.3311L150.613 75.3311Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.613 86.7289L150.613 84.9156L151.389 84.9156L151.389 86.7289L150.613 86.7289Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M144.016 80.1881C144.016 81.726 144.016 81.5507 144.016 82.9727C142.945 82.9727 142.076 81.726 142.076 80.1881C142.076 78.6502 142.945 77.4034 144.016 77.4034C144.016 78.7069 144.016 78.6502 144.016 80.1881Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          d="M157.985 80.1882C157.985 78.6503 157.985 78.8255 157.985 77.4035C159.057 77.4035 159.925 78.6503 159.925 80.1882C159.925 81.7261 159.057 82.9728 157.985 82.9728C157.985 81.6693 157.985 81.7261 157.985 80.1882Z"
          fill="currentColor"
          className="text-control"
        />

        <path
          d="M145.645 87.7573C145.645 87.0604 146.21 86.4955 146.907 86.4955H155.093C155.79 86.4955 156.355 87.0604 156.355 87.7573C156.355 87.7573 155.2 88.8087 151 88.8087C146.8 88.8087 145.645 87.7573 145.645 87.7573Z"
          fill="currentColor"
          className="text-control"
        />
        <ellipse
          cx="147.5"
          cy="79.2099"
          rx="1.55556"
          ry="1.55759"
          fill="currentColor"
          className="text-background-variant"
        />
        <ellipse
          cx="154.5"
          cy="79.2099"
          rx="1.55556"
          ry="1.55759"
          fill="currentColor"
          className="text-background-variant"
        />
      </g>
      <defs>
        <clipPath id="clip0_9224_57527">
          <rect width="189" height="205" fill="currentColor" className="text-surface" />
        </clipPath>
      </defs>
    </svg>
  );
};

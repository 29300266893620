import { FC, useEffect, useRef } from 'react';
import { ColumnItem, ColumnItemProps } from '..';
import { ScrollPagination } from './ScrollPagination';
import { ColumnItemSkeleton } from './ColumnItemSkeleton';

type ColumnItemsProps = {
  isLoading?: boolean;
  items: ColumnItemProps[];
  isLoadingPagination?: boolean;
  showPagination?: boolean;
  paginationCounter?: number;
  loadNextPage?: () => void;
  setIsColumnVisible?: (isVisible: boolean) => void;
};
export const ColumnItems: FC<ColumnItemsProps> = ({
  isLoading,
  items,
  isLoadingPagination,
  showPagination,
  paginationCounter,
  loadNextPage,
  setIsColumnVisible,
}) => {
  const ref = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver((entries) => {
      setIsColumnVisible && setIsColumnVisible(entries[0].isIntersecting);
    });
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ul ref={ref} className="bg-surface w-[100%] grow overflow-y-auto">
      {isLoading ? (
        <>
          {Array(6)
            .fill('')
            .map((_, i) => (
              <ColumnItemSkeleton key={`skeleton-${i}`} />
            ))}
        </>
      ) : (
        <>
          {items.map((item, index) => {
            return (
              <li key={item.href || item.label + index}>
                <ColumnItem {...item} />
              </li>
            );
          })}
          {showPagination && paginationCounter && (
            <ScrollPagination
              isLoading={!!isLoadingPagination}
              counter={paginationCounter}
              enableInfiniteScroll
              handleClick={() => {
                loadNextPage && loadNextPage();
              }}
            />
          )}
        </>
      )}
    </ul>
  );
};

'use client';

import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import useGlobalEvent from 'beautiful-react-hooks/useGlobalEvent';
import { useState } from 'react';

/**
 * Hook that returns the current window size.
 *
 * @returns {Object} An object containing the width and height of the window.
 */
export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const onWindowResize = useGlobalEvent('resize');

  const onWindowResizeHandler = useDebouncedCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  });

  onWindowResize(onWindowResizeHandler);

  return windowSize;
};

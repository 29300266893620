'use client';

import { ButtonIcon, ButtonVariant, Modal } from '@unique/component-library';
import { FC, useState } from 'react';
interface NetPromoterScoreProps {
  text: string;
  handleSubmitRating: (score: number) => void;
  handleIgnoreRating: () => void;
  shouldShow: boolean;
  closeModal: () => void;
}

export const NetPromoterScore: FC<NetPromoterScoreProps> = ({
  text,
  handleSubmitRating,
  handleIgnoreRating,
  shouldShow,
  closeModal,
}) => {
  const [rating, setRating] = useState<number>();

  const onRatingClick = (rating: number) => {
    setRating(rating);
  };

  const handleModalClose = () => {
    handleIgnoreRating();
    closeModal();
  };

  const handleSubmit = () => {
    if (rating) {
      handleSubmitRating(rating);
      closeModal();
    }
  };

  const ratingValueFromCount = (count: number) => count + 1;

  return (
    <Modal shouldShow={shouldShow} handleClose={handleModalClose}>
      <h2 className="text-on-background-main px-6 py-6 text-center font-extrabold text-[16px] sm:text-xl">{text}</h2>
      <div className="flex w-full justify-center">
        <div className="flex w-fit flex-col justify-center gap-4">
          <div className="text-on-background-main block text-left sm:hidden">Not likely</div>
          <div className="flex w-full flex-wrap gap-3">
            {[...Array(10)].map((x, i) => (
              <ButtonIcon
                variant={
                  !!rating && rating == ratingValueFromCount(i)
                    ? ButtonVariant.PRIMARY
                    : ButtonVariant.TERTIARY
                }
                onClick={() => onRatingClick(ratingValueFromCount(i))}
                key={i}
              >
                <div className="flex items-center">
                  <span className="subtitle-2">{i + 1}</span>
                </div>
              </ButtonIcon>
            ))}
          </div>
          <div className="flex justify-between">
            <div className="text-on-background-main hidden text-left sm:block">Not likely</div>
            <div className="text-on-background-main ml-auto text-right">Very likely</div>
          </div>
        </div>
      </div>
      <ButtonIcon
        onClick={handleSubmit}
        disabled={!rating}
        variant={ButtonVariant.PRIMARY}
        className="float-right mt-10"
      >
        Submit
      </ButtonIcon>
    </Modal>
  );
};
import { FC } from 'react';
import { IconFilePlain, IconLoading, IconProps } from '.';

export const IconFileLoading: FC<IconProps> = ({
  width = '24px',
  height = '32px',
  className = '',
}) => {
  return (
    <div className={`relative h-[${height}] w-[${width}] ${className}`}>
      <IconLoading className='mt-2.5 ml-1' width='16px' height='16px' />
      <IconFilePlain />
    </div>
  );
};

'use client';

import { IconLogoFavicon } from '@unique/icons';
import ReactDomServer from 'react-dom/server';
import { PRIMARY_COLOR_NAME } from '../constants/primary-color-name';

export enum FaviconTypes {
  Image = 'Image',
  Color = 'Color',
}

/**
 * Loads the favicon url based on an svg, sets the correct theme color,
 * converts it to base64 and sets it on the favicon link element.
 */
export const loadFaviconUrl = (favicon: string, type: FaviconTypes) => {
  const htmlLinkElements = document.getElementsByTagName('link');
  const faviconLinkElement = htmlLinkElements.namedItem('favicon');
  if (!faviconLinkElement) {
    return;
  }

  if (type === FaviconTypes.Color) {
    if (!favicon) {
      favicon = getComputedStyle(document.documentElement).getPropertyValue(
        `--${PRIMARY_COLOR_NAME}`,
      );
    }
    const favIconAsString = ReactDomServer.renderToString(<IconLogoFavicon color={favicon} />);
    const url = 'data:image/svg+xml;base64,' + window.btoa(favIconAsString);

    faviconLinkElement.href = url;
  }

  if (type === FaviconTypes.Image) {
    faviconLinkElement.href = favicon;
  }
};

/**
 * Loads the name in the <title></title> tag from local storage. If no name is found,
 * it will use the fallback name defined on the title elements data-attribute
 */
export const loadTabName = (name?: string) => {
  const title = document.getElementsByTagName('title');
  if (title[0]) {
    const fallbackTabName = title[0].dataset.fallbackTabName || 'Unique';
    title[0].innerHTML = name ?? fallbackTabName;
  }
};

export const setBgColor = (color?: string) => {
  if (!color) return;
  document.body.style.backgroundColor = color;
};

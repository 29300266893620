import { FC } from 'react';
import { IconProps } from '.';

export const IconSpaceSource: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="6" fill="currentColor" className="text-background-variant" />
      <path
        d="M7.52212 3.571L5.41813 6.5809C5.18623 6.90763 5.45772 7.33337 5.89888 7.33337H10.1012C10.5424 7.33337 10.8138 6.90763 10.582 6.5809L8.48362 3.571C8.26304 3.25417 7.7427 3.25417 7.52212 3.571Z"
        fill="#19AADA"
      />
      <path
        d="M3.83337 12.6666H6.83337C7.10837 12.6666 7.33337 12.4416 7.33337 12.1666V9.16663C7.33337 8.89163 7.10837 8.66663 6.83337 8.66663H3.83337C3.55837 8.66663 3.33337 8.89163 3.33337 9.16663V12.1666C3.33337 12.4416 3.55837 12.6666 3.83337 12.6666Z"
        fill="#FFC65C"
      />
      <path
        d="M10.6666 12.6666C11.7712 12.6666 12.6666 11.7712 12.6666 10.6666C12.6666 9.56206 11.7712 8.66663 10.6666 8.66663C9.56206 8.66663 8.66663 9.56206 8.66663 10.6666C8.66663 11.7712 9.56206 12.6666 10.6666 12.6666Z"
        fill="#D63384"
      />
    </svg>
  );
};

import { HTMLProps, useEffect, useRef } from 'react';
import cn from 'classnames';

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (indeterminate !== undefined && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  const Checkbox = () => (
    <input
      type="checkbox"
      ref={ref}
      data-is-checkbox
      className={cn({
        'border-control bg-surface checked:bg-primary-cta checked:text-on-primary indeterminate:bg-primary-cta indeterminate:text-on-primary disabled:hover:!border-control hover:border-on-control-dimmed hover:checked:bg-primary-cta hover:checked:text-on-primary hover:indeterminate:bg-primary-cta hover:indeterminate:text-on-primary focus:checked:bg-primary-cta focus:checked:text-on-primary group-hover:border-on-control-dimmed disabled:!bg-control cursor-pointer rounded-[3px] disabled:cursor-not-allowed':
          true,
        [className]: true,
      })}
      {...rest}
    />
  );

  if (rest.label) {
    return (
      <div className="flex items-center leading-4">
        <Checkbox />
        <label
          className="body-2 text-on-background-main ml-3 cursor-pointer font-semibold"
          htmlFor={rest.id ?? rest.label}
        >
          {rest.label}
        </label>
      </div>
    );
  }

  return <Checkbox />;
}
export default IndeterminateCheckbox;

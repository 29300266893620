export enum FileType {
  WORD = 'word',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',
  HTML = 'html',
  HTM = 'htm',
  MD = 'md',
  TXT = 'txt',
  XLS = 'xls',
  XLSX = 'xlsx',
  CSV = 'csv',
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
}

export const FILE_TYPES = {
  // there is a whole zoo of mime types for office & powerpoint documents ...
  // https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc/4212908#4212908
  [FileType.WORD]: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
  ],
  [FileType.DOC]: ['application/msword'],
  [FileType.DOCX]: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  [FileType.PPT]: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ],
  [FileType.PPTX]: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  [FileType.PDF]: ['application/pdf'],
  [FileType.HTM]: ['text/html'],
  [FileType.HTML]: ['text/html'],
  [FileType.MD]: ['text/markdown'],
  [FileType.TXT]: ['text/plain'],
  [FileType.CSV]: ['text/csv'],
  [FileType.XLS]: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  [FileType.XLSX]: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  [FileType.PNG]: ['image/png'],
  [FileType.JPG]: ['image/jpg'],
  [FileType.JPEG]: ['image/jpeg'],
};

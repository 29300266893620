import { IconCheckmark, IconInfo, IconWarning } from '@unique/icons';
import cn from 'classnames';
import { FC, useCallback, useEffect, useState, ReactNode } from 'react';
import { ToastVariant } from '../enums/toast-variant';

type ToastAction = {
  handleClick: () => void;
  label: string;
};

export type ToastProps = {
  message?: string;
  variant?: ToastVariant;
  duration?: number;
  onClear?: () => void;
  toastAction?: ToastAction;
  icon?: ReactNode;
};

const variantClasses: Record<ToastVariant, string> = {
  success: 'bg-success-dark text-on-success-dark',
  error: 'bg-error-dark text-on-error-dark',
  info: 'bg-info text-on-info',
  warning: 'bg-attention text-on-attention',
};

const defaultVisibleDuration = 4000;

export const Toast: FC<ToastProps> = ({
  duration = defaultVisibleDuration,
  message,
  variant = ToastVariant.INFO,
  onClear,
  toastAction,
  icon,
}) => {
  const [visible, setVisible] = useState(!!message);

  useEffect(() => {
    setVisible(!!message);
  }, [message]);

  const clearToast = useCallback(() => {
    setVisible(false);
    onClear && onClear();
  }, [onClear]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (visible && duration !== Infinity) {
      timer = setTimeout(() => {
        clearToast();
      }, duration);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [visible, duration, onClear, message, clearToast]);

  return (
    <div
      data-testid="toast"
      className={cn(
        'z-55 fixed left-1/2 top-[148px] inline-flex -translate-x-1/2 -translate-y-1/2 transform justify-center',
        {
          block: visible,
          hidden: !visible,
        },
      )}
    >
      <div className="flex justify-center">
        <div
          className={cn({
            [variantClasses[variant]]: true,
            'subtitle-2 mx-auto flex min-h-[40px] items-center rounded-lg p-1.5 px-3 text-center':
              true,
          })}
        >
          <span className="mr-2">
            {icon ? (
              icon
            ) : (
              <>
                {variant === ToastVariant.INFO && <IconInfo />}
                {variant === ToastVariant.WARNING && <IconWarning />}
                {variant === ToastVariant.SUCCESS && <IconCheckmark />}
                {variant === ToastVariant.ERROR && <IconWarning />}
              </>
            )}
          </span>
          <div className="flex-1 overflow-hidden text-ellipsis md:whitespace-nowrap">{message}</div>
          {toastAction && (
            <div
              className="ml-4 cursor-pointer whitespace-nowrap font-semibold underline"
              onClick={toastAction.handleClick}
            >
              {toastAction.label}
            </div>
          )}
          <span className="ml-2 cursor-pointer" onClick={clearToast}>
            &#10005;
          </span>
        </div>
      </div>
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconHierarchy: FC<IconProps> = ({ width = '16px', height = '17px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62038 0.5C5.2352 0.5 4.92294 0.822841 4.92294 1.22109V4.44477C4.92294 4.84301 5.2352 5.16585 5.62038 5.16585H10.6091C10.9943 5.16585 11.3065 4.84301 11.3065 4.44477V1.22109C11.3065 0.822841 10.9943 0.5 10.6091 0.5H5.62038ZM6.31781 3.72368V1.94217H9.91164V3.72368H6.31781ZM0.697432 11.8333C0.312251 11.8333 0 12.1561 0 12.5544V15.7781C0 16.1763 0.312251 16.4991 0.697432 16.4991H3.81537C4.20055 16.4991 4.5128 16.1763 4.5128 15.7781V12.5544C4.5128 12.1561 4.20055 11.8333 3.81537 11.8333H0.697432ZM1.39486 15.057V13.2755H3.11793V15.057H1.39486ZM5.74347 12.5544C5.74347 12.1561 6.05572 11.8333 6.4409 11.8333H9.55884C9.94402 11.8333 10.2563 12.1561 10.2563 12.5544V15.7781C10.2563 16.1763 9.94402 16.4991 9.55884 16.4991H6.4409C6.05572 16.4991 5.74347 16.1763 5.74347 15.7781V12.5544ZM7.13834 13.2755V15.057H8.8614V13.2755H7.13834ZM12.1846 11.8333C11.7994 11.8333 11.4872 12.1561 11.4872 12.5544V15.7781C11.4872 16.1763 11.7994 16.4991 12.1846 16.4991H15.3025C15.6877 16.4991 16 16.1763 16 15.7781V12.5544C16 12.1561 15.6877 11.8333 15.3025 11.8333H12.1846ZM12.882 15.057V13.2755H14.6051V15.057H12.882ZM1.64105 8.31308C1.64105 7.91483 1.9533 7.59199 2.33849 7.59199H7.38452V6.6163H8.77939V7.59199H13.8256C14.2108 7.59199 14.523 7.91483 14.523 8.31308V10.8581H13.1282V9.03416H8.77939V10.8071H7.38452V9.03416H3.03592V10.8581H1.64105V8.31308Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconViewFolders = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/View-Folders">
        <path
          id="Vector"
          d="M1.8 17C1.305 17 0.88125 16.8042 0.52875 16.4125C0.17625 16.0208 0 15.55 0 15V3C0 2.45 0.17625 1.97917 0.52875 1.5875C0.88125 1.19583 1.305 1 1.8 1H2.9925C3.4875 1 3.91125 1.19583 4.26375 1.5875C4.61625 1.97917 4.7925 2.45 4.7925 3V15C4.7925 15.55 4.61625 16.0208 4.26375 16.4125C3.91125 16.8042 3.4875 17 2.9925 17H1.8ZM8.415 17C7.92 17 7.49625 16.8042 7.14375 16.4125C6.79125 16.0208 6.615 15.55 6.615 15V3C6.615 2.45 6.79125 1.97917 7.14375 1.5875C7.49625 1.19583 7.92 1 8.415 1H9.6075C10.1025 1 10.5263 1.19583 10.8788 1.5875C11.2313 1.97917 11.4075 2.45 11.4075 3V15C11.4075 15.55 11.2313 16.0208 10.8788 16.4125C10.5263 16.8042 10.1025 17 9.6075 17H8.415ZM15.0075 17C14.5125 17 14.0888 16.8042 13.7363 16.4125C13.3838 16.0208 13.2075 15.55 13.2075 15V3C13.2075 2.45 13.3838 1.97917 13.7363 1.5875C14.0888 1.19583 14.5125 1 15.0075 1H16.2C16.695 1 17.1188 1.19583 17.4713 1.5875C17.8238 1.97917 18 2.45 18 3V15C18 15.55 17.8238 16.0208 17.4713 16.4125C17.1188 16.8042 16.695 17 16.2 17H15.0075Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconAlignLeft: FC<IconProps> = ({
  width = '24px',
  height = '24px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 22V2H4V22H2ZM6 17V14H16V17H6ZM6 10V7H22V10H6Z" fill="currentColor" />
    </svg>
  );
};

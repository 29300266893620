import { FC } from 'react';
import { IconProps } from '.';

export const IconAddSlim: FC<IconProps> = ({ width = '12px', height = '12px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.43993 6.55997L1.13513 6.55992C0.825773 6.55992 0.574995 6.30914 0.574999 5.99978C0.575002 5.69043 0.825785 5.43965 1.13514 5.43966L5.43992 5.4397L5.43988 1.13494C5.43988 0.825588 5.69065 0.574805 6.00001 0.574802C6.30936 0.574799 6.56014 0.825577 6.56015 1.13493L6.56019 5.43971L10.865 5.43975C11.1743 5.43976 11.4251 5.69054 11.4251 5.99989C11.4251 6.30925 11.1743 6.56002 10.865 6.56002L6.5602 6.55998L6.56024 10.8648C6.56025 11.1741 6.30947 11.4249 6.00012 11.4249C5.69076 11.4249 5.43998 11.1741 5.43998 10.8648L5.43993 6.55997Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

/* eslint-disable turbo/no-undeclared-env-vars */
// FIXME: Remove the above line, once we migrated to VITE and use proper ENV variables.
// ENV variables that change during BUILD time (not runtime) should be defined in turbo.json files
// so Turborepo can wipe the cache if they change.
import { ToplevelNavItemProps } from '@unique/component-library';
import { IconShapeHeadEngine, IconShapeChat } from '@unique/icons';

export const getTopLevelNavItems = (
  isKnowledgeUploadVisible: boolean,
  activeItem: 'chat' | 'knowledge-upload',
): ToplevelNavItemProps[] => {
  if (!process.env.CHAT_APP_URL) throw new Error('CHAT_APP_URL is not defined in your .env file');
  if (!process.env.KNOWLEDGE_UPLOAD_APP_URL)
    throw new Error('KNOWLEDGE_UPLOAD_APP_URL is not defined in your .env file');

  return [
    {
      href: process.env.CHAT_APP_URL,
      name: 'Chat',
      icon: <IconShapeChat />,
      isVisible: true,
      isActive: activeItem === 'chat',
    },
    {
      href: process.env.KNOWLEDGE_UPLOAD_APP_URL,
      name: 'Knowledge base',
      icon: <IconShapeHeadEngine />,
      isVisible: isKnowledgeUploadVisible,
      isActive: activeItem === 'knowledge-upload',
    },
  ];
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconFolderUnique: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector 232"
          d="M2 1.86607V17.1339C2 18.1645 2.74791 19 3.6705 19H24.3295C25.2521 19 26 18.1645 26 17.1339V4.12798C26 3.09737 25.2521 2.2619 24.3295 2.2619H12.8106C12.4163 2.2619 12.0347 2.10612 11.7333 1.8221L10.2667 0.439804C9.9653 0.155789 9.58373 0 9.18945 0H3.6705C2.74791 0 2 0.835467 2 1.86607Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          id="Vector 232"
          d="M2 1.86607V17.1339C2 18.1645 2.74791 19 3.6705 19H24.3295C25.2521 19 26 18.1645 26 17.1339V4.12798C26 3.09737 25.2521 2.2619 24.3295 2.2619H12.8106C12.4163 2.2619 12.0347 2.10612 11.7333 1.8221L10.2667 0.439804C9.9653 0.155789 9.58373 0 9.18945 0H3.6705C2.74791 0 2 0.835467 2 1.86607Z"
          fill="black"
          className="opacity-25"
        />
        <g id="Rectangle 1070" filter="url(#filter0_d_7495_18897)">
          <path
            d="M2 7C2 5.89543 2.89543 5 4 5H24C25.1046 5 26 5.89543 26 7V20C26 22.2091 24.2091 24 22 24H6C3.79086 24 2 22.2091 2 20V7Z"
            fill="currentColor"
            className="text-primary-cta"
          />
        </g>
        <path
          id="Exclude"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6008 17.5915L18.9326 16.7853C19.6198 15.9068 19.8289 14.871 19.8289 14.0086V13.6307C19.8289 11.6933 18.7961 9 13.7977 9H13.2314C8.25749 9 7.2002 11.6808 7.2002 13.6307V14.0086C7.2002 15.9211 8.24545 18.6897 13.2314 18.6897H13.7977C14.7484 18.6897 15.5547 18.5894 16.2383 18.4151L19.3544 20L20.6008 17.5915ZM13.5206 16.1099C11.1661 16.1099 10.7509 14.776 10.7509 13.8704V13.7313C10.7509 12.8257 11.1411 11.5421 13.5081 11.5421C15.8751 11.5421 16.2782 12.8257 16.2782 13.7313V13.8704C16.2782 14.776 15.8751 16.1099 13.5206 16.1099Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          id="Exclude"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6008 17.5915L18.9326 16.7853C19.6198 15.9068 19.8289 14.871 19.8289 14.0086V13.6307C19.8289 11.6933 18.7961 9 13.7977 9H13.2314C8.25749 9 7.2002 11.6808 7.2002 13.6307V14.0086C7.2002 15.9211 8.24545 18.6897 13.2314 18.6897H13.7977C14.7484 18.6897 15.5547 18.5894 16.2383 18.4151L19.3544 20L20.6008 17.5915ZM13.5206 16.1099C11.1661 16.1099 10.7509 14.776 10.7509 13.8704V13.7313C10.7509 12.8257 11.1411 11.5421 13.5081 11.5421C15.8751 11.5421 16.2782 12.8257 16.2782 13.7313V13.8704C16.2782 14.776 15.8751 16.1099 13.5206 16.1099Z"
          fill="black"
          className="opacity-25"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7495_18897"
          x="0"
          y="5"
          width="28"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7495_18897" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7495_18897"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

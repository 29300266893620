import { FC } from 'react';
import { IconProps } from '.';

export const IconBotSleeping: FC<IconProps> = ({ width = '48', height = '48' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9999 24.8793V23.0126C47.9999 13.4433 44.0745 0.140625 25.0762 0.140625H22.9237C4.01864 0.140625 0 13.3817 0 23.0126V24.8793C0 34.3253 3.97287 48 22.9237 48H25.0762C42.1589 47.3365 47.9999 37.1099 47.9999 24.8793Z"
        fill="#042834"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9335 24.9717V23.1983C46.9335 14.1075 43.2043 1.46988 25.156 1.46988H23.1111C5.15126 1.46988 1.33356 14.0489 1.33356 23.1983V24.9717C1.33356 33.9453 5.10779 46.9363 23.1111 46.9363H25.156C41.3845 46.306 46.9335 36.5907 46.9335 24.9717Z"
        fill="#073B4C"
      />
      <path
        d="M39.9651 33.7743C39.9651 36.0603 38.1119 37.9134 35.826 37.9134L12.1738 37.9134C9.88782 37.9134 8.03467 36.0603 8.03467 33.7743L8.03467 20.1743C8.03467 17.8883 9.88782 16.0352 12.1738 16.0352L35.826 16.0352C38.1119 16.0352 39.9651 17.8883 39.9651 20.1743L39.9651 33.7743Z"
        fill="url(#paint0_linear_1685_10996)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1131 16.0341L23.1131 11.895L24.887 11.895L24.887 16.0341L23.1131 16.0341Z"
        fill="url(#paint1_linear_1685_10996)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1131 42.0522L23.1131 37.9131L24.887 37.9131L24.887 42.0522L23.1131 42.0522Z"
        fill="url(#paint2_linear_1685_10996)"
      />
      <path
        d="M8.03463 27.1217C8.03463 30.6323 8.03463 30.2323 8.03463 33.4782C5.58537 33.4782 3.59985 30.6323 3.59985 27.1217C3.59985 23.611 5.58537 20.7651 8.03463 20.7651C8.03463 23.7405 8.03463 23.611 8.03463 27.1217Z"
        fill="url(#paint3_linear_1685_10996)"
      />
      <path
        d="M39.9646 27.122C39.9646 23.6114 39.9646 24.0114 39.9646 20.7655C42.4139 20.7655 44.3994 23.6114 44.3994 27.122C44.3994 30.6326 42.4139 33.4785 39.9646 33.4785C39.9646 30.5031 39.9646 30.6326 39.9646 27.122Z"
        fill="url(#paint4_linear_1685_10996)"
      />
      <path
        d="M28.4343 9.08676C28.4343 11.4544 26.5149 13.3737 24.1473 13.3737C21.7797 13.3737 19.8604 11.4544 19.8604 9.08676C19.8604 6.71914 21.7797 4.7998 24.1473 4.7998C26.5149 4.7998 28.4343 6.71914 28.4343 9.08676Z"
        fill="url(#paint5_linear_1685_10996)"
      />
      <path
        d="M11.7605 44.4C11.7605 42.8092 13.0501 41.5195 14.641 41.5195H33.36C34.9509 41.5195 36.2405 42.8092 36.2405 44.4C36.2405 44.4 33.6001 46.8 24.0001 46.8C14.4001 46.8 11.7605 44.4 11.7605 44.4Z"
        fill="url(#paint6_linear_1685_10996)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.75C12.4142 24.75 12.75 25.0858 12.75 25.5C12.75 25.8405 12.8852 26.404 13.3075 26.8733C13.7082 27.3185 14.4315 27.75 15.75 27.75C17.0685 27.75 17.7918 27.3185 18.1925 26.8733C18.6148 26.404 18.75 25.8405 18.75 25.5C18.75 25.0858 19.0858 24.75 19.5 24.75C19.9142 24.75 20.25 25.0858 20.25 25.5C20.25 26.1595 20.0102 27.096 19.3075 27.8767C18.5832 28.6815 17.4315 29.25 15.75 29.25C14.0685 29.25 12.9168 28.6815 12.1925 27.8767C11.4898 27.096 11.25 26.1595 11.25 25.5C11.25 25.0858 11.5858 24.75 12 24.75Z"
        fill="#02161D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 24.75C28.9142 24.75 29.25 25.0858 29.25 25.5C29.25 25.8405 29.3852 26.404 29.8075 26.8733C30.2082 27.3185 30.9315 27.75 32.25 27.75C33.5685 27.75 34.2918 27.3185 34.6925 26.8733C35.1148 26.404 35.25 25.8405 35.25 25.5C35.25 25.0858 35.5858 24.75 36 24.75C36.4142 24.75 36.75 25.0858 36.75 25.5C36.75 26.1595 36.5102 27.096 35.8075 27.8767C35.0832 28.6815 33.9315 29.25 32.25 29.25C30.5685 29.25 29.4168 28.6815 28.6925 27.8767C27.9898 27.096 27.75 26.1595 27.75 25.5C27.75 25.0858 28.0858 24.75 28.5 24.75Z"
        fill="#02161D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 33C21.75 32.5858 22.0858 32.25 22.5 32.25H25.5C25.9142 32.25 26.25 32.5858 26.25 33C26.25 33.4142 25.9142 33.75 25.5 33.75H22.5C22.0858 33.75 21.75 33.4142 21.75 33Z"
        fill="#02161D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5069 13.5V12.653L35.022 7.92664H31.5V6.75H36.8931V7.59705L33.3745 12.3234H36.9V13.5H31.5069ZM38.2534 13.5V12.9918L40.011 10.156H38.25V9.45H40.9466V9.95823L39.1873 12.794H40.95V13.5H38.2534ZM42.3034 12.9918V13.5H45V12.794H43.2373L44.9966 9.95823V9.45H42.3V10.156H44.061L42.3034 12.9918Z"
        fill="url(#paint7_linear_1685_10996)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1685_10996"
          x1="1.23229"
          y1="27.9516"
          x2="17.6378"
          y2="49.2991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FB7EA" />
          <stop offset="1" stopColor="#7EDEFE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1685_10996"
          x1="24.887"
          y1="13.9646"
          x2="23.1131"
          y2="13.9646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1685_10996"
          x1="24.887"
          y1="39.9827"
          x2="23.1131"
          y2="39.9827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1685_10996"
          x1="5.81724"
          y1="20.7651"
          x2="5.81724"
          y2="33.4782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1685_10996"
          x1="42.182"
          y1="33.4785"
          x2="42.182"
          y2="20.7655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1685_10996"
          x1="24.1473"
          y1="4.7998"
          x2="24.1473"
          y2="13.3737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1685_10996"
          x1="6.54534"
          y1="44.3956"
          x2="8.41842"
          y2="52.1378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FB7EA" />
          <stop offset="1" stopColor="#7EDEFE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1685_10996"
          x1="28.8936"
          y1="10.463"
          x2="33.2829"
          y2="18.2899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#FFE4A5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

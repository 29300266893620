import { FC } from 'react';
import { IconProps } from '.';

export const IconCreateSpace: FC<IconProps> = ({ width = '156px', height = '156px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 156 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M152.084 76.4746C152.084 77.8795 152.046 79.2745 151.971 80.6596C151.696 85.8382 150.897 90.9752 149.584 95.9904C149.582 95.9926 149.582 95.9926 149.584 95.9947C149.412 96.658 149.229 97.3193 149.036 97.9741C149.028 98.0058 149.019 98.0354 149.009 98.065C148.723 99.0473 148.418 100.021 148.093 100.985V100.987C142.988 116.133 133.292 129.29 120.37 138.61C107.447 147.93 91.9453 152.945 76.043 152.949C40.0748 152.949 9.94192 127.837 2.03113 94.0934C0.678789 88.3193 -0.00215785 82.4067 0.0019718 76.4746C0.0019718 75.2958 0.028577 74.1233 0.0817917 72.9572C0.712103 58.7012 5.31608 44.9108 13.368 33.1608C13.4646 33.0171 13.5633 32.8735 13.6642 32.7319C27.4062 12.9436 50.2227 -6.10352e-05 76.043 -6.10352e-05C112.534 -6.10352e-05 143.02 25.8492 150.385 60.3347C150.386 60.3357 150.387 60.3373 150.387 60.3389C150.861 62.5613 151.238 64.8175 151.517 67.1075C151.896 70.2153 152.086 73.3435 152.084 76.4746Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M152.87 145.389C151.355 144.346 150.287 142.768 149.879 140.968C149.838 140.79 149.565 140.862 149.606 141.04C150.034 142.912 151.149 144.552 152.727 145.634C152.878 145.737 153.019 145.491 152.87 145.389Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M152.261 129.828C151.932 129.961 151.575 130.01 151.222 129.969C150.87 129.929 150.533 129.802 150.241 129.598C150.092 129.493 149.951 129.739 150.099 129.843C150.422 130.067 150.794 130.207 151.184 130.252C151.573 130.298 151.968 130.246 152.333 130.103C152.369 130.092 152.399 130.068 152.417 130.036C152.436 130.004 152.442 129.966 152.434 129.929C152.424 129.893 152.4 129.862 152.368 129.843C152.336 129.824 152.297 129.819 152.261 129.828Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M14.1604 41.9021H8.19684C4.83349 48.5614 2.46583 55.6836 1.17041 63.0382H14.1604C14.5744 63.039 14.9844 62.9575 15.367 62.7985C15.7496 62.6395 16.0973 62.4061 16.39 62.1117C16.6827 61.8173 16.9148 61.4677 17.0729 61.0829C17.231 60.6981 17.312 60.2857 17.3113 59.8694V45.0688C17.3102 44.2289 16.9778 43.4238 16.387 42.8301C15.7963 42.2364 14.9955 41.9026 14.1604 41.9021Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M58.3396 41.9021H31.3198C30.4847 41.9026 29.6839 42.2364 29.0932 42.8301C28.5024 43.4238 28.17 44.2289 28.1689 45.0688V59.8694C28.17 60.7095 28.5023 61.5149 29.0929 62.109C29.6836 62.703 30.4845 63.0372 31.3198 63.0382H58.3396C59.1749 63.0372 59.9758 62.703 60.5665 62.109C61.1571 61.5149 61.4894 60.7095 61.4904 59.8694V45.0688C61.4893 44.2289 61.157 43.4238 60.5662 42.8301C59.9755 42.2364 59.1747 41.9026 58.3396 41.9021Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M102.517 41.9021H75.4976C74.6624 41.9026 73.8616 42.2364 73.2709 42.8301C72.6802 43.4238 72.3478 44.2289 72.3467 45.0688V59.8694C72.3477 60.7095 72.68 61.5149 73.2707 62.109C73.8614 62.703 74.6622 63.0372 75.4976 63.0382H102.517C103.353 63.0372 104.154 62.703 104.744 62.109C105.335 61.5149 105.667 60.7095 105.668 59.8694V45.0688C105.667 44.2289 105.335 43.4238 104.744 42.8301C104.153 42.2364 103.352 41.9026 102.517 41.9021Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M14.162 72.9568H0.0817917C0.0292772 74.123 0.00267199 75.2955 0.0019718 76.4743C-0.00215785 82.4064 0.678789 88.3189 2.03113 94.093H14.162C14.9973 94.092 15.7982 93.7578 16.3889 93.1637C16.9795 92.5697 17.3118 91.7643 17.3129 90.9242V76.1236C17.3118 75.2837 16.9794 74.4785 16.3886 73.8848C15.7979 73.2911 14.9971 72.9574 14.162 72.9568Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M58.3396 72.9568H31.3198C30.4847 72.9574 29.6839 73.2911 29.0932 73.8848C28.5024 74.4785 28.17 75.2837 28.1689 76.1236V90.9242C28.17 91.7643 28.5023 92.5697 29.0929 93.1637C29.6836 93.7578 30.4845 94.092 31.3198 94.093H58.3396C59.1749 94.092 59.9758 93.7578 60.5665 93.1637C61.1571 92.5697 61.4894 91.7643 61.4904 90.9242V76.1236C61.4893 75.2837 61.157 74.4785 60.5662 73.8848C59.9755 73.2911 59.1747 72.9574 58.3396 72.9568Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M102.517 72.9568H75.4976C74.6624 72.9574 73.8616 73.2911 73.2709 73.8848C72.6802 74.4785 72.3478 75.2837 72.3467 76.1236V90.9242C72.3477 91.7643 72.68 92.5697 73.2707 93.1637C73.8614 93.7578 74.6622 94.092 75.4976 94.093H102.517C102.603 94.093 102.69 94.0888 102.776 94.0803C103.113 94.0554 103.444 93.9732 103.755 93.8374C104.317 93.5952 104.798 93.1936 105.138 92.6816C105.477 92.1696 105.662 91.5693 105.668 90.9537V76.1236C105.667 75.2837 105.335 74.4785 104.744 73.8848C104.153 73.2911 103.352 72.9574 102.517 72.9568Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M14.7272 66.5739C18.1797 66.5739 20.9784 63.7592 20.9784 60.2871C20.9784 56.815 18.1797 54.0003 14.7272 54.0003C11.2748 54.0003 8.47607 56.815 8.47607 60.2871C8.47607 63.7592 11.2748 66.5739 14.7272 66.5739Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M13.8709 63.5592C13.763 63.5592 13.6565 63.5339 13.56 63.4854C13.4634 63.4368 13.3794 63.3663 13.3147 63.2794L11.6093 60.9925C11.5545 60.9191 11.5146 60.8355 11.492 60.7465C11.4693 60.6575 11.4643 60.565 11.4772 60.474C11.4901 60.3831 11.5207 60.2957 11.5672 60.2167C11.6138 60.1376 11.6753 60.0686 11.7484 60.0135C11.8214 59.9584 11.9045 59.9183 11.993 59.8955C12.0815 59.8728 12.1735 59.8677 12.2639 59.8807C12.3543 59.8937 12.4413 59.9245 12.5198 59.9712C12.5984 60.018 12.6671 60.0799 12.7218 60.1534L13.8376 61.6494L16.7032 57.3265C16.7538 57.25 16.8189 57.1843 16.8948 57.133C16.9707 57.0818 17.0559 57.0462 17.1455 57.0281C17.2351 57.01 17.3273 57.0099 17.417 57.0277C17.5066 57.0455 17.5919 57.0809 17.6679 57.1319C17.744 57.1829 17.8093 57.2484 17.8601 57.3248C17.9109 57.4012 17.9463 57.4869 17.9642 57.577C17.982 57.6672 17.9821 57.7599 17.9643 57.8501C17.9464 57.9402 17.9111 58.026 17.8604 58.1024L14.4495 63.2478C14.3878 63.3408 14.3048 63.4177 14.2075 63.4718C14.1102 63.526 14.0014 63.5558 13.8903 63.559C13.8838 63.5591 13.8774 63.5592 13.8709 63.5592Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M60.3107 67.2074C63.7631 67.2074 66.5619 64.3927 66.5619 60.9206C66.5619 57.4485 63.7631 54.6338 60.3107 54.6338C56.8583 54.6338 54.0596 57.4485 54.0596 60.9206C54.0596 64.3927 56.8583 67.2074 60.3107 67.2074Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M59.4559 64.1921C59.3479 64.1921 59.2415 64.1668 59.1449 64.1182C59.0484 64.0697 58.9644 63.9992 58.8996 63.9123L57.1942 61.6254C57.1394 61.5519 57.0996 61.4683 57.0769 61.3794C57.0543 61.2904 57.0493 61.1978 57.0622 61.1069C57.0751 61.016 57.1057 60.9285 57.1522 60.8495C57.1987 60.7705 57.2603 60.7015 57.3333 60.6464C57.4064 60.5913 57.4895 60.5512 57.578 60.5284C57.6664 60.5056 57.7585 60.5006 57.8489 60.5136C57.9393 60.5266 58.0262 60.5573 58.1048 60.6041C58.1834 60.6509 58.252 60.7128 58.3068 60.7863L59.4225 62.2823L62.2882 57.9594C62.3906 57.8053 62.5496 57.6984 62.7303 57.6621C62.911 57.6259 63.0987 57.6633 63.252 57.7661C63.4054 57.8689 63.5119 58.0287 63.5481 58.2104C63.5843 58.3921 63.5474 58.5809 63.4453 58.7352L60.0345 63.8806C59.9728 63.9737 59.8898 64.0505 59.7925 64.1047C59.6952 64.1588 59.5864 64.1887 59.4752 64.1918C59.4688 64.192 59.4623 64.1921 59.4559 64.1921Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M149.578 94.7981L147.621 75.7505L147.105 67.9111L152.582 66.9152C152.582 66.9152 153.402 72.3044 154.119 75.467C154.827 78.5878 153.176 94.3799 153.132 94.7964C153.535 95.1581 153.818 95.6341 153.945 96.1614C154.073 96.6887 154.038 97.2425 153.846 97.7495C153.653 98.2564 153.312 98.6928 152.868 99.0006C152.424 99.3085 151.897 99.4735 151.357 99.4738C150.818 99.474 150.291 99.3096 149.846 99.0021C149.402 98.6946 149.06 98.2586 148.868 97.7519C148.675 97.2451 148.639 96.6913 148.766 96.1639C148.893 95.6365 149.176 95.1602 149.578 94.7981Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M135.56 151.496L132.844 152.121L129.156 141.882L133.165 140.96L135.56 151.496Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M151.751 153.282H148.965L147.64 142.474L151.752 142.475L151.751 153.282Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M136.048 150.446L135.355 150.605L135.284 150.621L132.114 150.102L132.022 150.087L130.698 151.676C129.794 151.884 129.009 152.444 128.516 153.234C128.023 154.024 127.862 154.979 128.068 155.888L128.072 155.907L128.096 156L128.499 155.907L136.853 153.985L136.048 150.446Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M151.679 152.368L151.436 152.269L148.705 151.151L148.617 151.115L146.976 152.368C146.517 152.368 146.062 152.459 145.638 152.635C145.214 152.812 144.828 153.071 144.503 153.397C144.179 153.724 143.921 154.111 143.745 154.538C143.569 154.964 143.479 155.422 143.479 155.883V155.998L151.169 156H152.463V152.368H151.679Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M147.515 95.4381L149.859 117.827C149.859 117.827 152.978 140.245 152.392 143.338C151.807 146.431 152.539 146.873 152.539 146.873L147.193 146.49C147.193 146.49 147.34 144.576 146.461 143.692C145.582 142.808 146.022 140.157 146.022 140.157L135.652 103.687L129.354 122.835C129.354 122.835 133.333 138.364 133.186 138.658C133.04 138.953 135.707 145.418 135.707 145.418L129.74 147.408C129.74 147.408 130.472 145.052 129.447 144.315C128.421 143.579 127.543 144.168 128.421 142.842C129.3 141.517 121.498 131.781 121.669 125.506C121.721 123.619 121.783 121.594 121.858 119.519C122.255 108.539 123.004 96.1676 124.374 95.4381C126.864 94.1124 147.515 95.4381 147.515 95.4381Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M136.418 50.1058C139.601 50.1058 142.181 47.5106 142.181 44.3093C142.181 41.1079 139.601 38.5127 136.418 38.5127C133.234 38.5127 130.654 41.1079 130.654 44.3093C130.654 47.5106 133.234 50.1058 136.418 50.1058Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M109.411 86.8816C109.411 86.8816 120.214 75.4276 120.315 75.2117C121.069 69.4486 123.24 66.9064 123.565 67.1824L129.167 69.1341C129.167 69.1341 127.501 74.4406 126.373 77.5926C125.295 80.6045 112.87 87.961 112.663 88.3242C112.886 88.8185 112.954 89.3692 112.859 89.9033C112.763 90.4374 112.509 90.9298 112.129 91.3154C111.75 91.701 111.262 91.9615 110.732 92.0627C110.202 92.1638 109.654 92.1007 109.16 91.8817C108.667 91.6627 108.251 91.2981 107.968 90.8362C107.685 90.3743 107.547 89.8368 107.575 89.2949C107.602 88.7529 107.792 88.232 108.12 87.8011C108.448 87.3702 108.898 87.0496 109.411 86.8816Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.544 96.2116C123.544 96.2116 138.336 100.197 149.498 97.879C149.498 97.879 149.66 94.5787 149.601 93.3286C149.565 92.5423 149.402 92.2339 149.255 91.9559C149.169 91.7912 149.087 91.6372 149.041 91.4008C148.996 91.1672 148.907 91.0971 148.838 91.0427C148.72 90.9492 148.661 90.9026 148.984 90.1507C149.494 88.96 147.008 79.9816 147.008 79.9816L147.181 78.2938L147.291 78.8729L147.885 79.5067L147.831 81.7159L155.657 79.718C155.941 79.2417 156.053 78.6815 155.974 78.1321C155.894 77.5826 155.629 77.0775 155.222 76.702C155.149 76.6442 155.094 76.5654 155.066 76.476C154.246 72.2116 152.68 64.8695 151.05 61.5361C150.563 60.5238 149.935 59.5869 149.184 58.754V58.7494L149.176 58.7455C149.176 58.7448 149.175 58.7441 149.174 58.7433L149.169 58.7393L144.844 56.4324L144.845 56.4353L144.84 56.433L142.845 55.3686L140.317 52.0223L133.994 51.8136L131.78 55.001L129.102 56.0099C128.409 56.271 127.805 56.702 127.322 57.2485C126.465 57.6484 125.667 58.4146 125.12 59.78C124.883 60.3902 124.56 61.2009 124.188 62.1357L124.188 62.1357C122.945 65.2615 121.149 69.7752 120.137 72.8235C120.128 72.8487 120.116 72.8726 120.101 72.8946C119.75 73.4199 119.56 74.0378 119.556 74.6711C119.556 74.7172 119.551 74.7632 119.542 74.8082C119.542 74.8082 124.871 75.8524 126.456 78.6621L127.085 77.9759L127.197 77.4951L127.328 76.9323L127.49 79.0091L123.544 96.2116Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M142.664 42.2457C142.209 41.7938 142.895 40.5974 142.765 39.9672C142.617 39.2447 141.825 39.2236 141.636 38.5107C141.433 37.7438 140.62 37.3263 139.869 37.0832C137.943 36.4635 135.889 36.3689 133.915 36.8089C133.041 37.0235 132.181 37.2899 131.339 37.607C130.757 37.8128 131.318 37.2528 130.737 37.4586C130.516 37.5079 130.322 37.6375 130.191 37.8223C130.087 38.0211 130.228 38.3389 130.448 38.2987C129.738 38.6135 129.028 38.9283 128.318 39.2431C128.716 39.6625 129.115 40.0818 129.513 40.5013C128.947 40.3393 128.343 41.0178 128.566 41.5654C128.656 41.7382 128.786 41.8865 128.946 41.9975C129.105 42.1085 129.289 42.1789 129.482 42.2025C130.121 42.3276 129.636 42.9396 130.287 42.9276C130.938 42.9157 135.48 41.5732 135.796 42.1456C136.022 42.5533 135.997 43.0932 136.319 43.4291C136.731 43.8588 137.45 43.7016 137.974 43.4219C138.498 43.1422 139.03 42.7577 139.62 42.8248C140.235 42.8948 140.701 44.0389 140.852 44.6431C141.004 45.2472 140.908 45.8829 140.812 46.4984C140.685 47.3067 139.416 48.1151 139.289 48.9234C140.698 49.3151 142.208 48.0689 142.765 45.9018C142.71 44.5318 143.635 43.2086 142.664 42.2457Z"
        fill="currentColor"
        className="text-attention"
      />
      <path
        d="M104.002 97.2059C107.454 97.2059 110.253 94.3912 110.253 90.9191C110.253 87.447 107.454 84.6323 104.002 84.6323C100.549 84.6323 97.7505 87.447 97.7505 90.9191C97.7505 94.3912 100.549 97.2059 104.002 97.2059Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M103.144 94.1905C103.036 94.1905 102.929 94.1652 102.833 94.1166C102.736 94.0681 102.652 93.9976 102.588 93.9107L100.882 91.6238C100.827 91.5503 100.788 91.4667 100.765 91.3778C100.742 91.2888 100.737 91.1962 100.75 91.1053C100.763 91.0144 100.794 90.9269 100.84 90.8479C100.887 90.7689 100.948 90.6999 101.021 90.6448C101.094 90.5897 101.177 90.5496 101.266 90.5268C101.354 90.504 101.446 90.499 101.537 90.512C101.627 90.525 101.714 90.5557 101.793 90.6025C101.871 90.6493 101.94 90.7112 101.995 90.7847L103.111 92.2807L105.976 87.9578C106.027 87.8813 106.092 87.8155 106.168 87.7643C106.244 87.7131 106.329 87.6775 106.418 87.6594C106.508 87.6413 106.6 87.6412 106.69 87.659C106.78 87.6768 106.865 87.7122 106.941 87.7632C107.017 87.8142 107.082 87.8797 107.133 87.9561C107.184 88.0325 107.219 88.1182 107.237 88.2083C107.255 88.2984 107.255 88.3912 107.237 88.4814C107.219 88.5715 107.184 88.6572 107.133 88.7336L103.722 93.879C103.661 93.9721 103.578 94.0489 103.48 94.1031C103.383 94.1572 103.274 94.1871 103.163 94.1902C103.157 94.1904 103.15 94.1905 103.144 94.1905Z"
        fill="currentColor"
        className="text-surface"
      />
    </svg>
  );
};

import cn from 'classnames';
import { omit } from 'lodash';
import { FC } from 'react';
import { HeaderColumn, useTable } from 'react-table';

export interface ITable<T extends object> {
  id: string;
  columns: HeaderColumn<object>[];
  data: T[];
  headerClassName?: string;
  rowClassName?: string;
  cellClassName?: string;
  className?: string;
  onClick?: (rowId: string) => void;
}

const defaultTableRowHeadClasses =
  'vertical text-2xs border-b border-control font-semibold uppercase tracking-widest text-on-secondary';

const defaultTableHeadClasses =
  'gap-2 whitespace-nowrap py-3 overline-text first-of-type:pl-4 last-of-type:pr-4 sm:first-of-type:pl-6 sm:last-of-type:pr-6';

const defaultTableRowClasses =
  'z-0 border-b border-control transition-colors duration-[200ms] last-of-type:border-none focus-within:!z-20';

const defaultTableColumnClasses =
  'h-full items-center py-4 pr-5 body-2 leading-normal first-of-type:pl-4 last-of-type:pr-4 text-on-control-main sm:first-of-type:pl-6 sm:last-of-type:pr-6';

export const Table: FC<ITable<object>> = ({
  id,
  columns,
  data,
  headerClassName,
  rowClassName,
  cellClassName,
  className,
  onClick,
}) => {
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
    disableSorting: false,
  });

  return (
    <div className={className ?? ''}>
      <div className="body w-full">
        <table {...getTableProps()} className="w-full table-auto text-left">
          <thead>
            {headerGroups.map((headerGroup, trHeadId) => {
              const headerGroupProps = omit(headerGroup.getHeaderGroupProps(), 'key');
              return (
                <tr
                  {...headerGroupProps}
                  className={defaultTableRowHeadClasses}
                  key={`${id}-tr-head-${trHeadId}`}
                >
                  {headerGroup.headers.map((column, thId) => {
                    const headerProps = omit(column.getHeaderProps(), 'key');
                    return (
                      <th
                        {...headerProps}
                        className={cn([defaultTableHeadClasses, headerClassName])}
                        key={`${id}-th-${trHeadId}-${thId}`}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowId) => {
              prepareRow(row);
              const rowProps = omit(row.getRowProps(), 'key');
              return (
                <tr
                  onClick={() => row.original?.id && onClick && onClick(row.original.id)}
                  key={`${id}-tr-${rowId}`}
                  className={cn([
                    {
                      [defaultTableRowClasses]: true,
                    },
                    {
                      'hover:bg-secondary cursor-pointer': typeof onClick === 'function',
                    },
                    rowClassName,
                  ])}
                  {...rowProps}
                >
                  {row.cells.map((cell, cellId) => {
                    const cellProps = omit(cell.getCellProps(), 'key');
                    return (
                      <td
                        className={cn([defaultTableColumnClasses, cellClassName])}
                        key={`${id}-td-${rowId}-${cellId}`}
                        {...cellProps}
                        style={{
                          width: cell.column.width,
                          display: 'table-cell',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

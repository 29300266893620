import { FC } from 'react';
import { IconProps } from '.';

export const IconLocalUpload: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="6" fill="currentColor" className="text-background-variant" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5463 10.6512L12.1504 9.97658C12.7254 9.24153 12.9004 8.37484 12.9004 7.65322V7.33698C12.9004 5.71585 12.0362 3.46223 7.85374 3.46223H7.37987C3.21795 3.46223 2.33325 5.70541 2.33325 7.33698V7.65322C2.33325 9.25346 3.20787 11.5701 7.37987 11.5701H7.85374C8.64926 11.5701 9.32389 11.4862 9.8959 11.3404L12.5033 12.6665L13.5463 10.6512ZM7.62226 9.412C5.65212 9.412 5.30474 8.29582 5.30474 7.53803V7.42167C5.30474 6.66388 5.63123 5.58984 7.61181 5.58984C9.5924 5.58984 9.92971 6.66388 9.92971 7.42167V7.53803C9.92971 8.29582 9.5924 9.412 7.62226 9.412Z"
        fill="currentColor"
        className="text-primary-cta"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconLogout: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22222 2.22222H8.88889C9.5 2.22222 10 1.72222 10 1.11111C10 0.5 9.5 0 8.88889 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H8.88889C9.5 20 10 19.5 10 18.8889C10 18.2778 9.5 17.7778 8.88889 17.7778H2.22222V2.22222Z"
        fill="currentColor"
      />
      <path
        d="M19.6111 9.61108L16.5111 6.51108C16.1556 6.15553 15.5556 6.39997 15.5556 6.89997V8.88886H7.77778C7.16666 8.88886 6.66666 9.38886 6.66666 9.99997C6.66666 10.6111 7.16666 11.1111 7.77778 11.1111H15.5556V13.1C15.5556 13.6 16.1556 13.8444 16.5 13.4889L19.6 10.3889C19.8222 10.1778 19.8222 9.82219 19.6111 9.61108Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconEdit: FC<IconProps> = ({ width = '32px', height = '32px', className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36624 19.5322L3.9998 20.0002L4.48621 16.6283L14.8159 6.27551L17.7216 9.1666L7.36624 19.5322Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3104 8.58325L15.4239 5.69216L17.1136 3.99982L20 6.89091L18.3104 8.58325Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { Service, useClientSWR } from '@unique/next-commons/swr';
import { AuthContextProps } from 'react-oidc-context';
import { SWRConfiguration } from 'swr';
import { ThemeDocument, ThemeQuery, ThemeQueryVariables } from '../../@generated/graphql';

export function useThemeQuery(
  shouldFetch: boolean,
  auth: AuthContextProps,
  variables?: ThemeQueryVariables,
  options?: Partial<SWRConfiguration<ThemeQuery>>,
) {
  const key = shouldFetch && auth.isAuthenticated ? { query: ThemeDocument, variables } : null;
  return useClientSWR<ThemeQuery, ThemeQueryVariables>(key, options, Service.NODE_THEME);
}

import { FC } from 'react';
import { IconProps } from '.';

export const IconCheckmark: FC<IconProps> = ({
  width = '13px',
  height = '13px',
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3729 0.15359C12.9613 0.480469 13.1733 1.22243 12.8464 1.81082L6.97846 12.3731C6.79462 12.704 6.46748 12.9307 6.0931 12.9866C5.71872 13.0425 5.33961 12.9213 5.06713 12.6586L0.372767 8.13185C-0.11175 7.66464 -0.125777 6.89311 0.341435 6.40859C0.808648 5.92408 1.58018 5.91005 2.06469 6.37726L5.61773 9.8034L10.7157 0.627083C11.0426 0.0387013 11.7845 -0.173289 12.3729 0.15359Z"
        fill="currentColor"
      />
    </svg>
  );
};

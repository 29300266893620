import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  classes?: string;
  url?: string; // External link
};

export default function Badge(props: Props) {
  const { children, classes = '', url } = props;

  const BadgeUI = () => {
    return (
      <div
        className={`hover:box-shadow-xl text-on-error-light inline-block h-[16px] min-w-[16px] items-center justify-center rounded-[4px] text-center text-xs font-bold leading-[16px] transition ${
          url ? 'pointer-events-auto' : 'pointer-events-none'
        } ${classes} bg-error-light`}
      >
        {children}
      </div>
    );
  };

  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <BadgeUI />
      </a>
    );
  }

  return <BadgeUI />;
}

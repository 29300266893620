import { FC } from 'react';
import { IconProps } from '.';

export const IconBenchmarking: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0333 9.95588L20.3417 5.57059L19.4861 4.70588L16.0333 8.19559L14.2917 6.43529L13.4361 7.33088L16.0333 9.95588ZM1 22V19.5294H15.6667V22H1ZM16.8889 13.3529C15.1981 13.3529 13.7569 12.7507 12.5653 11.5463C11.3736 10.3419 10.7778 8.88529 10.7778 7.17647C10.7778 5.46765 11.3736 4.01103 12.5653 2.80662C13.7569 1.60221 15.1981 1 16.8889 1C18.5796 1 20.0208 1.60221 21.2125 2.80662C22.4042 4.01103 23 5.46765 23 7.17647C23 8.88529 22.4042 10.3419 21.2125 11.5463C20.0208 12.7507 18.5796 13.3529 16.8889 13.3529ZM1 12.1176V9.64706H8.7C8.84259 10.1 9.00556 10.5324 9.18889 10.9441C9.37222 11.3559 9.5963 11.7471 9.86111 12.1176H1ZM1 17.0588V14.5882H12.4889C12.9574 14.8765 13.4565 15.1184 13.9861 15.314C14.5157 15.5096 15.0759 15.6485 15.6667 15.7309V17.0588H1Z"
        fill="currentColor"
      />
    </svg>
  );
};

'use client';
import {
  ButtonIcon,
  ButtonVariant,
  InputField,
  SelectedItem,
  Textarea,
} from '@unique/component-library';
import Badge from '@unique/component-library/src/Badge';
import { IconLightBulb } from '@unique/icons';
import { UserPrompt } from '@unique/shared-library/@generated/graphql';
import { ChangeEvent } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export interface PromptsFormData extends FieldValues {
  userPrompts?: Partial<UserPrompt>[];
}

type SuggestedPromptsProps = {
  suggestedPrompts: Partial<UserPrompt>[];
  handleUpdateSuggestedPrompt: (suggestedPrompt: Partial<UserPrompt>, id: number) => void;
  handleRemoveSuggestedPrompt: (indexToRemove: string | number) => void;
  handleAddSuggestedPrompt: () => void;
  register: UseFormRegister<PromptsFormData>;
  title?: string;
  addButtonIcon?: JSX.Element;
};

export const SuggestedPrompts = ({
  suggestedPrompts,
  handleUpdateSuggestedPrompt,
  handleRemoveSuggestedPrompt,
  handleAddSuggestedPrompt,
  title = 'Suggested Prompts',
  addButtonIcon,
  register,
}: SuggestedPromptsProps) => {
  const handleChangePrompt = (
    e: ChangeEvent<HTMLTextAreaElement>,
    suggestedPrompt: Partial<UserPrompt>,
    index: number,
  ) => {
    const newValue = e.target.value;
    suggestedPrompt.prompt = newValue;
    handleUpdateSuggestedPrompt(suggestedPrompt, index);
  };

  const handleChangeTitle = (
    e: ChangeEvent<HTMLInputElement>,
    suggestedPrompt: Partial<UserPrompt>,
    index: number,
  ) => {
    const newValue = e.target.value;
    suggestedPrompt.title = newValue;
    handleUpdateSuggestedPrompt(suggestedPrompt, index);
  };

  const promptsLength =
    suggestedPrompts?.filter((prompt) => prompt.title && prompt.prompt).length || 0;

  return (
    <div className="mb-12">
      <div className="mb-3 flex items-center">
        <div className="subtitle-1 text-on-background-main mr-2">{title}</div>
        <Badge classes="body-2 !bg-background-variant !text-on-background-dimmed my-auto !h-6 px-[6px] py-[2px] leading-[20px]">{`${promptsLength} added`}</Badge>
      </div>
      {suggestedPrompts?.length > 0 && (
        <div>
          {suggestedPrompts.map((prompt, index) => (
            <div className="mb-4" key={`prompt-${index}`}>
              <SelectedItem
                content={
                  <div className="column space-between w-full">
                    <div className="flex w-full flex-col">
                      <InputField
                        name={`userPrompts.${index}.title`}
                        placeholder="Add Prompt Title *"
                        classes="subtitle-2 !w-full rounded-md !border-transparent h-8 !px-1 hover:!bg-background mb-1 text-ellipsis"
                        handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChangeTitle(e, prompt, index)
                        }
                        // only focus input that was just added and has no value yet
                        autoFocus={!prompt.title}
                        required={true}
                        register={register}
                      />
                      <Textarea
                        name={`userPrompts.${index}.prompt`}
                        placeholder="Add prompt *"
                        className="body-2 hover:!bg-background cursor-pointer rounded-md !border-transparent !px-1 hover:!border-transparent"
                        maxHeight={600}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          handleChangePrompt(e, prompt, index)
                        }
                        required={true}
                        register={register}
                      />
                    </div>
                    {(!prompt.title || !prompt.prompt) && (
                      <div className="body-2 text-attention-variant mt-4">* Mandatory fields</div>
                    )}
                  </div>
                }
                value={index}
                handleRemove={handleRemoveSuggestedPrompt}
              />
            </div>
          ))}
        </div>
      )}
      <ButtonIcon
        icon={addButtonIcon ? addButtonIcon : <IconLightBulb />}
        variant={ButtonVariant.SECONDARY}
        onClick={handleAddSuggestedPrompt}
        className="mt-2"
      >
        Add Prompt
      </ButtonIcon>
    </div>
  );
};

import cn from 'classnames';
import { FC, ReactNode } from 'react';

import { IconArrowDown } from '@unique/icons';
import { ButtonVariant } from '../../enums/button';
import { ButtonIcon } from '../ButtonIcon';

interface MenuHeaderProps {
  showDropdown: boolean;
  wrapper: ReactNode;
}

export const MenuHeader: FC<MenuHeaderProps> = ({ showDropdown, wrapper }) => {
  return (
    <div
      className={cn({
        'align-center subtitle-2 hover:bg-background-variant flex h-full cursor-pointer transition duration-150 ease-out hover:ease-in sm:px-4 lg:gap-x-1.5':
          true,
        'bg-background-variant': showDropdown,
      })}
    >
      <ButtonIcon
        className="pointer-events-none !w-min self-center !px-4 sm:!px-0"
        classNameIcon="flex justify-center"
        icon={wrapper}
        variant={ButtonVariant.SHAPE}
      />
      <div
        className={cn({
          'text-on-surface text-on-surface hidden h-min origin-center self-center transition-transform lg:block':
            true,
          'rotate-180': showDropdown,
        })}
      >
        <IconArrowDown />
      </div>
    </div>
  );
};

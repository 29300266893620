import { ReactNode, FC, MouseEvent } from 'react';
import { ButtonIcon } from '..';
import { ButtonVariant } from '../enums/button';

interface MenuItemProps {
  title: string;
  icon: ReactNode;
  externalUrl?: string;
  handleClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

export const MenuItem: FC<MenuItemProps> = ({ title, icon, externalUrl, handleClick }) => {
  const onClick = (ev: MouseEvent<HTMLButtonElement>) => {
    if (externalUrl?.length) {
      window.open(externalUrl, '_blank');
    } else {
      handleClick && handleClick(ev);
    }
  };

  return (
    <ButtonIcon
      className="subtitle-2 group mb-0 mr-auto !h-12 w-full !justify-start rounded-none border-none !px-4"
      icon={icon}
      variant={ButtonVariant.TERTIARY}
      handleClick={onClick}
    >
      <div className="ml-2.5">{title}</div>
    </ButtonIcon>
  );
};

interface AssistantWithSortingProperties {
  name: string;
  settings?: {
    isPinned: boolean;
  };
}

/**
 * Sorts an array of Assistant objects based on their settings.isPinned attribute and name.
 * @param {T[]} assistants -  Type of the assistant object, must extend AssistantWithSortingProperties interface
 * @returns {T[] | null} - The sorted array of Assistant objects, or null if the input array is null.
 * @template T - The type of Assistant objects in the array.
 */
export const sortAssistants = <T extends AssistantWithSortingProperties>(
  assistants: T[],
): T[] | null => {
  if (!assistants) return null;
  return assistants.sort((x, y) => {
    const xIsPinned = !!x.settings?.isPinned;
    const yIsPinned = !!y.settings?.isPinned;
    if (xIsPinned > yIsPinned) {
      return -1;
    } else if (xIsPinned === yIsPinned && x.name && y.name) {
      return x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1;
    } else {
      return 1;
    }
  });
};

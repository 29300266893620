import { FC } from 'react';
import { IconProps } from '.';

export const IconEmptySearch: FC<IconProps> = ({ width = '157px', height = '156px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 157 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.5 78C156.5 79.4329 156.461 80.8557 156.384 82.2685C156.102 87.5504 155.282 92.7898 153.936 97.9051C153.934 97.9072 153.934 97.9072 153.936 97.9094C153.759 98.586 153.572 99.2604 153.374 99.9284C153.365 99.9607 153.356 99.9908 153.346 100.021C153.052 101.023 152.739 102.016 152.406 102.999V103.001C147.169 118.449 137.224 131.869 123.969 141.375C110.713 150.881 94.812 155.996 78.5 156C41.6051 156 10.696 130.387 2.58145 95.9702C1.19427 90.0809 0.495783 84.0504 0.500019 78C0.500019 76.7977 0.52731 75.6018 0.581895 74.4124C1.22845 59.8721 5.95103 45.8066 14.2103 33.8223C14.3095 33.6757 14.4107 33.5292 14.5142 33.3849C28.6102 13.2018 52.0145 0 78.5 0C115.931 0 147.202 26.3649 154.757 61.5381L154.759 61.5424C155.246 63.8092 155.632 66.1104 155.918 68.4461C156.307 71.6158 156.502 74.8065 156.5 78Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M42.296 17.8225C40.6268 17.8244 39.0265 18.4884 37.8461 19.6687C36.6658 20.849 36.0019 22.4493 36 24.1186V105.297C36.0019 106.966 36.6658 108.567 37.8461 109.747C39.0265 110.927 40.6268 111.591 42.296 111.593H111.418C113.088 111.591 114.688 110.927 115.868 109.747C117.049 108.567 117.713 106.966 117.714 105.297V27.3886L105.713 17.8225H42.296Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M97.974 41.4271H66.5035C66.1131 41.4271 65.7386 41.272 65.4625 40.9959C65.1864 40.7198 65.0313 40.3453 65.0312 39.9548C65.0313 39.5643 65.1864 39.1898 65.4625 38.9137C65.7386 38.6376 66.1131 38.4825 66.5035 38.4825H97.974C98.3644 38.4825 98.7389 38.6376 99.015 38.9137C99.2911 39.1898 99.4463 39.5643 99.4463 39.9548C99.4463 40.3453 99.2911 40.7198 99.015 40.9959C98.7389 41.272 98.3644 41.4271 97.974 41.4271Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M104.047 46.3961H66.5035C66.1134 46.3956 65.7394 46.2402 65.4637 45.9641C65.188 45.6881 65.0332 45.3139 65.0332 44.9238C65.0332 44.5336 65.188 44.1595 65.4637 43.8834C65.7394 43.6073 66.1134 43.452 66.5035 43.4515H104.047C104.241 43.4512 104.432 43.4891 104.611 43.563C104.79 43.6368 104.953 43.7453 105.09 43.882C105.226 44.0187 105.335 44.1811 105.409 44.3599C105.483 44.5386 105.521 44.7303 105.521 44.9238C105.521 45.1173 105.483 45.3089 105.409 45.4877C105.335 45.6664 105.226 45.8288 105.09 45.9656C104.953 46.1023 104.79 46.2107 104.611 46.2846C104.432 46.3585 104.241 46.3963 104.047 46.3961Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M53.3981 47.6431C56.2721 47.6431 58.6019 45.3133 58.6019 42.4393C58.6019 39.5654 56.2721 37.2356 53.3981 37.2356C50.5241 37.2356 48.1943 39.5654 48.1943 42.4393C48.1943 45.3133 50.5241 47.6431 53.3981 47.6431Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M52.8824 44.4245C52.7654 44.4246 52.6514 44.3868 52.5578 44.3165L52.5519 44.3122L51.329 43.3767C51.2722 43.3333 51.2245 43.2792 51.1886 43.2173C51.1527 43.1555 51.1294 43.0872 51.12 43.0164C51.1106 42.9456 51.1152 42.8735 51.1336 42.8045C51.152 42.7354 51.1839 42.6707 51.2274 42.614C51.2709 42.5573 51.3251 42.5097 51.387 42.474C51.4489 42.4382 51.5173 42.4151 51.5881 42.4058C51.659 42.3965 51.731 42.4013 51.8 42.4199C51.869 42.4384 51.9337 42.4704 51.9903 42.5141L52.7824 43.1215L54.6543 40.6795C54.6977 40.6228 54.7518 40.5753 54.8136 40.5396C54.8754 40.5039 54.9435 40.4808 55.0143 40.4714C55.085 40.4621 55.1569 40.4667 55.2258 40.4852C55.2947 40.5036 55.3593 40.5355 55.4159 40.5789L55.4045 40.5951L55.4164 40.579C55.5306 40.6668 55.6054 40.7963 55.6242 40.9391C55.643 41.0819 55.6044 41.2264 55.5169 41.3408L53.3152 44.2119C53.2643 44.278 53.1988 44.3315 53.1238 44.3683C53.0488 44.4051 52.9664 44.4241 52.8829 44.4238L52.8824 44.4245Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M97.9748 60.7501H66.5054C66.1149 60.7501 65.7405 60.9052 65.4644 61.1813C65.1883 61.4574 65.0332 61.8319 65.0332 62.2223C65.0332 62.6128 65.1883 62.9872 65.4644 63.2633C65.7405 63.5394 66.1149 63.6945 66.5054 63.6945H97.9748C98.3653 63.6945 98.7397 63.5394 99.0158 63.2633C99.2919 62.9872 99.447 62.6128 99.447 62.2223C99.447 61.8319 99.2919 61.4574 99.0158 61.1813C98.7397 60.9052 98.3653 60.7501 97.9748 60.7501Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M104.048 65.7201H66.5054C66.1149 65.7201 65.7405 65.8752 65.4644 66.1513C65.1883 66.4274 65.0332 66.8018 65.0332 67.1923C65.0332 67.5827 65.1883 67.9572 65.4644 68.2333C65.7405 68.5094 66.1149 68.6645 66.5054 68.6645H104.048C104.439 68.6645 104.813 68.5094 105.089 68.2333C105.366 67.9572 105.521 67.5827 105.521 67.1923C105.521 66.8018 105.366 66.4274 105.089 66.1513C104.813 65.8752 104.439 65.7201 104.048 65.7201Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M97.974 85.9641H66.5035C66.1131 85.9641 65.7386 85.809 65.4625 85.5329C65.1864 85.2568 65.0313 84.8823 65.0312 84.4918C65.0313 84.1014 65.1864 83.7269 65.4625 83.4508C65.7386 83.1746 66.1131 83.0195 66.5035 83.0195H97.974C98.3644 83.0195 98.7389 83.1746 99.015 83.4508C99.2911 83.7269 99.4463 84.1014 99.4463 84.4918C99.4463 84.8823 99.2911 85.2568 99.015 85.5329C98.7389 85.809 98.3644 85.9641 97.974 85.9641Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M104.047 90.9333H66.5035C66.1134 90.9327 65.7394 90.7774 65.4637 90.5013C65.188 90.2253 65.0332 89.8511 65.0332 89.461C65.0332 89.0708 65.188 88.6966 65.4637 88.4206C65.7394 88.1445 66.1134 87.9892 66.5035 87.9886H104.047C104.241 87.9884 104.432 88.0263 104.611 88.1002C104.79 88.174 104.953 88.2824 105.09 88.4192C105.226 88.5559 105.335 88.7183 105.409 88.8971C105.483 89.0758 105.521 89.2674 105.521 89.461C105.521 89.6545 105.483 89.8461 105.409 90.0248C105.335 90.2036 105.226 90.366 105.09 90.5027C104.953 90.6395 104.79 90.7479 104.611 90.8217C104.432 90.8956 104.241 90.9335 104.047 90.9333Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M59.1374 64.7073C59.1377 65.3907 59.0033 66.0676 58.7419 66.6991C58.4806 67.3306 58.0974 67.9044 57.6142 68.3878C57.1311 68.8712 56.5575 69.2547 55.9261 69.5164C55.2947 69.7781 54.6179 69.9128 53.9345 69.9129C53.8898 69.9138 53.845 69.912 53.8005 69.9075C52.7773 69.8813 51.7846 69.5539 50.9466 68.9663C50.1085 68.3786 49.4624 67.5569 49.089 66.6039C48.7157 65.6509 48.6316 64.6089 48.8475 63.6084C49.0633 62.6079 49.5695 61.6933 50.3025 60.979C51.0356 60.2647 51.9631 59.7824 52.9688 59.5926C53.9746 59.4028 55.014 59.5138 55.957 59.9117C56.9 60.3097 57.7047 60.9769 58.2704 61.8299C58.8361 62.6829 59.1377 63.6838 59.1374 64.7073Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M53.3991 92.1803C56.273 92.1803 58.6029 89.8504 58.6029 86.9765C58.6029 84.1025 56.273 81.7727 53.3991 81.7727C50.5251 81.7727 48.1953 84.1025 48.1953 86.9765C48.1953 89.8504 50.5251 92.1803 53.3991 92.1803Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M117.808 28.0298H107.111C106.33 28.0297 105.581 27.7195 105.028 27.1673C104.476 26.6151 104.166 25.8661 104.166 25.0851V17.184C104.166 17.1494 104.176 17.1156 104.194 17.0863C104.213 17.0569 104.239 17.0334 104.27 17.0184C104.301 17.0033 104.336 16.9973 104.37 17.0011C104.405 17.0049 104.437 17.0183 104.464 17.0398L117.923 27.7015C117.952 27.7252 117.974 27.7576 117.985 27.7943C117.996 27.831 117.995 27.8701 117.982 27.9062C117.969 27.9423 117.946 27.9736 117.915 27.9957C117.884 28.0179 117.846 28.0298 117.808 28.0298Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M59.1372 64.7073C59.1375 65.3907 59.0031 66.0676 58.7417 66.6991C58.4804 67.3306 58.0972 67.9044 57.614 68.3878C57.1309 68.8712 56.5572 69.2547 55.9259 69.5164C55.2945 69.7781 54.6177 69.9128 53.9343 69.9129C53.8896 69.9138 53.8448 69.912 53.8003 69.9075C53.1281 68.2307 52.8689 66.4168 53.0447 64.6189C53.2205 62.8209 53.8261 61.0915 54.8104 59.5767C56.0209 59.7842 57.119 60.4129 57.9108 61.3518C58.7026 62.2906 59.137 63.4791 59.1372 64.7073Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M65.0312 62.2237C65.0319 61.8331 65.1874 61.4587 65.4636 61.1825C65.7398 60.9064 66.1142 60.7509 66.5048 60.7502H74.3467C74.8047 61.6818 75.1302 62.6728 75.3138 63.6946H66.5048C66.1145 63.6945 65.7401 63.5396 65.4639 63.2638C65.1876 62.9881 65.0321 62.614 65.0312 62.2237Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M75.4957 65.7201C75.4962 66.7146 75.3647 67.7047 75.1046 68.6645H66.5044C66.114 68.6645 65.7395 68.5094 65.4634 68.2333C65.1873 67.9572 65.0322 67.5828 65.0322 67.1923C65.0322 66.8019 65.1873 66.4274 65.4634 66.1513C65.7395 65.8752 66.114 65.7201 66.5044 65.7201H75.4957Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M94.8993 83.8787C94.6773 84.2427 94.3198 84.5035 93.9055 84.6039C93.4911 84.7043 93.0539 84.636 92.6899 84.414L73.9345 72.9739C73.7543 72.864 73.5975 72.7196 73.473 72.5491C73.3486 72.3786 73.259 72.1852 73.2093 71.98C73.1596 71.7749 73.1507 71.5619 73.1833 71.3534C73.2159 71.1448 73.2893 70.9447 73.3992 70.7645C73.5091 70.5842 73.6535 70.4274 73.824 70.303C73.9945 70.1786 74.1879 70.0889 74.3931 70.0392C74.5982 69.9895 74.8112 69.9807 75.0197 70.0133C75.2283 70.0459 75.4284 70.1193 75.6086 70.2292L94.3641 81.6693C94.728 81.8913 94.9889 82.2488 95.0893 82.6631C95.1897 83.0775 95.1213 83.5147 94.8993 83.8787Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M76.1434 72.4386C74.819 74.61 72.8804 76.3399 70.5727 77.4095C68.2651 78.4791 65.6921 78.8405 63.1792 78.4478C60.6662 78.0551 58.3261 76.926 56.4548 75.2034C54.5836 73.4807 53.2651 71.2419 52.6662 68.7699C52.0674 66.298 52.215 63.7039 53.0904 61.3159C53.9658 58.9278 55.5297 56.853 57.5843 55.3537C59.6389 53.8544 62.0919 52.9981 64.6332 52.893C67.1745 52.7879 69.6899 53.4387 71.8613 54.7632C74.77 56.5419 76.8544 59.4018 77.6572 62.7155C78.46 66.0291 77.9156 69.5259 76.1434 72.4386ZM56.9306 60.7195C55.9372 62.3481 55.4491 64.2346 55.5279 66.1406C55.6068 68.0465 56.249 69.8863 57.3734 71.4273C58.4979 72.9682 60.054 74.1411 61.8451 74.7977C63.6361 75.4542 65.5816 75.5649 67.4356 75.1158C69.2896 74.6666 70.9687 73.6778 72.2607 72.2744C73.5527 70.8709 74.3995 69.1158 74.694 67.2311C74.9885 65.3464 74.7175 63.4167 73.9153 61.6859C73.1131 59.9552 71.8157 58.5013 70.1871 57.5079C68.0026 56.1788 65.38 55.7705 62.8948 56.3726C60.4095 56.9746 58.2646 58.538 56.9306 60.7195Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M117.347 67.804C120.981 67.804 123.927 64.8579 123.927 61.2237C123.927 57.5895 120.981 54.6434 117.347 54.6434C113.713 54.6434 110.767 57.5895 110.767 61.2237C110.767 64.8579 113.713 67.804 117.347 67.804Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M116.921 98.1134C116.794 97.718 116.752 97.3003 116.798 96.8875C116.844 96.4748 116.977 96.0764 117.187 95.7184C117.398 95.3604 117.681 95.051 118.02 94.8104C118.358 94.5697 118.744 94.4033 119.151 94.322C119.28 94.2969 119.411 94.2808 119.542 94.2739L126.473 83.1491L120.142 77.1299C119.9 76.8999 119.706 76.6243 119.571 76.319C119.436 76.0136 119.363 75.6845 119.356 75.3508C119.35 75.0171 119.409 74.6853 119.531 74.3746C119.653 74.0639 119.835 73.7805 120.067 73.5407C120.3 73.3009 120.577 73.1094 120.883 72.9774C121.19 72.8454 121.52 72.7754 121.854 72.7715C122.187 72.7676 122.519 72.8299 122.828 72.9548C123.138 73.0796 123.419 73.2646 123.657 73.4989L132.399 82.1188L132.417 82.1402C132.687 82.5517 132.818 83.0394 132.789 83.5309C132.76 84.0225 132.574 84.4916 132.257 84.8689L122.545 96.3552C122.574 96.4473 122.599 96.5408 122.618 96.6354C122.699 97.0427 122.693 97.4625 122.601 97.8674C122.509 98.2723 122.332 98.6531 122.082 98.9849C121.832 99.3167 121.515 99.592 121.151 99.7926C120.788 99.9933 120.386 100.115 119.972 100.149C119.888 100.157 119.805 100.16 119.722 100.16C119.099 100.158 118.493 99.9585 117.99 99.5909C117.487 99.2233 117.113 98.7062 116.921 98.1134Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M106.509 152.805L103.225 152.805L101.662 140.135L106.51 140.136L106.509 152.805Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M107.347 155.989L96.7559 155.988V155.854C96.7559 155.313 96.8625 154.777 97.0696 154.277C97.2768 153.777 97.5804 153.322 97.9632 152.939C98.346 152.557 98.8005 152.253 99.3006 152.046C99.8007 151.839 100.337 151.732 100.878 151.732L107.347 151.732L107.347 155.989Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M139.156 148.263L136.334 149.944L128.51 139.858L132.675 137.378L139.156 148.263Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M141.505 150.571L132.405 155.989L132.336 155.874C132.059 155.409 131.877 154.893 131.799 154.358C131.721 153.822 131.749 153.276 131.882 152.751C132.015 152.227 132.25 151.733 132.574 151.299C132.898 150.866 133.304 150.5 133.769 150.223L139.327 146.913L141.505 150.571Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M101.247 147.225C99.047 121.316 97.7381 97.247 105.782 87.4283L105.844 87.3522L119.38 92.767L119.403 92.8154C119.448 92.9149 123.95 102.818 122.9 109.478L126.239 124.837L137.126 143.067C137.214 143.213 137.269 143.377 137.289 143.547C137.308 143.717 137.292 143.889 137.24 144.052C137.188 144.214 137.102 144.364 136.987 144.491C136.873 144.619 136.733 144.72 136.577 144.789L131.845 146.87C131.579 146.987 131.279 147.003 131.001 146.916C130.723 146.829 130.485 146.645 130.332 146.397L118.489 127.086L111.8 112.274C111.764 112.193 111.702 112.127 111.624 112.085C111.546 112.044 111.457 112.029 111.37 112.043C111.283 112.057 111.203 112.099 111.142 112.163C111.081 112.227 111.043 112.309 111.034 112.397L107.326 147.252C107.295 147.548 107.155 147.822 106.934 148.021C106.712 148.221 106.425 148.331 106.127 148.33H102.449C102.147 148.328 101.857 148.214 101.635 148.01C101.413 147.806 101.275 147.526 101.247 147.225Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M105.828 87.6337L105.763 87.6028L105.753 87.5319C105.247 83.9927 105.846 80.0603 107.532 75.8441C108.363 73.7805 109.908 72.0842 111.885 71.0641C113.862 70.044 116.139 69.7681 118.303 70.2866C119.549 70.5937 120.718 71.1562 121.735 71.9386C122.752 72.7209 123.596 73.706 124.213 74.8315C124.822 75.9455 125.193 77.1738 125.302 78.4386C125.412 79.7033 125.257 80.9771 124.849 82.1791C122.723 88.3948 119.966 94.2104 119.938 94.2685L119.88 94.3899L105.828 87.6337Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M91.0579 78.8184C91.3838 78.5611 91.7605 78.3755 92.1631 78.2739C92.5658 78.1722 92.9853 78.1568 93.3944 78.2287C93.8034 78.3005 94.1926 78.458 94.5365 78.6907C94.8805 78.9235 95.1713 79.2263 95.3901 79.5793C95.4588 79.6913 95.5197 79.808 95.5721 79.9286L108.42 82.5253L111.841 74.4868C111.971 74.1797 112.161 73.9015 112.4 73.6683C112.639 73.4351 112.922 73.2515 113.232 73.1281C113.542 73.0048 113.874 72.9441 114.207 72.9496C114.541 72.9551 114.87 73.0266 115.176 73.1601C115.482 73.2936 115.759 73.4864 115.99 73.7273C116.221 73.9682 116.402 74.2526 116.522 74.5638C116.643 74.8751 116.701 75.2072 116.692 75.5408C116.683 75.8745 116.609 76.2032 116.473 76.5079L111.46 87.7149L111.446 87.7389C111.155 88.1363 110.744 88.4294 110.274 88.5746C109.803 88.7199 109.299 88.7096 108.834 88.5453L94.6743 83.4707C94.5982 83.5301 94.5191 83.5855 94.4372 83.6366C94.0841 83.8553 93.6889 83.9969 93.2773 84.0522C92.8657 84.1075 92.447 84.0752 92.0488 83.9574C91.6505 83.8396 91.2817 83.639 90.9664 83.3687C90.6511 83.0985 90.3964 82.7646 90.2192 82.3891C90.183 82.3133 90.1504 82.2366 90.1214 82.159C89.9057 81.5745 89.8801 80.9368 90.0482 80.3369C90.2164 79.737 90.5698 79.2056 91.0579 78.8184Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M141.118 68.2706C140.484 67.1475 139.561 66.1189 138.34 65.7038C136.764 65.1677 135.056 65.7385 133.435 66.1196C132.187 66.4132 130.882 66.593 129.623 66.3452C128.364 66.0974 127.15 65.3648 126.577 64.2168C125.735 62.528 126.444 60.5141 126.393 58.6274C126.367 57.6004 126.108 56.5927 125.635 55.6804C125.163 54.7681 124.489 53.975 123.666 53.3607C122.842 52.7465 121.89 52.3272 120.881 52.1345C119.872 51.9418 118.832 51.9806 117.84 52.2482C116.288 51.8002 114.883 51.7588 113.923 52.952C112.715 52.952 111.556 53.4319 110.702 54.286C109.848 55.1402 109.368 56.2987 109.368 57.5066H113.723C113.517 58.6171 113.753 59.7643 114.381 60.7031C115.15 61.8326 116.486 62.6497 116.744 63.9915C116.993 65.2919 116.109 66.5257 115.182 67.4709C114.255 68.4161 113.179 69.3527 112.89 70.6447C112.712 71.6255 112.914 72.6371 113.453 73.4751C113.992 74.303 114.66 75.039 115.432 75.6548C118.911 78.6353 123.046 80.7505 127.499 81.8277C130.634 82.5826 134.064 82.7783 136.975 81.3895C138.139 80.834 139.177 80.0449 140.024 79.0713C140.871 78.0977 141.508 76.9604 141.897 75.7301C142.285 74.4998 142.417 73.2027 142.283 71.9195C142.149 70.6362 141.752 69.3942 141.118 68.2706Z"
        fill="currentColor"
        className="text-on-background-main"
      />
    </svg>
  );
};

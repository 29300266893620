import { FC } from 'react';
import { IconProps } from '.';

export const IconShapeHeadEngine: FC<IconProps> = ({ width = '52px', height = '52px' }) => {
  return (
    <svg
      className="group"
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3357 21.3988C26.2824 21.3988 25.4291 22.251 25.4291 23.303C25.4291 24.355 26.2824 25.2072 27.3357 25.2072C28.3891 25.2072 29.2424 24.355 29.2424 23.303C29.2424 22.251 28.3891 21.3988 27.3357 21.3988Z"
        fill="currentColor"
      />
      <path
        d="M27.3357 13.9818C22.3357 13.9818 18.2691 17.8967 18.0291 22.8237L15.4691 26.2326C15.1357 26.672 15.4557 27.2979 16.0024 27.2979H18.0024V31.2927C18.0024 32.7574 19.2024 33.9559 20.6691 33.9559H22.0024V37.9507H31.3357V31.7188C34.4824 30.2274 36.6691 27.0182 36.6691 23.303C36.6691 18.1497 32.4957 13.9818 27.3357 13.9818ZM31.3357 23.303C31.3357 23.4762 31.3224 23.6493 31.3091 23.8224L32.4157 24.7012C32.5224 24.7811 32.5491 24.9143 32.4824 25.0341L31.4157 26.8851C31.3491 27.0049 31.2024 27.0449 31.0957 27.0049L29.7757 26.4723C29.4957 26.6853 29.2024 26.8584 28.8824 26.9916L28.6691 28.4031C28.6557 28.5363 28.5357 28.6295 28.4024 28.6295H26.2691C26.1357 28.6295 26.0291 28.5363 26.0024 28.4031L25.8024 26.9916C25.4691 26.8584 25.1757 26.6853 24.8957 26.4723L23.5757 27.0049C23.4557 27.0449 23.3091 27.0049 23.2424 26.8851L22.1757 25.0341C22.1091 24.9276 22.1357 24.7811 22.2424 24.7012L23.3624 23.8224C23.3491 23.6493 23.3357 23.4762 23.3357 23.303C23.3357 23.1299 23.3624 22.9435 23.3891 22.7837L22.2557 21.9049C22.1491 21.825 22.1224 21.6918 22.1891 21.5586L23.2557 19.721C23.3224 19.6012 23.4557 19.5612 23.5757 19.6012L24.9091 20.1338C25.1757 19.9341 25.4824 19.7477 25.8024 19.6145L26.0024 18.203C26.0291 18.0698 26.1357 17.9766 26.2691 17.9766H28.4024C28.5357 17.9766 28.6424 18.0698 28.6691 18.203L28.8691 19.6145C29.1891 19.7477 29.4824 19.9208 29.7624 20.1338L31.0957 19.6012C31.2157 19.5612 31.3624 19.6012 31.4157 19.721L32.4824 21.5586C32.5491 21.6785 32.5224 21.825 32.4157 21.9049L31.2824 22.7837C31.3224 22.9435 31.3357 23.1166 31.3357 23.303Z"
        fill="currentColor"
        className="transition-colors"
      />
    </svg>
  );
};

'use client';
import { FC, useState, useMemo, useRef, useEffect } from 'react';
import { IconArrowDown, IconSync } from '@unique/icons';

type ScrollPaginationProps = {
  counter: number;
  isLoading: boolean;
  enableInfiniteScroll?: boolean;
  handleClick: () => void;
};

export const ScrollPagination: FC<ScrollPaginationProps> = ({
  counter,
  isLoading,
  enableInfiniteScroll,
  handleClick,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }),
    [],
  );

  useEffect(() => {
    if (enableInfiniteScroll && ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [enableInfiniteScroll, observer]);

  useEffect(() => {
    if (isVisible && enableInfiniteScroll && !isLoading) {
      handleClick();
    }
  }, [isVisible, isLoading, enableInfiniteScroll, handleClick]);

  return (
    <>
      <button
        ref={ref}
        disabled={isLoading}
        onClick={() => {
          handleClick();
        }}
        className="hover:bg-background my-1 flex h-[48px] w-full cursor-pointer items-center justify-center gap-[14px] pr-[12px] text-sm"
      >
        {isLoading ? (
          <>
            <div className="animate-reverse-spin text-on-background-main shrink-0">
              <IconSync width="18px" />
            </div>
          </>
        ) : (
          <>
            <div className="text-on-background-main shrink-0">
              <IconArrowDown width="18px" />
            </div>
          </>
        )}
        <div className="text-on-background-main text-sm">{counter} more files to load</div>
      </button>
    </>
  );
};

'use client';

import { FC } from 'react';
import { ErrorPage } from './ErrorPage';

interface InternalServerErrorPageProps {
  basePath?: string;
}

export const InternalServerErrorPage: FC<InternalServerErrorPageProps> = ({ basePath }) => {
  return (
    <ErrorPage
      basePath={basePath}
      statusCode={500}
      title="Internal Error Server"
      description="We apologize for the inconvenience, but it seems like there was an unexpected issue with our software. Our team is already on the case, working diligently to resolve the problem."
    />
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconBotSimple: FC<IconProps> = ({
  width = '16px',
  height = '15px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32162 3.35128V4.94836H12.6849C13.3919 4.94836 13.9701 5.53151 13.9701 6.24468V7.88248H15.7174C15.8724 7.88248 16 8.00988 16 8.16748V11.4877C16 11.644 15.8737 11.7727 15.7174 11.7727H13.9701V13.4105C13.9701 14.1237 13.3919 14.7069 12.6849 14.7069H3.17969C2.47266 14.7069 1.89453 14.1237 1.89453 13.4105V11.7727H0.282552C0.126302 11.7727 0 11.6453 0 11.489V8.16748C0 8.01119 0.126302 7.88248 0.282552 7.88248H1.89453V6.24599C1.89453 5.53282 2.47266 4.94968 3.17969 4.94968H7.54297V3.35128C7.04167 3.18579 6.68099 2.71166 6.68099 2.15084C6.68099 1.45343 7.24089 0.888672 7.93229 0.888672C8.6237 0.888672 9.18359 1.45343 9.18359 2.15084C9.18359 2.71166 8.82162 3.18579 8.32162 3.35128ZM5.34245 11.3616H10.6562C10.9154 11.3616 11.1276 11.5757 11.1276 11.8371V12.0604C11.1276 12.3217 10.9154 12.5358 10.6562 12.5358H5.34245C5.08333 12.5358 4.87109 12.3217 4.87109 12.0604V11.8371C4.87109 11.5744 5.08333 11.3616 5.34245 11.3616ZM10.2474 7.1391C10.9466 7.1391 11.5143 7.71042 11.5143 8.41703C11.5143 9.12232 10.9479 9.69364 10.2474 9.69364C9.54688 9.69364 8.98177 9.12232 8.98177 8.41703C8.98047 7.71042 9.54818 7.1391 10.2474 7.1391ZM5.7526 7.1391C6.45182 7.1391 7.01823 7.71042 7.01823 8.41703C7.01823 9.12232 6.45182 9.69364 5.7526 9.69364C5.05339 9.69364 4.48698 9.12232 4.48698 8.41703C4.48698 7.71042 5.05339 7.1391 5.7526 7.1391Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconReportType: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1.5V16.5H16.5V1.5H1.5ZM1 0C0.447715 0 0 0.447716 0 1V17C0 17.5523 0.447716 18 1 18H17C17.5523 18 18 17.5523 18 17V1C18 0.447715 17.5523 0 17 0H1ZM4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5ZM5 8C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10C5.55228 10 6 9.55228 6 9C6 8.44772 5.55228 8 5 8ZM4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14C4.44772 14 4 13.5523 4 13ZM8.75 4.5C8.33579 4.5 8 4.83579 8 5.25C8 5.66421 8.33579 6 8.75 6H13.25C13.6642 6 14 5.66421 14 5.25C14 4.83579 13.6642 4.5 13.25 4.5H8.75ZM8 9.25C8 8.83579 8.33579 8.5 8.75 8.5H13.25C13.6642 8.5 14 8.83579 14 9.25C14 9.66421 13.6642 10 13.25 10H8.75C8.33579 10 8 9.66421 8 9.25ZM8.75 12.5C8.33579 12.5 8 12.8358 8 13.25C8 13.6642 8.33579 14 8.75 14H13.25C13.6642 14 14 13.6642 14 13.25C14 12.8358 13.6642 12.5 13.25 12.5H8.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconBotErrorGeneric } from '@unique/icons';
import { ButtonVariant } from '../enums/button';
import { ButtonIcon } from './ButtonIcon';
import cn from 'classnames';

type ColumnErrorProps = {
  handleClick: () => void;
  wrapperClasses?: string;
};

export const ColumnError: FC<ColumnErrorProps> = ({ handleClick, wrapperClasses }) => {
  return (
    <div
      className={cn(
        'flex-start bg-background text-on-background-main relative flex h-full min-w-[200px] pl-[12px] pr-[13px]',
        wrapperClasses,
      )}
    >
      <div className="bg-surface flex h-[100%] w-[100%] grow flex-col items-center justify-center overflow-y-auto pb-8">
        <IconBotErrorGeneric />
        <p className="m-4 text-center text-sm">
          There was a problem
          <br />
          loading your data.
          <br />
          Check your network settings and
        </p>
        <ButtonIcon
          variant={ButtonVariant.SECONDARY}
          className="text-sm"
          handleClick={() => {
            handleClick();
          }}
        >
          Try Again
        </ButtonIcon>
      </div>
    </div>
  );
};

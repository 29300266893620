import { FC } from 'react';
import { IconProps } from '.';

export const IconChevronLeft: FC<IconProps> = ({ width = '24px', height = '24px', className = '' }) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46849 1.41438C6.79194 1.67313 6.84438 2.1451 6.58562 2.46855L2.96044 7.00003L6.58562 11.5315C6.84438 11.855 6.79194 12.3269 6.46849 12.5857C6.14505 12.8444 5.67308 12.792 5.41432 12.4685L1.41432 7.46855C1.19519 7.19464 1.19519 6.80542 1.41432 6.53151L5.41432 1.53151C5.67308 1.20806 6.14505 1.15562 6.46849 1.41438Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconTipsAndUpdates: FC<IconProps> = ({
  width = '32px',
  height = '32px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 26.6667H14.6667C14.6667 28.1333 13.4667 29.3333 12 29.3333C10.5333 29.3333 9.33333 28.1333 9.33333 26.6667ZM6.66667 25.3333H17.3333V22.6667H6.66667V25.3333ZM22 12.6667C22 17.76 18.4533 20.48 16.9733 21.3333H7.02667C5.54667 20.48 2 17.76 2 12.6667C2 7.14667 6.48 2.66667 12 2.66667C17.52 2.66667 22 7.14667 22 12.6667ZM28.4933 9.82667L26.6667 10.6667L28.4933 11.5067L29.3333 13.3333L30.1733 11.5067L32 10.6667L30.1733 9.82667L29.3333 8L28.4933 9.82667ZM25.3333 8L26.5867 5.25333L29.3333 4L26.5867 2.74667L25.3333 0L24.08 2.74667L21.3333 4L24.08 5.25333L25.3333 8Z"
        fill="currentColor"
      />
    </svg>
  );
};

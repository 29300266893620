import { FC } from 'react';
import { IconProps } from '.';

export const IconJpgFile: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 22.3304V1.66957C6.75 0.74749 7.42895 0 8.26648 0H17.1758L24 7.51304V22.3304C24 23.2525 23.321 24 22.4835 24H8.26648C7.42895 24 6.75 23.2525 6.75 22.3304Z"
        fill="#CAD6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3052 8.99999H9.70148C9.25039 8.99943 8.81793 8.82001 8.49896 8.50104C8.17999 8.18207 8.00055 7.74961 8 7.29852V2.69066C8.00055 2.23966 8.18004 1.80733 8.49904 1.48852C8.81804 1.16972 9.25049 0.99051 9.70148 0.990234H14.3093C14.7601 0.990787 15.1923 1.17011 15.5111 1.48888C15.8299 1.80765 16.0092 2.23985 16.0098 2.69066V7.29747C16.0098 7.52123 15.9657 7.74279 15.88 7.94948C15.7943 8.15618 15.6687 8.34396 15.5104 8.50208C15.3521 8.6602 15.1641 8.78556 14.9573 8.871C14.7505 8.95644 14.5289 9.00026 14.3052 8.99999ZM9.70148 1.74238C9.44997 1.74266 9.20882 1.84262 9.03088 2.02037C8.85294 2.19811 8.7527 2.43915 8.75215 2.69066V5.70173C9.21398 5.5043 9.81771 5.13746 10.3918 4.78862C10.8218 4.52736 11.2352 4.27619 11.5599 4.11383L13.0219 6.82052C13.0219 6.82052 13.1763 6.28326 13.675 6.35942C13.7953 6.3778 13.9337 6.40749 14.0792 6.43871C14.4994 6.52889 14.9793 6.63185 15.2576 6.51221V2.68961C15.2573 2.4382 15.1573 2.19716 14.9796 2.01938C14.8018 1.84161 14.5607 1.74162 14.3093 1.74134L9.70148 1.74238ZM14.5285 3.11986C14.5285 3.40275 14.2992 3.63208 14.0163 3.63208C13.7334 3.63208 13.5041 3.40275 13.5041 3.11986C13.5041 2.83698 13.7334 2.60766 14.0163 2.60766C14.2992 2.60766 14.5285 2.83698 14.5285 3.11986Z"
        fill="#1EB23C"
      />
      <path
        d="M17.25 5.83333V0L24 7.5H18.75C17.9216 7.5 17.25 6.75381 17.25 5.83333Z"
        fill="#92AAB1"
      />
      <path
        d="M0 11.1667C0 10.5223 0.811484 10 1.8125 10H19.9375C20.9385 10 21.75 10.5223 21.75 11.1667V22.8333C21.75 23.4777 20.9385 24 19.9375 24H1.8125C0.811484 24 0 23.4777 0 22.8333V11.1667Z"
        fill="#00C650"
      />
      <path
        d="M16.5396 18.1133V16.7866H19.3486V19.9233C19.0658 20.2563 18.6647 20.5428 18.1455 20.7827C17.6263 21.0226 17.1053 21.1426 16.5825 21.1426C15.9451 21.1426 15.383 20.9868 14.896 20.6753C14.409 20.3602 14.0223 19.8911 13.7358 19.2681C13.453 18.6414 13.3115 17.9056 13.3115 17.0605C13.3115 16.194 13.4548 15.4528 13.7412 14.8369C14.0312 14.221 14.4054 13.7609 14.8638 13.4565C15.3257 13.1522 15.8771 13 16.5181 13C17.2879 13 17.9038 13.1969 18.3657 13.5908C18.8312 13.9811 19.1302 14.5558 19.2627 15.3149L17.9736 15.6104C17.877 15.2057 17.6997 14.896 17.4419 14.6812C17.1841 14.4663 16.8761 14.3589 16.5181 14.3589C15.9631 14.3589 15.5137 14.5755 15.1699 15.0088C14.8262 15.4385 14.6543 16.0955 14.6543 16.98C14.6543 17.9289 14.8405 18.6504 15.2129 19.1445C15.5316 19.5706 15.9631 19.7837 16.5073 19.7837C16.7616 19.7837 17.0247 19.7246 17.2969 19.6064C17.5726 19.4847 17.8179 19.32 18.0327 19.1123V18.1133H16.5396Z"
        fill="white"
      />
      <path
        d="M7.51611 21.0078V13.1338H9.61084C10.3914 13.1338 10.9017 13.1714 11.1416 13.2466C11.5247 13.3683 11.8398 13.6279 12.0869 14.0254C12.3376 14.4229 12.4629 14.9349 12.4629 15.5615C12.4629 16.1309 12.3555 16.6089 12.1406 16.9956C11.9258 17.3787 11.6572 17.6491 11.335 17.8066C11.0127 17.9606 10.4577 18.0376 9.66992 18.0376H8.81592V21.0078H7.51611ZM8.81592 14.4658V16.7002H9.53564C10.019 16.7002 10.3467 16.6662 10.5186 16.5981C10.694 16.5301 10.8372 16.4084 10.9482 16.2329C11.0592 16.0539 11.1147 15.8354 11.1147 15.5776C11.1147 15.3162 11.0575 15.096 10.9429 14.917C10.8283 14.738 10.6868 14.618 10.5186 14.5571C10.3503 14.4963 9.99398 14.4658 9.44971 14.4658H8.81592Z"
        fill="white"
      />
      <path
        d="M4.83057 13.1338H6.13574V18.1182C6.13574 18.9238 6.05518 19.5236 5.89404 19.9175C5.73291 20.3114 5.49121 20.6139 5.16895 20.8252C4.85026 21.0365 4.45638 21.1421 3.9873 21.1421C3.36784 21.1421 2.88265 20.929 2.53174 20.5029C2.18083 20.0768 2.00358 19.4341 2 18.5747L3.22998 18.4028C3.2443 18.8647 3.30339 19.1924 3.40723 19.3857C3.54688 19.6507 3.75635 19.7832 4.03564 19.7832C4.30778 19.7832 4.5083 19.6883 4.63721 19.4985C4.76611 19.3052 4.83057 18.8791 4.83057 18.2202V13.1338Z"
        fill="white"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconStar: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_8638_1303)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.26901 0.809843C8.76755 -0.269946 7.23246 -0.269949 6.731 0.80984L5.42092 3.63082C5.21705 4.06981 4.80077 4.37226 4.32026 4.43049L1.23252 4.80472C0.0506164 4.94797 -0.423755 6.40792 0.448225 7.21851L2.7263 9.3362C3.08081 9.66575 3.23981 10.1551 3.14671 10.6301L2.54846 13.6824C2.31946 14.8507 3.56138 15.753 4.60175 15.1742L7.31976 13.662C7.74273 13.4267 8.25728 13.4267 8.68025 13.662L11.3982 15.1742C12.4386 15.753 13.6805 14.8507 13.4515 13.6824L12.8533 10.6301C12.7602 10.1551 12.9192 9.66575 13.2737 9.3362L15.5518 7.21852C16.4238 6.40793 15.9494 4.94797 14.7675 4.80472L11.6797 4.43049C11.1992 4.37226 10.783 4.06981 10.5791 3.63082L9.26901 0.809843Z"
          fill="currentColor"
          className="bg-on-background-dimmed"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_8638_1303"
          x="0"
          y="0"
          width="16"
          height="16.354"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8638_1303" />
        </filter>
      </defs>
    </svg>
  );
};

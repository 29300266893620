import { FC } from 'react';
import cn from 'classnames';

interface ProfileImageProps {
  image?: string;
  dimensions: string;
  initials?: string;
  fallbackImage?: string;
  basePath?: string;
}

export const ProfileImage: FC<ProfileImageProps> = ({
  image,
  dimensions,
  initials,
  fallbackImage,
  basePath,
}) => {
  const imageStyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: dimensions,
    height: dimensions,
    backgroundRepeat: 'no-repeat',
    backgroundImage: '',
    mask: '',
    WebkitMask: '',
    maskSize: '',
    WebkitMaskSize: '',
  };
  if (image) {
    imageStyles.mask = `url("${basePath ?? ''}/static/images/shape-profile.svg") no-repeat 50% 50%`;
    imageStyles.WebkitMask = `url("${
      basePath ?? ''
    }/static/images/shape-profile.svg") no-repeat 50% 50%`;
    imageStyles.maskSize = `${dimensions} ${dimensions}`;
    imageStyles.WebkitMaskSize = `${dimensions} ${dimensions}`;
    imageStyles.backgroundImage = `url('${image}')`;
  }

  if (!image && fallbackImage) {
    imageStyles.backgroundImage = `url('${fallbackImage}')`;
    imageStyles.backgroundSize = 'contain';
  }

  if (!image && !fallbackImage) {
    imageStyles.mask = `url("${basePath ?? ''}/static/images/shape-profile.svg") no-repeat 50% 50%`;
    imageStyles.WebkitMask = `url("${
      basePath ?? ''
    }/static/images/shape-profile.svg") no-repeat 50% 50%`;
    imageStyles.maskSize = `${dimensions} ${dimensions}`;
    imageStyles.WebkitMaskSize = `${dimensions} ${dimensions}`;
  }

  return (
    <div
      className={cn({
        'subtitle-2 relative flex flex-shrink-0 items-center justify-center': true,
        'bg-background text-on-background-main': !image && !fallbackImage,
      })}
      style={imageStyles}
      title="Profile Image"
    >
      {!image && <div className="subtitle-2 absolute">{initials}</div>}
    </div>
  );
};

export const fallbackThemeColors = [
  { hexValue: '#1fb7ea', name: 'color-primary-cta', id: 'color-primary-cta' },
  { hexValue: '#139ac7', name: 'color-primary-variant', id: 'color-primary-variant' },
  { hexValue: '#073b4c', name: 'color-secondary', id: 'color-secondary' },
  { hexValue: '#05313f', name: 'color-secondary-variant', id: 'color-secondary-variant' },
  { hexValue: '#02161d', name: 'color-background', id: 'color-background' },
  { hexValue: '#042834', name: 'color-background-variant', id: 'color-background-variant' },
  { hexValue: '#03212b', name: 'color-surface', id: 'color-surface' },
  { hexValue: '#05313f', name: 'color-control', id: 'color-control' },
  { hexValue: '#1fb7ea', name: 'color-info', id: 'color-info' },
  { hexValue: '#26f4bf', name: 'color-success-light', id: 'color-success-light' },
  { hexValue: '#06d6a0', name: 'color-success-dark', id: 'color-success-dark' },
  { hexValue: '#e05454', name: 'color-error-light', id: 'color-error-light' },
  { hexValue: '#d71c1c', name: 'color-error-dark', id: 'color-error-dark' },
  { hexValue: '#ffd166', name: 'color-attention', id: 'color-attention' },
  { hexValue: '#ed315d', name: 'color-attention-variant', id: 'color-attention-variant' },
  { hexValue: '#ffffff', name: 'color-on-primary', id: 'color-on-primary' },
  { hexValue: '#ffffff', name: 'color-on-secondary', id: 'color-on-secondary' },
  { hexValue: '#ffffff', name: 'color-on-background-main', id: 'color-on-background-main' },
  { hexValue: '#92aab1', name: 'color-on-background-dimmed', id: 'color-on-background-dimmed' },
  { hexValue: '#ffffff', name: 'color-on-surface', id: 'color-on-surface' },
  { hexValue: '#abbdc3', name: 'color-on-control-main', id: 'color-on-control-main' },
  { hexValue: '#2e5f6f', name: 'color-on-control-dimmed', id: 'color-on-control-dimmed' },
  { hexValue: '#02161d', name: 'color-on-info', id: 'color-on-info' },
  { hexValue: '#02161d', name: 'color-on-success-light', id: 'color-on-success-light' },
  { hexValue: '#02161d', name: 'color-on-success-dark', id: 'color-on-success-dark' },
  { hexValue: '#ffffff', name: 'color-on-error-light', id: 'color-on-error-light' },
  { hexValue: '#ffffff', name: 'color-on-error-dark', id: 'color-on-error-dark' },
  { hexValue: '#02161d', name: 'color-on-attention', id: 'color-on-attention' },
  { hexValue: '#ffffff', name: 'color-on-attention-variant', id: 'color-on-attention-variant' },
];

'use client';
import { Spinner } from '@unique/component-library';
import { FC, PropsWithChildren, useContext } from 'react';
import { ClientContext } from './client-provider';

export const WithClientContextReady: FC<PropsWithChildren> = ({ children }) => {
  const clientContext = useContext(ClientContext);
  return clientContext?.isReady ? (
    <>{children}</>
  ) : (
    <Spinner wrapperClasses="absolute left-0 top-0 flex h-screen w-full items-center justify-center" />
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconBook: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="book_3_FILL1_wght400_GRAD0_opsz24">
        <path
          id="Vector"
          d="M5.20312 17.25C4.3875 17.25 3.69141 16.9681 3.11484 16.4044C2.53828 15.8406 2.25 15.16 2.25 14.3625V3.6375C2.25 2.84 2.53828 2.15938 3.11484 1.59563C3.69141 1.03187 4.3875 0.75 5.20312 0.75H14.0625C14.5266 0.75 14.9238 0.911563 15.2543 1.23469C15.5848 1.55781 15.75 1.94625 15.75 2.4V12.3206C15.75 12.5406 15.6938 12.74 15.5813 12.9188C15.4688 13.0975 15.3141 13.235 15.1172 13.3313C14.9203 13.4275 14.7656 13.5684 14.6531 13.7541C14.5406 13.9397 14.4844 14.1425 14.4844 14.3625C14.4844 14.5825 14.5406 14.7888 14.6531 14.9813C14.7656 15.1738 14.9203 15.3113 15.1172 15.3938C15.3 15.4625 15.4512 15.5656 15.5707 15.7031C15.6902 15.8406 15.75 16.0056 15.75 16.1981V16.4044C15.75 16.6381 15.6691 16.8375 15.5074 17.0025C15.3457 17.1675 15.1453 17.25 14.9062 17.25H5.20312ZM7.79766 8.36063H10.1813L10.6031 9.51562C10.6313 9.61188 10.684 9.6875 10.7613 9.7425C10.8387 9.7975 10.9336 9.825 11.0461 9.825C11.2148 9.825 11.3484 9.75625 11.4469 9.61875C11.5453 9.48125 11.5594 9.33687 11.4891 9.18563L9.63281 4.35938C9.60469 4.26313 9.54844 4.1875 9.46406 4.1325C9.37969 4.0775 9.28125 4.05 9.16875 4.05H8.78906C8.67656 4.05 8.57812 4.0775 8.49375 4.1325C8.40938 4.1875 8.35313 4.26313 8.325 4.35938L6.46875 9.20625C6.39844 9.3575 6.41602 9.49844 6.52148 9.62906C6.62695 9.75969 6.76406 9.825 6.93281 9.825C7.04531 9.825 7.14023 9.7975 7.21758 9.7425C7.29492 9.6875 7.34766 9.61188 7.37578 9.51562L7.79766 8.36063ZM8.09297 7.515L8.95781 5.1225H9.02109L9.86484 7.515H8.09297ZM5.20312 15.6H13.0711C12.9867 15.4075 12.9199 15.2116 12.8707 15.0122C12.8215 14.8128 12.7969 14.5963 12.7969 14.3625C12.7969 14.1425 12.818 13.9294 12.8602 13.7231C12.9023 13.5169 12.9727 13.3175 13.0711 13.125H5.20312C4.8375 13.125 4.53516 13.2453 4.29609 13.4859C4.05703 13.7266 3.9375 14.0188 3.9375 14.3625C3.9375 14.72 4.05703 15.0156 4.29609 15.2494C4.53516 15.4831 4.8375 15.6 5.20312 15.6Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

import cn from 'classnames';
import { FC } from 'react';

type SkeletonProps = {
  className?: string;
};

const Skeleton: FC<SkeletonProps> = ({ className = '' }) => {
  const skeletonClass = cn({
    'animate-pulse transition px-2 bg-secondary opacity-20 rounded-lg w-full opacity-80 h-6': true,
    [className]: true,
  });
  return <div className={skeletonClass} />;
};

export default Skeleton;

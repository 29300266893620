import { FC } from 'react';
import { IconProps } from '.';

export const IconRocket: FC<IconProps> = ({ width = '48', height = '48' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75739 43.0281C3.74799 43.0281 3.73858 43.0281 3.72917 43.0281L3.75753 42.9999C3.81634 32.5733 10.0639 29.841 13.186 29.7793L15.1147 31.7214L15.1148 31.7213L17.0718 33.6919C16.9883 36.8135 14.2126 43.0419 3.78588 43.0282L3.75727 43.0566C3.75731 43.0471 3.75735 43.0376 3.75739 43.0281Z"
        fill="#FFC65C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.13007 38.6857C8.13004 38.6911 8.13002 38.6965 8.13 38.702L8.14639 38.6857C14.5644 38.6949 16.2729 34.8615 16.3242 32.9403L15.1364 31.7442L15.1366 31.744L13.9323 30.5313C12.0108 30.5693 8.16558 32.251 8.13015 38.669L8.11342 38.6856C8.11897 38.6856 8.12452 38.6856 8.13007 38.6857Z"
        fill="#F65D67"
      />
      <path
        d="M13.6179 17.8287L18.8757 16.6794L13.6071 25.8255L8.00678 26.9773C6.6653 27.2532 5.6861 25.7348 6.49089 24.6266L9.44864 20.5539C10.4573 19.165 11.941 18.1952 13.6179 17.8287Z"
        fill="#D63384"
      />
      <path
        d="M28.7799 33.0959L29.9656 27.8463L20.7832 33.0511L19.5924 38.6432C19.3072 39.9828 20.8188 40.9725 21.9325 40.1754L26.0257 37.2461C27.4215 36.2472 28.4017 34.7702 28.7799 33.0959Z"
        fill="#D63384"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3164 26.9277L18.0277 16.9703C23.152 8.03645 32.9269 2.81193 43.202 3.51505L14.5189 31.9993C14.0913 31.4457 13.0263 29.71 12.2873 28.3408C12.0482 27.8979 12.066 27.3642 12.3164 26.9277ZM19.576 34.2379L29.5728 28.596C38.5421 23.534 43.8345 13.7957 43.2029 3.51597L14.5198 32.0002C15.0704 32.4316 16.7986 33.5087 18.1627 34.2572C18.6039 34.4994 19.1377 34.4853 19.576 34.2379Z"
        fill="#EBEBED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6686 4.58426C36.7075 3.6756 39.9317 3.29729 43.2067 3.52117L43.2078 3.52013L43.2078 3.52125L43.2086 3.5213L43.2079 3.52199C43.4046 6.72671 43.0255 9.87877 42.1388 12.8529C40.2589 12.3915 38.338 11.3169 36.7127 9.68015C35.1885 8.14533 34.1604 6.35416 33.6686 4.58426Z"
        fill="#D63384"
      />
      <ellipse
        cx="32.1925"
        cy="14.7626"
        rx="4.93725"
        ry="4.93724"
        transform="rotate(45.1993 32.1925 14.7626)"
        fill="#1F505F"
      />
      <circle
        cx="32.1922"
        cy="14.7628"
        r="3.70293"
        transform="rotate(45.1993 32.1922 14.7628)"
        fill="#0A96E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6933 33.8374L20.405 34.0426C19.6254 34.5975 18.6248 34.7477 17.7751 34.3073C16.7607 33.7814 15.5112 32.9983 14.6334 32.1144L15.9471 30.8098C16.8072 31.6758 19.4696 33.1891 20.6933 33.8374ZM12.8745 25.9641L12.6673 26.2509C12.107 27.0267 11.9499 28.0262 12.3844 28.8789C12.9031 29.8969 13.6775 31.1519 14.5553 32.0358L15.8691 30.7312C15.009 29.8651 13.5143 27.1922 12.8745 25.9641Z"
        fill="#1F505F"
      />
      <path
        d="M16.6528 28.4553C23.8783 21.2799 23.8013 22.5161 25.4049 21.5034C24.3086 23.0269 24.0851 24.5536 18.3923 30.2069C14.4511 34.1208 13.3593 34.3352 12.9245 33.8973C12.4896 33.4594 12.7116 32.3692 16.6528 28.4553Z"
        fill="#D63384"
      />
    </svg>
  );
};

import { FC, ReactNode, useCallback, useContext, useEffect } from 'react';
import { DrawerContext } from './DrawerProvider';

export type DrawerProps = {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
};

export const Drawer: FC<DrawerProps> = ({ open, onClose, children, className }) => {
  const { isOpen, setIsOpen, setComponent } = useContext(DrawerContext);

  const onCloseEvent = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    // We don't remove component on close as we want to keep the component mounted during the animation
    if (open) {
      setComponent(<div className={className}>{children}</div>);
    }
    if (open != isOpen) {
      setIsOpen(open);
    }
  }, [open, children]);

  // When drawer.provider close drawer we trigger onClose event
  useEffect(() => {
    if (!isOpen) {
      onCloseEvent();
    }
  }, [isOpen]);

  return <></>;
};

import cn from 'classnames';
import { ChangeEvent, HTMLProps, ReactNode, Ref, useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form/dist/types';

type InputProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  label?: string;
  classes?: string;
  placeholder?: string;
  defaultValue?: string | number;
  value?: string | number;
  type?: string;
  isRequired?: boolean;
  readOnly?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  inputWrapperClasses?: string;
  disabled?: boolean;
  canClearInput?: boolean;
  autoFocus?: boolean;
  handleClearInput?: () => void;
  description?: string;
  errorMessage?: string;
  disabledToolTip?: ReactNode | string;
  register?: UseFormRegister<TFieldValues>;
  setRef?: (ref: Ref<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
} & HTMLProps<HTMLInputElement>;

type RegisterType<TFieldValues extends FieldValues> = Partial<
  UseFormRegisterReturn<Path<TFieldValues>>
>;

export const InputField = <TFieldValues extends FieldValues>(props: InputProps<TFieldValues>) => {
  const {
    name,
    label,
    classes,
    placeholder,
    defaultValue,
    value,
    type = 'text',
    isRequired = true,
    readOnly = false,
    handleChange,
    accept,
    autoFocus,
    inputWrapperClasses = '',
    disabled,
    canClearInput,
    handleClearInput,
    description,
    errorMessage = '',
    disabledToolTip,
    register,
    setRef,
    onFocus,
    onBlur,
    ...htmlProps
  } = props;

  const [inputValue, setInputValue] = useState<string | number | undefined>(value);
  const { ref: registerRef, ...registerRest }: RegisterType<TFieldValues> = register
    ? register(name, {
        required: { value: !!isRequired, message: 'This field is required' },
        valueAsNumber: type === 'number',
      })
    : { ref: undefined };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const inputClasses = cn(
    'px-4 py-2 w-full h-10 text-on-surface placeholder:text-on-control-dimmed bg-surface border border-control transition rounded-md',
    classes,
    {
      'focus:!border-primary-cta hover:border-primary-cta': !readOnly,
      'cursor-not-allowed focus:!border-control hover:border-control': readOnly,
      'mt-2': !!label,
      '!pr-14': !!htmlProps.maxLength,
    },
  );

  useEffect(() => {
    if (setRef) setRef(inputRef);
  }, [setRef, inputRef]);

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange && handleChange(event);
    registerRest && registerRest.onChange && registerRest.onChange(event);
  };

  return (
    <div
      className={cn('group', {
        [inputWrapperClasses]: true,
      })}
    >
      {htmlProps.maxLength && type === 'text' && (
        <div
          className={cn({
            'subtitle-2 absolute right-0 flex h-[40px] items-center px-2': true,
            'text-control': inputValue?.toString().length !== htmlProps.maxLength,
            'text-error-dark': inputValue?.toString().length === htmlProps.maxLength,
          })}
        >
          {inputValue?.toString().length}/{htmlProps.maxLength}
        </div>
      )}
      <label htmlFor={name} className="label subtitle-1 text-on-background-main font-bold">
        {label}
        {label && type !== 'file' && isRequired && (
          <span className="text-attention-variant ml-1">*</span>
        )}
        {description && (
          <div className="body-2 text-on-background-dimmed mb-2 mt-1 normal-case">
            {description}
          </div>
        )}
        <input
          {...registerRest}
          {...(registerRest.onChange && {
            onChange: (event: ChangeEvent) => {
              const target = event.target as HTMLInputElement;
              setInputValue(target.value);
              registerRest.onChange && registerRest.onChange(event);
            },
          })}
          ref={(element: HTMLInputElement) => {
            registerRef && registerRef(element);
            setInputValue(element?.value ?? 0);
            inputRef.current = element;
          }}
          autoComplete="off"
          defaultValue={defaultValue}
          value={value}
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholder}
          className={cn(inputClasses, {
            '!cursor-not-allowed': disabled,
          })}
          readOnly={readOnly}
          {...(handleChange && { onChange: handleChangeInput })}
          accept={accept}
          disabled={disabled}
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
          {...htmlProps}
        />
        {canClearInput && (
          <button className="-ml-6" onClick={handleClearInput}>
            <span className="body-2 text-on-background-dimmed">X</span>
          </button>
        )}
      </label>
      {disabled && disabledToolTip && (
        <div className="shadow-tooltip z-2 subtitle-2 bg-control text-on-secondary pointer-events-none absolute bottom-[40px] right-0 w-56 rounded-lg p-4 opacity-0 transition-all group-hover:opacity-100">
          {disabledToolTip}
        </div>
      )}
      <div
        className={cn({
          'subtitle-2 text-attention-variant h-6 pt-1 opacity-0 transition-opacity': true,
          '!opacity-100': errorMessage.length,
          hidden: !errorMessage.length,
        })}
      >
        {errorMessage}
      </div>
    </div>
  );
};

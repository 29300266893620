import { FC } from 'react';
import { IconProps } from '.';

export const IconBotGlasses: FC<IconProps> = ({ width = '48', height = '48' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9999 24.8793V23.0126C47.9999 13.4433 44.0745 0.140625 25.0762 0.140625H22.9237C4.01864 0.140625 0 13.3817 0 23.0126V24.8793C0 34.3253 3.97287 48.0001 22.9237 48.0001H25.0762C42.1589 47.3365 47.9999 37.1099 47.9999 24.8793Z"
        fill="#042834"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9335 24.9716V23.1982C46.9335 14.1075 43.2043 1.46986 25.156 1.46986H23.1111C5.15126 1.46986 1.33356 14.0489 1.33356 23.1982V24.9716C1.33356 33.9453 5.10779 46.9363 23.1111 46.9363H25.156C41.3845 46.306 46.9335 36.5907 46.9335 24.9716Z"
        fill="#073B4C"
      />
      <path
        d="M39.472 32.358C39.472 34.6158 37.6417 36.4461 35.384 36.4461L12.0238 36.4461C9.76606 36.446 7.93579 34.6158 7.93579 32.358L7.93579 18.9259C7.93579 16.6682 9.76607 14.8379 12.0238 14.8379L35.384 14.8379C37.6417 14.8379 39.472 16.6682 39.472 18.9259L39.472 32.358Z"
        fill="url(#paint0_linear_1685_10965)"
      />
      <path
        d="M35.7928 23.8885C35.7928 26.114 33.9887 27.9181 31.7631 27.9181C29.5376 27.9181 27.7335 26.114 27.7335 23.8885C27.7335 21.663 29.5376 19.8588 31.7631 19.8588C33.9887 19.8588 35.7928 21.663 35.7928 23.8885Z"
        fill="#042834"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7631 26.2391C33.0614 26.2391 34.1138 25.1867 34.1138 23.8885C34.1138 22.5903 33.0614 21.5379 31.7631 21.5379C30.4649 21.5379 29.4125 22.5903 29.4125 23.8885C29.4125 25.1867 30.4649 26.2391 31.7631 26.2391ZM31.7631 27.9181C33.9887 27.9181 35.7928 26.114 35.7928 23.8885C35.7928 21.663 33.9887 19.8588 31.7631 19.8588C29.5376 19.8588 27.7335 21.663 27.7335 23.8885C27.7335 26.114 29.5376 27.9181 31.7631 27.9181Z"
        fill="url(#paint1_linear_1685_10965)"
      />
      <path
        d="M19.6744 23.8885C19.6744 26.114 17.8702 27.9181 15.6447 27.9181C13.4192 27.9181 11.6151 26.114 11.6151 23.8885C11.6151 21.663 13.4192 19.8588 15.6447 19.8588C17.8702 19.8588 19.6744 21.663 19.6744 23.8885Z"
        fill="#042834"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6447 26.2391C16.943 26.2391 17.9954 25.1867 17.9954 23.8885C17.9954 22.5903 16.943 21.5379 15.6447 21.5379C14.3465 21.5379 13.2941 22.5903 13.2941 23.8885C13.2941 25.1867 14.3465 26.2391 15.6447 26.2391ZM15.6447 27.9181C17.8702 27.9181 19.6744 26.114 19.6744 23.8885C19.6744 21.663 17.8702 19.8588 15.6447 19.8588C13.4192 19.8588 11.6151 21.663 11.6151 23.8885C11.6151 26.114 13.4192 27.9181 15.6447 27.9181Z"
        fill="url(#paint2_linear_1685_10965)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8281 14.8363L22.8281 10.7483L24.5801 10.7483L24.5801 14.8363L22.8281 14.8363Z"
        fill="url(#paint3_linear_1685_10965)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8281 40.5329L22.8281 36.4449L24.5801 36.4449L24.5801 40.5329L22.8281 40.5329Z"
        fill="url(#paint4_linear_1685_10965)"
      />
      <path
        d="M7.93545 25.7867C7.93545 29.254 7.93545 28.8589 7.93545 32.0647C5.51643 32.0647 3.55542 29.254 3.55542 25.7867C3.55542 22.3194 5.51643 19.5086 7.93545 19.5086C7.93545 22.4473 7.93545 22.3194 7.93545 25.7867Z"
        fill="url(#paint5_linear_1685_10965)"
      />
      <path
        d="M39.4717 25.7867C39.4717 22.3194 39.4717 22.7144 39.4717 19.5086C41.8908 19.5086 43.8518 22.3194 43.8518 25.7867C43.8518 29.2539 41.8908 32.0647 39.4717 32.0647C39.4717 29.126 39.4717 29.2539 39.4717 25.7867Z"
        fill="url(#paint6_linear_1685_10965)"
      />
      <path
        d="M28.0833 7.97426C28.0833 10.3127 26.1877 12.2083 23.8493 12.2083C21.5109 12.2083 19.6152 10.3127 19.6152 7.97426C19.6152 5.63587 21.5109 3.74023 23.8493 3.74023C26.1877 3.74023 28.0833 5.63587 28.0833 7.97426Z"
        fill="url(#paint7_linear_1685_10965)"
      />
      <path
        d="M11.6155 42.8527C11.6155 41.2815 12.8892 40.0078 14.4604 40.0078H32.9483C34.5195 40.0078 35.7933 41.2815 35.7933 42.8527V42.8527C35.7933 42.8527 33.1855 45.2231 23.704 45.2231C14.2225 45.2231 11.6155 42.8527 11.6155 42.8527V42.8527Z"
        fill="url(#paint8_linear_1685_10965)"
      />
      <circle
        opacity="0.7"
        cx="15.1143"
        cy="23.0508"
        r="4.62709"
        transform="rotate(-165 15.1143 23.0508)"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="4.62709"
        cy="4.62709"
        r="4.62709"
        transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 28.2343 28.7178)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7609 16.9042C14.1371 16.2012 11.4566 17.2454 9.95509 19.3237C9.83523 19.2579 9.70563 19.2053 9.56765 19.1683C8.642 18.9203 7.69055 19.4696 7.44253 20.3952C7.1945 21.3209 7.74382 22.2723 8.66947 22.5204C8.70329 22.5294 8.73714 22.5374 8.77099 22.5444C8.52835 25.5469 10.4541 28.3877 13.4676 29.1951C16.8616 30.1046 20.3503 28.0904 21.2597 24.6963C22.1691 21.3023 20.155 17.8136 16.7609 16.9042ZM16.3119 18.5802C13.8435 17.9188 11.3063 19.3836 10.6449 21.852C9.98351 24.3204 11.4484 26.8576 13.9168 27.519C16.3851 28.1804 18.9223 26.7156 19.5838 24.2472C20.2452 21.7788 18.7803 19.2416 16.3119 18.5802Z"
        fill="#073B4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8592 16.9042C32.4831 16.2012 35.1635 17.2454 36.665 19.3237C36.7849 19.2579 36.9145 19.2053 37.0525 19.1683C37.9781 18.9203 38.9296 19.4696 39.1776 20.3952C39.4256 21.3209 38.8763 22.2723 37.9506 22.5204C37.9168 22.5294 37.883 22.5374 37.8491 22.5444C38.0918 25.5469 36.1661 28.3877 33.1525 29.1951C29.7585 30.1046 26.2698 28.0904 25.3604 24.6963C24.451 21.3023 26.4652 17.8136 29.8592 16.9042ZM30.3082 18.5802C32.7766 17.9188 35.3138 19.3836 35.9752 21.852C36.6366 24.3204 35.1718 26.8576 32.7034 27.519C30.235 28.1804 27.6978 26.7156 27.0364 24.2472C26.375 21.7788 27.8398 19.2416 30.3082 18.5802Z"
        fill="#073B4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.149 23.2777C21.6166 23.2777 20.9204 23.9566 20.8048 24.1415L19.8472 23.543C20.2021 22.9752 21.2938 22.1484 23.149 22.1484C25.0043 22.1484 26.096 22.9752 26.4509 23.543L25.4933 24.1415C25.3777 23.9566 24.6815 23.2777 23.149 23.2777Z"
        fill="#073B4C"
      />
      <path
        d="M20.8906 31.1826C21.0788 31.7472 22.0199 32.8765 24.2783 32.8765"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1685_10965"
          x1="1.21739"
          y1="26.6072"
          x2="17.4203"
          y2="47.6912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FB7EA" />
          <stop offset="1" stopColor="#7EDEFE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1685_10965"
          x1="31.7631"
          y1="19.8588"
          x2="31.7631"
          y2="27.9181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1685_10965"
          x1="15.6447"
          y1="19.8588"
          x2="15.6447"
          y2="27.9181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1685_10965"
          x1="24.5801"
          y1="12.7923"
          x2="22.8281"
          y2="12.7923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1685_10965"
          x1="24.5801"
          y1="38.4889"
          x2="22.8281"
          y2="38.4889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1685_10965"
          x1="5.74544"
          y1="19.5086"
          x2="5.74544"
          y2="32.0647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1685_10965"
          x1="41.6618"
          y1="32.0647"
          x2="41.6618"
          y2="19.5086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1685_10965"
          x1="23.8493"
          y1="3.74023"
          x2="23.8493"
          y2="12.2083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD166" />
          <stop offset="1" stopColor="#C28E14" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1685_10965"
          x1="6.46471"
          y1="42.8484"
          x2="8.31466"
          y2="50.495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FB7EA" />
          <stop offset="1" stopColor="#7EDEFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

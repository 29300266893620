'use client';

import { FC, ReactNode, createContext, useContext } from 'react';
import {
  getUseIgnoreNetPromoterScoreRatingQueryKey,
  getUseNetPromoterScoreTriggerCheckQueryQueryKey,
  getUseSubmitNetPromoterScoreRatingQueryKey,
  useIgnoreNetPromoterScoreMutation,
  useNetPromoterScoreTriggerCheckQuery,
  useSubmitNetPromoterScoreMutation,
} from '../hooks/swr/useNetPromoterScoreHooks';
import { LayoutContext } from '../src/DefaultLayout';
import { NetPromoterScore } from '../src/NetPromoterScore';
import { mutate } from 'swr';
import { useToast } from '../hooks/useToast';
import { ToastVariant } from '../enums/toast-variant';
import { FeatureFlagContext } from './FeatureFlagProvider';

export const NetPromoterScoreContext = createContext({});

type NetPromoterScoreProviderProps = {
  children: ReactNode;
};

export const NetPromoterScoreProvider: FC<NetPromoterScoreProviderProps> = ({
  children
}) => {
  const { shouldShowNetPromoterScore, setShouldShowNetPromoterScore } = useContext(LayoutContext);
  const delay = async (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const { showToast } = useToast();
  const { flags } = useContext(FeatureFlagContext);

  const { data: netPromoterScoreTriggerCheckResponse } = useNetPromoterScoreTriggerCheckQuery();
  const { trigger: submitRating } = useSubmitNetPromoterScoreMutation(
    getUseSubmitNetPromoterScoreRatingQueryKey(),
  );
  const { trigger: ignoreRating } = useIgnoreNetPromoterScoreMutation(
    getUseIgnoreNetPromoterScoreRatingQueryKey(),
  );

  const netPromoterScoreTriggerCheckData = netPromoterScoreTriggerCheckResponse?.netPromoterScoreTriggerCheck;

  const display = async (milliseconds: number) => {
    await delay(milliseconds);
    setShouldShowNetPromoterScore && setShouldShowNetPromoterScore(true)
  };

  const getUpdatedQuestionWithAppName = (question: string ): string => {
    const title = document.getElementsByTagName('title');
    return question.replace('Unique Chat', title?.[0]?.textContent || 'Unique Chat');
  }

  if (netPromoterScoreTriggerCheckData?.status) {
    display((netPromoterScoreTriggerCheckData?.delayInSecBeforeQuestionDisplay || 1) * 1000)
  }
  
  const handleSubmitRating = async (score: number) => {
    submitRating(
      { 
        input: { score, companyId: "", createdBy: "" } 
      }, {
        onSuccess() {
          showToast && showToast({
            message: "Thank you for the valued feedback!",
            variant: ToastVariant.SUCCESS,
          })
          mutate(getUseNetPromoterScoreTriggerCheckQueryQueryKey());
        },
      }
    );
  };

  const handleIgnoreRating = async () => {
    ignoreRating({}, {
      onSuccess() {
        mutate(getUseNetPromoterScoreTriggerCheckQueryQueryKey());
      },
    });
  };

  return (
    <NetPromoterScoreContext.Provider value={{}}>
      {children}
      {flags.FEATURE_FLAG_NET_PROMOTER_SCORE_UI_UN_7747 && shouldShowNetPromoterScore && netPromoterScoreTriggerCheckData?.question && (
        <NetPromoterScore
          text={getUpdatedQuestionWithAppName(netPromoterScoreTriggerCheckData.question)}
          handleSubmitRating={handleSubmitRating}
          handleIgnoreRating={handleIgnoreRating}
          shouldShow={shouldShowNetPromoterScore}
          closeModal={() =>
            setShouldShowNetPromoterScore && setShouldShowNetPromoterScore(false)
          }
        />
      )}
    </NetPromoterScoreContext.Provider>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconMenu: FC<IconProps> = ({ width = '15px', height = '15px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current transition duration-200"
    >
      <path
        d="M2.5 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconAlert: FC<IconProps> = ({ width = '24px', height = '24px', className = '' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.41L20.2145 19.7895H3.78545L12 5.41ZM12 1L0 22H24L12 1ZM13.0909 16.4737H10.9091V18.6842H13.0909V16.4737ZM13.0909 9.8421H10.9091V14.2632H13.0909V9.8421Z"
        fill="#B2B2B2"
      />
    </svg>
  );
};

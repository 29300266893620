import { FC } from 'react';
import { IconProps } from '.';

type IconLogoFaviconProps = IconProps & { color: string };
export const IconLogoFavicon: FC<IconLogoFaviconProps> = ({
  width = '32px',
  height = '32px',
  color = 'currentColor',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="favicon-path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M400 293.951L350.205 269.885C370.716 243.664 376.959 212.747 376.959 187.005V175.723C376.959 117.893 346.132 37.5 196.932 37.5H180.027C31.5597 37.5 0 117.52 0 175.723V187.005C0 244.09 31.2003 326.731 180.027 326.731H196.932C225.31 326.731 249.376 323.738 269.781 318.536L362.797 365.843L400 293.951ZM188.665 249.727C118.385 249.727 105.992 209.909 105.992 182.877V178.726C105.992 151.693 117.639 113.379 188.292 113.379C258.946 113.379 270.978 151.693 270.978 178.726V182.877C270.978 209.909 258.946 249.727 188.665 249.727Z"
        fill={color}
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconFilter: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_955_7581" fill="currentColor">
        <path d="M1.77734 2.67394V2.26709C1.77734 1.99095 2.0012 1.76709 2.27734 1.76709H13.7218C13.9979 1.76709 14.2218 1.99095 14.2218 2.26709V2.67394C14.2218 2.81203 14.1647 2.94397 14.064 3.03848L9.59325 7.23531C9.49257 7.32982 9.43546 7.46176 9.43546 7.59985V13.8887C9.43546 14.3537 8.85646 14.5669 8.55491 14.2131L6.68312 12.0167C6.60602 11.9262 6.56367 11.8112 6.56367 11.6924V7.59985C6.56367 7.46176 6.50656 7.32982 6.40588 7.23531L1.93513 3.03848C1.83445 2.94397 1.77734 2.81203 1.77734 2.67394Z" />
      </mask>
      <path
        d="M1.77734 2.67394V2.26709C1.77734 1.99095 2.0012 1.76709 2.27734 1.76709H13.7218C13.9979 1.76709 14.2218 1.99095 14.2218 2.26709V2.67394C14.2218 2.81203 14.1647 2.94397 14.064 3.03848L9.59325 7.23531C9.49257 7.32982 9.43546 7.46176 9.43546 7.59985V13.8887C9.43546 14.3537 8.85646 14.5669 8.55491 14.2131L6.68312 12.0167C6.60602 11.9262 6.56367 11.8112 6.56367 11.6924V7.59985C6.56367 7.46176 6.50656 7.32982 6.40588 7.23531L1.93513 3.03848C1.83445 2.94397 1.77734 2.81203 1.77734 2.67394Z"
        fill="currentColor"
      />
      <path
        d="M1.93513 3.03848L1.25072 3.76757L1.25072 3.76757L1.93513 3.03848ZM6.40588 7.23531L5.72146 7.9644L5.72146 7.9644L6.40588 7.23531ZM6.68312 12.0167L5.922 12.6653L5.92201 12.6653L6.68312 12.0167ZM8.55491 14.2131L7.7938 14.8617H7.7938L8.55491 14.2131ZM9.59325 7.23531L10.2777 7.9644L10.2777 7.9644L9.59325 7.23531ZM14.064 3.03848L14.7484 3.76757V3.76757L14.064 3.03848ZM0.777344 2.26709V2.67394H2.77734V2.26709H0.777344ZM1.25072 3.76757L5.72146 7.9644L7.0903 6.50622L2.61955 2.30939L1.25072 3.76757ZM5.56367 7.59985V11.6924H7.56367V7.59985H5.56367ZM5.92201 12.6653L7.7938 14.8617L9.31602 13.5644L7.44422 11.368L5.92201 12.6653ZM10.4355 13.8887V7.59985H8.43546V13.8887H10.4355ZM10.2777 7.9644L14.7484 3.76757L13.3796 2.30939L8.90884 6.50622L10.2777 7.9644ZM15.2218 2.67394V2.26709H13.2218V2.67394H15.2218ZM13.7218 0.76709H2.27734V2.76709H13.7218V0.76709ZM15.2218 2.26709C15.2218 1.43866 14.5502 0.76709 13.7218 0.76709V2.76709C13.4456 2.76709 13.2218 2.54323 13.2218 2.26709H15.2218ZM14.7484 3.76757C15.0505 3.48404 15.2218 3.08821 15.2218 2.67394H13.2218C13.2218 2.53584 13.2789 2.4039 13.3796 2.30939L14.7484 3.76757ZM10.4355 7.59985C10.4355 7.73794 10.3784 7.86989 10.2777 7.9644L8.90884 6.50622C8.60679 6.78975 8.43546 7.18558 8.43546 7.59985H10.4355ZM7.7938 14.8617C8.69846 15.9232 10.4355 15.2835 10.4355 13.8887H8.43546C8.43546 13.4238 9.01446 13.2106 9.31602 13.5644L7.7938 14.8617ZM5.56367 11.6924C5.56367 12.0489 5.69071 12.3939 5.922 12.6653L7.44423 11.368C7.52132 11.4585 7.56367 11.5735 7.56367 11.6924H5.56367ZM5.72146 7.9644C5.62078 7.86989 5.56367 7.73794 5.56367 7.59985H7.56367C7.56367 7.18558 7.39234 6.78975 7.0903 6.50622L5.72146 7.9644ZM0.777344 2.67394C0.777344 3.08821 0.948675 3.48403 1.25072 3.76757L2.61955 2.30939C2.72023 2.4039 2.77734 2.53585 2.77734 2.67394H0.777344ZM2.77734 2.26709C2.77734 2.54323 2.55349 2.76709 2.27734 2.76709V0.76709C1.44892 0.76709 0.777344 1.43866 0.777344 2.26709H2.77734Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_955_7581)"
      />
    </svg>
  );
};

import { ReactNode, useRef } from 'react';
import { IconClose } from '@unique/icons';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';

type Props = {
  value: string;
  setValue: (value: string) => void;
  config: BoxInputEnum;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  showClearButton?: boolean;
  className?: string;
  actions?: ReactNode;
  loader?: ReactNode;
  onClearButtonClicked?: () => void;
};

export enum BoxInputEnum {
  MUTABLE = 'MUTABLE',
  IMMUTABLE = 'IMMUTABLE',
}

export const TextBox = ({
  value,
  setValue,
  config,
  showClearButton = true,
  className,
  actions,
  loader,
  placeholder,
  onChange,
  onClearButtonClicked,
}: Props) => {
  const BoxInputConfig = {
    MUTABLE: {
      props: {
        disabled: false,
        autoFocus: true,
      },
      className:
        'border active:border-primary-cta border-control bg-surface focus-within:border-primary-cta',
    },
    IMMUTABLE: {
      props: {
        disabled: true,
        autoFocus: false,
      },
      className: 'border border-background bg-background',
    },
  };

  const CONFIG = BoxInputConfig[config as keyof typeof BoxInputConfig];

  const textBoxRef = useRef<HTMLTextAreaElement>(null);

  const clearInput = () => {
    onClearButtonClicked?.();
    setValue('');
    textBoxRef.current?.focus();
  };

  return (
    <div className="relative h-full">
      <textarea
        className={`placeholder:text-on-background-dimmed text-on-background-main h-full w-full resize-none rounded-lg pb-12 pt-5 ${className} ${CONFIG.className}`}
        {...CONFIG.props}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        ref={textBoxRef}
      />
      {loader && <div className="absolute left-2 top-2">{loader}</div>}
      {!!value && showClearButton && (
        <div className="absolute right-2 top-2">
          <ButtonIcon
            variant={ButtonVariant.SHAPE}
            onClick={clearInput}
            className="hover:text-on-surface text-on-surface !h-4 !cursor-pointer !p-0"
          >
            <IconClose width="18px" height="18px" />
          </ButtonIcon>
        </div>
      )}
      <div className="absolute bottom-2 right-2">{actions}</div>
    </div>
  );
};

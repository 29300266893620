import cn from 'classnames';
import { FC } from 'react';
import { IconInfo } from '@unique/icons';
import { Tooltip } from './Tooltip';

type ToggleSwitchProps = {
  id: string;
  active?: boolean;
  disabled?: boolean;
  activeText?: string;
  inactiveText?: string;
  extraClasses?: string;
  tooltipText?: string;
  tooltipClasses?: string;
  handleToggleChange: () => void;
};

export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  id = '',
  active = false,
  disabled = false,
  activeText = 'Active',
  inactiveText = 'Inactive',
  extraClasses = '',
  tooltipText = '',
  tooltipClasses = '',
  handleToggleChange,
}) => {
  return (
    <div className="flex items-center">
      <label
        htmlFor={id}
        data-testid={id}
        className={cn(
          {
            'flex items-center': true,
            'cursor-not-allowed': disabled,
          },
          extraClasses,
        )}
      >
        <div className="relative">
          <input
            type="checkbox"
            id={id}
            checked={active}
            data-checked={active}
            onChange={handleToggleChange}
            disabled={disabled}
            required={false}
            className={`sr-only ${disabled ? 'opacity-80' : ''}`}
          />
          <div
            className={cn({
              'block h-8 w-[56px] rounded-full transition': true,
              'bg-primary-cta': active,
              'bg-control': !active,
            })}
          ></div>
          <div
            className="dot bg-surface absolute left-0.5 top-0.5 h-7 w-7 rounded-full shadow-xl transition"
            data-testid="toggle"
          ></div>
        </div>
        <div
          className={cn({
            'text-on-background-main ml-3 flex items-center text-sm font-semibold transition': true,
            '!text-on-control-main': disabled,
          })}
          data-testid="tracker-active-text"
        >
          {active ? activeText : inactiveText}
        </div>
      </label>
      {tooltipText && (
        <Tooltip
          wrapper={
            <div className="text-on-background-main">
              <IconInfo />
            </div>
          }
          className={cn({ 'text-on-background-main': true }, tooltipClasses)}
          wrapperClasses="ml-2 mt-0.5"
          arrowPosition="none"
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  );
};

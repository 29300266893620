import { FC } from 'react';
import { IconProps } from '.';

export const IconFileUploadError: FC<IconProps> = ({
  width = '32px',
  height = '32px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 29.7739V2.22609C8 0.996653 8.94335 0 10.107 0H22.4858L31.9674 10.0174V29.7739C31.9674 31.0033 31.0241 32 29.8604 32H10.107C8.94335 32 8 31.0033 8 29.7739Z"
        fill="currentColor"
        className="text-error-light"
        />
      <path
        d="M22.6211 7.77778V0L31.9996 10H24.7052C23.5542 10 22.6211 9.00508 22.6211 7.77778Z"
        fill="currentColor"
        className="text-error-dark"
      />
      <path
        d="M13.6798 27.3335H26.3202C27.6128 27.3335 28.4185 25.9588 27.7722 24.864L21.452 14.1546C20.8058 13.0598 19.1942 13.0598 18.548 14.1546L12.2278 24.864C11.5815 25.9588 12.3872 27.3335 13.6798 27.3335ZM20 21.5713C19.5384 21.5713 19.1607 21.2009 19.1607 20.7482V19.1018C19.1607 18.6491 19.5384 18.2787 20 18.2787C20.4616 18.2787 20.8393 18.6491 20.8393 19.1018V20.7482C20.8393 21.2009 20.4616 21.5713 20 21.5713ZM20.8393 24.864H19.1607V23.2177H20.8393V24.864Z"
        fill="currentColor"
        className="text-on-error-light"
      />
    </svg>
  );
};

import { FC } from 'react';
import { ButtonIcon } from './ButtonIcon';
import { ButtonSize, ButtonVariant } from '../enums/button';
import { IconArrowDown } from '@unique/icons';
import Skeleton from './Skeleton';

type PaginationProps = {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  isLoading?: boolean;
  wrapperClasses?: string;
  handleNavigateToPage: (pageIndex: number) => void;
};

const MAX_PAGINATION_ITEMS_VISIBLE = 5;
export const Pagination: FC<PaginationProps> = ({
  totalCount,
  currentPage,
  pageSize,
  isLoading,
  wrapperClasses = '',
  handleNavigateToPage,
}) => {
  const totalNumOfPages = totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  let pagesToShow = Array.from(Array(totalNumOfPages), (_, index) => index + 1);
  const buttonClasses = 'subtitle-2 !px-0';

  if (
    pagesToShow.length > MAX_PAGINATION_ITEMS_VISIBLE &&
    currentPage >= MAX_PAGINATION_ITEMS_VISIBLE
  ) {
    pagesToShow = pagesToShow.slice(currentPage - 4, currentPage + 1);
  } else {
    pagesToShow = pagesToShow.slice(0, MAX_PAGINATION_ITEMS_VISIBLE);
  }

  if (totalNumOfPages < 2) return null;

  return (
    <div className={`flex items-center gap-x-2 ${wrapperClasses}`}>
      {isLoading ? (
        <>
          <Skeleton className="!h-8 !w-8" />
          <Skeleton className="ml-2 !h-8 !w-8" />
          <Skeleton className="ml-2 !h-8 !w-8" />
          <Skeleton className="ml-2 !h-8 !w-8" />
          <Skeleton className="ml-2 !h-8 !w-8" />
        </>
      ) : (
        <>
          <ButtonIcon
            className={buttonClasses}
            variant={ButtonVariant.SECONDARY}
            disabled={currentPage === 1}
            handleClick={() => handleNavigateToPage(currentPage - 1)}
            buttonSize={ButtonSize.SMALL}
          >
            <IconArrowDown className="origin-center rotate-90" />
          </ButtonIcon>

          {pagesToShow.map((pageIndex) => (
            <div key={`page${pageIndex}`}>
              <ButtonIcon
                className={buttonClasses}
                variant={
                  currentPage === pageIndex ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY
                }
                handleClick={() => handleNavigateToPage(pageIndex)}
                buttonSize={ButtonSize.SMALL}
              >
                {pageIndex}
              </ButtonIcon>
            </div>
          ))}
          <ButtonIcon
            className={buttonClasses}
            variant={ButtonVariant.SECONDARY}
            disabled={currentPage === totalNumOfPages}
            handleClick={() => handleNavigateToPage(currentPage + 1)}
            buttonSize={ButtonSize.SMALL}
          >
            <IconArrowDown className="origin-center -rotate-90" />
          </ButtonIcon>
        </>
      )}
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconApps: FC<IconProps> = ({ width = '24px', height = '24px' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={height} width={width}>
    <g>
      <path
        d="M16 8.27a0.25 0.25 0 0 0 0.12 -0.27 0.25 0.25 0 0 0 -0.12 -0.18L6.22 3.37a0.53 0.53 0 0 0 -0.41 0L2 5a0.27 0.27 0 0 0 -0.15 0.24 0.22 0.22 0 0 0 0.15 0.24l10.29 4.29a0.51 0.51 0 0 0 0.4 0Z"
        fill="currentColor"
        strokeWidth="1"
      ></path>
      <path
        d="M22.06 5.5a0.25 0.25 0 0 0 0.15 -0.23 0.24 0.24 0 0 0 -0.15 -0.22L12.4 0.85a1 1 0 0 0 -0.8 0l-3.22 1.4a0.25 0.25 0 0 0 -0.15 0.22 0.25 0.25 0 0 0 0.15 0.23l9.7 4.43a0.49 0.49 0 0 0 0.41 0Z"
        fill="currentColor"
        strokeWidth="1"
      ></path>
      <path
        d="M0.85 6.63a0.29 0.29 0 0 0 -0.24 0 0.26 0.26 0 0 0 -0.11 0.21V18a1 1 0 0 0 0.62 0.92l10.28 4.3a0.26 0.26 0 0 0 0.24 0 0.23 0.23 0 0 0 0.11 -0.2V11.34a0.25 0.25 0 0 0 -0.15 -0.23Z"
        fill="currentColor"
        strokeWidth="1"
      ></path>
      <path
        d="M13.25 22.94a0.25 0.25 0 0 0 0.35 0.23l9.31 -4.23a1 1 0 0 0 0.59 -0.94V6.89a0.25 0.25 0 0 0 -0.35 -0.23l-9.75 4.43a0.25 0.25 0 0 0 -0.15 0.23Z"
        fill="currentColor"
        strokeWidth="1"
      ></path>
    </g>
  </svg>
);

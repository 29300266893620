'use client';

import { useState, useEffect, useCallback } from 'react';

function useLocalStorage(key: string, initialValue: string) {
  // Get from local storage and handle possible errors
  // Return initialValue if any error or if item is missing in localStorage
  const getStoredValue = () => {
    try {
      const item = typeof window !== 'undefined' && localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  const [value, setValue] = useState<string>(getStoredValue);

  const updateValue = useCallback(
    (newValue: string) => {
      try {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, value],
  );

  // Use Effect hook to update localStorage when value changes
  useEffect(() => {
    updateValue(value);
  }, [key, value]);

  const remove = () => {
    localStorage.removeItem(key);
  };

  return [value, updateValue, remove] as const;
}

export default useLocalStorage;

import { Client, createClient } from 'graphql-ws';
import { logger } from '../logger';
import { serializeError } from 'serialize-error';

const MILLISECONDS = 1;
const SECONDS = 1000 * MILLISECONDS;
export const MINUTES = 60 * SECONDS;

const log = logger.child({
  package: 'next-commons',
  namespace: 'swr:create-ws-client',
});

export function createWsClient({
  service,
  serviceUrl,
  accessToken,
}: {
  service: string;
  serviceUrl: string;
  accessToken: string;
}): Client {
  let activeSocket: WebSocket | undefined;
  let timedOut: ReturnType<typeof setTimeout>;

  return createClient({
    url: `${serviceUrl.replace(/^http/, 'ws')}/graphql?token=${accessToken}`,
    connectionParams: async () => {
      return {
        authorization: `Bearer ${accessToken}`,
      };
    },
    keepAlive: 10_000,
    connectionAckWaitTimeout: 2_000,
    // Retry forever - we don't want to tap out of active subscriptions ever, they
    // should resume even if the client has connectivity issues for a longer period.
    retryAttempts: Number.MAX_SAFE_INTEGER,
    // Inspired from https://github.com/enisdenjo/graphql-ws/blob/master/src/client.ts#L477
    // Changed to cap the wait time at 3 minutes. Since we don't limit the number of retries,
    // we want to avoid exponential backoff getting out of hand if a client has connectivity
    // issues for a longer period.
    retryWait: async (retries) => {
      // 1000ms raised to the power of the number of retries (limited to 3 mins)
      // Plus some random time between 300ms and 3s to be nice to the server on reconnect
      const retryDelay =
        Math.min(3 * MINUTES, 1000 * 2 ** retries) + Math.floor(Math.random() * 2700 + 300);
      log.info(`Retrying WebSocket for service ${service} connection in ${retryDelay} ms`);
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    },
    shouldRetry: (error) => {
      log.warn(
        `Retrying WebSocket connection for service ${service}. Error was: ${JSON.stringify(
          serializeError(error),
        )}`,
      );
      return true;
    },
    on: {
      opened: (socket) => {
        log.info(`Websocket for service ${service} opened`);
        if (socket instanceof WebSocket) {
          activeSocket = socket;
        } else {
          throw new Error(`Socket for service ${service} is not a WebSocket`);
        }
      },
      connecting: () => {
        log.info(`Websocket for service ${service} connecting`);
      },
      connected: () => {
        log.info(`Websocket for service ${service} connected`);
      },
      closed: (event) => {
        log.info(`Websocket for service ${service} closed. Event: ${JSON.stringify(event)}`);
      },
      error: (error) => {
        log.error(
          `Websocket for service ${service} error. Error: ${JSON.stringify(serializeError(error))}`,
        );
      },
      ping: (received) => {
        if (!received) {
          // Wait 5 seconds for the poing before closing the connection
          timedOut = setTimeout(() => {
            log.info(
              { readyState: activeSocket?.readyState },
              `Websocket for service ${service} ping timed out after 5sec`,
            );
            if (activeSocket && activeSocket.readyState === WebSocket.OPEN) {
              activeSocket.close(4408, 'Ping timeout');
            }
          }, 5_000);
        }
      },
      pong: (received) => {
        if (received) {
          // Pong is received, clear connection close timeout
          clearTimeout(timedOut);
        }
      },
    },
  });
}

import { FC } from 'react';
import { IconProps } from '.';

export const IconBotErrorGeneric: FC<IconProps> = ({
  width = '126px',
  height = '126px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="63" cy="63" r="63" fill="#E3E3E3" />
      <path
        d="M98.3064 93.9854C98.3064 99.0336 94.214 103.126 89.1658 103.126L36.9339 103.126C31.8857 103.126 27.7933 99.0336 27.7933 93.9854L27.7933 63.9521C27.7933 58.9039 31.8857 54.8115 36.9339 54.8115L89.1658 54.8115C94.214 54.8115 98.3064 58.9039 98.3064 63.9521V93.9854Z"
        fill="#A0A0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.0923 54.809L61.0923 45.6684L65.0097 45.6684L65.0097 54.809L61.0923 54.809Z"
        fill="#C33450"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.0923 112.267L61.0923 103.126L65.0097 103.126L65.0097 112.267L61.0923 112.267Z"
        fill="#C33450"
      />
      <path
        d="M27.7935 79.2941C27.7935 87.0467 27.7935 86.1634 27.7935 93.3314C22.3847 93.3314 18 87.0467 18 79.2941C18 71.5415 22.3847 65.2568 27.7935 65.2568C27.7935 71.8274 27.7935 71.5415 27.7935 79.2941Z"
        fill="#C33450"
      />
      <path
        d="M98.3065 79.2942C98.3065 71.5416 98.3065 72.4249 98.3065 65.2569C103.715 65.2569 108.1 71.5416 108.1 79.2942C108.1 87.0468 103.715 93.3315 98.3065 93.3315C98.3065 86.7608 98.3065 87.0468 98.3065 79.2942Z"
        fill="#C33450"
      />
      <path
        d="M72.843 39.467C72.843 44.6955 68.6044 48.9341 63.3759 48.9341C58.1475 48.9341 53.9089 44.6955 53.9089 39.467C53.9089 34.2385 58.1475 30 63.3759 30C68.6044 30 72.843 34.2385 72.843 39.467Z"
        fill="#C33450"
      />
      <path
        d="M36.021 117.45C36.021 113.937 38.8689 111.089 42.382 111.089H83.7199C87.233 111.089 90.081 113.937 90.081 117.45C90.081 117.45 84.2502 122.75 63.0502 122.75C41.8502 122.75 36.021 117.45 36.021 117.45Z"
        fill="#A0A0A0"
      />
      <path d="M76.4142 92.5821H50.0502" stroke="#3E3E3E" strokeWidth="4" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.0602 83.4201C86.0363 83.4201 90.0702 79.3862 90.0702 74.4101C90.0702 69.4341 86.0363 65.4001 81.0602 65.4001C76.0841 65.4001 72.0502 69.4341 72.0502 74.4101C72.0502 79.3862 76.0841 83.4201 81.0602 83.4201Z"
        fill="#C33450"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6109 71.9608C86.1967 71.375 86.1967 70.4253 85.6109 69.8395C85.0251 69.2537 84.0754 69.2537 83.4896 69.8395L81.0502 72.2788L78.6109 69.8395C78.0251 69.2537 77.0754 69.2537 76.4896 69.8395C75.9038 70.4253 75.9038 71.375 76.4896 71.9608L78.9289 74.4001L76.4896 76.8395C75.9038 77.4253 75.9038 78.375 76.4896 78.9608C77.0754 79.5466 78.0251 79.5466 78.6109 78.9608L81.0502 76.5215L83.4896 78.9608C84.0754 79.5466 85.0251 79.5466 85.6109 78.9608C86.1967 78.375 86.1967 77.4253 85.6109 76.8395L83.1716 74.4001L85.6109 71.9608Z"
        fill="#3E3E3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6109 71.9608C86.1967 71.375 86.1967 70.4253 85.6109 69.8395C85.0251 69.2537 84.0754 69.2537 83.4896 69.8395L81.0502 72.2788L78.6109 69.8395C78.0251 69.2537 77.0754 69.2537 76.4896 69.8395C75.9038 70.4253 75.9038 71.375 76.4896 71.9608L78.9289 74.4001L76.4896 76.8395C75.9038 77.4253 75.9038 78.375 76.4896 78.9608C77.0754 79.5466 78.0251 79.5466 78.6109 78.9608L81.0502 76.5215L83.4896 78.9608C84.0754 79.5466 85.0251 79.5466 85.6109 78.9608C86.1967 78.375 86.1967 77.4253 85.6109 76.8395L83.1716 74.4001L85.6109 71.9608Z"
        fill="#3E3E3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0602 83.4201C50.0363 83.4201 54.0702 79.3862 54.0702 74.4101C54.0702 69.4341 50.0363 65.4001 45.0602 65.4001C40.0841 65.4001 36.0502 69.4341 36.0502 74.4101C36.0502 79.3862 40.0841 83.4201 45.0602 83.4201Z"
        fill="#C33450"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.6109 71.9608C50.1967 71.375 50.1967 70.4253 49.6109 69.8395C49.0251 69.2537 48.0754 69.2537 47.4896 69.8395L45.0502 72.2788L42.6109 69.8395C42.0251 69.2537 41.0754 69.2537 40.4896 69.8395C39.9038 70.4253 39.9038 71.375 40.4896 71.9608L42.9289 74.4001L40.4896 76.8395C39.9038 77.4253 39.9038 78.375 40.4896 78.9608C41.0754 79.5466 42.0251 79.5466 42.6109 78.9608L45.0502 76.5215L47.4896 78.9608C48.0754 79.5466 49.0251 79.5466 49.6109 78.9608C50.1967 78.375 50.1967 77.4253 49.6109 76.8395L47.1716 74.4001L49.6109 71.9608Z"
        fill="#3E3E3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.6109 71.9608C50.1967 71.375 50.1967 70.4253 49.6109 69.8395C49.0251 69.2537 48.0754 69.2537 47.4896 69.8395L45.0502 72.2788L42.6109 69.8395C42.0251 69.2537 41.0754 69.2537 40.4896 69.8395C39.9038 70.4253 39.9038 71.375 40.4896 71.9608L42.9289 74.4001L40.4896 76.8395C39.9038 77.4253 39.9038 78.375 40.4896 78.9608C41.0754 79.5466 42.0251 79.5466 42.6109 78.9608L45.0502 76.5215L47.4896 78.9608C48.0754 79.5466 49.0251 79.5466 49.6109 78.9608C50.1967 78.375 50.1967 77.4253 49.6109 76.8395L47.1716 74.4001L49.6109 71.9608Z"
        fill="#3E3E3E"
      />
      <path
        d="M101.956 9.33484C103.753 6.22172 108.247 6.22172 110.044 9.33484L121.367 28.9475C123.165 32.0606 120.918 35.952 117.323 35.952H94.6766C91.0819 35.952 88.8352 32.0606 90.6326 28.9475L101.956 9.33484Z"
        fill="#C33450"
      />
      <path d="M107.728 15.4054L107.424 25.9423H104.576L104.265 15.4054H107.728Z" fill="white" />
      <path
        d="M104.677 31.2349C105.045 31.5778 105.486 31.7493 106 31.7493C106.332 31.7493 106.638 31.6709 106.918 31.5141C107.199 31.3525 107.424 31.1394 107.595 30.8749C107.772 30.6055 107.863 30.3091 107.868 29.9858C107.863 29.5057 107.673 29.0942 107.3 28.7513C106.926 28.4084 106.493 28.237 106 28.237C105.486 28.237 105.045 28.4084 104.677 28.7513C104.309 29.0942 104.127 29.5057 104.132 29.9858C104.127 30.4707 104.309 30.8871 104.677 31.2349Z"
        fill="white"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconFeedback: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6667 0.666687H2.33333C1.41666 0.666687 0.674997 1.41669 0.674997 2.33335L0.666664 17.3334L4 14H15.6667C16.5833 14 17.3333 13.25 17.3333 12.3334V2.33335C17.3333 1.41669 16.5833 0.666687 15.6667 0.666687ZM4 10.6667V8.60835L9.73333 2.87502C9.9 2.70835 10.1583 2.70835 10.325 2.87502L11.8 4.35002C11.9667 4.51669 11.9667 4.77502 11.8 4.94169L6.05833 10.6667H4ZM14 10.6667H7.75L9.41667 9.00002H14V10.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

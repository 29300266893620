import { IngestionState } from '../@generated/graphql';
import {
  DELETABLE_INGESTION_STATES,
  FAILED_INGESTION_STATES,
  INGESTING_STATES,
} from './getIngestionStateType';

export const INGESTION_STATUS_TO_MESSAGE = {
  [IngestionState.Queued]: 'Uploading file',
  [IngestionState.IngestionReading]: 'Reading file',
  [IngestionState.IngestionEmbedding]: 'Analyzing file content',
  [IngestionState.Finished]: 'Preparing to chat',
  [IngestionState.MalwareScanning]: 'Scanning for malware',
};

export const isIngestionDone = (contentIngestionState: IngestionState) => {
  return DELETABLE_INGESTION_STATES.includes(contentIngestionState);
};

export const isIngestingContent = (contentIngestionState: IngestionState) => {
  return INGESTING_STATES.includes(contentIngestionState);
};

export const isIngestionFailed = (contentIngestionState: IngestionState) => {
  return FAILED_INGESTION_STATES.includes(contentIngestionState);
};
import { useRef, useEffect, useState } from 'react';
const THRESHOLD = 0;

export enum ScrollDirection {
  Up = 'UP',
  Down = 'DOWN',
}
export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.Up);

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    // add the option to define a custom wrapper (this is for example needed on pages that are wrapped with a scrollbar class)
    const scrollbarWrapper = document.getElementById('scrollbar-wrapper');
    prevScrollY.current = scrollbarWrapper ? scrollbarWrapper.scrollTop : window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = scrollbarWrapper ? scrollbarWrapper.scrollTop : window.scrollY;

      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection =
          scrollY > prevScrollY.current ? ScrollDirection.Down : ScrollDirection.Up;

        setScrollDirection(newScrollDirection);

        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    if (scrollbarWrapper) {
      scrollbarWrapper.addEventListener('scroll', onScroll);
    } else {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      if (scrollbarWrapper) {
        scrollbarWrapper.removeEventListener('scroll', onScroll);
      } else {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollDirection]);

  return scrollDirection;
};

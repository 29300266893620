import { FC } from 'react';
import { IconProps } from '.';

export const IconCloseSlim: FC<IconProps> = ({
  width = '13px',
  height = '13px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon (18px)/Close">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.05617 8L13.1147 12.0586C13.4064 12.3503 13.4064 12.8232 13.1147 13.1148C12.8231 13.4065 12.3502 13.4065 12.0585 13.1148L7.99998 9.05621L3.94145 13.1148C3.6498 13.4065 3.17692 13.4065 2.88526 13.1148C2.59359 12.8232 2.59359 12.3503 2.88524 12.0586L6.94379 8L2.88524 3.94137C2.59359 3.64971 2.59359 3.17683 2.88525 2.88517C3.17692 2.59352 3.6498 2.59352 3.94145 2.88518L7.99998 6.94379L12.0585 2.88516C12.3502 2.59349 12.8231 2.59349 13.1147 2.88515C13.4064 3.17681 13.4064 3.64968 13.1147 3.94135L9.05617 8Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconKey: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={height} width={width}>
      <g>
        <path
          d="M12.82 14.51 15.36 12l1.76 0.47a1.56 1.56 0 0 0 1.51 -0.47 1.58 1.58 0 0 0 0.37 -1.49l-0.47 -1.75 0.82 -0.76 1.76 0.47A1.56 1.56 0 0 0 22.62 8 1.58 1.58 0 0 0 23 6.52l-0.47 -1.75a2.35 2.35 0 0 0 -3.33 -3.33l-9.71 9.74a6.29 6.29 0 1 0 3.33 3.33Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M4.26 17.84a1.9 1.9 0 1 0 3.8 0 1.9 1.9 0 1 0 -3.8 0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconShapeExpandMenu: FC<IconProps> = ({ width = '50px', height = '50px' }) => {
  return (
    <svg
      className="group"
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="transition-colors"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6 14C14.7163 14 14 14.8266 14 15.8462V36.1538C14 37.1734 14.7163 38 15.6 38H20.4C21.2837 38 22 37.1734 22 36.1538V15.8462C22 14.8266 21.2837 14 20.4 14H15.6Z"
        fill="currentColor"
      />
      <path
        className="transition-colors"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2929 20.2929C30.6834 19.9024 31.3166 19.9024 31.7071 20.2929L37.7071 26.2929C38.0976 26.6834 38.0976 27.3166 37.7071 27.7071L31.7071 33.7071C31.3166 34.0976 30.6834 34.0976 30.2929 33.7071C29.9024 33.3166 29.9024 32.6834 30.2929 32.2929L35.5858 27L30.2929 21.7071C29.9024 21.3166 29.9024 20.6834 30.2929 20.2929Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { isLinkElement } from '@unique/shared-library/helpers/isLinkElement';

// TODO: this is a hack-ish way to handle the form dirty state when clicking outside the form, ticket https://unique-ch.atlassian.net/browse/UN-7115
// We are using the app routing and we are on version 13.0.0 of next.js, so we don't have access to the new router events
export const handleClickOutsideForm = (event: MouseEvent, isDirty: boolean) => {
  const eventTargetHTMLEl = event.target as HTMLElement;
  // also check if parent is a link element (this can be the case for top level navigation items)
  if (
    (isLinkElement(eventTargetHTMLEl) ||
      (eventTargetHTMLEl.parentElement && isLinkElement(eventTargetHTMLEl.parentElement))) &&
    isDirty
  ) {
    const linkEl = eventTargetHTMLEl.closest('a');
    const shouldLeavePage = confirm(
      'Warning: You have unsaved changes. Are you sure you want to leave the page?',
    );
    if (shouldLeavePage && linkEl?.href) {
      window.location.href = linkEl.href;
    } else {
      event.preventDefault();
    }
  }
};

import { createLogWriter } from '@roarr/browser-log-writer';

/**
 * HowTo
 *
 * In order to show logs in Production, you must set `ROARR_LOG=true` in `localStorage`.
 * e.g. paste `localStorage.setItem('ROARR_LOG', 'true')` into the browser console.
 *
 * !Important: Adhere to the conventions mentioned here: https://github.com/gajus/roarr#context-property-names and construct a child logger.
 *
 * !Important: Serialize error objects before logging: https://github.com/gajus/roarr#logging-errors
 *
 * Examples:
 *
 *  import { logger } from '@unique/next-commons/logger'
 *
 *  const log = logger.child({
 *    application: 'chat',
 *    namespace: 'ChatMessages',
 *  });
 *
 *  log.info('Hello World');
 *
 *  const log = logger.child({
 *     package: 'next-commons',
 *     namespace: 'oidc-auth:require-auth',
 *   });
 *
 *  import serializeError from 'serialize-error';
 *
 *  log.error({ error: serializeError(error) }, 'Failed to authenticate user');
 */

globalThis.ROARR = globalThis.ROARR ?? {};
globalThis.ROARR.write = createLogWriter();

export { Roarr as logger } from 'roarr';

'use client';

import { FC, ReactNode, createContext, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

type FeatureFlagContextProps = {
  // flags: Record<keyof typeof FeatureFlagsEnum, boolean>;
  // flags is a record with key a value of FeatureFlagsEnum and value a boolean
  flags: Record<string, boolean>;
};
export const FeatureFlagContext = createContext<FeatureFlagContextProps>({ flags: {} });

type FeatureFlagProviderProps = {
  children: ReactNode;
  flags: Record<string, string>;
};

const zitadelCompanyIdKey = 'urn:zitadel:iam:user:resourceowner:id';

export const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({ children, flags }) => {
  const { user } = useAuth();
  const processedFlags = useMemo(() => {
    const result: Record<string, boolean> = {};
    Object.keys(flags).forEach((flag) => {
      result[flag] = false;
      if (['1', 'true', 'True'].includes(flags[flag])) {
        result[flag] = true;
      } else {
        // If the flag is not a stringified boolean, it is a list of ids
        if (user) {
          const companyId = user?.profile?.[zitadelCompanyIdKey];
          const ids = flags[flag].split(',');
          result[flag] = ids.includes(`${companyId ?? ''}`);
        }
      }
    });
    return result;
  }, [flags, user]);

  return (
    <FeatureFlagContext.Provider value={{ flags: processedFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

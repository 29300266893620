import { FC, useRef, useState } from 'react';

import { ProfileImage } from '../ProfileImage';
import { getInitials, useOutsideClick } from '../helpers/index';
import { MenuDropdown, UserMenuItem } from './MenuDropdown';
import { MenuHeader } from './MenuHeader';

interface UserMenuProps {
  userName: string;
  userEmail: string;
  image?: string;
  basePath?: string;
  menuSections: UserMenuItem[][];
}

export const UserMenu: FC<UserMenuProps> = ({ userName, userEmail, image, basePath, menuSections }) => {
  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(dropdownRef, () => setShowUserDropdown(false));

  const ProfileImageNode = (
    <div className="h-10 w-10 lg:mr-2">
      <ProfileImage
        image={image}
        initials={getInitials(userName, userEmail)}
        dimensions="100%"
        basePath={basePath}
      />
    </div>
  );

  return (
    <div
      onClick={() => setShowUserDropdown(!showUserDropdown)}
      className="hover:bg-surface h-full transition"
      ref={dropdownRef}
    >
      <MenuHeader showDropdown={showUserDropdown} wrapper={ProfileImageNode} />
      <MenuDropdown
        showDropdown={showUserDropdown}
        sections={menuSections}
        userName={userName}
        userEmail={userEmail}
        profileImage={ProfileImageNode}
      />
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconDelete: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.799615 4.89639C0.706976 4.07316 1.29924 3.3307 2.12247 3.23806L9.37068 2.42242C10.1939 2.32978 10.9364 2.92204 11.029 3.74527L11.0652 4.06673L0.835789 5.21785L0.799615 4.89639Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M3.79105 2.54691L3.84697 3.04377L4.34383 2.98786L7.14961 2.67212L7.64647 2.61621L7.59056 2.11935C7.51609 1.45754 6.91921 0.981412 6.25741 1.05589L4.85452 1.21375C4.19271 1.28823 3.71658 1.8851 3.79105 2.54691Z"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.001 7.05957H0.707031L1.37267 16.8422C1.44407 17.8917 2.31618 18.7065 3.36805 18.7065H9.58377C10.6549 18.7065 11.5358 17.8626 11.5819 16.7925L12.001 7.05957ZM5.74951 10.0002C5.74951 9.58603 5.41373 9.25024 4.99951 9.25024C4.5853 9.25024 4.24951 9.58603 4.24951 10.0002V15.0002C4.24951 15.4145 4.5853 15.7502 4.99951 15.7502C5.41373 15.7502 5.74951 15.4145 5.74951 15.0002V10.0002ZM8.74951 10.0002C8.74951 9.58603 8.41373 9.25024 7.99951 9.25024C7.5853 9.25024 7.24951 9.58603 7.24951 10.0002V15.0002C7.24951 15.4145 7.5853 15.7502 7.99951 15.7502C8.41373 15.7502 8.74951 15.4145 8.74951 15.0002V10.0002Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconExpiredFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4858 0L31.9674 10.0174V18.6667L29.3333 14.6667L26.6667 18.6667L24 14.6667L21.3333 18.6667L18.6667 14.6667L16 18.6667L13.3333 14.6667L10.6667 18.6667L8 14.6667V2.22609C8 0.996653 8.94335 0 10.107 0H22.4858Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M22.6209 7.77778V0L31.9995 10H24.705C23.554 10 22.6209 9.00508 22.6209 7.77778Z"
        fill="currentColor"
        className="text-on-background-dimmed"
      />
      <path
        d="M8 30V20L10.6667 24L13.3333 20L16 24L18.6667 20L21.3333 24L24 20L26.6667 24L29.3333 20L32 24V30C32 31.1046 31.1046 32 30 32H10C8.89543 32 8 31.1046 8 30Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
    </svg>
  );
};

import { MutableRefObject } from 'react';

/**
 * Truncates the placeholder text of a textarea element if it exceeds the width of the element.
 * It ensures that the placeholder text does not overflow the textarea by adding an ellipsis
 * to the truncated text.
 *
 * @param {string} textareaRef - The ref of the textarea element whose placeholder text needs to be truncated.
 * @param {string} originalPlaceholderText - The original placeholder text of the textarea element.
 *
 */
export const truncatePlaceholderText = (
  textareaRef: MutableRefObject<HTMLTextAreaElement | null>,
  originalPlaceholderText?: string,
) => {
  const textarea = textareaRef?.current;
  if (!textarea || !originalPlaceholderText) return;

  const style = window.getComputedStyle(textarea);

  // Create a canvas context to measure text
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return;
  context.font = style.fontSize + ' ' + style.fontFamily;

  // Measure the text and the available width
  const textWidth = context.measureText(originalPlaceholderText).width;
  const availableWidth =
    textarea.clientWidth -
    parseFloat(style.paddingLeft) -
    parseFloat(style.paddingRight) -
    parseFloat(style.borderLeftWidth) -
    parseFloat(style.borderRightWidth);

  // Truncate text if it's too wide
  if (textWidth > availableWidth) {
    let truncatedText = '';
    let currentWidth = 0;
    const ellipsisWidth = context.measureText('…').width;

    for (let i = 0; i < originalPlaceholderText.length; i++) {
      const charWidth = context.measureText(originalPlaceholderText[i]).width;
      if (currentWidth + charWidth + ellipsisWidth > availableWidth) {
        truncatedText += '…';
        break;
      }
      truncatedText += originalPlaceholderText[i];
      currentWidth += charWidth;
    }
    textarea.setAttribute('placeholder', truncatedText);
  } else {
    textarea.setAttribute('placeholder', originalPlaceholderText);
  }
};

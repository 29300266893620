import { FC } from 'react';
import { IconProps } from '.';

export const IconPptFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29.7739V2.22609C9 0.996653 9.90527 0 11.022 0H22.9011L32 10.0174V29.7739C32 31.0033 31.0947 32 29.978 32H11.022C9.90527 32 9 31.0033 9 29.7739Z"
        fill="#CAD6D9"
      />
      <path d="M23 7.77778V0L32 10H25C23.8954 10 23 9.00508 23 7.77778Z" fill="#92AAB1" />
      <path d="M20 24.25C24 24.25 26 22.25 26 18.25H22V14.25H20V18.25V24.25Z" fill="#E48762" />
      <path d="M24 16.25V12.25C25.5 12.25 28 14.75 28 16.25H24Z" fill="#F9D4C4" />
      <path
        d="M0 9.5C0 8.67157 0.671573 8 1.5 8H16.5C17.3284 8 18 8.67157 18 9.5V24.5C18 25.3284 17.3284 26 16.5 26H1.5C0.671573 26 0 25.3284 0 24.5V9.5Z"
        fill="#C25B33"
      />
      <path
        d="M6 21.7715V11.75H8.66602C9.65951 11.75 10.3089 11.7979 10.6143 11.8936C11.1019 12.0485 11.5029 12.3789 11.8174 12.8848C12.1364 13.3906 12.2959 14.0423 12.2959 14.8398C12.2959 15.5645 12.1592 16.1729 11.8857 16.665C11.6123 17.1527 11.2705 17.4967 10.8604 17.6973C10.4502 17.8932 9.74381 17.9912 8.74121 17.9912H7.6543V21.7715H6ZM7.6543 13.4453V16.2891H8.57031C9.18555 16.2891 9.60254 16.2458 9.82129 16.1592C10.0446 16.0726 10.2269 15.9176 10.3682 15.6943C10.5094 15.4665 10.5801 15.1885 10.5801 14.8604C10.5801 14.5277 10.5072 14.2474 10.3613 14.0195C10.2155 13.7917 10.0355 13.639 9.82129 13.5615C9.6071 13.484 9.15365 13.4453 8.46094 13.4453H7.6543Z"
        fill="white"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconStop: FC<IconProps> = ({ width = '14px', height = '14px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.55566" y="1.55554" width="10.8889" height="10.8889" rx="2" fill="currentColor" />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconCalendar: FC<IconProps> = ({ width = '14px', height = '14px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4 0C11.8103 0 12.1484 0.308832 12.1946 0.706703L12.2 0.8V1.6H13C14.3255 1.6 15.4 2.67452 15.4 4L15.4 7.2L15.4 13.6C15.4 14.9255 14.3255 16 13 16H3.4C2.07452 16 1 14.9255 1 13.6V7.2V4C1 2.67452 2.07452 1.6 3.4 1.6H4.2V0.8C4.2 0.358172 4.55817 0 5 0C5.41027 0 5.74841 0.308832 5.79462 0.706703L5.8 0.8V1.6H10.6V0.8C10.6 0.358172 10.9582 0 11.4 0ZM13.8 4.00002V6.4H2.6V4.00002C2.6 3.5582 2.95817 3.20002 3.4 3.20002H4.2V4L4.20538 4.0933C4.25159 4.49117 4.58973 4.8 5 4.8C5.44183 4.8 5.8 4.44183 5.8 4V3.20002H10.6V4L10.6054 4.0933C10.6516 4.49117 10.9897 4.8 11.4 4.8C11.8418 4.8 12.2 4.44183 12.2 4V3.20002H13C13.4418 3.20002 13.8 3.5582 13.8 4.00002ZM13.8 8H2.6V13.6C2.6 14.0419 2.95817 14.4 3.4 14.4H13C13.4418 14.4 13.8 14.0419 13.8 13.6V8ZM4.2 10.4C4.2 9.95817 4.55817 9.6 5 9.6H6.6C7.04183 9.6 7.4 9.95817 7.4 10.4V12C7.4 12.4418 7.04183 12.8 6.6 12.8H5C4.55817 12.8 4.2 12.4418 4.2 12V10.4Z"
        fill="currentColor"
      />
    </svg>
  );
};

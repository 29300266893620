'use client';

import { FC } from 'react';
import { ErrorPage } from './ErrorPage';

interface NotImplementedPageProps {
  basePath?: string;
}

export const NotImplementedPage: FC<NotImplementedPageProps> = ({ basePath }) => {
  return (
    <ErrorPage
      statusCode={501}
      title="Not Implemented"
      description="The server lacks the ability to fulfill the request."
      basePath={basePath}
    />
  );
};

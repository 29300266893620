import { FC } from 'react';
import { IconProps } from '.';

export const IconFilePlain: FC<IconProps> = ({
  width = '32px',
  height = '32px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 29.7739V2.22609C0 0.996653 0.943347 0 2.10703 0H14.4858L23.9674 10.0174V29.7739C23.9674 31.0033 23.0241 32 21.8604 32H2.10703C0.943348 32 0 31.0033 0 29.7739Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M14.6211 7.77778V0L23.9996 10H16.7052C15.5542 10 14.6211 9.00508 14.6211 7.77778Z"
        fill="currentColor"
        className="text-background-variant"
      />
    </svg>
  );
};

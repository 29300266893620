import { FC } from 'react';
import { IconProps } from '.';

export const IconCopy: FC<IconProps> = ({ width = '13px', height = '13px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9474 0H2.68421C1.75789 0 1 0.736364 1 1.63636V12.2727C1 12.7227 1.37895 13.0909 1.84211 13.0909C2.30526 13.0909 2.68421 12.7227 2.68421 12.2727V2.45455C2.68421 2.00455 3.06316 1.63636 3.52632 1.63636H11.9474C12.4105 1.63636 12.7895 1.26818 12.7895 0.818182C12.7895 0.368182 12.4105 0 11.9474 0ZM12.4442 3.75545L16.5116 7.70727C16.8232 8.01 17 8.42727 17 8.86091V16.3636C17 17.2636 16.2421 18 15.3158 18H6.04421C5.11789 18 4.36842 17.2636 4.36842 16.3636L4.37684 4.90909C4.37684 4.00909 5.12632 3.27273 6.05263 3.27273H11.2484C11.6947 3.27273 12.1242 3.44455 12.4442 3.75545ZM11.9474 9H15.7368L11.1053 4.5V8.18182C11.1053 8.63182 11.4842 9 11.9474 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

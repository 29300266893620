import { FC } from 'react';
import { IconProps } from '.';

export const IconAnalytics: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.77273 23H3.22727C2.82727 23 2.5 22.55 2.5 22V15C2.5 14.45 2.82727 14 3.22727 14H5.77273C6.17273 14 6.5 14.45 6.5 15V22C6.5 22.55 6.17273 23 5.77273 23Z"
        fill="currentColor"
      />
      <path
        d="M18.2273 1H20.7727C21.1727 1 21.5 1.45 21.5 2V22C21.5 22.55 21.1727 23 20.7727 23H18.2273C17.8273 23 17.5 22.55 17.5 22V2C17.5 1.45 17.8273 1 18.2273 1Z"
        fill="currentColor"
      />
      <path
        d="M10.7273 9H13.2727C13.6727 9 14 9.45 14 10V22C14 22.55 13.6727 23 13.2727 23H10.7273C10.3273 23 10 22.55 10 22V10C10 9.45 10.3273 9 10.7273 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconUpload: FC<IconProps> = ({ width = '20px', height = '20px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5125 6.9075C14.0025 4.10437 11.73 2 9 2C6.8325 2 4.95 3.3325 4.0125 5.2825C1.755 5.5425 0 7.61438 0 10.125C0 12.8144 2.0175 15 4.5 15H14.25C16.32 15 18 13.18 18 10.9375C18 8.7925 16.4625 7.05375 14.5125 6.9075ZM10.5 9.3125V12.5625H7.5V9.3125H5.25L9 5.25L12.75 9.3125H10.5Z"
        fill="currentcolor"
      />
    </svg>
  );
};

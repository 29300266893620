import { useState } from 'react';
import ReactDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, isDate } from 'date-fns';

import { IconCalendar, IconEdit } from '@unique/icons';
import { ButtonVariant } from '../enums/button';
import { ButtonIcon } from './ButtonIcon';

type DatepickerProps = {
  defaultDate: Date;
  onDateSelected: (date: Date) => void;
  label: string;
  variant?: 'normal' | 'dark';
};

const CustomDatePickerInput = (props: {
  defaultValue: Date;
  onClick?: () => void;
  label: string;
}) => {
  const { onClick, defaultValue, label } = props;
  return (
    <div className="flex flex-col items-start">
      <label className="subtitle-2 text-on-surface">{label}</label>
      <ButtonIcon
        variant={ButtonVariant.SECONDARY}
        onClick={onClick}
        icon={<IconCalendar width="16px" height="16px" />}
      >
        <div className="flex items-center">
          {format(defaultValue, 'MMM dd, yyyy')}
          <div className="ml-2">
            <IconEdit width="18px" height="18px" />
          </div>
        </div>
      </ButtonIcon>
    </div>
  );
};

const Datepicker = (props: DatepickerProps) => {
  const { defaultDate, onDateSelected, label = 'Select Date' } = props;
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const handleDateChanged = (date: Date) => {
    if (date && isDate(date)) {
      setSelectedDate(date);
      onDateSelected(date);
    }
  };
  if (!defaultDate || !isDate(defaultDate)) {
    return null;
  }

  return (
    <div>
      <ReactDatepicker
        selected={selectedDate}
        onChange={handleDateChanged}
        startDate={selectedDate}
        openToDate={selectedDate}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        customInput={<CustomDatePickerInput defaultValue={selectedDate} label={label} />}
      />
    </div>
  );
};

export default Datepicker;

import {
  IconCSVFile,
  IconExcelFile,
  IconExternalFile,
  IconHtmlFile,
  IconMdFile,
  IconPdfFile,
  IconPptFile,
  IconTextFile,
  IconWordFile,
  IconJpgFile,
  IconPngFile,
} from '@unique/icons';
import { FC } from 'react';
import { FILE_TYPES, FileType } from '../constants/file-type';

export const getFileTypeByMimeType = (mimeType: string) => {
  return Object.keys(FILE_TYPES).find((fileType) => {
    return FILE_TYPES[fileType as FileType].includes(mimeType);
  });
};

export const getTruncatedString = (text: string, MAX_THRESHOLD = 20) => {
  if (text.length <= MAX_THRESHOLD) return text;
  else
    return `${text.substring(0, MAX_THRESHOLD / 2)}...${text.substring(
      text.length - MAX_THRESHOLD / 2,
    )}`;
};

export const getFileTypeVisuals = (fileType: string | undefined, isExternal?: boolean) => {
  switch (fileType) {
    case FileType.WORD:
    case FileType.DOC:
    case FileType.DOCX:
      return IconWordFile;
    case FileType.PPT:
    case FileType.PPTX:
      return IconPptFile;
    case FileType.PDF:
      return IconPdfFile;
    case FileType.HTML:
    case FileType.HTM:
      return IconHtmlFile;
    case FileType.MD:
      return IconMdFile;
    case FileType.TXT:
      return IconTextFile;
    case FileType.CSV:
      return IconCSVFile;
    case FileType.XLSX:
    case FileType.XLS:
      return IconExcelFile;
    case FileType.JPG:
    case FileType.JPEG:
      return IconJpgFile;
    case FileType.PNG:
      return IconPngFile;
    default:
      return isExternal ? IconExternalFile : IconTextFile;
  }
};

export const getMimeTypeVisuals = (
  mimeType: string,
  isExternal: boolean,
): FC<{ width?: string; height?: string }> => {
  const fileType = getFileTypeByMimeType(mimeType);
  return getFileTypeVisuals(fileType, isExternal);
};

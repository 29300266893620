'use client';

import { FC, ReactNode, createContext, useCallback, useState } from 'react';
import { Toast, ToastProps } from '../src/Toast';

type ToastContextProps = {
  showToast?: (vars: ToastProps) => void;
};
export const ToastContext = createContext<ToastContextProps>({});

type ToastProviderProps = {
  children: ReactNode;
};

// TODO:
// Add hasOffset property back. It should be passed to the ToastProvider as a prop.
// With the current setup (e.g. the ToastProvider being rendered on server side) we cannot pass the hasOffset
// because the values of this needs to be taken from the LayoutContext which can only be done on client side
// However we plan to move the ToastProvider to the client side in the future so we can add the hasOffset prop back then
export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<ToastProps>();

  const showToast = useCallback(
    ({ message, variant, onClear, toastAction, duration, icon }: ToastProps) => {
      const newToast = {
        message,
        variant,
        onClear: () => {
          setToast(undefined);
          onClear && onClear();
        },
        toastAction,
        duration,
        icon,
      };
      setToast(newToast);
    },
    [],
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast
        message={toast?.message}
        variant={toast?.variant}
        onClear={toast?.onClear}
        duration={toast?.duration}
        toastAction={toast?.toastAction}
        icon={toast?.icon}
      />
    </ToastContext.Provider>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconLightBulb: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.07143 16.9878C7.07143 17.3065 7.32857 17.6253 7.71429 17.6253H10.2857C10.6714 17.6253 10.9286 17.3065 10.9286 16.9878V16.3503H7.07143V16.9878ZM9 4.87529C6.49286 4.87529 4.5 6.85154 4.5 9.33779C4.5 10.8678 5.27143 12.2065 6.42857 12.9715V14.4378C6.42857 14.7565 6.68571 15.0753 7.07143 15.0753H10.9286C11.3143 15.0753 11.5714 14.7565 11.5714 14.4378V12.9715C12.7286 12.1428 13.5 10.804 13.5 9.33779C13.5 6.85154 11.5071 4.87529 9 4.87529Z"
        fill="currentColor"
      />
      <path
        d="M9.00003 3.075C9.50402 3.075 9.90002 2.67 9.90002 2.175L9.90002 1.275C9.90002 0.779999 9.49503 0.374999 9.00003 0.374999C8.50503 0.374999 8.10003 0.779999 8.10003 1.275L8.10003 2.175C8.10003 2.67 8.50503 3.075 9.00003 3.075Z"
        fill="currentColor"
      />
      <path
        d="M14.409 4.79421L15.048 4.15521C15.399 3.80421 15.399 3.23721 15.048 2.88621C14.697 2.53521 14.13 2.53521 13.779 2.88621L13.14 3.52521C12.789 3.87621 12.789 4.44321 13.14 4.7942C13.482 5.1362 14.058 5.1362 14.409 4.79421Z"
        fill="currentColor"
      />
      <path
        d="M15.3 9.37544C15.3 9.87044 15.705 10.2754 16.2 10.2754L17.1 10.2754C17.595 10.2754 18 9.87044 18 9.37544C18 8.88044 17.595 8.47544 17.1 8.47544L16.2 8.47544C15.705 8.47544 15.3 8.88044 15.3 9.37544Z"
        fill="currentColor"
      />
      <path
        d="M0.900014 10.2754L1.80001 10.2754C2.29501 10.2754 2.70001 9.87044 2.70001 9.37544C2.70001 8.88044 2.29501 8.47544 1.80001 8.47544L0.900014 8.47544C0.405014 8.47544 1.44271e-05 8.88044 1.43838e-05 9.37544C1.43405e-05 9.87044 0.405014 10.2754 0.900014 10.2754Z"
        fill="currentColor"
      />
      <path
        d="M3.59101 4.79441C3.94201 5.14541 4.50901 5.14541 4.86001 4.79441C5.21101 4.44341 5.21101 3.87641 4.86001 3.52541L4.22101 2.88642C3.87001 2.53542 3.30301 2.53542 2.95201 2.88642C2.60101 3.23742 2.60101 3.80441 2.95201 4.15541L3.59101 4.79441Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconShapeCollapseMenu: FC<IconProps> = ({ width = '50px', height = '50px' }) => {
  return (
    <svg
      className="group"
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="transition-colors"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4 14C14.6268 14 14 14.8266 14 15.8462V36.1538C14 37.1734 14.6268 38 15.4 38H19.6C20.3732 38 21 37.1734 21 36.1538V15.8462C21 14.8266 20.3732 14 19.6 14H15.4Z"
        fill="currentColor"
      />
      <path
        className="transition-colors"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 14C24.3431 14 23 14.8266 23 15.8462V36.1538C23 37.1734 24.3431 38 26 38H35C36.6569 38 38 37.1734 38 36.1538V27H29.4142L33.7071 31.2929C34.0976 31.6834 34.0976 32.3166 33.7071 32.7071C33.3166 33.0976 32.6834 33.0976 32.2929 32.7071L26.2929 26.7071C25.9024 26.3166 25.9024 25.6834 26.2929 25.2929L32.2929 19.2929C32.6834 18.9024 33.3166 18.9024 33.7071 19.2929C34.0976 19.6834 34.0976 20.3166 33.7071 20.7071L29.4142 25H38V15.8462C38 14.8266 36.6569 14 35 14H26Z"
        fill="currentColor"
      />
    </svg>
  );
};

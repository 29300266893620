'use client';

import { useState, useEffect, useMemo } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const tailwindConfig = require('@/tailwind.config.js');

enum ScreenType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  DESKTOP_SMALL = 'desktop-small',
}

/**
 * Function to get the current screen type based on the window width
 * @returns {Object} An object with boolean values indicating the current screen type.
 */
const getCurrentScreenType = () => {
  const BREAK_POINT_FOR_DESKTOP = +resolveConfig(tailwindConfig).theme.screens.xl.replace('px', '');
  const BREAK_POINT_FOR_TABLET = +resolveConfig(tailwindConfig).theme.screens.lg.replace('px', '');
  const BREAK_POINT_FOR_MOBILE = +resolveConfig(tailwindConfig).theme.screens.sm.replace('px', '');
  if (window.innerWidth < BREAK_POINT_FOR_MOBILE) {
    return ScreenType.MOBILE;
  } else if (window.innerWidth < BREAK_POINT_FOR_TABLET) {
    return ScreenType.TABLET;
  } else if (window.innerWidth < BREAK_POINT_FOR_DESKTOP) {
    return ScreenType.DESKTOP_SMALL;
  }
  return ScreenType.DESKTOP;
};

export function useScreens() {
  const [screenType, setScreenType] = useState<ScreenType>(getCurrentScreenType());

  useEffect(() => {
    const onResize = () => {
      setScreenType(getCurrentScreenType());
    };
    // Listen to resize so we can hide columns on mobile view
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const { isMobile, isTablet, isDesktop, isSmallDesktop } = useMemo(() => {
    return {
      isMobile: screenType === ScreenType.MOBILE,
      isTablet: screenType === ScreenType.TABLET,
      isDesktop: screenType === ScreenType.DESKTOP,
      isSmallDesktop: screenType === ScreenType.DESKTOP_SMALL,
    };
  }, [screenType]);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isSmallDesktop,
  };
}

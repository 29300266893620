import { FC } from 'react';
import { IconProps } from '.';

export const IconCaretUp: FC<IconProps> = ({ width = '15px', height = '15px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5856 10.4685C13.3269 10.7919 12.8549 10.8444 12.5315 10.5856L7.99997 6.96044L3.46849 10.5856C3.14505 10.8444 2.67308 10.7919 2.41432 10.4685C2.15556 10.145 2.208 9.67308 2.53145 9.41432L7.53145 5.41432C7.80537 5.19519 8.19458 5.19519 8.46849 5.41432L13.4685 9.41432C13.7919 9.67308 13.8444 10.145 13.5856 10.4685Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { useContext } from 'react';
import { ToastContext } from '../providers/ToastProvider';

/**
 * useToast Hook
 *
 * @returns {ToastContext} ToastContext
 *
 * Example:
 *  const { showToast } = useToast();
 *  showToast({ message: 'Successfully updated', variant: ToastVariant.SUCCESS });
 */
export const useToast = () => useContext(ToastContext);

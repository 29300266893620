import { FC } from 'react';
import { IconProps } from '.';

export const IconConfluence: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="6" fill="#CAD6D9" />
      <path
        d="M4.14911 16.2877C3.96658 16.5757 3.7874 16.8658 3.61162 17.1579C3.45868 17.4137 3.53524 17.7464 3.79084 17.9L7.29469 20.05C7.55047 20.2035 7.88246 20.1264 8.03611 19.8708C8.20059 19.5772 8.37109 19.287 8.54749 19.0004C9.92906 16.7231 11.3353 16.9789 13.8415 18.1819L17.3201 19.8191C17.6011 19.9473 17.9084 19.8191 18.0359 19.5633L19.6987 15.8019C19.826 15.5456 19.6987 15.2134 19.4422 15.0852C18.7008 14.7523 17.2435 14.0612 15.9646 13.4217C11.2335 11.1437 7.21743 11.2973 4.14911 16.2877Z"
        fill="url(#paint0_linear_1400_12710)"
      />
      <path
        d="M19.8517 7.74056C20.0342 7.45259 20.2134 7.16251 20.3892 6.87037C20.5426 6.61459 20.4655 6.2819 20.2099 6.12825L16.7061 3.97828C16.4503 3.82481 16.1183 3.9019 15.9647 4.1575C15.8004 4.45124 15.6299 4.74143 15.4533 5.02787C14.0717 7.3052 12.6654 7.04942 10.1593 5.84636L6.68069 4.20919C6.39969 4.08095 6.0931 4.20919 5.96486 4.46497L4.30281 8.22636C4.17474 8.48268 4.30281 8.81554 4.55859 8.94308C5.30001 9.27595 6.75796 9.96709 8.03616 10.6065C12.7681 12.8592 16.7833 12.7308 19.8517 7.74056Z"
        fill="url(#paint1_linear_1400_12710)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1400_12710"
          x1="19.6145"
          y1="21.1636"
          x2="9.03118"
          y2="15.087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052CC" />
          <stop offset="0.918" stopColor="#2380FB" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1400_12710"
          x1="4.38625"
          y1="2.85761"
          x2="14.9696"
          y2="8.93496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052CC" />
          <stop offset="0.918" stopColor="#2380FB" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import cn from 'classnames';
import Link from 'next/link';
import { FC, ReactNode } from 'react';
import { Tooltip } from '../Tooltip';
import { useScreens } from '@unique/shared-library';

export interface ToplevelNavItemProps {
  href: string;
  name: string;
  icon: ReactNode;
  isVisible: boolean;
  isExternal?: boolean;
  isActive?: boolean;
  className?: string;
  prefetch?: boolean;
}

export const ToplevelNavItem: FC<ToplevelNavItemProps> = ({
  name,
  icon,
  href,
  isVisible,
  isExternal,
  isActive,
  className = '',
  prefetch = true,
}) => {
  const { isMobile } = useScreens();

  if (!href) {
    console.warn(
      'href for top level navigation item is null, so you might be missing a NEXT_PUBLIC env var in your local development environment that represents one of the links in the navigation!',
    );
    return null;
  }

  return (
    <Link
      href={href}
      {...(isExternal ? { target: '_blank' } : {})}
      className={cn({
        'z-55 text-on-secondary hover:bg-secondary focus:border-on-background-dimmed relative my-1.5 flex h-[50px] w-[50px] items-center justify-center overflow-visible rounded-[20px] focus:rounded-3xl focus:border-2 focus:outline-0':
          true,
        [name?.toLowerCase()]: true,
        hidden: isVisible === false,
        [className]: true,
        'bg-primary-cta !text-on-primary pointer-events-none': isActive,
      })}
      data-navigation-id={name?.toLowerCase()}
      prefetch={prefetch}
    >
      {!isMobile ? (
        <Tooltip
          wrapper={<span className="pointer-events-none">{icon}</span>}
          arrowPosition="left"
          wrapperClasses="h-[50px] w-[50px] flex items-center justify-center"
          className="group-focus:rounded-1xl !-top-y-1/2 !z-55 left-[75px] whitespace-nowrap group-focus:outline-0 sm:!min-w-fit"
        >
          {name}
        </Tooltip>
      ) : (
        <>{icon}</>
      )}
      <span className="sr-only">{name}</span>
    </Link>
  );
};

'use client';

import * as Sentry from '@sentry/nextjs';
import { FC, ReactNode, useEffect } from 'react';

type SentryProviderProps = {
  children: ReactNode;
  sentryDsn: string;
  release?: string;
};

export const SentryProvider: FC<SentryProviderProps> = ({ children, sentryDsn, release }) => {
  useEffect(() => {
    if (!sentryDsn || Sentry.isInitialized()) {
      return;
    }

    Sentry.init({
      dsn: sentryDsn,
      release: release,

      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1,

      // Setting this option to true will print useful information to the console while you're setting up Sentry.
      debug: false,

      replaysOnErrorSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // You can remove this option if you're not planning to use the Sentry Session Replay feature:
      integrations: [
        Sentry.replayIntegration({
          // Additional Replay configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
    });
  }, [sentryDsn]);

  return <>{children}</>;
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconAssistant: FC<IconProps> = ({
  width = '40px',
  height = '41px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 41"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 21.1761V19.616C40 11.6182 36.7288 0.5 20.8969 0.5H19.1031C3.34887 0.5 0 11.5666 0 19.616V21.1761C0 29.0709 3.31073 40.5 19.1031 40.5H20.8969C35.1325 39.9454 40 31.3982 40 21.1761Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1111 21.2532V19.771C39.1111 12.1731 36.0035 1.61084 20.9631 1.61084H19.259C4.29251 1.61084 1.11108 12.1242 1.11108 19.771V21.2532C1.11108 28.7532 4.25628 39.6108 19.259 39.6108H20.9631C34.4869 39.084 39.1111 30.9641 39.1111 21.2532Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M33.3042 28.6454C33.3042 30.5504 31.7599 32.0947 29.855 32.0947L10.1448 32.0947C8.23985 32.0947 6.69556 30.5504 6.69556 28.6454L6.69556 17.3121C6.69556 15.4071 8.23985 13.8628 10.1448 13.8628L29.855 13.8628C31.7599 13.8628 33.3042 15.4071 33.3042 17.3121L33.3042 28.6454Z"
        fill="currentColor"
        className="text-on-background-dimmed"
      />
      <path
        d="M30.2001 21.5001C30.2001 23.3779 28.6779 24.9001 26.8001 24.9001C24.9223 24.9001 23.4001 23.3779 23.4001 21.5001C23.4001 19.6223 24.9223 18.1001 26.8001 18.1001C28.6779 18.1001 30.2001 19.6223 30.2001 21.5001Z"
        fill="currentColor"
        className="text-secondary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8001 23.4834C27.8954 23.4834 28.7834 22.5955 28.7834 21.5001C28.7834 20.4047 27.8954 19.5168 26.8001 19.5168C25.7047 19.5168 24.8168 20.4047 24.8168 21.5001C24.8168 22.5955 25.7047 23.4834 26.8001 23.4834ZM26.8001 24.9001C28.6779 24.9001 30.2001 23.3779 30.2001 21.5001C30.2001 19.6223 28.6779 18.1001 26.8001 18.1001C24.9223 18.1001 23.4001 19.6223 23.4001 21.5001C23.4001 23.3779 24.9223 24.9001 26.8001 24.9001Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M16.6002 21.5001C16.6002 23.3779 15.078 24.9001 13.2002 24.9001C11.3225 24.9001 9.80023 23.3779 9.80023 21.5001C9.80023 19.6223 11.3225 18.1001 13.2002 18.1001C15.078 18.1001 16.6002 19.6223 16.6002 21.5001Z"
        fill="currentColor"
        className="text-secondary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2002 23.4834C14.2956 23.4834 15.1836 22.5955 15.1836 21.5001C15.1836 20.4047 14.2956 19.5168 13.2002 19.5168C12.1049 19.5168 11.2169 20.4047 11.2169 21.5001C11.2169 22.5955 12.1049 23.4834 13.2002 23.4834ZM13.2002 24.9001C15.078 24.9001 16.6002 23.3779 16.6002 21.5001C16.6002 19.6223 15.078 18.1001 13.2002 18.1001C11.3225 18.1001 9.80023 19.6223 9.80023 21.5001C9.80023 23.3779 11.3225 24.9001 13.2002 24.9001Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0431 26.6006C17.3833 26.6006 17.6591 26.8764 17.6591 27.2165C17.6591 27.4908 17.8258 27.8145 18.2507 28.0978C18.6713 28.3782 19.2879 28.5716 19.9996 28.5716C20.7114 28.5716 21.328 28.3782 21.7486 28.0978C22.1735 27.8145 22.3402 27.4908 22.3402 27.2165C22.3402 26.8764 22.616 26.6006 22.9562 26.6006C23.2963 26.6006 23.5721 26.8764 23.5721 27.2165C23.5721 28.0308 23.077 28.6926 22.4319 29.1227C21.7824 29.5557 20.9207 29.8035 19.9996 29.8035C19.0786 29.8035 18.2169 29.5557 17.5674 29.1227C16.9223 28.6926 16.4272 28.0308 16.4272 27.2165C16.4272 26.8764 16.703 26.6006 17.0431 26.6006Z"
        fill="currentColor"
        className="text-on-background-main"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2612 13.8619L19.2612 10.4126L20.7394 10.4126L20.7394 13.8619L19.2612 13.8619Z"
        fill="currentColor"
        className="text-attention-variant"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2612 35.544L19.2612 32.0947L20.7394 32.0947L20.7394 35.544L19.2612 35.544Z"
        fill="currentColor"
        className="text-attention-variant"
      />
      <path
        d="M6.69559 23.1013C6.69559 26.0268 6.69559 25.6935 6.69559 28.3984C4.65454 28.3984 2.99994 26.0268 2.99994 23.1013C2.99994 20.1758 4.65454 17.8042 6.69559 17.8042C6.69559 20.2837 6.69559 20.1758 6.69559 23.1013Z"
        fill="currentColor"
        className="text-attention-variant"
      />
      <path
        d="M33.3043 23.1013C33.3043 20.1758 33.3043 20.5091 33.3043 17.8042C35.3453 17.8042 36.9999 20.1758 36.9999 23.1013C36.9999 26.0268 35.3453 28.3984 33.3043 28.3984C33.3043 25.9189 33.3043 26.0268 33.3043 23.1013Z"
        fill="currentColor"
        className="text-attention-variant"
      />
      <path
        d="M23.6954 8.07246C23.6954 10.0455 22.096 11.6449 20.1229 11.6449C18.1499 11.6449 16.5505 10.0455 16.5505 8.07246C16.5505 6.09945 18.1499 4.5 20.1229 4.5C22.096 4.5 23.6954 6.09945 23.6954 8.07246Z"
        fill="currentColor"
        className="text-attention-variant"
      />
      <path
        d="M9.80029 37.5C9.80029 36.1743 10.875 35.0996 12.2007 35.0996H27.7999C29.1256 35.0996 30.2003 36.1743 30.2003 37.5C30.2003 37.5 28 39.5 20 39.5C12 39.5 9.80029 37.5 9.80029 37.5Z"
        fill="currentColor"
        className="text-on-background-dimmed"
      />
    </svg>
  );
};

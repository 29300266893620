import { FC } from 'react';
import { IconProps } from '.';

export const IconFolderSpace: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1.86607V17.1339C2 18.1645 2.74791 19 3.6705 19H24.3295C25.2521 19 26 18.1645 26 17.1339V4.12798C26 3.09737 25.2521 2.2619 24.3295 2.2619H12.8106C12.4163 2.2619 12.0347 2.10612 11.7333 1.8221L10.2667 0.439804C9.9653 0.155789 9.58373 0 9.18945 0H3.6705C2.74791 0 2 0.835467 2 1.86607Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        id="Vector 232"
        d="M2 1.86607V17.1339C2 18.1645 2.74791 19 3.6705 19H24.3295C25.2521 19 26 18.1645 26 17.1339V4.12798C26 3.09737 25.2521 2.2619 24.3295 2.2619H12.8106C12.4163 2.2619 12.0347 2.10612 11.7333 1.8221L10.2667 0.439804C9.9653 0.155789 9.58373 0 9.18945 0H3.6705C2.74791 0 2 0.835467 2 1.86607Z"
        fill="black"
        className="opacity-25"
      />
      <g filter="url(#filter0_d_5453_228564)">
        <path
          d="M2 7C2 5.89543 2.89543 5 4 5H24C25.1046 5 26 5.89543 26 7V20C26 22.2091 24.2091 24 22 24H6C3.79086 24 2 22.2091 2 20V7Z"
          fill="currentColor"
          className="text-primary-cta"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4815 9.27514L11.1976 12.7603C10.9459 13.1386 11.2406 13.6316 11.7194 13.6316H16.2812C16.7601 13.6316 17.0548 13.1386 16.8031 12.7603L14.5253 9.27514C14.2858 8.90829 13.721 8.90829 13.4815 9.27514ZM9.07895 20H12.5526C12.8711 20 13.1316 19.7395 13.1316 19.4211V15.9474C13.1316 15.6289 12.8711 15.3684 12.5526 15.3684H9.07895C8.76053 15.3684 8.5 15.6289 8.5 15.9474V19.4211C8.5 19.7395 8.76053 20 9.07895 20ZM19.5005 17.6842C19.5005 18.9632 18.4637 20 17.1847 20C15.9057 20 14.8689 18.9632 14.8689 17.6842C14.8689 16.4052 15.9057 15.3684 17.1847 15.3684C18.4637 15.3684 19.5005 16.4052 19.5005 17.6842Z"
        fill="black"
        className="opacity-25"
      />
      <defs>
        <filter
          id="filter0_d_5453_228564"
          x="0"
          y="5"
          width="28"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5453_228564" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5453_228564"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

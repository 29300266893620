import { LayoutContext, useScreens } from '@unique/shared-library';
import { FC, useContext, useMemo } from 'react';
import cn from 'classnames';

export const NavigationOverlay: FC = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(LayoutContext);
  const { isMobile } = useScreens();

  const showOverlay = useMemo(() => {
    return isMobile && isMenuExpanded;
  }, [isMenuExpanded, isMobile]);

  const handleClickCloseMenu = () => {
    setIsMenuExpanded && setIsMenuExpanded(false);
  };

  return (
    <div
      onClick={handleClickCloseMenu}
      className={cn({
        'absolute left-0 top-0 z-[55] h-screen w-screen overflow-hidden transition-opacity': true,
        'opacity-100': showOverlay,
        'pointer-events-none opacity-0': !showOverlay,
      })}
    >
      <div className="bg-secondary-variant h-full w-full opacity-80" />
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconSharepoint: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1400_12804)">
        <path
          d="M12.2791 13.6953C15.9781 13.6953 18.9768 10.6966 18.9768 6.9976C18.9768 3.29858 15.9781 0.299927 12.2791 0.299927C8.58007 0.299927 5.58142 3.29858 5.58142 6.9976C5.58142 10.6966 8.58007 13.6953 12.2791 13.6953Z"
          fill="#036C70"
        />
        <path
          d="M17.8605 19.2767C21.2513 19.2767 24 16.5279 24 13.1371C24 9.74637 21.2513 6.9976 17.8605 6.9976C14.4697 6.9976 11.7209 9.74637 11.7209 13.1371C11.7209 16.5279 14.4697 19.2767 17.8605 19.2767Z"
          fill="#1A9BA1"
        />
        <path
          d="M13.1163 23.7418C15.7364 23.7418 17.8604 21.6177 17.8604 18.9976C17.8604 16.3775 15.7364 14.2534 13.1163 14.2534C10.4961 14.2534 8.37207 16.3775 8.37207 18.9976C8.37207 21.6177 10.4961 23.7418 13.1163 23.7418Z"
          fill="#37C6D0"
        />
        <path
          opacity="0.1"
          d="M13.3953 6.34458V18.2553C13.3926 18.6691 13.1418 19.0409 12.759 19.1985C12.6372 19.2501 12.5062 19.2766 12.3739 19.2767H8.37765C8.37207 19.1818 8.37207 19.0925 8.37207 18.9976C8.37021 18.9044 8.37394 18.8112 8.38324 18.7185C8.48539 16.9344 9.58449 15.3603 11.2242 14.6497V13.6115C7.575 13.0333 5.08553 9.60625 5.66381 5.95708C5.66782 5.9318 5.67197 5.90654 5.67627 5.88132C5.70404 5.69308 5.74319 5.50669 5.79348 5.32318H12.3739C12.9371 5.32533 13.3932 5.78137 13.3953 6.34458Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M11.8158 5.88132H5.67625C5.05604 9.52391 7.50616 12.9796 11.1487 13.5998C11.259 13.6186 11.3698 13.6346 11.4809 13.6478C9.75067 14.4683 8.48983 16.7985 8.38267 18.7185C8.37338 18.8112 8.36965 18.9044 8.3715 18.9976C8.3715 19.0925 8.3715 19.1818 8.37708 19.2767C8.38715 19.4643 8.41141 19.6509 8.44963 19.8348H11.8152C12.2291 19.832 12.6009 19.5812 12.7585 19.1985C12.81 19.0767 12.8366 18.9457 12.8366 18.8134V6.90271C12.8345 6.33972 12.3788 5.88377 11.8158 5.88132Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M11.8158 5.88132H5.67627C5.05619 9.52425 7.50668 12.9801 11.1496 13.6002C11.2241 13.6129 11.2989 13.6243 11.3738 13.6344C9.69934 14.5141 8.48818 16.8387 8.38325 18.7185H11.8158C12.3781 18.7143 12.8329 18.2595 12.8372 17.6971V6.90271C12.8351 6.33951 12.379 5.88346 11.8158 5.88132Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M11.2577 5.88132H5.67626C5.09075 9.32028 7.24563 12.632 10.627 13.4899C9.34663 14.9527 8.56134 16.7827 8.38325 18.7185H11.2577C11.8209 18.7164 12.2769 18.2603 12.2791 17.6971V6.90271C12.2788 6.33875 11.8216 5.88163 11.2577 5.88132Z"
          fill="black"
        />
        <path
          d="M1.02307 5.88132H11.256C11.821 5.88132 12.2791 6.33936 12.2791 6.90439V17.1373C12.2791 17.7023 11.821 18.1604 11.256 18.1604H1.02307C0.458044 18.1604 0 17.7023 0 17.1373V6.90439C0 6.33936 0.458044 5.88132 1.02307 5.88132Z"
          fill="url(#paint0_linear_1400_12804)"
        />
        <path
          d="M4.5695 11.8958C4.32975 11.7368 4.12948 11.5251 3.98401 11.2769C3.84306 11.0174 3.7728 10.7254 3.78028 10.4302C3.76773 10.0304 3.90261 9.64009 4.15926 9.33341C4.42895 9.02635 4.77754 8.79897 5.16726 8.67591C5.61141 8.52972 6.07662 8.45769 6.54418 8.46271C7.15906 8.44024 7.77307 8.52622 8.35813 8.71667V10.0004C8.10392 9.84639 7.82704 9.73338 7.53767 9.66551C7.22368 9.58851 6.90151 9.5499 6.57823 9.55053C6.23732 9.53801 5.89859 9.60971 5.59199 9.75927C5.35529 9.86137 5.20179 10.0942 5.2013 10.352C5.20034 10.5084 5.26041 10.659 5.36874 10.7717C5.4967 10.9047 5.64806 11.0129 5.81526 11.091C6.0013 11.1837 6.28037 11.3068 6.65247 11.4605C6.69343 11.4734 6.73337 11.4895 6.7719 11.5085C7.13812 11.6516 7.49141 11.8258 7.82791 12.0292C8.08273 12.1864 8.2966 12.4018 8.45191 12.6577C8.61114 12.9478 8.68832 13.2759 8.67516 13.6065C8.69334 14.0169 8.5678 14.4208 8.32019 14.7485C8.07339 15.0498 7.74342 15.2719 7.37135 15.387C6.93372 15.5242 6.47698 15.5905 6.01842 15.5835C5.60699 15.5853 5.19617 15.5517 4.79051 15.483C4.44799 15.427 4.11373 15.3287 3.79535 15.1905V13.8371C4.09969 14.0544 4.43969 14.2169 4.8 14.3171C5.1591 14.429 5.53239 14.4887 5.90847 14.4945C6.25654 14.5166 6.604 14.4429 6.91312 14.2813C7.12966 14.1591 7.26136 13.9277 7.25582 13.6791C7.25727 13.5061 7.18885 13.3399 7.06606 13.2181C6.91336 13.0682 6.73657 12.945 6.54309 12.8536C6.31984 12.742 5.99108 12.5948 5.55685 12.4121C5.21141 12.2732 4.88065 12.1003 4.5695 11.8958Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1400_12804"
          x1="2.13312"
          y1="5.08192"
          x2="10.1459"
          y2="18.9598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#058F92" />
          <stop offset="0.5" stopColor="#038489" />
          <stop offset="1" stopColor="#026D71" />
        </linearGradient>
        <clipPath id="clip0_1400_12804">
          <rect width="24" height="23.4419" fill="white" transform="translate(0 0.299927)" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconLater: FC<IconProps> = ({ width = '12px', height = '12px' }) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM10.84 11.04L7.576 9.032C7.336 8.888 7.192 8.632 7.192 8.352V4.6C7.2 4.272 7.472 4 7.8 4C8.128 4 8.4 4.272 8.4 4.6V8.16L11.472 10.008C11.76 10.184 11.856 10.56 11.68 10.848C11.504 11.128 11.128 11.216 10.84 11.04Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconNoPrompts: FC<IconProps> = ({
  width = '157px',
  height = '157px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 157 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 1762">
        <path
          id="Vector"
          d="M156.5 75.3815C156.5 76.7663 156.462 78.1413 156.387 79.5067C156.115 84.6113 155.322 89.6748 154.021 94.6184C154.018 94.6204 154.018 94.6204 154.021 94.6225C153.85 95.2764 153.668 95.9282 153.477 96.5737C153.468 96.6049 153.46 96.6341 153.45 96.6633C153.166 97.6315 152.864 98.5908 152.541 99.5411V99.5432C147.478 114.473 137.861 127.442 125.044 136.629C112.226 145.816 96.8512 150.759 81.0786 150.763C45.4034 150.763 15.5162 126.01 7.66987 92.7484C6.32855 87.0568 5.65315 81.2288 5.65725 75.3815C5.65725 74.2196 5.68363 73.0638 5.73641 71.9144C6.36159 57.8621 10.928 44.2688 18.9143 32.6868C19.0101 32.5452 19.1081 32.4036 19.2081 32.2641C32.8381 12.7586 55.4686 0 81.0786 0C117.272 0 147.51 25.4798 154.814 59.4723L154.816 59.4764C155.287 61.6671 155.661 63.891 155.937 66.1483C156.314 69.2116 156.501 72.2952 156.5 75.3815Z"
          fill="currentColor"
          className="text-background"
        />
        <path
          id="Vector 250"
          d="M120.958 51.9242V39.9208H126.58C128.789 39.9208 130.58 38.13 130.58 35.9209V4.90991C130.58 2.70077 128.789 0.909912 126.58 0.909912H46.1799C43.9708 0.909912 42.1799 2.70077 42.1799 4.90991V35.9208C42.1799 38.13 43.9708 39.9208 46.1799 39.9208H112.539C112.539 50.0037 118.152 52.1243 120.958 51.9242Z"
          fill="white"
          stroke="currentColor"
          className="text-background-variant"
        />
        <path
          id="Vector_2"
          d="M61.4609 9.24097H110.26C110.942 9.24136 111.597 9.50201 112.089 9.96831C112.58 10.4346 112.869 11.0704 112.896 11.7423C112.9 11.7762 112.901 11.8103 112.9 11.8444C112.899 12.5345 112.621 13.1962 112.126 13.6842C111.631 14.1722 110.96 14.4468 110.26 14.4478H61.4609C60.7606 14.4478 60.0889 14.1735 59.5937 13.6853C59.0985 13.197 58.8203 12.5348 58.8203 11.8444C58.8203 11.1539 59.0985 10.4917 59.5937 10.0035C60.0889 9.51525 60.7606 9.24097 61.4609 9.24097Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector_3"
          d="M61.4609 17.5718H110.26C110.942 17.5722 111.597 17.8328 112.089 18.2991C112.58 18.7654 112.869 19.4012 112.896 20.0731C112.9 20.107 112.901 20.1411 112.9 20.1752C112.899 20.8654 112.621 21.527 112.126 22.015C111.631 22.503 110.96 22.7776 110.26 22.7786H61.4609C60.7606 22.7786 60.0889 22.5043 59.5937 22.0161C59.0985 21.5278 58.8203 20.8657 58.8203 20.1752C58.8203 19.4847 59.0985 18.8225 59.5937 18.3343C60.0889 17.8461 60.7606 17.5718 61.4609 17.5718Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector_4"
          d="M61.4609 26.9441H110.26C110.942 26.9445 111.597 27.2051 112.089 27.6714C112.58 28.1377 112.869 28.7735 112.896 29.4454C112.9 29.4794 112.901 29.5134 112.9 29.5475C112.899 30.2377 112.621 30.8993 112.126 31.3873C111.631 31.8753 110.96 32.1499 110.26 32.1509H61.4609C60.7606 32.1509 60.0889 31.8766 59.5937 31.3884C59.0985 30.9002 58.8203 30.238 58.8203 29.5475C58.8203 28.857 59.0985 28.1948 59.5937 27.7066C60.0889 27.2184 60.7606 26.9441 61.4609 26.9441Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector 249"
          d="M29.9952 98.9171V86.9138H24.2603C22.0511 86.9138 20.2603 85.1229 20.2603 82.9138V51.9028C20.2603 49.6937 22.0511 47.9028 24.2603 47.9028H105.7C107.91 47.9028 109.7 49.6937 109.7 51.9028V82.9138C109.7 85.1229 107.91 86.9138 105.7 86.9138H38.5133C38.5133 96.9966 32.8346 99.1172 29.9952 98.9171Z"
          fill="white"
          stroke="currentColor"
          className="text-background-variant"
        />
        <path
          id="Vector_5"
          d="M89.3794 56.2336H40.5807C39.8987 56.234 39.2432 56.4947 38.7517 56.961C38.2602 57.4273 37.9709 58.063 37.9443 58.735C37.9408 58.7689 37.9394 58.803 37.9401 58.8371C37.9411 59.5272 38.2196 60.1888 38.7146 60.6769C39.2096 61.1649 39.8807 61.4395 40.5807 61.4405H89.3794C90.0798 61.4405 90.7514 61.1662 91.2466 60.6779C91.7418 60.1897 92.02 59.5275 92.02 58.8371C92.02 58.1466 91.7418 57.4844 91.2466 56.9962C90.7514 56.5079 90.0798 56.2336 89.3794 56.2336Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          id="Vector_6"
          d="M89.3794 64.5645H40.5807C39.8987 64.5648 39.2432 64.8255 38.7517 65.2918C38.2602 65.7581 37.9709 66.3938 37.9443 67.0658C37.9408 67.0997 37.9394 67.1338 37.9401 67.1679C37.9411 67.858 38.2196 68.5197 38.7146 69.0077C39.2096 69.4957 39.8807 69.7703 40.5807 69.7713H89.3794C90.0798 69.7713 90.7514 69.497 91.2466 69.0088C91.7418 68.5205 92.02 67.8583 92.02 67.1679C92.02 66.4774 91.7418 65.8152 91.2466 65.327C90.7514 64.8387 90.0798 64.5645 89.3794 64.5645Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          id="Vector_7"
          d="M89.3794 73.9368H40.5807C39.8987 73.9372 39.2432 74.1978 38.7517 74.6641C38.2602 75.1304 37.9709 75.7662 37.9443 76.4381C37.9408 76.472 37.9394 76.5061 37.9401 76.5402C37.9411 77.2303 38.2196 77.892 38.7146 78.38C39.2096 78.868 39.8807 79.1426 40.5807 79.1436H89.3794C90.0798 79.1436 90.7514 78.8693 91.2466 78.3811C91.7418 77.8928 92.02 77.2306 92.02 76.5402C92.02 75.8497 91.7418 75.1875 91.2466 74.6993C90.7514 74.2111 90.0798 73.9368 89.3794 73.9368Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          id="Vector_8"
          d="M15.7464 116.668C15.6637 116.668 15.581 116.663 15.499 116.652C15.2162 116.617 14.944 116.523 14.7002 116.376C14.4563 116.228 14.2464 116.031 14.084 115.797C13.9216 115.563 13.8105 115.296 13.758 115.016C13.7054 114.736 13.7127 114.448 13.7792 114.17C13.7949 114.106 13.8139 114.042 13.8362 113.98L7.70568 106.121C7.43579 105.773 7.28537 105.346 7.27676 104.905C7.26814 104.464 7.40179 104.032 7.65786 103.673L9.80526 100.686L12.612 102.854L11.4844 104.794L15.9499 112.632C16.0397 112.64 16.1288 112.654 16.2165 112.675C16.6976 112.791 17.1196 113.079 17.4026 113.486C17.6856 113.892 17.8099 114.388 17.7519 114.88C17.694 115.372 17.4579 115.826 17.0882 116.156C16.7186 116.485 16.2412 116.667 15.7464 116.668Z"
          fill="currentColor"
          className="text-on-control-dimmed"
        />
        <path
          id="Vector_9"
          d="M26.3544 153.906L28.6071 153.906L29.6786 145.205L26.354 145.206L26.3544 153.906Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector_10"
          d="M32.9512 156L25.8714 156L25.8713 153.261L30.2157 153.261C30.5749 153.261 30.9306 153.332 31.2625 153.47C31.5943 153.607 31.8959 153.809 32.1499 154.063C32.4039 154.318 32.6054 154.62 32.7429 154.952C32.8804 155.284 32.9512 155.64 32.9512 156Z"
          fill="currentColor"
          className="text-on-control-dimmed"
        />
        <path
          id="Vector_11"
          d="M7.79871 153.906L10.0514 153.906L11.123 145.205L7.79834 145.206L7.79871 153.906Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector_12"
          d="M14.3936 156L7.3138 156L7.31368 153.261L11.6581 153.261C12.0173 153.261 12.373 153.332 12.7049 153.47C13.0367 153.607 13.3383 153.809 13.5923 154.063C13.8463 154.318 14.0478 154.62 14.1853 154.952C14.3228 155.284 14.3935 155.64 14.3936 156Z"
          fill="currentColor"
          className="text-on-control-dimmed"
        />
        <path
          id="Vector_13"
          d="M9.18164 101.11L14.2391 103.034L15.4739 99.5999C15.6378 99.1439 15.6355 98.6445 15.4673 98.19C15.2991 97.7355 14.9759 97.3552 14.5548 97.1162C14.1288 96.8745 13.6305 96.7933 13.1499 96.8872C12.6693 96.9811 12.2381 97.2441 11.9341 97.6285L9.18164 101.11Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector_14"
          d="M25.9546 146.883C25.7526 146.882 25.5565 146.815 25.3971 146.691C25.2376 146.567 25.124 146.393 25.074 146.197C22.552 136.536 19.9913 131.693 17.2453 126.094C17.1767 125.954 17.0653 125.84 16.9275 125.768C16.7896 125.696 16.6325 125.67 16.4789 125.694C16.3253 125.718 16.1832 125.79 16.0734 125.9C15.9636 126.01 15.8917 126.153 15.8683 126.306L12.4646 146.002C12.4306 146.221 12.318 146.421 12.1478 146.564C11.9776 146.707 11.7614 146.783 11.5394 146.778L6.48624 146.654C6.36363 146.652 6.24281 146.625 6.13108 146.574C6.01935 146.523 5.91905 146.451 5.83623 146.36C5.74874 146.273 5.68181 146.168 5.64036 146.052C5.5989 145.936 5.58398 145.812 5.59668 145.689C7.88538 130.576 9.68738 116.895 13.1527 112.722C13.2488 112.631 13.3589 112.556 13.4789 112.5L13.3737 112.484C13.3437 112.479 13.2636 112.467 13.2626 112.386L13.2617 112.308L13.3407 112.293C13.4669 112.289 13.5928 112.308 13.7121 112.349L21.6212 113.568C21.8403 113.602 22.0397 113.714 22.1821 113.884C22.3246 114.054 22.4004 114.271 22.3955 114.492C22.8255 116.507 23.4844 119.547 23.5434 119.696C27.7632 127.729 29.4006 134.055 31.1026 144.857C31.1378 145.086 31.0852 145.32 30.9552 145.511C30.8253 145.703 30.6277 145.839 30.4021 145.89L26.1605 146.859C26.093 146.875 26.0239 146.883 25.9546 146.883Z"
          fill="currentColor"
          className="text-on-control-dimmed"
        />
        <path
          id="Vector_15"
          d="M21.5177 114.885C21.4467 114.885 21.376 114.877 21.3069 114.86L13.5202 113.006C13.4008 112.978 13.2885 112.926 13.19 112.853C13.0916 112.78 13.0091 112.687 12.9477 112.581C12.8844 112.471 12.8439 112.349 12.8286 112.224C12.8133 112.098 12.8236 111.97 12.8588 111.848C13.6984 108.933 12.5974 105.32 11.2825 101.849C11.1153 100.834 11.2791 99.7926 11.7497 98.8783C12.2203 97.964 12.9727 97.2259 13.8952 96.7734L13.9164 96.7632L18.0046 96.9698C18.6759 97.1461 19.2985 97.4724 19.8257 97.9242C20.353 98.3761 20.7711 98.9417 21.0486 99.5786C22.6243 103.271 23.0755 108.01 22.4278 114.065C22.404 114.29 22.2978 114.498 22.1298 114.65C21.9618 114.801 21.7438 114.885 21.5177 114.885Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector_16"
          d="M22.5544 106.449C22.1388 106.449 21.7334 106.32 21.395 106.078L18.3829 103.92L20.5398 101.103L22.4803 102.226L30.2941 97.7317C30.3021 97.6418 30.3163 97.5525 30.3367 97.4646C30.4288 97.0751 30.6343 96.7218 30.9271 96.4492C31.2198 96.1766 31.5867 95.9971 31.9814 95.9334C32.376 95.8696 32.7807 95.9244 33.1442 96.0909C33.5077 96.2575 33.8137 96.5282 34.0236 96.8688C34.2335 97.2095 34.3377 97.6049 34.3232 98.0049C34.3087 98.4049 34.176 98.7916 33.942 99.1161C33.708 99.4406 33.3831 99.6884 33.0085 99.828C32.6338 99.9676 32.2263 99.9929 31.8373 99.9006C31.7729 99.885 31.7094 99.8661 31.647 99.844L23.7947 106.024C23.4408 106.301 23.0039 106.451 22.5544 106.449Z"
          fill="currentColor"
          className="text-on-control-dimmed"
        />
        <path
          id="Vector_17"
          d="M17.4308 104.813L21.9047 101.766L19.8022 98.7847C19.523 98.3887 19.113 98.1044 18.6447 97.9818C18.1764 97.8593 17.6798 97.9064 17.2429 98.115C16.8009 98.3259 16.4487 98.6882 16.2501 99.1364C16.0515 99.5845 16.0195 100.089 16.1598 100.559L17.4308 104.813Z"
          fill="currentColor"
          className="text-control"
        />
        <path
          id="Vector_18"
          d="M15.281 95.2342C17.7734 95.2342 19.7939 93.211 19.7939 90.7154C19.7939 88.2197 17.7734 86.1965 15.281 86.1965C12.7886 86.1965 10.7681 88.2197 10.7681 90.7154C10.7681 93.211 12.7886 95.2342 15.281 95.2342Z"
          fill="currentColor"
          className="text-on-control-dimmed"
        />
        <path
          id="Vector_19"
          d="M15.6934 95.3388C15.016 95.3359 14.4935 94.6218 14.5239 93.9442C14.5542 93.2667 15.0087 92.6705 15.5563 92.2713C16.1038 91.872 16.7459 91.6288 17.3566 91.3353C17.9672 91.0417 18.5725 90.6756 18.9495 90.1121C19.1772 89.7433 19.3132 89.3251 19.3459 88.8928C19.3786 88.4604 19.3072 88.0265 19.1375 87.6275C18.7867 86.8296 18.1843 86.1689 17.4226 85.7465C16.2912 85.0833 14.9517 84.8712 13.6712 85.1525C12.3906 85.4339 11.2629 86.1879 10.5126 87.2644L9.73936 89.3724C8.9515 90.3211 8.82653 91.7299 9.31084 92.8644C9.79516 93.9989 10.8264 94.8525 11.99 95.2583C13.1706 95.6382 14.4345 95.6761 15.6357 95.3677"
          fill="currentColor"
          className="text-on-background-main"
        />
        <path
          id="Vector_20"
          d="M10.8168 88.5732C10.5076 88.0958 10.0859 87.702 9.58887 87.4263C9.09181 87.1507 8.53467 87.0018 7.96655 86.9926C7.39843 86.9835 6.83679 87.1145 6.33115 87.374C5.82551 87.6335 5.3914 88.0136 5.0671 88.4807C4.52423 89.2627 4.32707 90.2267 3.9538 91.1027C3.27771 92.6714 2.04815 93.9357 0.5 94.6541C1.85554 94.8928 3.20599 94.2023 4.28971 93.3527C5.37343 92.5032 6.3265 91.4662 7.5402 90.8164C8.495 90.307 9.56764 90.0603 10.6487 90.1015"
          fill="currentColor"
          className="text-on-background-main"
        />
        <g id="Group 1761">
          <g id="Group 998">
            <path
              id="Path"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M151.38 70.0022V68.6862C151.38 61.94 148.624 52.5618 135.287 52.5618H133.776C120.505 52.5618 117.684 61.8965 117.684 68.6862V70.0022C117.684 76.6615 120.473 86.3019 133.776 86.3019H135.287C147.279 85.8342 151.38 78.6245 151.38 70.0022Z"
              fill="currentColor"
              className="text-background-variant"
            />
            <g id="Group 738">
              <path
                id="Path_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M150.628 70.0672V68.817C150.628 62.4081 148.011 53.4988 135.341 53.4988H133.905C121.297 53.4988 118.617 62.3668 118.617 68.817V70.0672C118.617 76.3935 121.267 85.5519 133.905 85.5519H135.341C146.733 85.1076 150.628 78.2584 150.628 70.0672Z"
                fill="currentColor"
                className="text-background-variant"
              />
            </g>
          </g>
          <path
            id="Union"
            d="M145.738 76.3027C145.738 77.9096 144.437 79.2122 142.832 79.2122L126.228 79.2122C124.624 79.2122 123.323 77.9095 123.323 76.3027L123.323 66.743C123.323 65.1361 124.624 63.8335 126.228 63.8335L142.832 63.8335C144.437 63.8335 145.738 65.1361 145.738 66.743V76.3027Z"
            fill="currentColor"
            className="text-on-control-dimmed"
          />
          <g id="Group 500">
            <g id="Group 499">
              <path
                id="Line 67 (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M133.911 63.8326L133.911 60.9231L135.157 60.9231L135.157 63.8326L133.911 63.8326Z"
                fill="currentColor"
                className="text-primary-variant"
              />
              <path
                id="Line 67 (Stroke)_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M133.911 82.1214L133.911 79.2119L135.157 79.2119L135.157 82.1214L133.911 82.1214Z"
                fill="currentColor"
                className="text-primary-variant"
              />
              <path
                id="Ellipse 66"
                d="M123.326 71.6261C123.326 74.0938 123.326 73.8126 123.326 76.0942C121.607 76.0942 120.213 74.0938 120.213 71.6261C120.213 69.1584 121.607 67.158 123.326 67.158C123.326 69.2494 123.326 69.1584 123.326 71.6261Z"
                fill="currentColor"
                className="text-primary-variant"
              />
              <path
                id="Ellipse 71"
                d="M145.74 71.6264C145.74 69.1587 145.74 69.4398 145.74 67.1582C147.46 67.1582 148.854 69.1587 148.854 71.6264C148.854 74.094 147.46 76.0945 145.74 76.0945C145.74 74.003 145.74 74.094 145.74 71.6264Z"
                fill="currentColor"
                className="text-primary-variant"
              />
              <path
                id="Ellipse 65"
                d="M137.645 58.9492C137.645 60.6134 136.298 61.9626 134.636 61.9626C132.974 61.9626 131.626 60.6134 131.626 58.9492C131.626 57.2849 132.974 55.9358 134.636 55.9358C136.298 55.9358 137.645 57.2849 137.645 58.9492Z"
                fill="currentColor"
                className="text-primary-variant"
              />
            </g>
          </g>
          <path
            id="Rectangle 247"
            d="M125.939 83.7713C125.939 82.6531 126.845 81.7466 127.964 81.7466H141.099C142.217 81.7466 143.124 82.6531 143.124 83.7713C143.124 83.7713 141.27 85.4583 134.531 85.4583C127.792 85.4583 125.939 83.7713 125.939 83.7713Z"
            fill="currentColor"
            className="text-on-control-dimmed"
          />
          <ellipse
            id="Ellipse 75"
            cx="128.916"
            cy="70.0566"
            rx="2.496"
            ry="2.49927"
            fill="currentColor"
            className="text-control"
          />
          <ellipse
            id="Ellipse 76"
            cx="140.147"
            cy="70.0566"
            rx="2.496"
            ry="2.49927"
            fill="currentColor"
            className="text-control"
          />
        </g>
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconInfo: FC<IconProps> = ({ width = '12px', height = '12px' }) => {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <path
        d="M10.2452 1.75996C7.90353 -0.584614 4.10458 -0.586945 1.75997 1.75473C-0.584608 4.09645 -0.586967 7.89543 1.75476 10.24C4.09643 12.5846 7.89545 12.5869 10.2401 10.2452C12.5846 7.90358 12.587 4.10454 10.2452 1.75996ZM6.83775 9.80727C6.83775 9.89985 6.76273 9.97482 6.6702 9.97482H5.32982C5.2373 9.97482 5.16227 9.89985 5.16227 9.80727V4.83145C5.16227 4.73887 5.2373 4.66391 5.32982 4.66391H6.6702C6.76273 4.66391 6.83775 4.73885 6.83775 4.83145V9.80727ZM6.00001 3.96856C5.46422 3.96856 5.02828 3.53268 5.02828 2.99686C5.02828 2.46109 5.46419 2.02513 6.00001 2.02513C6.53584 2.02513 6.97175 2.46106 6.97175 2.99686C6.97175 3.53268 6.53578 3.96856 6.00001 3.96856Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconCSVFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29.7739V2.22609C9 0.996653 9.90527 0 11.022 0H22.9011L32 10.0174V29.7739C32 31.0033 31.0947 32 29.978 32H11.022C9.90527 32 9 31.0033 9 29.7739Z"
        fill="#CAD6D9"
      />
      <path d="M23 7.77778V0L32 10H25C23.8954 10 23 9.00508 23 7.77778Z" fill="#92AAB1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.875C12 16.3918 12.3918 16 12.875 16L27.625 16C28.1082 16 28.5 16.3918 28.5 16.875C28.5 17.3582 28.1082 17.75 27.625 17.75H12.875C12.3918 17.75 12 17.3582 12 16.875Z"
        fill="#705273"
        fillOpacity="0.29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.875C12 12.3918 12.3918 12 12.875 12L27.625 12C28.1082 12 28.5 12.3918 28.5 12.875C28.5 13.3582 28.1082 13.75 27.625 13.75H12.875C12.3918 13.75 12 13.3582 12 12.875Z"
        fill="#705273"
        fillOpacity="0.29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.875C12 20.3918 12.3918 20 12.875 20L27.625 20C28.1082 20 28.5 20.3918 28.5 20.875C28.5 21.3582 28.1082 21.75 27.625 21.75H12.875C12.3918 21.75 12 21.3582 12 20.875Z"
        fill="#705273"
        fillOpacity="0.29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.875C12 24.3918 12.3918 24 12.875 24L27.625 24C28.1082 24 28.5 24.3918 28.5 24.875C28.5 25.3582 28.1082 25.75 27.625 25.75H12.875C12.3918 25.75 12 25.3582 12 24.875Z"
        fill="#705273"
        fillOpacity="0.29"
      />
      <path
        d="M0 11.5C0 10.6716 0.895431 10 2 10H22C23.1046 10 24 10.6716 24 11.5V26.5C24 27.3284 23.1046 28 22 28H2C0.89543 28 0 27.3284 0 26.5V11.5Z"
        fill="#705273"
      />
      <path
        d="M7.33581 20.0204L8.59063 20.5003C8.39484 21.3652 8.06912 21.998 7.61347 22.3988C7.15782 22.7961 6.59715 22.9947 5.93147 22.9947C5.09493 22.9947 4.41679 22.6766 3.89706 22.0402C3.29902 21.3054 3 20.314 3 19.0659C3 17.7475 3.3008 16.7157 3.9024 15.9703C4.42569 15.3234 5.12875 15 6.01157 15C6.73064 15 7.33225 15.2426 7.81637 15.7278C8.16167 16.0723 8.41442 16.5838 8.57461 17.2624L7.29309 17.6315C7.21121 17.2131 7.04746 16.8932 6.80184 16.6717C6.55977 16.4467 6.27321 16.3342 5.94215 16.3342C5.4687 16.3342 5.08247 16.5381 4.78345 16.9459C4.48443 17.3538 4.33492 18.027 4.33492 18.9657C4.33492 19.9396 4.48087 20.6322 4.77277 21.0435C5.06467 21.4548 5.44379 21.6605 5.91012 21.6605C6.25185 21.6605 6.54732 21.5304 6.7965 21.2703C7.04568 21.0066 7.22545 20.59 7.33581 20.0204Z"
        fill="white"
      />
      <path
        d="M9.37022 20.3474L10.6357 20.1997C10.7781 21.1877 11.2444 21.6816 12.0347 21.6816C12.4263 21.6816 12.7342 21.5814 12.9585 21.381C13.1827 21.1771 13.2949 20.9257 13.2949 20.6269C13.2949 20.4511 13.2557 20.3017 13.1774 20.1786C13.0991 20.0556 12.9798 19.9554 12.8196 19.878C12.6594 19.7972 12.2714 19.6636 11.6556 19.4773C11.1038 19.312 10.6998 19.1327 10.4435 18.9394C10.1872 18.746 9.9825 18.4929 9.82943 18.18C9.67992 17.8635 9.60516 17.5243 9.60516 17.1622C9.60516 16.7403 9.70128 16.3606 9.8935 16.0231C10.0893 15.6856 10.3581 15.4307 10.6998 15.2584C11.0415 15.0861 11.4634 15 11.9653 15C12.72 15 13.3091 15.2022 13.7327 15.6065C14.1563 16.0108 14.3806 16.5891 14.4055 17.3415L13.108 17.41C13.051 16.9952 12.9282 16.7051 12.7395 16.5399C12.5509 16.3746 12.2821 16.292 11.9333 16.292C11.5844 16.292 11.3156 16.3641 11.127 16.5082C10.9383 16.6524 10.844 16.8317 10.844 17.0461C10.844 17.2571 10.9294 17.4346 11.1003 17.5788C11.2711 17.7229 11.6645 17.8811 12.2803 18.0534C12.9318 18.2397 13.4017 18.4366 13.69 18.644C13.9819 18.8479 14.2044 19.1134 14.3575 19.4403C14.5105 19.7638 14.5871 20.1575 14.5871 20.6216C14.5871 21.2931 14.377 21.8574 13.957 22.3144C13.5405 22.7715 12.8873 23 11.9973 23C10.4239 23 9.54821 22.1158 9.37022 20.3474Z"
        fill="white"
      />
      <path
        d="M17.3156 22.8629L15.0249 15.1318H16.4292L18.0525 20.8537L19.6224 15.1318H21L18.7039 22.8629H17.3156Z"
        fill="white"
      />
    </svg>
  );
};

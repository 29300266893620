import { FC } from 'react';
import { TopLevelNavigation, TopLevelNavigationProps } from './TopLevelNavigation';
import { SecondLevelNavigation, SecondLevelNavigationProps } from './SecondLevelNavigation';
import { NavigationOverlay } from './NavigationOverlay';

export const Navigation: FC<TopLevelNavigationProps & SecondLevelNavigationProps> = ({
  isExpanded,
  items,
  hasSecondLevel,
  children,
  logo,
  logoUrl,
  secondLevelClassname,
  secondLevelFooter,
  handleClickItem,
  handleClickToggleMenuButton,
}) => {
  const handleClickToggleMenu = () => {
    handleClickToggleMenuButton && handleClickToggleMenuButton(!isExpanded);
  };

  return (
    <>
      <NavigationOverlay />
      <TopLevelNavigation
        items={items}
        isExpanded={isExpanded}
        hasSecondLevel={hasSecondLevel}
        handleClickToggleMenuButton={handleClickToggleMenu}
      />
      {hasSecondLevel && (
        <SecondLevelNavigation
          logo={logo}
          isExpanded={isExpanded}
          handleClickItem={handleClickItem}
          {...(logoUrl && { logoUrl })}
          {...(secondLevelClassname && { secondLevelClassname })}
          {...(secondLevelFooter && { secondLevelFooter })}
        >
          {children}
        </SecondLevelNavigation>
      )}
    </>
  );
};

import { FC, ReactNode } from 'react';
import { ButtonIcon } from '../ButtonIcon';
import { ButtonVariant } from '../../enums/button';

interface MenuDropdownHeaderProps {
  userName: string;
  userEmail: string;
  profileImage: ReactNode;
}

export const MenuDropdownHeader: FC<MenuDropdownHeaderProps> = ({
  userName,
  userEmail,
  profileImage,
}) => {
  return (
    <div className="px-6 py-4 text-center">
      <ButtonIcon
        className="pointer-events-none h-8 w-8 min-w-[52px] self-center px-3 md:h-10 md:w-10 md:px-5"
        classNameIcon="w-8 h-8 md:w-10 md:h-9"
        icon={profileImage}
        variant={ButtonVariant.SHAPE}
      />
      <div className="subtitle-2 text-on-surface my-2">{userName}</div>
      <div className="body-2 text-on-background-dimmed truncate">{userEmail}</div>
    </div>
  );
};

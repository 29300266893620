import { FC } from 'react';
import { IconProps } from '.';

export const IconDisabled: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
        <path d="M2.5 2.5L13.5 13.5" stroke="currentColor" strokeWidth="2" />
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconEmail: FC<IconProps> = ({ width = '20px', height = '20px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3796_2734)">
        <path
          d="M17.1111 3.33337H2.88886C1.91108 3.33337 1.11997 4.13337 1.11997 5.11115L1.11108 15.7778C1.11108 16.7556 1.91108 17.5556 2.88886 17.5556H17.1111C18.0889 17.5556 18.8889 16.7556 18.8889 15.7778V5.11115C18.8889 4.13337 18.0889 3.33337 17.1111 3.33337ZM16.7555 7.11115L10.4711 11.04C10.1866 11.2178 9.81331 11.2178 9.52886 11.04L3.24442 7.11115C3.0222 6.96893 2.88886 6.72893 2.88886 6.47115C2.88886 5.8756 3.53775 5.52004 4.04442 5.83115L9.99997 9.5556L15.9555 5.83115C16.4622 5.52004 17.1111 5.8756 17.1111 6.47115C17.1111 6.72893 16.9778 6.96893 16.7555 7.11115Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3796_2734">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

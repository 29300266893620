import { FC } from 'react';
import { IconProps } from '.';

export const IconFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 22.3304V1.66957C6 0.74749 6.70751 0 7.58027 0H16.8644L23.9756 7.51304V22.3304C23.9756 23.2525 23.2681 24 22.3953 24H7.58027C6.70751 24 6 23.2525 6 22.3304Z"
        fill="#CAD6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34375 14.1562C8.34375 13.7938 8.6592 13.5 9.04833 13.5L20.9255 13.5C21.3146 13.5 21.63 13.7938 21.63 14.1562C21.63 14.5187 21.3146 14.8125 20.9255 14.8125H9.04833C8.6592 14.8125 8.34375 14.5187 8.34375 14.1562Z"
        fill="#E8EDEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34375 11.1562C8.34375 10.7938 8.6592 10.5 9.04833 10.5L20.9255 10.5C21.3146 10.5 21.63 10.7938 21.63 11.1562C21.63 11.5187 21.3146 11.8125 20.9255 11.8125H9.04833C8.6592 11.8125 8.34375 11.5187 8.34375 11.1562Z"
        fill="#E8EDEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34375 17.1562C8.34375 16.7938 8.6592 16.5 9.04833 16.5L20.9255 16.5C21.3146 16.5 21.63 16.7938 21.63 17.1562C21.63 17.5187 21.3146 17.8125 20.9255 17.8125H9.04833C8.6592 17.8125 8.34375 17.5187 8.34375 17.1562Z"
        fill="#E8EDEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34375 20.1562C8.34375 19.7938 8.6592 19.5 9.04833 19.5L20.9255 19.5C21.3146 19.5 21.63 19.7938 21.63 20.1562C21.63 20.5187 21.3146 20.8125 20.9255 20.8125H9.04833C8.6592 20.8125 8.34375 20.5187 8.34375 20.1562Z"
        fill="#E8EDEF"
      />
      <path
        d="M16.9658 5.83333V0L23.9997 7.5H18.5289C17.6656 7.5 16.9658 6.75381 16.9658 5.83333Z"
        fill="#92AAB1"
      />
    </svg>
  );
};

import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  Tooltip,
  TooltipSize,
} from '@unique/component-library';
import { IconCopy } from '@unique/icons';
import { FC } from 'react';

type CopyToClipboardProps = {
  text: string;
  copiedText: string;
  copy: (text: string) => void;
  iconOnly?: boolean;
};

export const CopyToClipboard: FC<CopyToClipboardProps> = ({
  text,
  copiedText,
  copy,
  iconOnly = false,
}) => {
  if (iconOnly) {
    return (
      <div className="absolute bottom-2 right-2 hidden group-hover:block">
        <Tooltip
          wrapper={
            <ButtonIcon
              icon={<IconCopy />}
              onClick={() => copy(text)}
              buttonSize={ButtonSize.SMALL}
              variant={ButtonVariant.SECONDARY}
              className="!bg-background-variant !text-on-background-main"
            />
          }
          arrowPosition="none"
          size={TooltipSize.SMALL}
          className="!z-1 !right-10 !top-0 !z-auto !opacity-100"
        >
          {copiedText ? 'Copied' : 'Copy'}
        </Tooltip>
      </div>
    );
  }
  return (
    <div className="absolute bottom-2 right-2 hidden group-hover:block">
      <ButtonIcon
        icon={<IconCopy />}
        className="!ml-auto !flex"
        onClick={() => copy(text)}
        variant={ButtonVariant.SECONDARY}
        buttonSize={ButtonSize.SMALL}
      >
        {`${copiedText ? 'Copied' : 'Copy'}`}
      </ButtonIcon>
    </div>
  );
};

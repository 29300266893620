import { FC } from 'react';
import { IconProps } from '.';

export const IconAlignCenter: FC<IconProps> = ({
  width = '24px',
  height = '24px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 22V17H6V14H11V10H3V7H11V2H13V7H21V10H13V14H18V17H13V22H11Z"
        fill="currentColor"
      />
    </svg>
  );
};

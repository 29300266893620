'use client';

import { FC } from 'react';
import { ErrorPage } from './ErrorPage';

export const TooManyRequestsPage: FC = () => {
  return (
    <ErrorPage
      statusCode={429}
      title="Too Many Requests"
      description="Our servers are currently handling an extraordinary volume of requests."
      showTryAgain={true}
      showReturnHome={false}
    />
  );
};

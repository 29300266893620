import cn from 'classnames';
import { FC } from 'react';

import { ToggleMenuButton } from './ToggleMenuButton';
import { ToplevelNavItem, ToplevelNavItemProps } from './TopLevelNavItem';

export interface TopLevelNavigationProps {
  items: ToplevelNavItemProps[];
  isExpanded: boolean;
  hasSecondLevel?: boolean;
  handleClickToggleMenuButton?: (isExpanded?: boolean) => void;
}

export const TopLevelNavigation: FC<TopLevelNavigationProps> = ({
  items,
  isExpanded,
  hasSecondLevel,
  handleClickToggleMenuButton,
}) => {
  return (
    <div className="bg-secondary-variant fixed left-0 top-0 z-[60] h-screen sm:relative sm:left-auto">
      <ToggleMenuButton
        handleClick={() => handleClickToggleMenuButton && handleClickToggleMenuButton()}
        isExpanded={isExpanded}
        hasSecondLevel={hasSecondLevel}
      />
      <div
        className={cn({
          'duration-400 bg-secondary-variant -ml-[64px] mt-[55px] h-full w-[64px] transition-all ease-in-out sm:ml-0 md:mt-[80px]':
            true,
          'ml-0': isExpanded,
        })}
      >
        <div className="mt-1 flex flex-col items-center md:mt-0">
          {items.map((item) => (
            <ToplevelNavItem key={item.name} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

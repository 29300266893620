'use client';
import { useToast, ToastVariant } from '@unique/shared-library';
import { useState } from 'react';

export const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState<string>('');
  const { showToast } = useToast();

  const copy = async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
    }
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      typeof showToast !== 'undefined' &&
        showToast({
          message: 'Copied to clipboard',
          variant: ToastVariant.SUCCESS,
        });
      window.setTimeout(() => setCopiedText(''), 2000);
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText('');
      typeof showToast !== 'undefined' &&
        showToast({
          message: 'Text could not be copied',
          variant: ToastVariant.ERROR,
        });
    }
  };

  return { copiedText, copy };
};

'use client';
import { useCopyToClipboard } from '@unique/shared-library';
import { ReactNode } from 'react';

type Props = {
  children: (props: { copiedText: string }) => ReactNode;
  text: string;
};

export const CopyToClipboardWrapper = ({ children, text }: Props) => {
  const { copiedText, copy } = useCopyToClipboard();

  return (
    <div onClick={() => copy(text)} role="button">
      {children({ copiedText })}
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconTextFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29.7739V2.22609C9 0.996653 9.90527 0 11.022 0H22.9011L32 10.0174V29.7739C32 31.0033 31.0947 32 29.978 32H11.022C9.90527 32 9 31.0033 9 29.7739Z"
        fill="#CAD6D9"
      />
      <path d="M23 7.77778V0L32 10H25C23.8954 10 23 9.00508 23 7.77778Z" fill="#92AAB1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.875C12 16.3918 12.3918 16 12.875 16L27.625 16C28.1082 16 28.5 16.3918 28.5 16.875C28.5 17.3582 28.1082 17.75 27.625 17.75H12.875C12.3918 17.75 12 17.3582 12 16.875Z"
        fill="#9F9E9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.875C12 12.3918 12.3918 12 12.875 12L27.625 12C28.1082 12 28.5 12.3918 28.5 12.875C28.5 13.3582 28.1082 13.75 27.625 13.75H12.875C12.3918 13.75 12 13.3582 12 12.875Z"
        fill="#9F9E9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.875C12 20.3918 12.3918 20 12.875 20L27.625 20C28.1082 20 28.5 20.3918 28.5 20.875C28.5 21.3582 28.1082 21.75 27.625 21.75H12.875C12.3918 21.75 12 21.3582 12 20.875Z"
        fill="#9F9E9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.875C12 24.3918 12.3918 24 12.875 24L27.625 24C28.1082 24 28.5 24.3918 28.5 24.875C28.5 25.3582 28.1082 25.75 27.625 25.75H12.875C12.3918 25.75 12 25.3582 12 24.875Z"
        fill="#9F9E9E"
      />
      <path
        d="M0 11.5C0 10.6716 0.895431 10 2 10H22C23.1046 10 24 10.6716 24 11.5V26.5C24 27.3284 23.1046 28 22 28H2C0.89543 28 0 27.3284 0 26.5V11.5Z"
        fill="#454444"
      />
      <path d="M5.0918 23.25V16.1133H3V14.6602H8.5957V16.1133H6.50977V23.25H5.0918Z" fill="white" />
      <path
        d="M9.05664 23.25L11.4648 18.7676L9.2793 14.6602H10.9434L12.3555 17.4199L13.7383 14.6602H15.3848L13.1992 18.832L15.6074 23.25H13.8906L12.3262 20.2793L10.7617 23.25H9.05664Z"
        fill="white"
      />
      <path
        d="M18.166 23.25V16.1133H16.0742V14.6602H21.6699V16.1133H19.584V23.25H18.166Z"
        fill="white"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconScope: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3987 1.56167C11.2031 1.46741 10.9744 1.48284 10.7923 1.60257C10.6102 1.72231 10.5 1.92977 10.5 2.15266V15.8483C10.5 16.2085 10.7828 16.5005 11.1316 16.5005H15.8684C16.2172 16.5005 16.5 16.2085 16.5 15.8483V4.43527C16.5 4.18189 16.3579 3.95144 16.1355 3.84428L11.3987 1.56167ZM11.7632 15.1961V3.1767L15.2368 4.85062V15.1961H11.7632ZM2.25 1.50049C2.66421 1.50049 3 1.79248 3 2.15266V15.8483C3 16.2085 2.66421 16.5005 2.25 16.5005C1.83579 16.5005 1.5 16.2085 1.5 15.8483V2.15266C1.5 1.79248 1.83579 1.50049 2.25 1.50049ZM6.75 1.50049C7.16422 1.50049 7.5 1.79248 7.5 2.15266V15.8483C7.5 16.2085 7.16422 16.5005 6.75 16.5005C6.33579 16.5005 6 16.2085 6 15.8483V2.15266C6 1.79248 6.33579 1.50049 6.75 1.50049Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2359 1.8995C11.1595 1.8627 11.0708 1.86825 10.9984 1.9159L10.7923 1.60258L10.9984 1.9159C10.9246 1.96439 10.875 2.05251 10.875 2.15267V15.8483C10.875 16.0128 11.0011 16.1255 11.1316 16.1255H15.8684C15.9989 16.1255 16.125 16.0128 16.125 15.8483V4.43528C16.125 4.32101 16.061 4.22465 15.9727 4.18211L11.2359 1.8995ZM10.5863 1.28926C10.8779 1.09744 11.2466 1.07214 11.5615 1.22386L16.2983 3.50646C16.6548 3.67823 16.875 4.04277 16.875 4.43528V15.8483C16.875 16.4042 16.4356 16.8755 15.8684 16.8755H11.1316C10.5644 16.8755 10.125 16.4042 10.125 15.8483V2.15267C10.125 1.80704 10.2959 1.48024 10.5863 1.28926ZM1.125 2.15267C1.125 1.53787 1.67961 1.12549 2.25 1.12549C2.82039 1.12549 3.375 1.53787 3.375 2.15267V15.8483C3.375 16.4631 2.82039 16.8755 2.25 16.8755C1.67961 16.8755 1.125 16.4631 1.125 15.8483V2.15267ZM2.25 1.87549C1.99197 1.87549 1.875 2.04709 1.875 2.15267V15.8483C1.875 15.9539 1.99197 16.1255 2.25 16.1255C2.50803 16.1255 2.625 15.9539 2.625 15.8483V2.15267C2.625 2.04709 2.50804 1.87549 2.25 1.87549ZM5.625 2.15267C5.625 1.53787 6.17961 1.12549 6.75 1.12549C7.32039 1.12549 7.875 1.53787 7.875 2.15267V15.8483C7.875 16.4631 7.3204 16.8755 6.75 16.8755C6.17961 16.8755 5.625 16.4631 5.625 15.8483V2.15267ZM6.75 1.87549C6.49197 1.87549 6.375 2.04709 6.375 2.15267V15.8483C6.375 15.9539 6.49197 16.1255 6.75 16.1255C7.00804 16.1255 7.125 15.9539 7.125 15.8483V2.15267C7.125 2.04709 7.00804 1.87549 6.75 1.87549ZM11.5637 2.85916C11.6729 2.79055 11.8097 2.78289 11.926 2.83889L15.3996 4.5128C15.5294 4.57532 15.6118 4.70661 15.6118 4.85062V15.1961C15.6118 15.4033 15.444 15.5711 15.2368 15.5711H11.7632C11.5561 15.5711 11.3882 15.4033 11.3882 15.1961V3.17671C11.3882 3.04771 11.4545 2.92778 11.5637 2.85916ZM12.1382 3.77368V14.8211H14.8618V5.08618L12.1382 3.77368Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { IconCheckmark, IconLater, IconSync, IconWarning } from '@unique/icons';
import { format } from 'date-fns';
import { FC } from 'react';
import { IngestionState } from '../@generated/graphql';

export const CLICKABLE_INGESTION_STATES = [IngestionState.Failed, IngestionState.Finished];
export const DELETABLE_INGESTION_STATES = [
  IngestionState.Failed,
  IngestionState.FailedMalwareFound,
  IngestionState.Finished,
  IngestionState.FailedCreatingChunks,
  IngestionState.FailedEmbedding,
  IngestionState.FailedGettingFile,
  IngestionState.FailedImage,
  IngestionState.FailedParsing,
  IngestionState.FailedRedelivered,
  IngestionState.FailedTooLessContent,
];

export const FAILED_INGESTION_STATES = [
  IngestionState.Failed,
  IngestionState.FailedMalwareFound,
  IngestionState.FailedCreatingChunks,
  IngestionState.FailedEmbedding,
  IngestionState.FailedGettingFile,
  IngestionState.FailedImage,
  IngestionState.FailedParsing,
  IngestionState.FailedRedelivered,
  IngestionState.FailedTooLessContent,
];

export const INGESTING_STATES = [
  IngestionState.IngestionChunking,
  IngestionState.IngestionEmbedding,
  IngestionState.IngestionReading,
  IngestionState.Queued,
  IngestionState.MalwareScanning,
];

type IngestionStateType = { width?: string; height?: string };

const getIngestionLabelWithDate = (text: string, ingestionProgressUpdatedAt?: string) => {
  return ingestionProgressUpdatedAt
    ? `${text} on ${format(new Date(ingestionProgressUpdatedAt), 'PP')}`
    : text;
};

export const getIngestionStateType = (
  ingestionState: IngestionState,
  ingestionProgressUpdatedAt?: string,
): [FC<IngestionStateType>, string, string?] => {
  switch (ingestionState) {
    case IngestionState.Failed:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (General Error)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedImage:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (Images are not supported)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedCreatingChunks:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (While creating chunks)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedEmbedding:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (While creating embedding)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedGettingFile:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (While fetching the file)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedParsing:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (While parsing the text from the doucument)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedRedelivered:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (General error or time limit exceeded)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedTooLessContent:
      return [
        IconWarning,
        getIngestionLabelWithDate('Ingestion failed (Could not parse a lot of text. Document might have no meaning)', ingestionProgressUpdatedAt),
        'text-error-light',
      ];
    case IngestionState.FailedMalwareFound:
      return [IconWarning, 'Rejected by malware scanner', 'text-error-light'];
    case IngestionState.MalwareScanning:
      return [IconLater, 'Scanning for malware', 'text-attention'];
    case IngestionState.Queued:
      return [IconLater, 'Queued for ingestion', 'text-attention'];
    case IngestionState.IngestionReading:
      return [IconSync, 'Ingestion in progress... (reading)', 'text-info'];
    case IngestionState.IngestionChunking:
      return [IconSync, 'Ingestion in progress... (chunking)', 'text-info'];
    case IngestionState.IngestionEmbedding:
      return [IconSync, 'Ingestion in progress... (embedding)', 'text-info'];
    case IngestionState.Finished:
    default: {
      if (!ingestionProgressUpdatedAt)
        return [IconCheckmark, 'Ingestion completed', 'text-success-dark'];

      return [
        IconCheckmark,
        getIngestionLabelWithDate('Ingested', ingestionProgressUpdatedAt),
        'text-success-dark',
      ];
    }
  }
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconNewWindow: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 12 12"
    >
      <path d="M9.55588 10.1482H2.44477C2.11884 10.1482 1.85218 9.88156 1.85218 9.55564V2.44453C1.85218 2.1186 2.11884 1.85193 2.44477 1.85193H5.40773C5.73366 1.85193 6.00033 1.58527 6.00033 1.25934C6.00033 0.933415 5.73366 0.666748 5.40773 0.666748H1.85218C1.1944 0.666748 0.666992 1.20008 0.666992 1.85193V10.1482C0.666992 10.8001 1.20033 11.3334 1.85218 11.3334H10.1485C10.8003 11.3334 11.3337 10.8001 11.3337 10.1482V6.59267C11.3337 6.26675 11.067 6.00008 10.7411 6.00008C10.4151 6.00008 10.1485 6.26675 10.1485 6.59267V9.55564C10.1485 9.88156 9.88181 10.1482 9.55588 10.1482ZM7.18551 1.25934C7.18551 1.58527 7.45218 1.85193 7.7781 1.85193H9.31292L3.90255 7.2623C3.67144 7.49341 3.67144 7.86675 3.90255 8.09786C4.13366 8.32897 4.50699 8.32897 4.7381 8.09786L10.1485 2.68749V4.2223C10.1485 4.54823 10.4151 4.8149 10.7411 4.8149C11.067 4.8149 11.3337 4.54823 11.3337 4.2223V0.666748H7.7781C7.45218 0.666748 7.18551 0.933415 7.18551 1.25934Z" />
    </svg>
  );
};

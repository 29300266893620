import { FC } from 'react';
import { IconProps } from '.';

export const IconLogo: FC<IconProps> = ({ height = '24px', className = '' }) => {
  return (
    <svg
      viewBox="0 0 147 24"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      className={className}
    >
      <path
        d="M81.523 0c10.932 0 13.191 5.876 13.191 10.103v.825c0 1.882-.457 4.141-1.96 6.058l3.648 1.76L93.676 24l-6.815-3.458c-1.495.38-3.259.6-5.338.6h-1.239c-10.905 0-13.19-6.041-13.19-10.214v-.825C67.093 5.85 69.405 0 80.283 0zM7.337.56v9.3c0 2.32.794 4.995 5.42 4.995h.11c4.571 0 5.31-2.675 5.31-4.995V.56h7.337v9.356c0 4.012-1.095 10.917-12.264 10.917H12.4c-11.477 0-12.382-6.75-12.4-10.781L0 .56h7.337zM146.65.357V5.3h-13.053v2.608h12.64v5.079h-12.64v2.8h13.245v4.997H126.19V.357h20.461zM63.844 0v20.75h-7.98V0h7.98zm40.862 0v9.702c0 2.336.795 5.03 5.426 5.03h.109c4.481 0 5.284-2.583 5.315-4.884L115.557 0h7.343v9.756c0 4.04-1.095 10.993-12.275 10.993h-.85c-11.617 0-12.412-6.952-12.412-10.993V0h7.343zM40.26 0C51.746 0 52.652 6.798 52.67 10.856v9.893h-7.344v-9.7c0-2.337-.794-5.03-5.425-5.03h-.11c-4.575 0-5.314 2.693-5.314 5.03v9.7h-7.344v-9.755C27.134 6.954 28.23 0 39.409 0h.85zM80.89 5.547c-5.177 0-6.03 2.8-6.03 4.776v.303c0 1.976.908 4.887 6.057 4.887 5.15 0 6.032-2.91 6.032-4.887v-.303c0-1.976-.882-4.776-6.059-4.776z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconExternalFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#CAD6D9" />
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V6.75H0V4Z"
        fill="#073B4C"
      />
      <circle cx="3.375" cy="3.375" r="1.125" fill="#E05454" />
      <circle cx="7.125" cy="3.375" r="1.125" fill="#FFD166" />
      <circle cx="10.875" cy="3.375" r="1.125" fill="#06D6A0" />
      <path
        d="M8.78571 12.6848L4.5 15.6196L8.78571 18.0652M13.4286 9.75L10.5714 21M15.2143 12.6848L19.5 15.6196L15.2143 18.0652"
        stroke="#073B4C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

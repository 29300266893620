import { FC } from 'react';
import { IconProps } from '.';

export const IconEyeClosed: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8 2.66675C4.36364 2.66675 1.25818 4.92857 0 8.12129C1.25818 11.314 4.36364 13.5758 8 13.5758C11.6364 13.5758 14.7418 11.314 16 8.12129C14.7418 4.92857 11.6364 2.66675 8 2.66675ZM8 11.7577C5.99273 11.7577 4.36364 10.1286 4.36364 8.12129C4.36364 6.11402 5.99273 4.48493 8 4.48493C10.0073 4.48493 11.6364 6.11402 11.6364 8.12129C11.6364 10.1286 10.0073 11.7577 8 11.7577ZM8 5.93948C6.79273 5.93948 5.81818 6.91402 5.81818 8.12129C5.81818 9.32857 6.79273 10.3031 8 10.3031C9.20727 10.3031 10.1818 9.32857 10.1818 8.12129C10.1818 6.91402 9.20727 5.93948 8 5.93948Z" />
      {/* Diagonal line added from top-left to bottom-right */}
      <path d="M1,1 L15,15" stroke="currentColor" strokeWidth="1" />
      {/* Optionally, you can adjust the strokeWidth to make the line thicker or thinner */}
    </svg>
  );
};

import { IconProps } from '.';
import { FC } from 'react';

export const IconArrowUp: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66602 7.99984L3.60602 8.93984L7.33268 5.21984V13.3332H8.66602V5.21984L12.386 8.9465L13.3327 7.99984L7.99935 2.6665L2.66602 7.99984Z"
        fill="currentColor"
      />
    </svg>
  );
};

'use client';

import { FC, ReactNode, createContext, useCallback, useEffect, useRef, useState } from 'react';

const DELAY_SCROLL_STOP = 100; // test isScrolling after 34ms without scroll.
const DELAY_DISABLE_SCROLL = 1000; // disable scroll after 500ms without scroll up or prompt update.

interface ScrollWrapperContextProps {
  scrollToBottom?: () => void;
  scrollToBottomCancelable?: () => void;
}

export const ScrollWrapperContext = createContext<ScrollWrapperContextProps>({});

interface ScrollWrapperProps {
  children: ReactNode;
  id?: string;
  className?: string;
}

/*
	ScrollWrapper is a component providing vertical scroll. Design for chat.
	- scollToBotton() smooth scroll (load chat or send prompt)
	- scrollToBottomCancelable() instant scroll and cancel if user scroll up (prompt update scenario)
	Methods are shared using the ScrollWrapperContext.Provider.
*/
export const ScrollWrapper: FC<ScrollWrapperProps> = ({ id, className, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  /*
		Component track current scrolling state
		Used to make sure we don't scroll again if already scrolling
	*/
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  // isScrollDisabled count each up scroll
  const [isScrollDisabled, setIsScrollDisabled] = useState<number>(0);
  // Keep timer reference to reset at interval
  const [disableScrollTimer, setDisableScrollTimer] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );
  // Keep timer reference to reset at interval
  const [countScrollToBottomCancelable, setCountScrollToBottomCancelable] = useState<number>(0);
  // Keep timer reference to reset at interval
  const [countResetTimer, setCountResetTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  /*
  	Each time user scroll UP we reinitialize timeout to disable scroll
  */
  useEffect(() => {
    clearTimeout(disableScrollTimer);
    const timer = setTimeout(() => {
      setIsScrollDisabled(0);
      setDisableScrollTimer(undefined);
    }, DELAY_DISABLE_SCROLL);
    setDisableScrollTimer(timer);
  }, [isScrollDisabled]);

  /*
    Reset countScrollToBottomCancelable if not changed in a 500ms interval
  */
  useEffect(() => {
    clearTimeout(countResetTimer);
    const timer = setTimeout(() => {
      setCountScrollToBottomCancelable(0);
    }, DELAY_DISABLE_SCROLL);
    setCountResetTimer(timer);
  }, [countScrollToBottomCancelable]);

  /*
		Listen to scroll event to keep up to date isScrolling and isScrollDisabled
  */
  useEffect(() => {
    let scrollTimer: NodeJS.Timeout | undefined = undefined;
    const current: HTMLDivElement | null = ref.current;
    const scrollListener = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        setIsScrolling(false);
      }, DELAY_SCROLL_STOP);
    };

    current?.addEventListener('scroll', scrollListener, { passive: true });

    /*
      Wheel event
    */

    // Track previous position to detect scroll direction in scrollListener
    let previousPosition: number = ref?.current?.scrollTop ?? 0;

    const wheelAndTouchListener = () => {
      // If user scroll up, we disable scroll for 1s after last scroll
      // Add a Threshold to avoid triggering on small scroll
      // Also ignore 5 first ScrollToBottomCancelable call
      if (
        !isScrollDisabled &&
        countScrollToBottomCancelable >= 3 &&
        ref &&
        ref.current &&
        previousPosition - ref.current.scrollTop > 0
      ) {
        setIsScrollDisabled(isScrollDisabled + 1);
      }
      previousPosition = ref?.current?.scrollTop ?? 0;
    };
    current?.addEventListener('wheel', wheelAndTouchListener, { passive: true });

    // Touch event because scrolling on mobile doesn't trigger wheel event
    const onTouchStart = () => {
      previousPosition = ref?.current?.scrollTop ?? 0;
    };

    current?.addEventListener('touchstart', onTouchStart, { passive: true });
    current?.addEventListener('touchmove', wheelAndTouchListener, { passive: true });
    current?.addEventListener('touchend', wheelAndTouchListener, { passive: true });

    return () => {
      current?.removeEventListener('scroll', scrollListener);
      current?.removeEventListener('wheel', wheelAndTouchListener);
      current?.removeEventListener('touchstart', onTouchStart);
      current?.removeEventListener('touchmove', wheelAndTouchListener);
      current?.removeEventListener('touchend', wheelAndTouchListener);
    };
  }, [countScrollToBottomCancelable]);

  /*
		Instant scroll to position bottom. Can be cancelled if user scroll up
	*/
  const scrollToBottomCancelable = useCallback(() => {
    if (isScrolling) {
      return;
    }
    setCountScrollToBottomCancelable(countScrollToBottomCancelable + 1);
    // If scroll is Disabled and there is a timer open, we reset it
    if (isScrollDisabled) {
      setIsScrollDisabled(isScrollDisabled + 1);
    } else {
      ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'instant' });
    }
  }, [isScrolling]);

  /*
		Smooth scroll from any position to bottom. User has to wait until scroll ends
	*/
  const scrollToBottom = useCallback(() => {
    if (isScrolling) {
      return;
    }
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
  }, [isScrolling]);

  return (
    <ScrollWrapperContext.Provider
      value={{
        scrollToBottom,
        scrollToBottomCancelable,
      }}
    >
      <div ref={ref} id={id} className={className}>
        {children}
      </div>
    </ScrollWrapperContext.Provider>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconSpaceAccess: FC<IconProps> = ({ width = '157px', height = '156px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 157 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.5 75.3815C156.5 76.7663 156.462 78.1413 156.387 79.5067C156.115 84.6113 155.322 89.6748 154.021 94.6184C154.019 94.6204 154.019 94.6204 154.021 94.6225C153.85 95.2764 153.669 95.9282 153.477 96.5737C153.469 96.6049 153.46 96.6341 153.45 96.6633C153.166 97.6315 152.864 98.5908 152.541 99.5411V99.5432C147.478 114.473 137.862 127.442 125.044 136.629C112.226 145.816 96.8514 150.759 81.0787 150.763C45.4036 150.763 15.5163 126.01 7.66999 92.7484C6.32867 87.0568 5.65327 81.2288 5.65737 75.3815C5.65737 74.2196 5.68376 73.0638 5.73654 71.9144C6.36171 57.8621 10.9282 44.2688 18.9144 32.6868C19.0103 32.5452 19.1082 32.4036 19.2082 32.2641C32.8382 12.7586 55.4688 0 81.0787 0C117.273 0 147.51 25.4798 154.814 59.4723L154.817 59.4764C155.287 61.6671 155.661 63.891 155.937 66.1483C156.314 69.2116 156.501 72.2952 156.5 75.3815Z"
        fill="currentColor"
        className="text-background"
      />
      <path
        d="M120.958 51.9245V39.9211H126.58C128.789 39.9211 130.58 38.1302 130.58 35.9211V4.91016C130.58 2.70102 128.789 0.910156 126.58 0.910156H46.1799C43.9708 0.910156 42.1799 2.70101 42.1799 4.91015V35.9211C42.1799 38.1302 43.9708 39.9211 46.1799 39.9211H112.539C112.539 50.0039 118.152 52.1245 120.958 51.9245Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M120.958 51.9245V39.9211H126.58C128.789 39.9211 130.58 38.1302 130.58 35.9211V4.91016C130.58 2.70102 128.789 0.910156 126.58 0.910156H46.1799C43.9708 0.910156 42.1799 2.70101 42.1799 4.91015V35.9211C42.1799 38.1302 43.9708 39.9211 46.1799 39.9211H112.539C112.539 50.0039 118.152 52.1245 120.958 51.9245Z"
        stroke="currentColor"
        className="text-background-variant"
      />
      <path
        d="M61.4609 9.24072H110.26C110.942 9.24111 111.597 9.50177 112.089 9.96807C112.58 10.4344 112.869 11.0701 112.896 11.7421C112.9 11.776 112.901 11.8101 112.9 11.8441C112.899 12.5343 112.621 13.1959 112.126 13.6839C111.631 14.172 110.96 14.4466 110.26 14.4475H61.4609C60.7606 14.4475 60.0889 14.1733 59.5937 13.685C59.0985 13.1968 58.8203 12.5346 58.8203 11.8441C58.8203 11.1537 59.0985 10.4915 59.5937 10.0032C60.0889 9.51501 60.7606 9.24072 61.4609 9.24072Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M61.4609 17.5718H110.26C110.942 17.5722 111.597 17.8328 112.089 18.2991C112.58 18.7654 112.869 19.4012 112.896 20.0731C112.9 20.107 112.901 20.1411 112.9 20.1752C112.899 20.8654 112.621 21.527 112.126 22.015C111.631 22.503 110.96 22.7776 110.26 22.7786H61.4609C60.7606 22.7786 60.0889 22.5043 59.5937 22.0161C59.0985 21.5278 58.8203 20.8657 58.8203 20.1752C58.8203 19.4847 59.0985 18.8225 59.5937 18.3343C60.0889 17.8461 60.7606 17.5718 61.4609 17.5718Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M61.4609 26.9438H110.26C110.942 26.9442 111.597 27.2049 112.089 27.6712C112.58 28.1375 112.869 28.7732 112.896 29.4452C112.9 29.4791 112.901 29.5132 112.9 29.5473C112.899 30.2374 112.621 30.899 112.126 31.3871C111.631 31.8751 110.96 32.1497 110.26 32.1507H61.4609C60.7606 32.1507 60.0889 31.8764 59.5937 31.3881C59.0985 30.8999 58.8203 30.2377 58.8203 29.5473C58.8203 28.8568 59.0985 28.1946 59.5937 27.7064C60.0889 27.2181 60.7606 26.9438 61.4609 26.9438Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M29.995 98.9171V86.9138H24.26C22.0509 86.9138 20.26 85.1229 20.26 82.9138V51.9028C20.26 49.6937 22.0509 47.9028 24.26 47.9028H105.7C107.909 47.9028 109.7 49.6937 109.7 51.9028V82.9138C109.7 85.1229 107.909 86.9138 105.7 86.9138H38.5131C38.5131 96.9966 32.8344 99.1172 29.995 98.9171Z"
        fill="currentColor"
        className="text-surface"
      />
      <path
        d="M29.995 98.9171V86.9138H24.26C22.0509 86.9138 20.26 85.1229 20.26 82.9138V51.9028C20.26 49.6937 22.0509 47.9028 24.26 47.9028H105.7C107.909 47.9028 109.7 49.6937 109.7 51.9028V82.9138C109.7 85.1229 107.909 86.9138 105.7 86.9138H38.5131C38.5131 96.9966 32.8344 99.1172 29.995 98.9171Z"
        stroke="currentColor"
        className="text-background-variant"
      />
      <path
        d="M89.3795 56.2334H40.5809C39.8988 56.2338 39.2433 56.4944 38.7518 56.9607C38.2604 57.427 37.971 58.0628 37.9445 58.7348C37.9409 58.7687 37.9395 58.8027 37.9402 58.8368C37.9412 59.527 38.2197 60.1886 38.7147 60.6766C39.2097 61.1646 39.8808 61.4392 40.5809 61.4402H89.3795C90.0799 61.4402 90.7515 61.1659 91.2467 60.6777C91.7419 60.1895 92.0202 59.5273 92.0202 58.8368C92.0202 58.1463 91.7419 57.4842 91.2467 56.9959C90.7515 56.5077 90.0799 56.2334 89.3795 56.2334Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M89.3795 64.5645H40.5809C39.8988 64.5648 39.2433 64.8255 38.7518 65.2918C38.2604 65.7581 37.971 66.3938 37.9445 67.0658C37.9409 67.0997 37.9395 67.1338 37.9402 67.1679C37.9412 67.858 38.2197 68.5197 38.7147 69.0077C39.2097 69.4957 39.8808 69.7703 40.5809 69.7713H89.3795C90.0799 69.7713 90.7515 69.497 91.2467 69.0088C91.7419 68.5205 92.0202 67.8583 92.0202 67.1679C92.0202 66.4774 91.7419 65.8152 91.2467 65.327C90.7515 64.8387 90.0799 64.5645 89.3795 64.5645Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M89.3795 73.937H40.5809C39.8988 73.9374 39.2433 74.1981 38.7518 74.6644C38.2604 75.1307 37.971 75.7664 37.9445 76.4384C37.9409 76.4723 37.9395 76.5063 37.9402 76.5404C37.9412 77.2306 38.2197 77.8922 38.7147 78.3802C39.2097 78.8683 39.8808 79.1429 40.5809 79.1438H89.3795C90.0799 79.1438 90.7515 78.8695 91.2467 78.3813C91.7419 77.8931 92.0202 77.2309 92.0202 76.5404C92.0202 75.85 91.7419 75.1878 91.2467 74.6995C90.7515 74.2113 90.0799 73.937 89.3795 73.937Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M15.7463 116.668C15.6636 116.668 15.5809 116.663 15.4988 116.652C15.2161 116.618 14.9439 116.524 14.7001 116.376C14.4562 116.229 14.2462 116.031 14.0839 115.797C13.9215 115.563 13.8104 115.297 13.7579 115.016C13.7053 114.736 13.7125 114.448 13.779 114.17C13.7948 114.106 13.8138 114.043 13.8361 113.98L7.70556 106.121C7.43567 105.773 7.28525 105.346 7.27664 104.905C7.26802 104.464 7.40167 104.032 7.65774 103.673L9.80514 100.687L12.6119 102.855L11.4843 104.794L15.9498 112.632C16.0395 112.64 16.1286 112.655 16.2164 112.675C16.6975 112.791 17.1195 113.079 17.4025 113.486C17.6855 113.892 17.8097 114.389 17.7518 114.881C17.6939 115.373 17.4577 115.826 17.0881 116.156C16.7185 116.485 16.2411 116.667 15.7463 116.668Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M26.3541 153.905L28.6068 153.905L29.6784 145.205L26.3538 145.205L26.3541 153.905Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M32.9513 156L25.8715 156L25.8714 153.261L30.2158 153.261C30.575 153.261 30.9307 153.332 31.2626 153.47C31.5945 153.607 31.896 153.809 32.15 154.063C32.4041 154.318 32.6055 154.62 32.743 154.952C32.8805 155.284 32.9513 155.64 32.9513 156Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M7.79871 153.905L10.0514 153.905L11.123 145.205L7.79834 145.205L7.79871 153.905Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M14.3938 156L7.31405 156L7.31392 153.261L11.6583 153.261C12.0175 153.261 12.3732 153.332 12.7051 153.47C13.037 153.607 13.3385 153.809 13.5925 154.063C13.8466 154.318 14.0481 154.62 14.1855 154.952C14.323 155.284 14.3938 155.64 14.3938 156Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M9.18152 101.11L14.239 103.034L15.4737 99.5999C15.6377 99.1439 15.6354 98.6445 15.4672 98.19C15.299 97.7355 14.9758 97.3552 14.5546 97.1162C14.1286 96.8745 13.6303 96.7933 13.1497 96.8872C12.6692 96.9811 12.238 97.2441 11.934 97.6285L9.18152 101.11Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M25.9547 146.882C25.7527 146.882 25.5566 146.815 25.3972 146.69C25.2378 146.566 25.1241 146.393 25.0741 146.197C22.5521 136.536 19.9914 131.693 17.2455 126.094C17.1768 125.954 17.0655 125.84 16.9276 125.768C16.7898 125.696 16.6326 125.67 16.479 125.694C16.3254 125.718 16.1833 125.79 16.0735 125.9C15.9637 126.01 15.8918 126.152 15.8684 126.306L12.4647 146.001C12.4307 146.221 12.3182 146.421 12.148 146.564C11.9778 146.707 11.7615 146.783 11.5396 146.778L6.48636 146.654C6.36375 146.652 6.24293 146.624 6.1312 146.574C6.01948 146.523 5.91917 146.45 5.83635 146.36C5.74887 146.273 5.68193 146.168 5.64048 146.051C5.59902 145.935 5.5841 145.811 5.5968 145.689C7.8855 130.575 9.6875 116.895 13.1529 112.722C13.2489 112.631 13.359 112.556 13.479 112.5L13.3738 112.483C13.3438 112.479 13.2638 112.466 13.2628 112.386L13.2618 112.308L13.3408 112.293C13.467 112.289 13.5929 112.308 13.7123 112.349L21.6214 113.568C21.8405 113.602 22.0398 113.714 22.1823 113.884C22.3247 114.054 22.4005 114.27 22.3956 114.492C22.8256 116.506 23.4845 119.546 23.5435 119.696C27.7633 127.729 29.4007 134.055 31.1027 144.856C31.1379 145.085 31.0853 145.319 30.9554 145.511C30.8254 145.703 30.6278 145.838 30.4023 145.89L26.1607 146.859C26.0931 146.874 26.024 146.882 25.9547 146.882Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M21.5178 114.885C21.4468 114.885 21.3761 114.877 21.307 114.86L13.5203 113.006C13.401 112.978 13.2886 112.926 13.1901 112.853C13.0917 112.78 13.0092 112.687 12.9478 112.581C12.8845 112.471 12.844 112.349 12.8287 112.224C12.8134 112.098 12.8237 111.97 12.8589 111.848C13.6986 108.933 12.5975 105.32 11.2826 101.849C11.1154 100.834 11.2792 99.7926 11.7498 98.8783C12.2204 97.964 12.9728 97.2259 13.8953 96.7734L13.9165 96.7632L18.0047 96.9698C18.676 97.1461 19.2986 97.4724 19.8259 97.9242C20.3531 98.3761 20.7712 98.9417 21.0487 99.5786C22.6245 103.271 23.0757 108.01 22.4279 114.065C22.4041 114.29 22.298 114.498 22.13 114.65C21.9619 114.801 21.7439 114.885 21.5178 114.885Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M22.5543 106.449C22.1386 106.449 21.7333 106.32 21.3949 106.078L18.3828 103.92L20.5397 101.103L22.4802 102.226L30.294 97.7317C30.302 97.6418 30.3162 97.5525 30.3365 97.4646C30.4287 97.0751 30.6342 96.7218 30.9269 96.4492C31.2197 96.1766 31.5866 95.9971 31.9812 95.9334C32.3759 95.8696 32.7805 95.9244 33.1441 96.0909C33.5076 96.2575 33.8136 96.5282 34.0235 96.8688C34.2333 97.2095 34.3376 97.6049 34.3231 98.0049C34.3085 98.4049 34.1759 98.7916 33.9419 99.1161C33.7078 99.4406 33.383 99.6884 33.0083 99.828C32.6337 99.9676 32.2261 99.9929 31.8372 99.9006C31.7728 99.885 31.7093 99.8661 31.6469 99.844L23.7946 106.024C23.4407 106.301 23.0037 106.451 22.5543 106.449Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M17.4307 104.813L21.9046 101.766L19.802 98.7847C19.5229 98.3887 19.1129 98.1044 18.6446 97.9818C18.1762 97.8593 17.6797 97.9064 17.2428 98.115C16.8007 98.3259 16.4486 98.6882 16.25 99.1364C16.0514 99.5845 16.0194 100.089 16.1597 100.559L17.4307 104.813Z"
        fill="currentColor"
        className="text-control"
      />
      <path
        d="M15.2809 95.2339C17.7733 95.2339 19.7938 93.2108 19.7938 90.7151C19.7938 88.2194 17.7733 86.1963 15.2809 86.1963C12.7884 86.1963 10.7679 88.2194 10.7679 90.7151C10.7679 93.2108 12.7884 95.2339 15.2809 95.2339Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        d="M15.6931 95.3388C15.0158 95.3359 14.4933 94.6218 14.5236 93.9442C14.554 93.2667 15.0085 92.6705 15.556 92.2713C16.1036 91.872 16.7457 91.6288 17.3563 91.3353C17.9669 91.0417 18.5722 90.6756 18.9492 90.1121C19.177 89.7433 19.3129 89.3251 19.3457 88.8928C19.3784 88.4604 19.3069 88.0265 19.1373 87.6275C18.7864 86.8296 18.184 86.1689 17.4224 85.7465C16.2909 85.0833 14.9515 84.8712 13.6709 85.1525C12.3904 85.4339 11.2626 86.1879 10.5123 87.2644L9.73912 89.3724C8.95125 90.3211 8.82628 91.7299 9.3106 92.8644C9.79492 93.9989 10.8261 94.8525 11.9898 95.2583C13.1704 95.6382 14.4342 95.6761 15.6354 95.3677"
        fill="currentColor"
        className="text-on-background-main"
      />
      <path
        d="M10.8168 88.5732C10.5076 88.0958 10.0859 87.702 9.58887 87.4263C9.09181 87.1507 8.53467 87.0018 7.96655 86.9926C7.39843 86.9835 6.83679 87.1145 6.33115 87.374C5.82551 87.6335 5.3914 88.0136 5.0671 88.4807C4.52423 89.2627 4.32707 90.2267 3.9538 91.1027C3.27771 92.6714 2.04815 93.9357 0.5 94.6541C1.85554 94.8928 3.20599 94.2023 4.28971 93.3527C5.37343 92.5032 6.3265 91.4662 7.5402 90.8164C8.495 90.307 9.56764 90.0603 10.6487 90.1015"
        fill="currentColor"
        className="text-on-background-main"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.38 70.0019V68.6859C151.38 61.9397 148.624 52.5615 135.287 52.5615H133.776C120.505 52.5615 117.684 61.8963 117.684 68.6859V70.0019C117.684 76.6612 120.473 86.3017 133.776 86.3017H135.287C147.279 85.8339 151.38 78.6243 151.38 70.0019Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.628 70.0669V68.8167C150.628 62.4078 148.011 53.4985 135.341 53.4985H133.905C121.297 53.4985 118.617 62.3666 118.617 68.8167V70.0669C118.617 76.3932 121.267 85.5517 133.905 85.5517H135.341C146.733 85.1073 150.628 78.2582 150.628 70.0669Z"
        fill="currentColor"
        className="text-background-variant"
      />
      <path
        d="M145.738 76.3022C145.738 77.9091 144.437 79.2117 142.832 79.2117L126.228 79.2117C124.623 79.2117 123.323 77.9091 123.323 76.3022L123.323 66.7425C123.323 65.1356 124.623 63.833 126.228 63.833L142.832 63.833C144.437 63.833 145.738 65.1356 145.738 66.7425V76.3022Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.911 63.8323L133.911 60.9229L135.156 60.9229L135.156 63.8323L133.911 63.8323Z"
        fill="currentColor"
        className="text-primary-variant"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.911 82.1214L133.911 79.2119L135.156 79.2119L135.156 82.1214L133.911 82.1214Z"
        fill="currentColor"
        className="text-primary-variant"
      />
      <path
        d="M123.326 71.6258C123.326 74.0935 123.326 73.8124 123.326 76.094C121.606 76.094 120.213 74.0935 120.213 71.6258C120.213 69.1582 121.606 67.1577 123.326 67.1577C123.326 69.2492 123.326 69.1582 123.326 71.6258Z"
        fill="currentColor"
        className="text-primary-variant"
      />
      <path
        d="M145.74 71.6261C145.74 69.1584 145.74 69.4396 145.74 67.158C147.459 67.158 148.853 69.1584 148.853 71.6261C148.853 74.0938 147.459 76.0942 145.74 76.0942C145.74 74.0028 145.74 74.0938 145.74 71.6261Z"
        fill="currentColor"
        className="text-primary-variant"
      />
      <path
        d="M137.645 58.9489C137.645 60.6132 136.298 61.9623 134.636 61.9623C132.974 61.9623 131.626 60.6132 131.626 58.9489C131.626 57.2847 132.974 55.9355 134.636 55.9355C136.298 55.9355 137.645 57.2847 137.645 58.9489Z"
        fill="currentColor"
        className="text-primary-variant"
      />
      <path
        d="M125.939 83.7713C125.939 82.6531 126.845 81.7466 127.964 81.7466H141.099C142.217 81.7466 143.124 82.6531 143.124 83.7713C143.124 83.7713 141.27 85.4583 134.531 85.4583C127.792 85.4583 125.939 83.7713 125.939 83.7713Z"
        fill="currentColor"
        className="text-on-control-dimmed"
      />
      <ellipse
        cx="128.916"
        cy="70.0564"
        rx="2.496"
        ry="2.49927"
        fill="currentColor"
        className="text-control"
      />
      <ellipse
        cx="140.147"
        cy="70.0564"
        rx="2.496"
        ry="2.49927"
        fill="currentColor"
        className="text-control"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconRefresh: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={height} width={width}>
      <path
        d="m5.25 14.248 0 4.5 -4.5 0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
      <path
        d="m18.75 9.748 0 -4.5 4.5 0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
      <path
        d="M19.032 5.245A9.752 9.752 0 0 1 8.246 21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
      <path
        d="M4.967 18.751A9.753 9.753 0 0 1 15.754 3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
    </svg>
  );
};

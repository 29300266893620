import { FC } from 'react';
import { IconProps } from '.';

export const IconConfigure: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7968 9.87034C15.8349 9.59256 15.8539 9.30553 15.8539 8.99997C15.8539 8.70368 15.8349 8.40738 15.7873 8.1296L17.7197 6.66664C17.891 6.53701 17.9386 6.28701 17.8339 6.10182L16.0062 3.02775C15.892 2.82405 15.654 2.75923 15.4446 2.82405L13.1696 3.71294C12.6936 3.36108 12.1891 3.06479 11.6275 2.84257L11.2848 0.490713C11.2467 0.268491 11.0563 0.111084 10.8279 0.111084H7.17256C6.9441 0.111084 6.76324 0.268491 6.72516 0.490713L6.38248 2.84257C5.82085 3.06479 5.30683 3.37034 4.84039 3.71294L2.56534 2.82405C2.35592 2.74997 2.11794 2.82405 2.00372 3.02775L0.185577 6.10182C0.0713485 6.29627 0.109425 6.53701 0.299806 6.66664L2.23217 8.1296C2.18458 8.40738 2.1465 8.71294 2.1465 8.99997C2.1465 9.28701 2.16554 9.59256 2.21313 9.87034L0.280767 11.3333C0.109425 11.4629 0.0618294 11.7129 0.166539 11.8981L1.9942 14.9722C2.10842 15.1759 2.3464 15.2407 2.55582 15.1759L4.83087 14.287C5.30683 14.6389 5.81133 14.9352 6.37296 15.1574L6.71564 17.5092C6.76324 17.7315 6.9441 17.8889 7.17256 17.8889H10.8279C11.0563 17.8889 11.2467 17.7315 11.2753 17.5092L11.618 15.1574C12.1796 14.9352 12.6936 14.6389 13.16 14.287L15.4351 15.1759C15.6445 15.25 15.8825 15.1759 15.9967 14.9722L17.8244 11.8981C17.9386 11.6944 17.891 11.4629 17.7101 11.3333L15.7968 9.87034ZM9.00022 12.3333C7.11544 12.3333 5.57336 10.8333 5.57336 8.99997C5.57336 7.16664 7.11544 5.66664 9.00022 5.66664C10.885 5.66664 12.4271 7.16664 12.4271 8.99997C12.4271 10.8333 10.885 12.3333 9.00022 12.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import resolveConfig from 'tailwindcss/resolveConfig';
import sharedConfig from '@unique/tailwind-config';

const fullConfig = resolveConfig(sharedConfig);

export enum SpinnerTheme {
  LIGHT = 'light',
  DARK = 'dark',
}
interface SpinnerProps {
  wrapperClasses?: string;
  size?: number;
  theme?: SpinnerTheme;
}

export const Spinner: FC<SpinnerProps> = ({
  wrapperClasses,
  size = 24,
  theme = SpinnerTheme.DARK,
}) => {
  return (
    <div className={wrapperClasses}>
      <ClipLoader
        color={
          theme === SpinnerTheme.DARK
            ? fullConfig.theme.textColor.on.surface
            : fullConfig.theme.textColor.on.secondary
        }
        size={size}
      />
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconThumbsDown: FC<IconProps> = ({
  width = '14px',
  height = '15px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.02778H12.6C12.215 1.02778 11.9 1.33889 11.9 1.71914V7.94136C11.9 8.3216 12.215 8.63272 12.6 8.63272H14V1.02778ZM0.119 5.95025C0.0420001 6.12309 0 6.30975 0 6.50333V7.25C0 8.01049 0.63 8.63272 1.4 8.63272H5.25L4.606 11.8475C4.571 11.9996 4.592 12.1656 4.662 12.3038C4.823 12.6149 5.026 12.8984 5.278 13.1473L5.6 13.4722L10.087 9.04062C10.353 8.7779 10.5 8.42531 10.5 8.05889V2.64556C10.5 1.7537 9.765 1.02778 8.862 1.02778H3.192C2.695 1.02778 2.24 1.28358 1.988 1.69839L0.119 5.95025Z"
        fill="currentColor"
      />
    </svg>
  );
};

'use client';

import { ProgressBar } from '@unique/component-library';
import { IconLogo } from '@unique/icons';
import { FC, useEffect, useState } from 'react';
import { fallbackThemeColors } from '../constants/fallback-theme-colors';

const MAX_PROGRESS = 3;
const ease = (i: number) => (1 / (1 + Math.pow(Math.E, -i)) - 0.5) * 2;

const getColorHexForColor = (colorId: string) => {
  return fallbackThemeColors.find((color) => color.id === colorId)?.hexValue;
};

const themeLoadingPageBackgroundColor = '#F6F6F6';
const progressBarBackgroundColor = '#CFCFCF';

export const ThemeLoadingPage: FC = () => {
  const [progressBarCompleted, setProgressBarCompleted] = useState(0);

  useEffect(() => {
    const step = 0.05;

    const increaseProgress = setInterval(() => {
      setProgressBarCompleted((progressBarCompleted) => {
        const newProgress = progressBarCompleted + step;
        if (newProgress > MAX_PROGRESS) {
          clearInterval(increaseProgress);
        }

        return newProgress;
      });
    }, 200);

    return () => {
      clearInterval(increaseProgress);
    };
  }, []);

  const progressBarCompletedPercentage = ease(progressBarCompleted) * 100;

  return (
    <div
      className="fixed left-0 top-0 z-[60] flex h-screen w-screen flex-col items-center justify-center px-4 py-16 text-center"
      style={{ backgroundColor: themeLoadingPageBackgroundColor }}
    >
      <p className="mb-4" style={{ color: getColorHexForColor('color-on-control-main') }}>
        <IconLogo width="97" className="my-auto" />
      </p>
      <div className="w-full max-w-[240px]">
        <ProgressBar
          completed={progressBarCompletedPercentage}
          customProgressBarStyle={{ background: getColorHexForColor('color-primary-cta') }}
          customProgressBgStyle={{ background: progressBarBackgroundColor }}
        />
      </div>
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconWorld: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.993 1C4.129 1 1 4.136 1 8C1 11.864 4.129 15 7.993 15C11.864 15 15 11.864 15 8C15 4.136 11.864 1 7.993 1ZM12.844 5.2H10.779C10.555 4.325 10.233 3.485 9.813 2.708C11.101 3.149 12.172 4.045 12.844 5.2ZM8 2.428C8.581 3.268 9.036 4.199 9.337 5.2H6.663C6.964 4.199 7.419 3.268 8 2.428ZM2.582 9.4C2.47 8.952 2.4 8.483 2.4 8C2.4 7.517 2.47 7.048 2.582 6.6H4.948C4.892 7.062 4.85 7.524 4.85 8C4.85 8.476 4.892 8.938 4.948 9.4H2.582ZM3.156 10.8H5.221C5.445 11.675 5.767 12.515 6.187 13.292C4.899 12.851 3.828 11.962 3.156 10.8ZM5.221 5.2H3.156C3.828 4.038 4.899 3.149 6.187 2.708C5.767 3.485 5.445 4.325 5.221 5.2ZM8 13.572C7.419 12.732 6.964 11.801 6.663 10.8H9.337C9.036 11.801 8.581 12.732 8 13.572ZM9.638 9.4H6.362C6.299 8.938 6.25 8.476 6.25 8C6.25 7.524 6.299 7.055 6.362 6.6H9.638C9.701 7.055 9.75 7.524 9.75 8C9.75 8.476 9.701 8.938 9.638 9.4ZM9.813 13.292C10.233 12.515 10.555 11.675 10.779 10.8H12.844C12.172 11.955 11.101 12.851 9.813 13.292ZM11.052 9.4C11.108 8.938 11.15 8.476 11.15 8C11.15 7.524 11.108 7.062 11.052 6.6H13.418C13.53 7.048 13.6 7.517 13.6 8C13.6 8.483 13.53 8.952 13.418 9.4H11.052Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconNewFolder: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon (18px)/Folder">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.91563 0.777771C2.30274 0.777771 1.8059 1.26936 1.80588 1.87578L1.8058 3.70586C1.8058 3.80294 1.84477 3.89604 1.91414 3.96468C1.98352 4.03332 2.07761 4.07189 2.17572 4.07189H11.6418C11.8461 4.07189 12.0117 3.90805 12.0118 3.70592L12.0118 3.17723C12.0118 2.57078 11.515 2.07915 10.9021 2.07915H6.99854C6.83964 2.07915 6.71082 1.9517 6.71082 1.79447C6.71082 1.23296 6.25077 0.777771 5.68327 0.777771H2.91563ZM1.8877 4.95032C1.22888 4.95032 0.71512 5.51493 0.78404 6.16322L1.42996 12.239C1.48937 12.7979 1.96566 13.2222 2.53362 13.2222H11.6684C12.2476 13.2222 12.7295 12.7814 12.7748 12.2101L13.004 9.31909L13.2198 6.12149C13.2625 5.48769 12.7545 4.95032 12.1125 4.95032H1.8877Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconSync: FC<IconProps> = ({ width = '16px', height = '14px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1693 4.15919L11.0005 6.32796C10.7518 6.57671 10.9228 6.99647 11.2726 6.99647H12.664C12.664 9.56946 10.573 11.6605 8 11.6605C7.3859 11.6605 6.78735 11.5439 6.25099 11.3185C5.97115 11.2019 5.65244 11.2874 5.44256 11.4973C5.04612 11.8937 5.18604 12.5622 5.70685 12.7721C6.41423 13.0597 7.19157 13.2152 8 13.2152C11.4358 13.2152 14.2187 10.4323 14.2187 6.99647H15.6101C15.9599 6.99647 16.131 6.57671 15.8822 6.33574L13.7134 4.16696C13.5657 4.0115 13.317 4.0115 13.1693 4.15919ZM3.33597 6.99647C3.33597 4.42349 5.42701 2.33245 8 2.33245C8.6141 2.33245 9.21265 2.44905 9.74901 2.67448C10.0289 2.79108 10.3476 2.70557 10.5574 2.49569C10.9539 2.09925 10.814 1.43073 10.2931 1.22085C9.58577 0.933239 8.80843 0.777771 8 0.777771C4.56417 0.777771 1.7813 3.56064 1.7813 6.99647H0.389862C0.0400597 6.99647 -0.130955 7.41624 0.117793 7.65721L2.28657 9.82598C2.44203 9.98145 2.68301 9.98145 2.83848 9.82598L5.00725 7.65721C5.24822 7.41624 5.07721 6.99647 4.72741 6.99647H3.33597Z"
        fill="currentcolor"
      />
    </svg>
  );
};

import cn from 'classnames';
import { FC } from 'react';

import { IconShapeCollapseMenu, IconShapeExpandMenu } from '@unique/icons';
import { Tooltip } from '../Tooltip';

interface ToggleMenuButtonProps {
  isExpanded: boolean;
  hasSecondLevel?: boolean;
  handleClick: () => void;
}

export const ToggleMenuButton: FC<ToggleMenuButtonProps> = ({
  isExpanded,
  hasSecondLevel,
  handleClick,
}) => {
  return (
    <div
      className={cn({
        'z-55 bg-secondary-variant top-0 hidden w-[64px] sm:fixed sm:block sm:cursor-not-allowed md:h-20':
          true,
        'sm:cursor-not-allowed': !hasSecondLevel,
        '!fixed !block': isExpanded,
      })}
    >
      <button
        onClick={handleClick}
        className={cn({
          'top-navigation text-on-secondary hover:bg-secondary group my-1 ml-2 mr-[114px] flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-[20px] focus:rounded-3xl sm:mr-0 md:mt-2':
            true,
          'hover:bg-secondary-variant sm:!text-on-background-dimmed sm:pointer-events-none':
            !hasSecondLevel,
        })}
      >
        <Tooltip
          wrapper={isExpanded ? <IconShapeCollapseMenu /> : <IconShapeExpandMenu />}
          arrowPosition="left"
          className="!z-55 left-[75px] top-0 whitespace-nowrap sm:!min-w-fit"
        >
          {isExpanded ? 'Collapse' : 'Expand'}
        </Tooltip>
      </button>
    </div>
  );
};

import Link from 'next/link';
import { FC } from 'react';

type DefaultMarkdownLinkProps = {
  children?: React.ReactNode;
  href: unknown;
  target: unknown;
};

export const DefaultMarkdownLink: FC<DefaultMarkdownLinkProps> = ({ children, href, target }) => {
  // Test if href is a valid URL otherwise <Link> throw an exception
  // while running a prefetch.
  try {
    new URL(typeof href === 'string' ? href : '/');
  } catch {
    return children;
  }
  return (
    <Link
      className="text-primary-cta cursor-pointer px-2 underline"
      {...{ href: typeof href === 'string' ? href : '/' }}
      {...{ target: typeof target === 'string' ? target : '_blank' }}
      rel="noreferrer"
    >
      {children}
    </Link>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconExcelFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29.7739V2.22609C9 0.996653 9.90527 0 11.022 0H22.9011L32 10.0174V29.7739C32 31.0033 31.0947 32 29.978 32H11.022C9.90527 32 9 31.0033 9 29.7739Z"
        fill="#CAD6D9"
      />
      <path d="M23 7.77778V0L32 10H25C23.8954 10 23 9.00508 23 7.77778Z" fill="#92AAB1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 13.125C20.5 12.6418 20.8918 12.25 21.375 12.25H27.125C27.6082 12.25 28 12.6418 28 13.125C28 13.6082 27.6082 14 27.125 14H21.375C20.8918 14 20.5 13.6082 20.5 13.125Z"
        fill="#69C997"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 17.125C20.5 16.6418 20.8918 16.25 21.375 16.25H27.125C27.6082 16.25 28 16.6418 28 17.125C28 17.6082 27.6082 18 27.125 18H21.375C20.8918 18 20.5 17.6082 20.5 17.125Z"
        fill="#54AD7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 21.125C20.5 20.6418 20.8918 20.25 21.375 20.25H27.125C27.6082 20.25 28 20.6418 28 21.125C28 21.6082 27.6082 22 27.125 22H21.375C20.8918 22 20.5 21.6082 20.5 21.125Z"
        fill="#2B593D"
      />
      <path
        d="M0 9.5C0 8.67157 0.671573 8 1.5 8H16.5C17.3284 8 18 8.67157 18 9.5V24.5C18 25.3284 17.3284 26 16.5 26H1.5C0.671573 26 0 25.3284 0 24.5V9.5Z"
        fill="#3D8A58"
      />
      <path
        d="M5 22.0215L7.80957 16.792L5.25977 12H7.20117L8.84863 15.2197L10.4619 12H12.3828L9.83301 16.8672L12.6426 22.0215H10.6396L8.81445 18.5557L6.98926 22.0215H5Z"
        fill="white"
      />
    </svg>
  );
};

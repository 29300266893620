import { FC } from 'react';
import { IconProps } from '.';

export const IconShapeChat: FC<IconProps> = ({ width = '52px', height = '52px' }) => {
  return (
    <svg
      className="group"
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34 19.9741H33V27.9637C33 28.513 32.55 28.9624 32 28.9624H20V29.9611C20 31.0597 20.9 31.9585 22 31.9585H32L36 35.9533V21.9715C36 20.8729 35.1 19.9741 34 19.9741ZM31 24.9676V17.9767C31 16.8781 30.1 15.9793 29 15.9793H18C16.9 15.9793 16 16.8781 16 17.9767V30.9598L20 26.965H29C30.1 26.965 31 26.0662 31 24.9676Z"
        fill="currentColor"
      />
    </svg>
  );
};

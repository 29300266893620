'use client';

import { ErrorPage } from '@unique/shared-library';
import { FC } from 'react';

interface SpaceNotFoundPageProps {
  basePath?: string;
}

export const SpaceNotFoundPage: FC<SpaceNotFoundPageProps> = ({ basePath }) => {
  return (
    <ErrorPage
      basePath={basePath}
      statusCode={404}
      title="Page Not Found"
      description="The space you're looking for was permanently deleted by one of its Admins. As a security measure, all the associated content is no longer accessible."
    />
  );
};

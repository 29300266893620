import { FC } from 'react';
import { IconProps } from '.';

export const IconHelpCenter: FC<IconProps> = ({ width = '20px', height = '20px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3796_2730)">
        <path
          d="M10 4.57764e-05C4.48 4.57764e-05 0 4.48005 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48005 15.52 4.57764e-05 10 4.57764e-05ZM17.46 7.12005L14.68 8.27005C14.17 6.91005 13.1 5.83005 11.73 5.33005L12.88 2.55005C14.98 3.35005 16.65 5.02005 17.46 7.12005ZM10 13C8.34 13 7 11.66 7 10C7 8.34005 8.34 7.00005 10 7.00005C11.66 7.00005 13 8.34005 13 10C13 11.66 11.66 13 10 13ZM7.13 2.54005L8.3 5.32005C6.92 5.82005 5.83 6.91005 5.32 8.29005L2.54 7.13005C3.35 5.02005 5.02 3.35005 7.13 2.54005ZM2.54 12.87L5.32 11.72C5.83 13.1 6.91 14.18 8.29 14.68L7.12 17.46C5.02 16.65 3.35 14.98 2.54 12.87ZM12.88 17.46L11.73 14.68C13.1 14.17 14.18 13.09 14.68 11.71L17.46 12.88C16.65 14.98 14.98 16.65 12.88 17.46Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3796_2730">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

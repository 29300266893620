import { IconArrowDiagonal } from '@unique/icons';
import cn from 'classnames';
import { FC } from 'react';

interface TagProps {
  label: string;
  onClick?: () => void;
}

export const Tag: FC<TagProps> = ({ label, onClick }) => {
  const clickable = typeof onClick === 'function';
  return (
    <button
      className={cn({
        'body-2 border-background-variant text-on-background-dimmed flex items-center gap-x-2 rounded-[8px] border px-2 py-[2px] text-left leading-[20px] transition':
          true,
        'pointer-events-none': !clickable,
        'hover:bg-primary-cta hover:text-on-primary': clickable,
      })}
      {...(clickable && { onClick })}
    >
      {label}
      <span className="flex h-[20px] shrink-0 items-center self-end">
        <IconArrowDiagonal width="12px" height="12px" />
      </span>
    </button>
  );
};

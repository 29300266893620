/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GraphqlFetcherArgs,
  Service,
  useClientSWR,
  useClientSWRMutation,
} from '@unique/next-commons/swr';
import { SWRMutationConfiguration } from 'swr/mutation';
import {
  SubmitNetPromoterScoreRatingDocument,
  SubmitNetPromoterScoreRatingMutation,
  SubmitNetPromoterScoreRatingMutationVariables,
  NetPromoterScoreTriggerCheckQuery,
  NetPromoterScoreTriggerCheckQueryVariables,
  NetPromoterScoreTriggerCheckDocument,
  IgnoreNetPromoterScoreRatingMutation,
  IgnoreNetPromoterScoreRatingMutationVariables,
  IgnoreNetPromoterScoreRatingDocument,
} from '../../@generated/graphql';

export function useNetPromoterScoreTriggerCheckQuery(
  variables?: NetPromoterScoreTriggerCheckQueryVariables,
) {
  return useClientSWR<NetPromoterScoreTriggerCheckQuery, NetPromoterScoreTriggerCheckQueryVariables>(
    getUseNetPromoterScoreTriggerCheckQueryQueryKey(variables),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
    Service.NODE_CHAT,
  );
}

export function useSubmitNetPromoterScoreMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      SubmitNetPromoterScoreRatingMutation,
      any,
      GraphqlFetcherArgs<any>,
      SubmitNetPromoterScoreRatingMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
  SubmitNetPromoterScoreRatingMutation,
  SubmitNetPromoterScoreRatingMutationVariables
  >(key, SubmitNetPromoterScoreRatingDocument, options, Service.NODE_CHAT);
}

export function getUseSubmitNetPromoterScoreRatingQueryKey(
  variables?: SubmitNetPromoterScoreRatingMutationVariables,
): GraphqlFetcherArgs<SubmitNetPromoterScoreRatingMutationVariables> {
  return { query: SubmitNetPromoterScoreRatingDocument, variables };
}

export function getUseNetPromoterScoreTriggerCheckQueryQueryKey(
  variables?: NetPromoterScoreTriggerCheckQueryVariables,
): GraphqlFetcherArgs<NetPromoterScoreTriggerCheckQueryVariables> {
  return { query: NetPromoterScoreTriggerCheckDocument, variables };
}

export function useIgnoreNetPromoterScoreMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      IgnoreNetPromoterScoreRatingMutation,
      any,
      GraphqlFetcherArgs<any>,
      IgnoreNetPromoterScoreRatingMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
  IgnoreNetPromoterScoreRatingMutation,
  IgnoreNetPromoterScoreRatingMutationVariables
  >(key, IgnoreNetPromoterScoreRatingDocument, options, Service.NODE_CHAT);
}

export function getUseIgnoreNetPromoterScoreRatingQueryKey(
  variables?: IgnoreNetPromoterScoreRatingMutationVariables,
): GraphqlFetcherArgs<IgnoreNetPromoterScoreRatingMutationVariables> {
  return { query: IgnoreNetPromoterScoreRatingDocument, variables };
}

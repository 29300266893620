import { FC } from 'react';
import { IconProps } from '.';

export const IconHtmlFile: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29.7739V2.22609C9 0.996653 9.90527 0 11.022 0H22.9011L32 10.0174V29.7739C32 31.0033 31.0947 32 29.978 32H11.022C9.90527 32 9 31.0033 9 29.7739Z"
        fill="#CAD6D9"
      />
      <path d="M23 7.77778V0L32 10H25C23.8954 10 23 9.00508 23 7.77778Z" fill="#92AAB1" />
      <path
        d="M0 13.5C0 12.6716 1.08198 12 2.41667 12H26.5833C27.918 12 29 12.6716 29 13.5V28.5C29 29.3284 27.918 30 26.5833 30H2.41667C1.08198 30 0 29.3284 0 28.5V13.5Z"
        fill="#FF8F2B"
      />
      <path
        d="M4 24.1582V17H5.18164V19.8174H7.50586V17H8.69238V24.1582H7.50586V21.0283H5.18164V24.1582H4Z"
        fill="white"
      />
      <path
        d="M11.2363 24.1582V18.2109H9.49316V17H14.1562V18.2109H12.418V24.1582H11.2363Z"
        fill="white"
      />
      <path
        d="M14.9082 24.1582V17H16.6807L17.7451 21.8828L18.7998 17H20.5771V24.1582H19.4785V18.5234L18.3115 24.1582H17.1689L16.0117 18.5234V24.1582H14.9082Z"
        fill="white"
      />
      <path d="M21.793 24.1582V17.0586H22.9746V22.9521H25.9238V24.1582H21.793Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9167 3.17263C15.1469 3.40279 15.1469 3.77597 14.9167 4.00614L12.9229 5.99995L14.9167 7.99376C15.1469 8.22393 15.1469 8.59711 14.9167 8.82727C14.6865 9.05744 14.3134 9.05744 14.0832 8.82727L11.6726 6.41671C11.4425 6.18654 11.4425 5.81336 11.6726 5.58319L14.0832 3.17263C14.3134 2.94246 14.6865 2.94246 14.9167 3.17263Z"
        fill="#FF8F2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2317 8.82737C18.0016 8.59721 18.0016 8.22403 18.2317 7.99386L20.2255 6.00005L18.2317 4.00624C18.0016 3.77607 18.0016 3.40289 18.2317 3.17273C18.4619 2.94256 18.8351 2.94256 19.0652 3.17273L21.4758 5.58329C21.706 5.81346 21.706 6.18664 21.4758 6.41681L19.0652 8.82737C18.8351 9.05754 18.4619 9.05754 18.2317 8.82737Z"
        fill="#FF8F2B"
      />
    </svg>
  );
};

const getInitialsBasedOnString = (text: string) => {
  return `${text.charAt(0)?.toUpperCase() ?? ''}${text.charAt(1)?.toUpperCase() ?? ''}`;
};

/**
 * Returns the initial letters of a user according to it's email and name
 */
export const getInitials = (name?: string, email?: string) => {
  if (!name) {
    if (!email) return 'AU'; // Anonymous User
    return getInitialsBasedOnString(email);
  }

  // eslint-disable-next-line no-useless-escape
  const parsedUserName = name.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').trim(); // Remove special characters

  if (parsedUserName?.length <= 1) {
    return parsedUserName?.toUpperCase();
  }

  const nameParts = parsedUserName.replace(' Guest', '').split(' ');

  if (nameParts.length === 1) {
    return getInitialsBasedOnString(nameParts[0]);
  }

  const initials = `${nameParts[0].charAt(0) ?? ''}${nameParts[1].charAt(0) ?? ''}`;
  return initials.toUpperCase();
};

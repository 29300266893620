export const IconViewFiles = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/View-files">
        <path
          id="Vector"
          d="M1 5C0.716667 5 0.479167 4.90417 0.2875 4.7125C0.0958333 4.52083 0 4.28333 0 4V2C0 1.71667 0.0958333 1.47917 0.2875 1.2875C0.479167 1.09583 0.716667 1 1 1H3C3.28333 1 3.52083 1.09583 3.7125 1.2875C3.90417 1.47917 4 1.71667 4 2V4C4 4.28333 3.90417 4.52083 3.7125 4.7125C3.52083 4.90417 3.28333 5 3 5H1ZM6 5C5.71667 5 5.47917 4.90417 5.2875 4.7125C5.09583 4.52083 5 4.28333 5 4V2C5 1.71667 5.09583 1.47917 5.2875 1.2875C5.47917 1.09583 5.71667 1 6 1H17C17.2833 1 17.5208 1.09583 17.7125 1.2875C17.9042 1.47917 18 1.71667 18 2V4C18 4.28333 17.9042 4.52083 17.7125 4.7125C17.5208 4.90417 17.2833 5 17 5H6ZM6 11C5.71667 11 5.47917 10.9042 5.2875 10.7125C5.09583 10.5208 5 10.2833 5 10V8C5 7.71667 5.09583 7.47917 5.2875 7.2875C5.47917 7.09583 5.71667 7 6 7H17C17.2833 7 17.5208 7.09583 17.7125 7.2875C17.9042 7.47917 18 7.71667 18 8V10C18 10.2833 17.9042 10.5208 17.7125 10.7125C17.5208 10.9042 17.2833 11 17 11H6ZM6 17C5.71667 17 5.47917 16.9042 5.2875 16.7125C5.09583 16.5208 5 16.2833 5 16V14C5 13.7167 5.09583 13.4792 5.2875 13.2875C5.47917 13.0958 5.71667 13 6 13H17C17.2833 13 17.5208 13.0958 17.7125 13.2875C17.9042 13.4792 18 13.7167 18 14V16C18 16.2833 17.9042 16.5208 17.7125 16.7125C17.5208 16.9042 17.2833 17 17 17H6ZM1 17C0.716667 17 0.479167 16.9042 0.2875 16.7125C0.0958333 16.5208 0 16.2833 0 16V14C0 13.7167 0.0958333 13.4792 0.2875 13.2875C0.479167 13.0958 0.716667 13 1 13H3C3.28333 13 3.52083 13.0958 3.7125 13.2875C3.90417 13.4792 4 13.7167 4 14V16C4 16.2833 3.90417 16.5208 3.7125 16.7125C3.52083 16.9042 3.28333 17 3 17H1ZM1 11C0.716667 11 0.479167 10.9042 0.2875 10.7125C0.0958333 10.5208 0 10.2833 0 10V8C0 7.71667 0.0958333 7.47917 0.2875 7.2875C0.479167 7.09583 0.716667 7 1 7H3C3.28333 7 3.52083 7.09583 3.7125 7.2875C3.90417 7.47917 4 7.71667 4 8V10C4 10.2833 3.90417 10.5208 3.7125 10.7125C3.52083 10.9042 3.28333 11 3 11H1Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconThumbsUp: FC<IconProps> = ({
  width = '14px',
  height = '13px',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12.4722H1.4C1.785 12.4722 2.1 12.1611 2.1 11.7809V5.55864C2.1 5.17839 1.785 4.86728 1.4 4.86728H0V12.4722ZM13.881 7.54975C13.958 7.37691 14 7.19025 14 6.99667V6.25C14 5.48951 13.37 4.86728 12.6 4.86728H8.75L9.394 1.65247C9.429 1.50037 9.408 1.33444 9.338 1.19617C9.177 0.885062 8.974 0.601605 8.722 0.352716L8.4 0.0277777L3.913 4.45938C3.647 4.7221 3.5 5.07469 3.5 5.44111V10.8614C3.5 11.7463 4.235 12.4722 5.138 12.4722H10.815C11.305 12.4722 11.767 12.2164 12.019 11.8016L13.881 7.54975Z"
        fill="currentColor"
      />
    </svg>
  );
};

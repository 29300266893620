import { ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { usePortal } from './helpers';

type PortalProps = {
  id: string;
  children: ReactNode;
  parent?: HTMLElement; // Automatic set element to direct parent position, if necessary
};

export const Portal = (props: PortalProps) => {
  const { id, children, parent } = props;
  const targetRenderEl = usePortal(id);

  /**
   * If the parent element is fixed inside the page (aka is fixed or has any
   * parent that is fixed), the portal container also needs to be fixed.
   * Otherwise, the element would not be correctly tied to it's parent when the user scrolls.
   */
  const isFixed = useMemo(() => {
    if (!parent) return false;
    let newParent = parent;

    do {
      if (getComputedStyle(newParent).position === 'fixed') return true;
      else if (!newParent.offsetParent) return false;
      newParent = newParent.offsetParent as HTMLElement;
    } while (newParent);

    return false;
  }, [parent]);

  const left =
    Math.round(parent?.getBoundingClientRect()?.left || 0) + Math.round(parent?.offsetWidth || 0);
  let top = Math.round(parent?.getBoundingClientRect()?.top || 0);

  if (!isFixed && typeof window !== 'undefined') {
    top = top + (window?.pageYOffset ?? 0); // If it's not fixed, the initial pos has to consider scrolling already
  }

  if (targetRenderEl) {
    return createPortal(
      parent ? (
        <div className={`${isFixed ? 'fixed' : 'absolute'} z-55`} style={{ left, top }}>
          {children}
        </div>
      ) : (
        children
      ),
      targetRenderEl,
    );
  } else {
    return <>{children}</>;
  }
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconTermsAndConditions: FC<IconProps> = ({ width = '20', height = '20' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M15.5556 8.88895C15.9333 8.88895 16.3 8.9334 16.6667 8.98895V3.63339L8.33333 6.10352e-05L0 3.63339V9.08895C0 14.1334 3.55555 18.8556 8.33333 20.0001C8.94444 19.8556 9.53333 19.6445 10.1111 19.389C9.34444 18.3001 8.88889 16.9778 8.88889 15.5556C8.88889 11.8778 11.8778 8.88895 15.5556 8.88895Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M15.5555 11.1107C13.0999 11.1107 11.1111 13.0995 11.1111 15.5551C11.1111 18.0107 13.0999 19.9995 15.5555 19.9995C18.0111 19.9995 19.9999 18.0107 19.9999 15.5551C19.9999 13.0995 18.0111 11.1107 15.5555 11.1107ZM15.5555 12.644C16.2444 12.644 16.7999 13.2107 16.7999 13.8884C16.7999 14.5662 16.2333 15.1329 15.5555 15.1329C14.8777 15.1329 14.3111 14.5662 14.3111 13.8884C14.3111 13.2107 14.8666 12.644 15.5555 12.644ZM15.5555 18.6107C14.5222 18.6107 13.6222 18.0995 13.0666 17.3107C13.1222 16.5107 14.7444 16.1107 15.5555 16.1107C16.3666 16.1107 17.9888 16.5107 18.0444 17.3107C17.4888 18.0995 16.5888 18.6107 15.5555 18.6107Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

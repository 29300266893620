'use client';
import { IconCloseSlim } from '@unique/icons';
import cn from 'classnames';
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from 'react';

type DrawerContextProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setComponent: (component: ReactNode) => void;
};

export const DrawerContext = createContext<DrawerContextProps>({
  isOpen: false,
  setIsOpen: () => {},
  setComponent: () => {},
});

export enum DRAWER_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

type DrawerProviderProps = {
  children: ReactNode;
  position?: DRAWER_POSITION;
  className?: string;
};

export const DRAWER_HEIGHT_CLOSE = 82;
export const DRAWER_HEIGHT_OPEN = '70vh';

export const DrawerProvider: FC<DrawerProviderProps> = ({
  children,
  position = DRAWER_POSITION.BOTTOM,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState<ReactNode | null>(null);

  return (
    <>
      <DrawerContext.Provider value={{ isOpen, setIsOpen, setComponent }}>
        {children}
        {/* Black background */}
        <div
          onClick={() => setIsOpen(false)}
          className={cn({
            'fixed inset-0 bg-[#000] transition-all': true,
            'opacity-60': isOpen,
            'pointer-events-none !opacity-0': !isOpen,
            '-z-50 mt-28': position === 'top',
            'z-50': position === 'bottom',
          })}
        ></div>

        {/* Drawer content */}
        <div
          className={cn({
            [`bg-background text-on-background-main text-on-surface fixed inset-x-0 flex h-[40vh] flex-col gap-8 overflow-hidden rounded-2xl p-[24px] text-sm transition-all sm:ml-[64px] ${className}`]:
              true,
            'opacity-100': isOpen,
            'border-background-variant top-[0] -z-50 mt-28 !rounded-t-none border-t':
              position === 'top',
            'shadow-footer bottom-[0] z-50 !rounded-b-none': position === 'bottom',
            'pointer-events-none -bottom-[40vh] opacity-0': !isOpen,
          })}
        >
          <button className="z-55 absolute right-4 top-4" onClick={() => setIsOpen(false)}>
            <IconCloseSlim height="18px" width="18px" />
          </button>
          <div className="bg-surface absolute inset-0 overflow-auto pt-1">{component}</div>
        </div>
      </DrawerContext.Provider>
    </>
  );
};

export default DrawerProvider;

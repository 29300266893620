import { IconCloseSlim } from '@unique/icons';
import cn from 'classnames';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { Spinner } from '..';
import { Portal } from './Portal';

export type ModalProps = {
  title?: string;
  children?: ReactNode;
  shouldShow?: boolean;
  icon?: ReactNode;
  classNames?: string;
  wrapperClassNames?: string;
  bodyClassNames?: string;
  isLoading?: boolean;
  handleClose?: () => void;
};

export const Modal: FC<ModalProps> = ({
  title,
  children,
  shouldShow = false, // Do not set to true per default or pages can't scroll. To test: open integrations, then switch to meeting types, won't be scrollable
  icon,
  classNames,
  wrapperClassNames = '',
  bodyClassNames = '',
  isLoading = false,
  handleClose,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Also make body scrollable again on unmount
    return () => {
      document.body.style.overflow = '';
    };
  }, [shouldShow]);

  useEffect(() => {
    // TODO: Understand why it was removed by Thea on master.
    // window &&
    //   window.setTimeout(() => {
    //     if (!modalRef.current) return;

    //     const form = modalRef.current.querySelector('form');
    //     if (!form) return;

    //     // Loop over form elements and find the first focusable element
    //     let firstFocusableEl: Element | undefined = undefined;
    //     for (const element of Array.from(form.elements)) {
    //       // skip elements that are not focusable
    //       if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
    //         const { type, readOnly, tagName } = element;
    //         if (
    //           ['radio', 'file', 'button'].includes(type) ||
    //           readOnly ||
    //           tagName.toLowerCase() === 'select'
    //         )
    //           continue;
    //       }
    //       if (element instanceof HTMLSelectElement) continue;
    //       firstFocusableEl = element;
    //       break;
    //     }

    //     if (firstFocusableEl && firstFocusableEl instanceof HTMLFormElement && shouldShow) {
    //       firstFocusableEl.focus();
    //     }
    //   }, 10);

    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        document.body.style.overflow = 'visible';
        handleClose && handleClose();
      }
    };

    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [shouldShow, handleClose]);

  const handleClickCloseModal = () => {
    handleClose && handleClose();
    document.body.style.overflow = 'visible';
  };

  return (
    <div role="dialog" key={title}>
      <>
        <Portal id="portal-modal-body">
          <div
            ref={modalRef}
            key={title}
            className={cn({
              'modal text-on-attention pointer-events-none fixed inset-0 z-[60] flex items-center justify-center overflow-y-auto overflow-x-hidden opacity-0 outline-none focus:outline-none':
                true,
              '!pointer-events-auto !opacity-100': shouldShow,
            })}
          >
            <div
              className={`hide-scrollbar sm:min-w-300 relative mx-auto max-h-[100%] w-auto min-w-full max-w-3xl overflow-y-visible shadow-xl ${wrapperClassNames}`}
            >
              {handleClose && (
                <button
                  data-testid="button-modal-close"
                  className="title-l absolute right-4 top-1 z-10 bg-transparent p-1 leading-none outline-none focus:outline-none"
                  onClick={handleClickCloseModal}
                >
                  <span className="text-on-background-main hover:text-on-control-main inline-flex items-center justify-center font-normal transition-colors">
                    <IconCloseSlim width="18" height="18" />
                  </span>
                </button>
              )}
              {/*content*/}
              <div
                className={`bg-background relative flex w-full flex-col rounded-2xl p-6 shadow-lg outline-none focus:outline-none ${
                  classNames ? classNames : ''
                }`}
              >
                {isLoading && (
                  <Spinner wrapperClasses="flex-1 flex items-center justify-center h-full" />
                )}
                {!isLoading && (
                  <>
                    {/*header*/}
                    <span
                      className={cn({
                        'm-auto': !!icon,
                        flex: true,
                      })}
                    >
                      {icon && <span className="mr-3 shrink-0">{icon}</span>}
                      {title && (
                        <div
                          data-testid="modal-title"
                          className="title-s text-on-background-main mb-6"
                        >
                          {title}
                        </div>
                      )}
                    </span>
                    {/*body*/}
                    <div
                      className={cn({
                        'relative flex-auto overflow-hidden': true,
                        [bodyClassNames]: true,
                      })}
                    >
                      {children}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={cn({
              'bg-secondary-variant pointer-events-none fixed inset-0 z-[55] opacity-0': true,
              '!pointer-events-auto !opacity-60': shouldShow,
            })}
          />
        </Portal>
      </>
    </div>
  );
};

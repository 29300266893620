/** @type {import('tailwindcss').Config} */
import sharedConfig from '@unique/tailwind-config';

module.exports = {
  ...sharedConfig,
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './app/**/*.{js,ts,jsx,tsx,mdx}',
    '../../packages/component-library/src/**/*.{js,ts,jsx,tsx}',
    '../../packages/shared-library/src/**/*.{js,ts,jsx,tsx}',
    '../../packages/shared-library/helpers/**/*.{js,ts,jsx,tsx}',
    '../../packages/icons/**/*.{js,ts,jsx,tsx}',
  ],
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/aspect-ratio'),
  ],
};

import { IconCloseSlim, IconEdit } from '@unique/icons';
import cn from 'classnames';
import { FC } from 'react';
import { ButtonSize, ButtonVariant } from '../enums/button';
import { ButtonIcon } from './ButtonIcon';

type SelectedItemProps = {
  icon?: JSX.Element;
  content: string | JSX.Element;
  value: string | number;
  handleEdit?: (value: number | string) => void;
  handleRemove?: (value: number | string) => void;
  className?: string;
  iconClassName?: string;
  customEditButton?: JSX.Element;
};

export const SelectedItem: FC<SelectedItemProps> = ({
  icon,
  content,
  value,
  handleEdit,
  handleRemove,
  className,
  iconClassName,
  customEditButton,
}) => {
  return (
    <div
      className={cn(
        {
          'subtitle-2 border-b-background bg-surface text-on-background-main flex min-h-[60px] items-start gap-x-3 rounded-lg border-b pb-4 pl-4 pr-2 pt-2':
            true,
        },
        className,
      )}
    >
      {icon && (
        <div
          className={cn(
            {
              'bg-background-variant text-on-background-dimmed flex h-6 w-6 items-center justify-center rounded-[10px]':
                true,
            },
            iconClassName,
          )}
        >
          {icon}
        </div>
      )}
      {content}
      <div className="ml-auto flex items-center gap-x-2.5">
        {!!customEditButton && customEditButton}
        {!customEditButton && handleEdit && (
          <ButtonIcon
            icon={<IconEdit width="18px" height="18px" />}
            variant={ButtonVariant.SECONDARY}
            onClick={() => handleEdit(value)}
            buttonSize={ButtonSize.SMALL}
          />
        )}
        {handleRemove && (
          <ButtonIcon
            icon={<IconCloseSlim width="18px" height="18px" />}
            variant={ButtonVariant.SECONDARY}
            onClick={() => handleRemove(value)}
            buttonSize={ButtonSize.SMALL}
          />
        )}
      </div>
    </div>
  );
};

'use client';
import { FC, useState } from 'react';
import { IconCaret } from '@unique/icons';

type ColumnItemSkeletonProps = {
  hasCaret?: boolean;
  showLabelPlaceholder?: boolean;
};

export const ColumnItemSkeleton: FC<ColumnItemSkeletonProps> = ({
  hasCaret = false,
  showLabelPlaceholder = false,
}) => {
  const [isSmallerSkeleton] = useState(Math.random() >= 0.4);
  return (
    <div
      className={
        'flex h-[56px] w-[100%] grow items-center gap-[16px] py-[12px] pl-[24px] pr-[16px]'
      }
    >
      <div>
        <div className="bg-background-variant h-[24px] w-[24px] animate-pulse rounded-[8px]"></div>
      </div>

      <div className={`w-full grow ${!!isSmallerSkeleton && 'mr-[48px]'}`}>
        <div className={'bg-background-variant h-[16px] w-full animate-pulse rounded-[8px]'}></div>
      </div>
      {!!showLabelPlaceholder && (
        <>
          <div className="min-w-[200px] grow pl-3">
            <div className="bg-background-variant h-[16px] w-full animate-pulse rounded-[8px]"></div>
          </div>
        </>
      )}
      {!!hasCaret && <div className="pr-[8px]">{<IconCaret />}</div>}
    </div>
  );
};

export const SUPPORTED_MIME_TYPES_EXCEL = {
  'application/ms-excel': ['.xlsx'],
  'application/msexcel': ['.xlsx'],
  'application/vnd.ms-excel': ['.xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

export const SUPPORTED_MIME_TYPES_IMAGE = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
};

export const SUPPORTED_MIME_TYPES = {
  'application/pdf': ['.pdf'],
  'application/x-pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': ['.dotx'],
  'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
  'application/vnd.ms-word.template.macroEnabled.12': ['.dotm'],
  'application/vnd.ms-powerpoint': ['.pot', '.ppa', '.pps', '.ppt', '.pwz'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.openxmlformats-officedocument.presentationml.template': ['.potx'],
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
  'text/html': ['.htm', '.html'],
  'text/markdown': ['.markdn', '.markdown', '.md', '.mdown'],
  // deprecated, but still out there. source: https://mimetype.io/text/x-markdown
  'text/x-markdown': ['.markdn', '.markdown', '.md', '.mdown'],
  'text/plain': ['.text', '.txt'],
  // EXCEL/CSV has still an issue but customers request it: https://unique-ch.atlassian.net/browse/UN-5002
  'text/csv': ['.csv'],
  ...SUPPORTED_MIME_TYPES_EXCEL,
};

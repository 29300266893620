import { FC, useRef, useState } from 'react';

import { useOutsideClick } from '../helpers/index';
import { MenuDropdown, UserMenuItem } from './MenuDropdown';
import { MenuHeader } from './MenuHeader';
import { IconHelp } from '@unique/icons';

interface HelpMenuProps {
  userName: string;
  userEmail: string;
  menuSections: UserMenuItem[][];
}

export const HelpMenu: FC<HelpMenuProps> = ({ userName, userEmail, menuSections }) => {
  const [showHelpDropdown, setShowHelpDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(dropdownRef, () => setShowHelpDropdown(false));

  return (
    <div
      onClick={() => setShowHelpDropdown(!showHelpDropdown)}
      className="hover:bg-surface relative h-full transition hidden sm:flex"
      ref={dropdownRef}
    >
      <MenuHeader
        showDropdown={showHelpDropdown}
        wrapper={
          <div className="subtitle-2 text-on-background-main flex items-center gap-x-2 uppercase lg:pr-2">
            <IconHelp />
            <span>Help</span>
          </div>
        }
      />
      <MenuDropdown
        showDropdown={showHelpDropdown}
        sections={menuSections}
        userName={userName}
        userEmail={userEmail}
      />
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconNewChat: FC<IconProps> = ({ width = '19px', height = '20px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9061 8.95013L9.75952 9.24865L10.0697 7.09767L16.3035 0.847116C16.4984 0.651723 16.8147 0.65115 17.0103 0.845835L18.154 1.98441C18.35 2.17944 18.3504 2.49645 18.1551 2.69207L11.9061 8.95013Z"
        fill="currentColor"
      />
      <path
        d="M0 18.7243V7.28711C0 5.63026 1.34315 4.28711 3 4.28711H9.67177C9.84566 4.28711 9.93671 4.4937 9.81939 4.62205L8.7921 5.74586C8.20243 6.39095 7.87544 7.23331 7.87544 8.1073V9.06116C7.87544 9.33731 7.65158 9.56116 7.37544 9.56116H5.50025C4.94797 9.56116 4.50025 10.0089 4.50025 10.5612V10.8112C4.50025 11.3634 4.94796 11.8112 5.50025 11.8112H9.80093C10.7292 11.8112 11.6194 11.4424 12.2757 10.7861L14.2899 8.77208C14.4151 8.64687 14.6292 8.73414 14.6313 8.9112L14.6702 12.2864C14.6894 13.9566 13.3408 15.3209 11.6704 15.3209H3.92636C3.76894 15.3209 3.62071 15.3951 3.52629 15.521L0.900065 19.0242C0.611811 19.4087 0 19.2049 0 18.7243Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { IconMenu } from '@unique/icons';
import {
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import cn from 'classnames';
import { ButtonIcon, ButtonSize, Spinner, SpinnerTheme, useOutsideClick } from '..';

import ReactDOM from 'react-dom';
import { LayoutContext } from '@unique/shared-library';

const PopupMenuPortal = ({ children }: { children: ReactNode }) => {
  return ReactDOM.createPortal(children, document.body);
};

export type PopupMenuItem = {
  label: string;
  icon?: ReactNode;
  isLoading?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

type PopupMenuProps = {
  menuItems: PopupMenuItem[];
  buttonClassesClosed?: string;
  buttonClassesOpened?: string;
};

export const PopupMenu: FC<PopupMenuProps> = ({
  menuItems,
  buttonClassesClosed = '',
  buttonClassesOpened = '',
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const menuRef = useRef(null);
  const { secondLevelNavContainerId } = useContext(LayoutContext);
  const popUpMenuWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollContainer = document.getElementById(secondLevelNavContainerId);
    if (!scrollContainer) return;
    scrollContainer.style.overflowY = showMenu ? 'hidden' : 'auto';
  }, [showMenu]);

  const toggleMenu: MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    setShowMenu(!showMenu);
    if (!popUpMenuWrapperRef?.current) return;
    const rect = popUpMenuWrapperRef.current.getBoundingClientRect();
    setPosition({
      top: rect.top,
      left: rect.right,
    });
  };

  const handleClickOutsideDropdown = () => {
    setShowMenu(false);
  };

  useOutsideClick(menuRef, handleClickOutsideDropdown);

  return (
    <div className="relative w-[30px]" ref={popUpMenuWrapperRef}>
      <ButtonIcon
        icon={<IconMenu />}
        buttonSize={ButtonSize.SMALL}
        onClick={toggleMenu}
        className={cn({
          [buttonClassesClosed]: !showMenu,
          [buttonClassesOpened]: showMenu,
        })}
      ></ButtonIcon>
      {showMenu && (
        <PopupMenuPortal>
          <div
            className="bg-secondary text-on-secondary absolute top-0 z-10 ml-5 min-w-[130px] rounded-[8px] py-2"
            ref={menuRef}
            style={{ top: position.top, left: position.left }}
          >
            {menuItems.map(({ label, icon, isLoading, onClick }) => {
              return (
                <button
                  key={label}
                  className="subtitle-2 text-on-secondary hover:bg-secondary-variant flex w-full items-center gap-x-2 px-4 py-2.5 transition disabled:opacity-60"
                  onClick={onClick}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner theme={SpinnerTheme.LIGHT} size={18} wrapperClasses="mt-1" />
                  ) : (
                    icon
                  )}
                  <span>{label}</span>
                </button>
              );
            })}
          </div>
        </PopupMenuPortal>
      )}
    </div>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconPuzzle: FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17.2C3 16.9333 3.0875 16.7083 3.2625 16.525C3.4375 16.3417 3.65 16.25 3.9 16.25C4.03333 16.25 4.1625 16.2708 4.2875 16.3125C4.4125 16.3542 4.53333 16.4167 4.65 16.5C4.86667 16.6333 5.08333 16.75 5.3 16.85C5.51667 16.95 5.75 17 6 17C6.55 17 7.02083 16.8042 7.4125 16.4125C7.80417 16.0208 8 15.55 8 15C8 14.45 7.80417 13.9792 7.4125 13.5875C7.02083 13.1958 6.55 13 6 13C5.75 13 5.50833 13.0417 5.275 13.125C5.04167 13.2083 4.83333 13.3333 4.65 13.5C4.55 13.5833 4.43333 13.6458 4.3 13.6875C4.16667 13.7292 4.03333 13.75 3.9 13.75C3.65 13.75 3.4375 13.6583 3.2625 13.475C3.0875 13.2917 3 13.0667 3 12.8V9C3 8.71667 3.09583 8.47917 3.2875 8.2875C3.47917 8.09583 3.71667 8 4 8H7.75C7.66667 7.75 7.60417 7.5 7.5625 7.25C7.52083 7 7.5 6.75 7.5 6.5C7.5 5.25 7.9375 4.1875 8.8125 3.3125C9.6875 2.4375 10.75 2 12 2C13.25 2 14.3125 2.4375 15.1875 3.3125C16.0625 4.1875 16.5 5.25 16.5 6.5C16.5 6.75 16.4792 7 16.4375 7.25C16.3958 7.5 16.3333 7.75 16.25 8H20C20.2833 8 20.5208 8.09583 20.7125 8.2875C20.9042 8.47917 21 8.71667 21 9V12.8C21 13.0833 20.9042 13.3208 20.7125 13.5125C20.5208 13.7042 20.2833 13.8 20 13.8C19.8667 13.8 19.75 13.7708 19.65 13.7125C19.55 13.6542 19.45 13.5833 19.35 13.5C19.1667 13.3333 18.9583 13.2083 18.725 13.125C18.4917 13.0417 18.25 13 18 13C17.45 13 16.9792 13.1958 16.5875 13.5875C16.1958 13.9792 16 14.45 16 15C16 15.55 16.1958 16.0208 16.5875 16.4125C16.9792 16.8042 17.45 17 18 17C18.25 17 18.4917 16.9583 18.725 16.875C18.9583 16.7917 19.1667 16.6667 19.35 16.5C19.4333 16.4167 19.5292 16.3458 19.6375 16.2875C19.7458 16.2292 19.8667 16.2 20 16.2C20.2833 16.2 20.5208 16.2958 20.7125 16.4875C20.9042 16.6792 21 16.9167 21 17.2V21C21 21.2833 20.9042 21.5208 20.7125 21.7125C20.5208 21.9042 20.2833 22 20 22H4C3.71667 22 3.47917 21.9042 3.2875 21.7125C3.09583 21.5208 3 21.2833 3 21V17.2Z"
        fill="currentColor"
      />
    </svg>
  );
};

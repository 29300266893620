'use client';

import { FC, ReactNode, createContext, useContext } from 'react';
import {
  AgreedToTermsAndConditionsMutation,
  LatestTermsAndConditionsQuery,
} from '../@generated/graphql';
import { TermsAndConditionsAcceptStatus } from '../enums/terms-and-conditions';
import {
  getUseLatestTermsAndConditionsQueryKey,
  useAgreedToTermsAndConditionsMutation,
  useCreateTermsAndConditionsMutation,
  useLatestTermsAndConditionsQuery,
} from '../hooks/swr/useTermsAndConditionsHooks';
import { LayoutContext } from '../src/DefaultLayout';
import { TermsAndConditions } from '../src/TermsAndConditions';

export const TermsAndConditionsContext = createContext({});

type TermsAndConditionsProviderProps = {
  children: ReactNode;
  allowEdit?: boolean;
};

export const TermsAndConditionsProvider: FC<TermsAndConditionsProviderProps> = ({
  children,
  allowEdit,
}) => {
  const { shouldShowTermsAndConditions, setShouldShowTermsAndConditions } =
    useContext(LayoutContext);

  const { data: latestTermsAndConditions } = useLatestTermsAndConditionsQuery();
  const { trigger: agreeToTermsAndConditions, isMutating } = useAgreedToTermsAndConditionsMutation(
    getUseLatestTermsAndConditionsQueryKey(),
  );

  const { trigger: createTermsAndConditions, isMutating: isCreatingTermsAndConditions } =
    useCreateTermsAndConditionsMutation(getUseLatestTermsAndConditionsQueryKey());

  const terms = latestTermsAndConditions?.latestTermsAndConditions;

  const handleAccept = async () => {
    agreeToTermsAndConditions(
      { termsAndConditionsId: terms?.id || '' },
      {
        revalidate: false,
        throwOnError: false,
        optimisticData: (currentData) => {
          // We must type-cast here, as SWR has no generic to correctly set the type of currentData
          const data = currentData as unknown as LatestTermsAndConditionsQuery;
          data.latestTermsAndConditions.acceptanceStatus = TermsAndConditionsAcceptStatus.ACCEPTED;

          return { ...currentData, data } as AgreedToTermsAndConditionsMutation;
        },
      },
    );
  };

  const handleCreateTermsAndConditions = async (termsAndConditions: string) => {
    createTermsAndConditions(
      { termsAndConditions },
      {
        revalidate: false,
        throwOnError: false,
        optimisticData: (currentData) => {
          // We must type-cast here, as SWR has no generic to correctly set the type of currentData
          const data = currentData as unknown as LatestTermsAndConditionsQuery;
          data.latestTermsAndConditions.text = termsAndConditions;

          return { ...currentData, data };
        },
      },
    );
  };

  const isAccepted = terms?.acceptanceStatus === TermsAndConditionsAcceptStatus.ACCEPTED;
  const displayTermsAndConditions =
    !!terms && !!terms.text?.length && (!isAccepted || shouldShowTermsAndConditions);

  return (
    <TermsAndConditionsContext.Provider value={{}}>
      {children}
      {terms && !!terms.text?.length && (
        <TermsAndConditions
          text={terms.text}
          date={new Date(terms.createdAt)}
          isLoadingAccept={isMutating}
          isLoadingCreate={isCreatingTermsAndConditions}
          handleAccept={handleAccept}
          createTermsAndConditions={handleCreateTermsAndConditions}
          shouldShow={displayTermsAndConditions}
          isAccepted={isAccepted}
          allowEdit={allowEdit}
          closeModal={() =>
            setShouldShowTermsAndConditions && setShouldShowTermsAndConditions(false)
          }
        />
      )}
    </TermsAndConditionsContext.Provider>
  );
};

'use client';
import { FC, useEffect } from 'react';
import { useToast } from '../hooks/useToast';
import { ToastVariant } from '../enums/toast-variant';
import { IconOffline } from '@unique/icons';

export const OfflineHandler: FC = () => {
  const { showToast } = useToast();
  const isLinkElement = (tagName?: string) => tagName === 'A' || tagName === 'BUTTON';
  const isFormElement = (tagName: string) => tagName === 'TEXTAREA' || tagName === 'INPUT';

  const displayOfflineToast = () =>
    showToast &&
    showToast({
      message: 'Offline. Your network is unavailable. Check your connection and retry.',
      icon: <IconOffline />,
      variant: ToastVariant.WARNING,
      duration: 8_000,
    });

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (navigator.onLine) return;
      if (event instanceof MouseEvent) {
        const target = event.target as HTMLElement;
        if (isLinkElement(target.tagName) || isLinkElement(target.parentElement?.tagName)) {
          event.preventDefault();
          event.stopPropagation();
          displayOfflineToast();
        }
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (navigator.onLine) return;
      if (event.key === 'Enter') {
        const target = event.target as HTMLElement;
        if (isFormElement(target.tagName)) {
          event.preventDefault();
          event.stopPropagation();
          displayOfflineToast();
        }
      }
    };

    const handleOnline = () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('keydown', handleKeyDown, true);
    };

    const handleOffline = () => {
      document.addEventListener('click', handleClick, true);
      document.addEventListener('keydown', handleKeyDown, true);
    };

    // Attach event listeners for online and offline events
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initialize the correct state based on the current online status
    if (!navigator.onLine) {
      handleOffline();
    }

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return null;
};

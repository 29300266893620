import { FC } from 'react';
import { IconProps } from '.';

export const IconTunings: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 15.0002C0 15.5502 0.45 16.0002 1 16.0002H6V14.0002H1C0.45 14.0002 0 14.4502 0 15.0002ZM0 3.00024C0 3.55024 0.45 4.00024 1 4.00024H10V2.00024H1C0.45 2.00024 0 2.45024 0 3.00024ZM10 17.0002V16.0002H17C17.55 16.0002 18 15.5502 18 15.0002C18 14.4502 17.55 14.0002 17 14.0002H10V13.0002C10 12.4502 9.55 12.0002 9 12.0002C8.45 12.0002 8 12.4502 8 13.0002V17.0002C8 17.5502 8.45 18.0002 9 18.0002C9.55 18.0002 10 17.5502 10 17.0002ZM4 7.00024V8.00024H1C0.45 8.00024 0 8.45024 0 9.00024C0 9.55024 0.45 10.0002 1 10.0002H4V11.0002C4 11.5502 4.45 12.0002 5 12.0002C5.55 12.0002 6 11.5502 6 11.0002V7.00024C6 6.45024 5.55 6.00024 5 6.00024C4.45 6.00024 4 6.45024 4 7.00024ZM18 9.00024C18 8.45024 17.55 8.00024 17 8.00024H8V10.0002H17C17.55 10.0002 18 9.55024 18 9.00024ZM13 6.00024C13.55 6.00024 14 5.55024 14 5.00024V4.00024H17C17.55 4.00024 18 3.55024 18 3.00024C18 2.45024 17.55 2.00024 17 2.00024H14V1.00024C14 0.450244 13.55 0.000244141 13 0.000244141C12.45 0.000244141 12 0.450244 12 1.00024V5.00024C12 5.55024 12.45 6.00024 13 6.00024Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconFolderCloud: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1.86607V17.1339C2 18.1645 2.74791 19 3.6705 19H24.3295C25.2521 19 26 18.1645 26 17.1339V4.12798C26 3.09737 25.2521 2.2619 24.3295 2.2619H12.8106C12.4163 2.2619 12.0347 2.10612 11.7333 1.8221L10.2667 0.439804C9.9653 0.155789 9.58373 0 9.18945 0H3.6705C2.74791 0 2 0.835467 2 1.86607Z"
        fill="currentColor"
        className="text-primary-cta"
      />
      <path
        d="M2 1.86607V17.1339C2 18.1645 2.74791 19 3.6705 19H24.3295C25.2521 19 26 18.1645 26 17.1339V4.12798C26 3.09737 25.2521 2.2619 24.3295 2.2619H12.8106C12.4163 2.2619 12.0347 2.10612 11.7333 1.8221L10.2667 0.439804C9.9653 0.155789 9.58373 0 9.18945 0H3.6705C2.74791 0 2 0.835467 2 1.86607Z"
        fill="black"
        className="opacity-20"
      />
      <g filter="url(#filter0_d_7360_20678)">
        <path
          d="M2 7C2 5.89543 2.89543 5 4 5H24C25.1046 5 26 5.89543 26 7V20C26 22.2091 24.2091 24 22 24H6C3.79086 24 2 22.2091 2 20V7Z"
          fill="currentColor"
          className="text-primary-cta"
        />
      </g>
      <g clipPath="url(#clip0_7360_20678)">
        <path
          d="M12.1016 11.8465L12.1017 11.8459L15.4605 13.8578L17.462 13.0155C17.8687 12.8397 18.3072 12.7496 18.7503 12.75C18.8241 12.75 18.8971 12.7533 18.9697 12.7582C18.7291 11.8199 18.2211 10.9717 17.5075 10.3167C16.7939 9.66163 15.9054 9.22795 14.95 9.06835C13.9946 8.90874 13.0134 9.03007 12.1256 9.4176C11.2379 9.80512 10.4818 10.4422 9.94922 11.2513C9.96633 11.2511 9.98318 11.25 10.0003 11.25C10.7427 11.249 11.4705 11.4556 12.1016 11.8465Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          d="M12.1014 11.8459L12.1012 11.8465C11.4701 11.4556 10.7423 11.249 10 11.25C9.98285 11.25 9.96597 11.2511 9.94888 11.2513C9.22233 11.2603 8.51197 11.4671 7.89413 11.8495C7.27629 12.2319 6.77432 12.7754 6.44219 13.4217C6.11005 14.0679 5.96028 14.7924 6.009 15.5174C6.05771 16.2424 6.30305 16.9404 6.71866 17.5364L9.68066 16.2899L10.9974 15.7358L13.9291 14.5021L15.4602 13.8578L12.1014 11.8459Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          d="M18.9691 12.7582C18.8965 12.7534 18.8236 12.75 18.7498 12.75C18.3067 12.7496 17.8682 12.8401 17.4615 13.0159L15.46 13.8578L16.0403 14.2054L17.9428 15.345L18.7729 15.8422L21.611 17.5422C21.8689 17.0635 22.0026 16.5277 21.9997 15.9839C21.9969 15.4401 21.8576 14.9058 21.5948 14.4297C21.3319 13.9537 20.9538 13.5512 20.4951 13.2592C20.0363 12.9672 19.5117 12.7949 18.9691 12.7582Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          d="M18.7732 15.8423L17.9431 15.3451L16.0407 14.2056L15.4603 13.8579L13.9292 14.5022L10.9975 15.7359L9.68075 16.29L6.71875 17.5365C7.08683 18.0657 7.57752 18.498 8.14893 18.7964C8.72033 19.0948 9.35546 19.2504 10.0001 19.2501H18.7501C19.3368 19.2503 19.9125 19.0916 20.4163 18.7909C20.9201 18.4902 21.333 18.0588 21.6114 17.5423L18.7732 15.8423Z"
          fill="currentColor"
          className="text-primary-cta"
        />
      </g>
      <g clipPath="url(#clip0_7360_20678)">
        <path
          d="M12.1016 11.8465L12.1017 11.8459L15.4605 13.8578L17.462 13.0155C17.8687 12.8397 18.3072 12.7496 18.7503 12.75C18.8241 12.75 18.8971 12.7533 18.9697 12.7582C18.7291 11.8199 18.2211 10.9717 17.5075 10.3167C16.7939 9.66163 15.9054 9.22795 14.95 9.06835C13.9946 8.90874 13.0134 9.03007 12.1256 9.4176C11.2379 9.80512 10.4818 10.4422 9.94922 11.2513C9.96633 11.2511 9.98318 11.25 10.0003 11.25C10.7427 11.249 11.4705 11.4556 12.1016 11.8465Z"
          fill="black"
          className="opacity-25"
        />
        <path
          d="M12.1014 11.8459L12.1012 11.8465C11.4701 11.4556 10.7423 11.249 10 11.25C9.98285 11.25 9.96597 11.2511 9.94888 11.2513C9.22233 11.2603 8.51197 11.4671 7.89413 11.8495C7.27629 12.2319 6.77432 12.7754 6.44219 13.4217C6.11005 14.0679 5.96028 14.7924 6.009 15.5174C6.05771 16.2424 6.30305 16.9404 6.71866 17.5364L9.68066 16.2899L10.9974 15.7358L13.9291 14.5021L15.4602 13.8578L12.1014 11.8459Z"
          fill="black"
          className="opacity-25"
        />
        <path
          d="M18.9691 12.7582C18.8965 12.7534 18.8236 12.75 18.7498 12.75C18.3067 12.7496 17.8682 12.8401 17.4615 13.0159L15.46 13.8578L16.0403 14.2054L17.9428 15.345L18.7729 15.8422L21.611 17.5422C21.8689 17.0635 22.0026 16.5277 21.9997 15.9839C21.9969 15.4401 21.8576 14.9058 21.5948 14.4297C21.3319 13.9537 20.9538 13.5512 20.4951 13.2592C20.0363 12.9672 19.5117 12.7949 18.9691 12.7582Z"
          fill="black"
          className="opacity-25"
        />
        <path
          d="M18.7732 15.8423L17.9431 15.3451L16.0407 14.2056L15.4603 13.8579L13.9292 14.5022L10.9975 15.7359L9.68075 16.29L6.71875 17.5365C7.08683 18.0657 7.57752 18.498 8.14893 18.7964C8.72033 19.0948 9.35546 19.2504 10.0001 19.2501H18.7501C19.3368 19.2503 19.9125 19.0916 20.4163 18.7909C20.9201 18.4902 21.333 18.0588 21.6114 17.5423L18.7732 15.8423Z"
          fill="black"
          className="opacity-25"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7360_20678"
          x="0"
          y="5"
          width="28"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7360_20678" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7360_20678"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7360_20678">
          <rect width="16" height="10.25" fill="white" transform="translate(6 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { IconProps } from '.';
import { FC } from 'react';

export const IconArrowDiagonal: FC<IconProps> = ({ width = '14px', height = '14px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0789 10.7341L1.60892 0.264151C1.43282 0.0880499 1.20869 0 0.936535 0C0.66438 0 0.440252 0.0880499 0.264151 0.264151C0.0880503 0.440251 0 0.664379 0 0.936535C0 1.20869 0.0880503 1.43282 0.264151 1.60892L10.7341 12.0789H5.35506C5.0829 12.0789 4.85477 12.171 4.67067 12.3551C4.48656 12.5392 4.39451 12.7673 4.39451 13.0395C4.39451 13.3116 4.48656 13.5397 4.67067 13.7238C4.85477 13.9079 5.0829 14 5.35506 14H13.0395C13.3116 14 13.5397 13.9079 13.7238 13.7238C13.9079 13.5397 14 13.3116 14 13.0395V5.35506C14 5.0829 13.9079 4.85477 13.7238 4.67067C13.5397 4.48656 13.3116 4.39451 13.0395 4.39451C12.7673 4.39451 12.5392 4.48656 12.3551 4.67067C12.171 4.85477 12.0789 5.0829 12.0789 5.35506V10.7341Z"
        fill="currentColor"
      />
    </svg>
  );
};

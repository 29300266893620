import { FC } from 'react';
import { IconProps } from '.';

export const IconFolder: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1763">
        <path
          id="Vector 232"
          d="M2 1.65517V15.1975C2 16.1116 2.74105 16.8527 3.65517 16.8527H24.1247C25.0388 16.8527 25.7798 16.1116 25.7798 15.1975V3.66144C25.7798 2.74732 25.0388 2.00627 24.1247 2.00627H12.7114C12.3207 2.00627 11.9426 1.86809 11.6441 1.61617L10.1908 0.390098C9.89223 0.138182 9.51416 0 9.12349 0H3.65517C2.74105 0 2 0.741045 2 1.65517Z"
          fill="currentColor"
          className="text-primary-cta"
        />
        <path
          id="Vector 232"
          d="M2 1.65517V15.1975C2 16.1116 2.74105 16.8527 3.65517 16.8527H24.1247C25.0388 16.8527 25.7798 16.1116 25.7798 15.1975V3.66144C25.7798 2.74732 25.0388 2.00627 24.1247 2.00627H12.7114C12.3207 2.00627 11.9426 1.86809 11.6441 1.61617L10.1908 0.390098C9.89223 0.138182 9.51416 0 9.12349 0H3.65517C2.74105 0 2 0.741045 2 1.65517Z"
          fill="black"
          className="opacity-25"
        />
        <g id="Rectangle 944" filter="url(#filter0_d_4513_533)">
          <path
            d="M2 6.13476C2 5.22063 2.74105 4.47958 3.65517 4.47958H24.1247C25.0388 4.47958 25.7798 5.22063 25.7798 6.13476V19.2758C25.7798 20.1899 25.0388 20.931 24.1247 20.931H3.65517C2.74105 20.931 2 20.1899 2 19.2758V6.13476Z"
            fill="currentColor"
            className="text-primary-cta"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4513_533"
          x="0"
          y="4.47958"
          width="27.7798"
          height="20.4514"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4513_533" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4513_533"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconCaret: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.53151 13.5856C5.20806 13.3269 5.15562 12.8549 5.41438 12.5315L9.03956 7.99997L5.41438 3.46849C5.15562 3.14505 5.20806 2.67308 5.53151 2.41432C5.85495 2.15556 6.32692 2.208 6.58568 2.53145L10.5857 7.53145C10.8048 7.80536 10.8048 8.19458 10.5857 8.46849L6.58568 13.4685C6.32692 13.7919 5.85495 13.8444 5.53151 13.5856Z"
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconSearch: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.572266 5.7793C0.572266 6.53125 0.713542 7.23763 0.996094 7.89844C1.27865 8.55469 1.67057 9.13346 2.17188 9.63477C2.67318 10.1361 3.25195 10.528 3.9082 10.8105C4.56901 11.0931 5.27539 11.2344 6.02734 11.2344C6.61979 11.2344 7.1849 11.1432 7.72266 10.9609C8.26042 10.7786 8.7526 10.528 9.19922 10.209L12.5625 13.5791C12.6445 13.6566 12.7334 13.7135 12.8291 13.75C12.9294 13.791 13.0342 13.8115 13.1436 13.8115C13.2985 13.8115 13.4352 13.7751 13.5537 13.7021C13.6722 13.6338 13.7633 13.5381 13.8271 13.415C13.8955 13.292 13.9297 13.1553 13.9297 13.0049C13.9297 12.8955 13.9092 12.793 13.8682 12.6973C13.8317 12.6016 13.777 12.5173 13.7041 12.4443L10.3613 9.08105C10.7122 8.62533 10.9857 8.11719 11.1816 7.55664C11.3822 6.99609 11.4824 6.40365 11.4824 5.7793C11.4824 5.02734 11.3411 4.32324 11.0586 3.66699C10.776 3.00618 10.3841 2.42513 9.88281 1.92383C9.38151 1.42253 8.80046 1.0306 8.13965 0.748047C7.4834 0.465495 6.7793 0.324219 6.02734 0.324219C5.27539 0.324219 4.56901 0.465495 3.9082 0.748047C3.25195 1.0306 2.67318 1.42253 2.17188 1.92383C1.67057 2.42513 1.27865 3.00618 0.996094 3.66699C0.713542 4.32324 0.572266 5.02734 0.572266 5.7793ZM1.74121 5.7793C1.74121 5.18685 1.85059 4.63314 2.06934 4.11816C2.29264 3.59863 2.60026 3.1429 2.99219 2.75098C3.38867 2.35449 3.8444 2.04688 4.35938 1.82812C4.87891 1.60482 5.4349 1.49316 6.02734 1.49316C6.61979 1.49316 7.1735 1.60482 7.68848 1.82812C8.20801 2.04688 8.66374 2.35449 9.05566 2.75098C9.44759 3.1429 9.75521 3.59863 9.97852 4.11816C10.2018 4.63314 10.3135 5.18685 10.3135 5.7793C10.3135 6.37174 10.2018 6.92773 9.97852 7.44727C9.75521 7.96224 9.44759 8.41569 9.05566 8.80762C8.66374 9.19954 8.20801 9.50716 7.68848 9.73047C7.1735 9.95378 6.61979 10.0654 6.02734 10.0654C5.4349 10.0654 4.87891 9.95378 4.35938 9.73047C3.8444 9.50716 3.38867 9.19954 2.99219 8.80762C2.60026 8.41569 2.29264 7.96224 2.06934 7.44727C1.85059 6.92773 1.74121 6.37174 1.74121 5.7793Z"
        fill="currentColor"
      />
    </svg>
  );
};
